import React from "react";
import { Grid } from "semantic-ui-react";

import Layout from "../../../components/Front/Layout";

const CGV = () => {
  window.scrollTo(0, 0);
  return (
    <Layout
      mainPane={
        <Grid stackable container doubling>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h1>Politique de protection des données personnelles</h1>
            </Grid.Column>
          </Grid.Row>
          {process.env.REACT_APP_THEME === "ARAIR" ? (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  La politique de protection des données personnelles de l'outil de commande MAD est
                  disponible sur demande à l'adresse{" "}
                  <a
                    href="mailto:mad@arair.fr?subject=Demande d'accès à la politique de protection des données personnelles"
                    title="Politique de protection des données personnelles"
                  >
                    mad@arair.fr
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row columns={1}>
              <Grid.Column>
                <h2>A. Définitions</h2>
                <p>
                  Les parties s'engagent à respecter les règles applicables en matière de traitement
                  des données à caractère personnel, y compris :
                </p>
                <ul>
                  <li>
                    (i) La Loi Informatique et Libertés du 6 janvier 1978 telle qu’amendée, la
                    directive 2002/58/CE du Parlement européen et du Conseil du 12 Juillet 2002, le
                    règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016
                    relatif à la protection des personnes physiques à l'égard du traitement des
                    données à caractère personnel et à la libre circulation de ces données
                    ("Règlement Général sur la Protection des Données"), et
                  </li>
                  <li>
                    (ii) Toute autre future législation applicable qui pourrait les compléter ou les
                    remplacer. (Ci-après ensemble "Textes en vigueur sur la protection des données")
                  </li>
                </ul>
                <p>(Ci-après ensemble "Textes en vigueur sur la protection des données").</p>
                <p>
                  Les termes utilisés dans cette politique ont le sens indiqué dans les Textes en
                  vigueur sur la protection des données, et notamment, dans le Règlement Général sur
                  la Protection des Données.
                </p>
                <h2>B. Protection des données personnelles</h2>
                <h3>.1 Dispositions générales relatives aux Données</h3>
                <p>
                  PHARMA DOM sous son nom de marque ARAIR Assistance (ci-après “la Société ”)
                  respecte et protège la vie privée de l’Utilisateur et prend toutes les précautions
                  nécessaires pour assurer la sécurité et la confidentialité de ses données
                  personnelles.
                </p>
                <p>
                  La collecte des données à caractère personnel, à l’occasion de l’ouverture d’un
                  Compte Structure et/ou d’un Compte Associé sur l’Application, est nécessitée par
                  l’accès à l’Application.
                </p>
                <p>
                  A cet effet, la Société collecte, traite et stocke certaines informations
                  nominatives afin de permettre la prise en charge et le suivi des Commandes
                  réalisées sur l’Application. La Société peut être amenée à divulguer ces Données
                  afin de répondre aux exigences légales, mettre en œuvre les CGU et répondre à
                  d’éventuelles réclamations de tiers. Dans tous les cas, ces informations seront
                  divulguées conformément aux lois et réglementations applicables
                </p>
                <p>La Société pourra également partager ces informations avec :</p>
                <ul>
                  <li>
                    un prestataire tiers mettant en œuvre des services pour le compte de la Société
                    (comme la gestion et la livraison des commandes de produits, de gaz ou de
                    fourniture de prestations de services, ou encore la réalisation d’une enquête en
                    ligne, par exemple) ;
                  </li>
                  <li>des sociétés tierces dans le cas d’opérations de fusion.</li>
                </ul>
                <p>
                  Ces informations ne seront pas vendues à une tierce partie à des fins commerciales
                  ou de prospection.
                </p>
                <h3>.2 Utilisation des Données </h3>
                <h4>Section 1 - Utilisation des Données de l’Utilisateur</h4>
                <p>
                  Les Données des Utilisateurs sont traitées par la Société sur la base légale de
                  l’exécution du contrat entre la Société et l’établissement. La Société agit en
                  qualité de responsable de traitement et transmet ces Données à des prestataires de
                  services selon la finalité de traitement ayant justifié la collecte des données
                  personnelles de l’Utilisateur et pendant la durée de conservation indiquée
                  ci-dessous
                </p>
                <table className="rgpdTable">
                  <thead>
                    <tr>
                      <td>Moment de la collecte des Données</td>
                      <td>Catégorie de Données</td>
                      <td>Finalité de traitement des Données</td>
                      <td>Durée de conservation des Données</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Création d’un Compte</td>
                      <td>
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Adresse mail professionnelle</li>
                          <li>Numéro de téléphone professionnel</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Gestion du Compte</li>
                          <li>
                            Utilisation des fonctionnalités de l’Application par l’Utilisateur,
                            comprenant notamment la gestion des commandes réalisées par
                            l’Utilisateur sur l'Application.
                          </li>
                        </ul>
                      </td>
                      <td>
                        Les Données sont conservées toute la durée de l’existence du Compte, jusqu’à
                        cinq (5) ans à compter de la clôture du Compte
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <p>
                  La nature optionnelle ou obligatoire des Données demandées sera indiquée lors de
                  la collecte desdites Données. Les Données obligatoires sont nom, prénom, adresse
                  e-mail, et nom de l'établissement.
                </p>
                <p>
                  A titre complémentaire, les Utilisateurs acceptent que la Société puisse utiliser
                  leurs Données aux fins suivantes :
                </p>
                <ul>
                  <li>
                    toute opération relative à la gestion des comptes des Utilisateurs concernant
                    les commandes, les livraisons, les réclamations et le suivi de la relation
                    client telle que la réalisation d'enquêtes de satisfaction. La décision de
                    répondre à une enquête revient entièrement à chaque Utilisateur. La Société
                    utilise les informations collectées grâce à ces enquêtes pour améliorer ses
                    services.
                  </li>
                  <li>pour les services ou le support demandés par l’Utilisateur ;</li>
                  <li>
                    pour contacter ponctuellement l’Utilisateur si ce dernier rencontre une
                    difficulté dans la navigation sur l’Application ;
                  </li>
                  <li>
                    pour personnaliser, mesurer et améliorer les services et contenus accessibles
                    depuis l’Application ;
                  </li>
                  <li>
                    pour vérifier l’exactitude des informations et les vérifier avec des tierces
                    parties ;
                  </li>
                  <li>pour empêcher des activités que la Société juge illicites ; et</li>
                  <li>pour la mise en œuvre des CGU.</li>
                </ul>
                <p>
                  Conformément aux Textes en vigueur sur la protection des données, les Utilisateurs
                  disposent du droit d’accès, de rectification, de modification, d’effacement et de
                  limitation sur leurs Données sous le lien suivant :
                  <a
                    href="https://contactprivacy.airliquide.com/fr"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://contactprivacy.airliquide.com/fr
                  </a>
                  .
                </p>
                <p>
                  Si les Utilisateurs estiment, après avoir contacté la Société, que leurs droits «
                  Informatique et Libertés » ne sont pas respectés, ils peuvent adresser une
                  réclamation à la CNIL.
                </p>
                <h4>
                  Section 2 - Utilisation des Données de patient et/ou personne de l’entourage
                </h4>
                <p>
                  Par patient (ci-après le ou les “Patient(s)”), on entend l’utilisateur final du
                  Matériel. Dans le cadre de la réalisation des Prestations par la Société,
                  l'Utilisateur peut, dans certaines situations, être amené à transmettre à la
                  Société des Données relatives à certains Patients. Les Données des Patients sont
                  des champs facultatifs saisis à la discrétion de l’Utilisateur dans le cadre de
                  l’utilisation de l’Application. Les Données des Patients sont traitées par la
                  Société, en sa qualité de sous-traitant, et transmises à des prestataires de
                  services selon la finalité de traitement ayant justifié la collecte des données
                  personnelles des Patients et pendant la durée de conservation indiquée ci-dessous
                  :
                </p>
                <table className="rgpdTable">
                  <thead>
                    <tr>
                      <td>Moment de la collecte des Données</td>
                      <td>Catégorie de Données</td>
                      <td>Finalité de traitement des Données</td>
                      <td>Durée de conservation des Données</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Demande de devis</td>
                      <td>
                        Patient:
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Taille</li>
                          <li>Poids</li>
                          <li>Pathologie</li>
                        </ul>
                      </td>
                      <td>Permettre l’établissement du devis</td>
                      <td>Les Données sont conservées pendant la durée de validité du devis.</td>
                    </tr>
                    <tr>
                      <td>Commande de Matériel</td>
                      <td>
                        Patient:
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Adresse postale</li>
                          <li>Numéro de téléphone</li>
                          <li>Numéro de chambre</li>
                          <li>Taille</li>
                          <li>Poids</li>
                          <li>Pathologie</li>
                          <li>Risque infectieux</li>
                        </ul>
                        Personne de l’entourage:
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Numéro de téléphone</li>
                        </ul>
                      </td>
                      <td>Permettre la livraison de la commande</td>
                      <td>
                        Les Données sont conservées jusqu’à 18 mois à compter de la fin de cette
                        commande
                      </td>
                    </tr>
                    <tr>
                      <td>Demande de dépannage</td>
                      <td>
                        Patient:
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Adresse postale</li>
                          <li>Numéro de chambre</li>
                          <li>Taille</li>
                          <li>Poids</li>
                          <li>Pathologie</li>
                          <li>Risque infectieux</li>
                        </ul>
                        Personne de l’entourage:
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Numéro de téléphone</li>
                        </ul>
                      </td>
                      <td>Réaliser l’opération de maintenance</td>
                      <td>
                        Les Données sont conservées jusqu’à 18 mois à compter de la fin de
                        l’opération de dépannage
                      </td>
                    </tr>
                    <tr>
                      <td>Demande de reprise de Matériel</td>
                      <td>
                        Patient:
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Adresse postale</li>
                          <li>Numéro de téléphone</li>
                          <li>Numéro de chambre</li>
                          <li>Risque infectieux</li>
                        </ul>
                        Personne de l’entourage:
                        <ul>
                          <li>Nom</li>
                          <li>Prénom</li>
                          <li>Numéro de téléphone</li>
                        </ul>
                      </td>
                      <td>Permettre la reprise du Matériel</td>
                      <td>
                        Les Données sont conservées jusqu'à 18 mois à compter de la date de reprise
                        du Matériel.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <p>
                  Le but de ces clauses est de définir les conditions dans lesquelles la Société
                  s’engage à réaliser, pour le compte de l’Utilisateur, les opérations de
                  traitements de données à caractère personnel définies ci-dessous, qui sont
                  nécessaires pour la fourniture de la prestation et des services associés par la
                  Société.
                </p>
                <ul>
                  <li>
                    <strong>
                      Transferts des données personnelles en dehors de l’Espace Economique Européen
                      (EEE)
                    </strong>
                  </li>
                </ul>
                <p>
                  Les données personnelles transmises par l’Utilisateur peuvent faire l’objet de
                  traitement dans des pays en dehors de l’espace économique européen pour les
                  finalités définies ci-dessus:
                </p>
                <ul>
                  <li>
                    soit dans un pays assurant un niveau de protection adéquat pour lequel la
                    Commission européenne a rendu une décision d’adéquation, reconnaissant à ce pays
                    un niveau de protection des données équivalent à celui prévu par la
                    Réglementation de l’EEE
                  </li>
                  <li>
                    soit dans un pays ne bénéficiant pas d’une décision d’adéquation de la
                    Commission européenne. Dans une telle situation, la Société veille à mettre en
                    place des garanties appropriées au sens de la réglementation applicable en
                    encadrant notamment ce ou ces transferts par des clauses contractuelles types
                    approuvées par la Commission Européenne. Pour obtenir la référence ou copie de
                    ces textes ou savoir comment y accéder, l’Utilisateur peut adresser une demande
                    écrite selon les coordonnées postales définies ci-dessous.
                  </li>
                </ul>
                <h5>Engagements</h5>
                <p>La Société s'engage à :</p>
                <ul>
                  <li>
                    traiter les Données uniquement pour la ou les seule(s) finalité(s) qui fait/font
                    l’objet de la sous-traitance ;
                  </li>
                  <li>
                    traiter les Données conformément aux instructions documentées de l’Utilisateur
                    définies dans la présente politique. Si la Société considère qu’une instruction
                    constitue une violation du Règlement Général sur la Protection des Données ou de
                    toute autre disposition du droit de l’Union ou du droit des Etats membres
                    relative à la protection des données, elle en informe immédiatement
                    l'Utilisateur ;
                  </li>
                  <li>
                    garantir la confidentialité des Données traitées dans le cadre de la présente
                    politique ;
                  </li>
                  <li>
                    à mettre en œuvre les mesures techniques et organisationnelles appropriées afin
                    de garantir un niveau de sécurité adapté ;
                  </li>
                  <li>
                    veiller à ce que les personnes autorisées à traiter les Données en vertu de la
                    présente politique:
                    <ul>
                      <li>
                        s’engagent à respecter la confidentialité ou soient soumises à une
                        obligation légale appropriée de confidentialité ;
                      </li>
                      <li>
                        reçoivent la formation nécessaire en matière de protection des données à
                        caractère personnel ;
                      </li>
                      <li>
                        prennent en compte, s’agissant de ses outils, produits, applications ou
                        services, les principes de protection des données dès la conception et de
                        protection des données par défaut.
                      </li>
                    </ul>
                  </li>
                  <li>
                    à détruire toutes les Données au terme de la durée de conservation définie
                    ci-dessus ;
                  </li>
                  <li>
                    à notifier à l’Utilisateur toute violation de Données dans les meilleurs délais
                    après en avoir pris connaissance. Cette notification est accompagnée de toute
                    documentation utile afin de permettre à l’Utilisateur, si nécessaire, de
                    notifier cette violation à l’autorité de contrôle compétente ;
                  </li>
                  <li>
                    à aider l'Utilisateur pour la réalisation d’analyses d’impact relative à la
                    protection des données ;
                  </li>
                  <li>
                    à aider l'Utilisateur pour la réalisation de la consultation préalable de
                    l’autorité de contrôle ;
                  </li>
                  <li>
                    à communiquer à l’Utilisateur le nom et les coordonnées de son délégué à la
                    protection des données, s’il en a désigné un conformément à l’article 37 du
                    règlement européen sur la protection des données :
                    <p>
                      Déléguée à la Protection des données
                      <br />
                      75, quai d’Orsay - 75007 Paris.
                      <br />
                      <a
                        href="https://contactprivacy.airliquide.com/fr"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        https://contactprivacy.airliquide.com/fr
                      </a>
                    </p>
                  </li>
                  <li>
                    à mettre à la disposition de l’Utilisateur la documentation nécessaire pour
                    démontrer le respect de toutes ses obligations et pour permettre la réalisation
                    d'audits, y compris des inspections, par l'Utilisateur ou un autre auditeur
                    qu'il a mandaté, et contribuer à ces audits.
                  </li>
                </ul>
                <p>
                  La Société peut faire appel à un autre sous-traitant (ci-après, « le sous-traitant
                  ultérieur ») pour mener des activités de traitement spécifiques. Dans ce cas, il
                  informe préalablement et par écrit l'Utilisateur de tout changement envisagé
                  concernant l’ajout ou le remplacement d’autres sous-traitants. Cette information
                  doit indiquer clairement les activités de traitement sous-traitées, l’identité et
                  les coordonnées du sous-traitant et les dates du contrat de sous-traitance.
                  L'Utilisateur dispose d’un délai maximum de quinze (15) jours à compter de la date
                  de réception de cette information pour présenter ses objections. Cette
                  sous-traitance ne peut être effectuée que si l'Utilisateur n'a pas émis
                  d'objection pendant le délai convenu. Le sous-traitant ultérieur est tenu de
                  respecter les obligations du présent contrat pour le compte et selon les
                  instructions de l’Utilisateur. Il appartient à la Société de s’assurer que le
                  sous-traitant ultérieur présente les mêmes garanties suffisantes quant à la mise
                  en œuvre de mesures techniques et organisationnelles appropriées de manière à ce
                  que le traitement réponde aux exigences du Règlement Général sur la Protection des
                  Données. Si le sous-traitant ultérieur ne remplit pas ses obligations en matière
                  de protection des données, la Société demeure pleinement responsable devant
                  l'Utilisateur de l'exécution par l’autre sous-traitant de ses obligations.
                </p>
                <table className="rgpdTable">
                  <thead>
                    <tr>
                      <td>sous-traitant ultérieur</td>
                      <td>activité sous traitée</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ALEHOS Services</td>
                      <td>facturation</td>
                    </tr>
                    <tr>
                      <td>ADS</td>
                      <td>éditeur informatique pour mises à jour du logiciel</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2>C. Cookies</h2>
                <p>
                  Un "cookie" est une suite d'informations, généralement de petite taille et
                  identifié par un nom, qui peut être transmis au navigateur par un site web sur
                  lequel l’Utilisateur se connecte. Le navigateur web le conservera pendant une
                  certaine durée, et le renverra au serveur web chaque fois que l’Utilisateur s’y
                  reconnectera. Les cookies ont de multiples usages : ils peuvent servir à mémoriser
                  l’identifiant client auprès d'un site marchand, le contenu courant du panier
                  d'achat, un identifiant permettant de tracer la navigation pour des finalités
                  statistiques ou publicitaires, etc.
                </p>
                <p>
                  La Société n’utilise que des cookies d’identifiant de session sur l’Application
                  pour améliorer ses services.
                </p>
                <p>
                  Ces cookies permettent à l’Application de fonctionner de manière optimale.
                  L’Utilisateur peut s’y opposer et les supprimer en utilisant les paramètres de son
                  navigateur. Cependant son expérience utilisateur risque d’être dégradée.
                </p>
                <table className="rgpdTable">
                  <thead>
                    <tr>
                      <td>Nom du cookie</td>
                      <td>Finalité</td>
                      <td>Durée de conservation</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>jwtToken</td>
                      <td>Permet de pouvoir reconnecter automatiquement l’utilisateur</td>
                      <td>6 heures</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2>D. Hameçonnage ou faux e-mails à l’Utilisateur</h2>
                <p>
                  Dans le cas où les Utilisateurs recevraient un e-mail non sollicité prétendant
                  provenir d’la Société et demandant des informations personnelles (telles que carte
                  de crédit, identifiant ou mot de passe) ou demandant de vérifier ou de confirmer
                  son Compte ou d’autres informations personnelles en cliquant sur un lien, cet
                  e-mail a probablement été envoyé par un individu tentant d’obtenir illégalement
                  des informations, parfois appelé "hameçonneur" ou "usurpateur".
                </p>
                <p>
                  La Société ne demande pas ce type d'information par e-mail. L’Utilisateur est
                  invité à ne pas fournir les informations requises et à ne pas cliquer sur le lien
                  proposé.
                </p>
                <br />
                <p>Date de mise à jour : 1er avril 2023</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      }
    />
  );
};
export default CGV;
