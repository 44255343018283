import React from "react";
import styled from "styled-components";

import { Grid, Card } from "semantic-ui-react";
import { Link } from "react-router-dom";

const logo = require(`../../../../ui/assets/img/${process.env.REACT_APP_LOGO}`).default;
const logoTheme =
  require(`../../../../ui/assets/img/${process.env.REACT_APP_THEME}-logo.png`).default;

const Wrapper = styled.div`
  background: ${(props) => props.theme.button.primary.background};
  color: #fff;
  border-top: 1px solid ${(props) => props.theme.button.secondary.background};
  padding: 40px 0 0;
`;

const FooterContent = styled.div`
  padding: 15px;
`;

const AppName = styled.div`
  font-family: "DaxRegular";
  font-size: 20px;
`;

const AppMotto = styled(AppName)`
  font-size: 15px;
  font-style: italic;
`;

const FooterLinkTitle = styled.div`
  text-transform: uppercase;
  margin: 0 0 10px;
  font-weight: bold;
`;

const FooterLinksUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const FooterLinksLi = styled.li`
  list-style: none;
  line-height: 30px;

  & a {
    color: #fff !important;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  float: left;
  position: relative;
  top: -3px;
  height: 50px;
  width: 100%;
  object-fit: contain;
`;

const Copyright = styled.div`
  padding: 10px 0;
  text-align: center;
  color: ${(props) => props.theme.button.primary.background};
  background: #deedf4; /* ${(props) => props.theme.border.light}; */
`;

const Footer = () => (
  <Wrapper className="noPrint">
    <Grid container stackable>
      <Grid.Row columns={4}>
        <Grid.Column>
          <FooterContent>
            {process.env.REACT_APP_THEME === "ARAIR" ? (
              <Card style={{ width: 150, height: 70 }}>
                <Card.Content>
                  <Logo src={logo} alt={`logo ${process.env.REACT_APP_LOGO}`} />
                </Card.Content>
              </Card>
            ) : (
              <>
                <AppName>{process.env.REACT_APP_NAME}</AppName>
                <AppMotto>{process.env.REACT_APP_MOTTO}</AppMotto>
              </>
            )}
            <Card style={{ width: 150, height: 70 }}>
              <Card.Content>
                {process.env.REACT_APP_THEME === "ARAIR" ? (
                  <a href="https://www.arair.fr" rel="noopener noreferrer" target="_blank">
                    <Logo src={logoTheme} alt="logo" />
                  </a>
                ) : (
                  <a href="#" rel="noopener noreferrer" target="_blank">
                    <Logo src={logoTheme} alt="logo" />
                  </a>
                )}
              </Card.Content>
            </Card>
          </FooterContent>
        </Grid.Column>
        <Grid.Column>
          <FooterContent>
            <FooterLinkTitle>Conditions générales</FooterLinkTitle>
            <FooterLinksUl>
              <FooterLinksLi>
                -{" "}
                <Link
                  to={`${
                    process.env.REACT_APP_THEME === "ARAIR"
                      ? "/conditions-generales-utilisation"
                      : "#"
                  }`}
                >
                  Conditions générales d&apos;utilisation
                </Link>
              </FooterLinksLi>
              <FooterLinksLi>
                -{" "}
                <Link
                  to={`${
                    process.env.REACT_APP_THEME === "ARAIR"
                      ? "/conditions-generales-de-location"
                      : "#"
                  }`}
                >
                  Conditions générales de location
                </Link>
              </FooterLinksLi>
              <FooterLinksLi>
                -{" "}
                <Link
                  to={`${
                    process.env.REACT_APP_THEME === "ARAIR" ? "/conditions-generales-de-vente" : "#"
                  }`}
                >
                  Conditions générales de vente
                </Link>
              </FooterLinksLi>
            </FooterLinksUl>
          </FooterContent>
        </Grid.Column>
        <Grid.Column>
          <FooterContent>
            <FooterLinkTitle>LIENS UTILES</FooterLinkTitle>
            {process.env.REACT_APP_THEME !== "ADS" ? (
              <FooterLinksUl>
                <FooterLinksLi>
                  -{" "}
                  {process.env.REACT_APP_THEME === "ARAIR" && (
                    <a href="https://www.arair.fr" rel="noopener noreferrer" target="_blank">
                      https://www.arair.fr
                    </a>
                  )}
                  {process.env.REACT_APP_THEME === "AGIR" && (
                    <a href="https://www.agiradom.com" rel="noopener noreferrer" target="_blank">
                      https://www.agiradom.com
                    </a>
                  )}
                </FooterLinksLi>
                <FooterLinksLi>
                  -{" "}
                  <Link
                    to={`${
                      process.env.REACT_APP_THEME === "ARAIR"
                        ? "/politique-de-protection-des-donnees-personnelles"
                        : "#"
                    }`}
                  >
                    Politique de protection des données personnelles
                  </Link>
                </FooterLinksLi>
              </FooterLinksUl>
            ) : (
              <FooterLinksUl>
                <FooterLinksLi>
                  - <Link to="#">Politique de protection des données personnelles</Link>
                </FooterLinksLi>
              </FooterLinksUl>
            )}
          </FooterContent>
        </Grid.Column>
        <Grid.Column>
          <FooterContent>
            {process.env.REACT_APP_THEME === "ARAIR" && (
              <>
                <FooterLinkTitle>ARAIR Assistance</FooterLinkTitle>
                28 Avenue Marcel Dassault
                <br />
                Quartier des 2 lions
                <br />
                37200 TOURS
                <br />
                <br />
                Tél : 02 47 25 45 12
                <br />
                Fax : 02 47 25 44 89
                <br />
                Courriel : mad@arair.fr
              </>
            )}
            {process.env.REACT_APP_THEME === "AGIR" && (
              <>
                {" "}
                <FooterLinkTitle>AGIR à dom</FooterLinkTitle>
                36, chemin du Vieux Chêne
                <br />
                38240 MEYLAN
                <br />
                <br />
                Tél : 04 76 51 03 04
                <br />
                Courriel : info@agiradom.com
              </>
            )}
            {process.env.REACT_APP_THEME === "ADS" && (
              <>
                {" "}
                <FooterLinkTitle>ADS</FooterLinkTitle>
                12, avenue Charles de Gaulle
                <br />
                31130 BALMA
                <br />
                <br />
                Tél :
                <br />
                Courriel : web@ads31.com
              </>
            )}
          </FooterContent>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column />
      </Grid.Row>
    </Grid>
    <Copyright>
      Copyright &copy; {new Date().getFullYear()} {process.env.REACT_APP_THEME}. Tous droits
      réservés.
    </Copyright>
  </Wrapper>
);

export default Footer;
