import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  LISTE_CLIENT_REQUEST,
  LISTE_CLIENT_SUCCESS,
  LISTE_CLIENT_FAILURE,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  FUSION_CLIENT_FAILURE
} from "../constants/ActionTypes";

export function* getClients() {
  try {
    const listClient = gql`
      {
        listClient {
          iPKClient
          sLibelle
          iFKCatalogue
          sLibelleCatalogue
          iNbGroupe
        }
      }
    `;
    const response = yield call(fetchApollo, listClient);
    const clients = response.data.listClient;

    if (clients !== null) {
      yield put({ type: LISTE_CLIENT_SUCCESS, payload: clients });
    } else {
      yield put({
        type: LISTE_CLIENT_FAILURE,
        payload: "Aucun client trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: LISTE_CLIENT_FAILURE, payload: message });
  }
}

export function* getClientsWithoutCatalogue() {
  try {
    const listClient = gql`
      {
        listClientWithoutCatalogue {
          iPKClient
          sLibelle
          iFKCatalogue
          sLibelleCatalogue
          iNbGroupe
        }
      }
    `;
    const response = yield call(fetchApollo, listClient);
    const clients = response.data.listClientWithoutCatalogue;

    if (clients !== null) {
      yield put({ type: LISTE_CLIENT_SUCCESS, payload: clients });
    } else {
      yield put({
        type: LISTE_CLIENT_FAILURE,
        payload: "Aucun client trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: LISTE_CLIENT_FAILURE, payload: message });
  }
}

export function* addClient({ payload: { client } }) {
  try {
    const CLIENT_REQUEST = gql`mutation {
        addClient(client: ${JSON.stringify(JSON.stringify(client))}) {
          iPKClient
          sLibelle
          iFKCatalogue
        }
      }`;
    const response = yield call(fetchApollo, CLIENT_REQUEST);
    const savedClient = response.data.addClient;

    if (savedClient !== null) {
      yield put({ type: ADD_CLIENT_SUCCESS, payload: savedClient });
      yield put({
        type: LISTE_CLIENT_REQUEST,
        payload: null
      });
    } else {
      yield put({
        type: ADD_CLIENT_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_CLIENT_FAILURE, payload: message });
  }
}

export function* editClient({ payload: { client } }) {
  try {
    const CLIENT_REQUEST = gql`mutation {
        editClient(client: ${JSON.stringify(JSON.stringify(client))}) {
          iPKClient
          sLibelle
          iFKCatalogue
        }
      }`;
    const response = yield call(fetchApollo, CLIENT_REQUEST);
    const savedClient = response.data.editClient;

    if (savedClient !== null) {
      yield put({ type: EDIT_CLIENT_SUCCESS, payload: savedClient });
      yield put({
        type: LISTE_CLIENT_REQUEST,
        payload: null
      });
    } else {
      yield put({
        type: EDIT_CLIENT_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_CLIENT_FAILURE, payload: message });
  }
}

export function* deleteClient({ payload: { iPKClient } }) {
  try {
    const CLIENT_REQUEST = gql`mutation {
        deleteClient(iPKClient: ${iPKClient}) {
          iPKClient
        }
      }`;
    const response = yield call(fetchApollo, CLIENT_REQUEST);
    const deletedClient = response.data.deleteClient;

    if (deletedClient !== null) {
      yield put({ type: DELETE_CLIENT_SUCCESS, payload: deletedClient });
      yield put({
        type: LISTE_CLIENT_REQUEST,
        payload: null
      });
    } else {
      yield put({
        type: DELETE_CLIENT_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_CLIENT_FAILURE, payload: message });
  }
}

export function* fusionClient({ payload: { sLibelle, client } }) {
  try {
    const FUSION_REQUEST = gql`mutation {
        fusionClient(sLibelle: "${sLibelle}", client: ${JSON.stringify(
      JSON.stringify(client)
    )}) {
          iPKClient
          sLibelle
        }
      }`;
    const response = yield call(fetchApollo, FUSION_REQUEST);
    const savedClient = response.data.fusionClient;

    if (savedClient !== null) {
      yield put({
        type: LISTE_CLIENT_REQUEST,
        payload: null
      });
    } else {
      yield put({
        type: FUSION_CLIENT_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: FUSION_CLIENT_FAILURE, payload: message });
  }
}
