import React, { Component } from "react";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { Grid, Form, Card, Message } from "semantic-ui-react";

import { fetchApollo } from "../../graphql/apolloClient";

import Introduction from "../../components/Screens/Connexion/Introduction";
import Submit from "../../components/Core/Btn/Submit";
import Layout from "../../components/Front/Layout";

const ConnexionFormSubmit = styled(Submit)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 0 auto;
  }
`;

const BtnBack = styled(Submit)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 0 auto;
  }
  margin-right: 10px !important;
`;

const FormWrapper = styled.div`
  width: 70%;
`;

class MotDePasseOublie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sEmail: "",
      sMessage: null,
      sType: null,
      errors: {},
    };
  }

  onChange = (evt) => {
    const {
      target: { id, value },
    } = evt;

    const { errors } = this.state;
    const nextErrors = { ...errors };
    if (nextErrors[id]) {
      delete nextErrors[id];
    }

    this.setState({
      [id]: value,
      errors: nextErrors,
    });
  };

  checkForm = (evt) => {
    evt.preventDefault();

    const { sEmail } = this.state;
    const errors = {};

    if (sEmail.length === 0) {
      errors.sEmail = "Veuillez saisir votre email.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      const FORGET_PASSWORD = gql`mutation
        {
          forgetPassword (sEmail: "${sEmail.trim()}") {
            iPKUtilisateur
            sNom
            sPrenom
            sCodeCivilite
          }
        }
      `;
      fetchApollo(FORGET_PASSWORD)
        .then((response) => {
          const { forgetPassword } = response.data;

          if (forgetPassword) {
            this.setState({
              sMessage: `${forgetPassword.sCodeCivilite} ${forgetPassword.sNom} ${forgetPassword.sPrenom} votre demande de renouvellement de mot de passe a bien été envoyé`,
              sType: "positive",
            });
          }
        })
        .catch((err) => {
          this.setState({
            sMessage: err.replace("GraphQL error: ", ""),
            sType: "negative",
          });
        });
    }
  };

  render() {
    const { sEmail, sMessage, sType, errors } = this.state;

    return (
      <Layout
        mainPane={
          <Grid stackable container>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Card style={{ margin: "0 auto", width: 500 }}>
                  <Card.Content>
                    <h1>Mot de passe oublié</h1>
                    <Introduction>Un nouveau mot de passe vous sera envoyé par email.</Introduction>
                    <FormWrapper>
                      <Form onSubmit={this.checkForm}>
                        <Form.Input
                          label="Email :"
                          placeholder="Email"
                          id="sEmail"
                          value={sEmail}
                          onChange={this.onChange}
                          error={errors.sEmail}
                        />
                        <Form.Field>
                          <BtnBack
                            primary
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              window.history.back();
                            }}
                          >
                            Annuler
                          </BtnBack>
                          <ConnexionFormSubmit
                            disabled={process.env.REACT_APP_DEMO === "1"}
                            primary
                            onClick={this.checkForm}
                          >
                            Valider
                          </ConnexionFormSubmit>
                        </Form.Field>
                      </Form>
                    </FormWrapper>
                    {sMessage && sType === "positive" && (
                      <Message fluid positive>
                        {sMessage}
                      </Message>
                    )}
                    {sMessage && sType === "negative" && (
                      <Message fluid negative>
                        {sMessage}
                      </Message>
                    )}
                  </Card.Content>
                  {process.env.REACT_APP_DEMO === "1" && (
                    <i style={{ margin: "0 auto" }}>Fonctionnalitée désactivée pour la démo</i>
                  )}
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
    );
  }
}

export default MotDePasseOublie;
