import React, { Component } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";
import { Form, Message } from "semantic-ui-react";

import { Redirect, Link } from "react-router-dom";

import { connect } from "react-redux";
import { signUserIn } from "../../redux/actions/user";

import Screen from "../../components/Core/Screen";
import Logo from "../../components/Screens/Connexion/Logo";
import FormWrapper from "../../components/Screens/Connexion/Form/Wrapper";
import Field from "../../components/Core/Form/Field";
import Introduction from "../../components/Screens/Connexion/Introduction";
import Submit from "../../components/Core/Btn/Submit";
import PasswordInput from "../../components/Core/Form/Input/PasswordInput";

const logo = require(`../../ui/assets/img/${process.env.REACT_APP_LOGO}`).default;

const ConnexionField = styled(Field)`
  display: block;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: inline-flex;
    width: 100%;
  }
`;

const ConnexionFormSubmit = styled(Submit)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 0 auto;
  }
`;

class Connexion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      dCGUAccepte: props.layout === "admin" ? true : false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { signInError } = this.props;

    if (signInError !== prevProps.signInError) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, signin: signInError },
      }));
    }
  }

  onChange = (evt) => {
    const {
      target: { id, value },
    } = evt;

    this.setState((prevState) => ({
      [id]: value,
      errors: Object.values(prevState.errors).filter((error) => error.id !== id),
    }));
  };

  checkForm = (evt) => {
    evt.preventDefault();

    const { dispatchSignUserIn } = this.props;
    const { login, password, dCGUAccepte } = this.state;
    const errors = {};
    const errorMessages = {
      login: "Veuillez saisir votre identifiant.",
      password: "Veuillez saisir votre mot de passe.",
      dCGUAccepte: "Merci de valider les CGU.",
    };

    if (login.length === 0) {
      errors.login = errorMessages.login;
    }

    if (password.length === 0) {
      errors.password = errorMessages.password;
    }

    if (!dCGUAccepte) {
      errors.dCGUAccepte = errorMessages.dCGUAccepte;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      dispatchSignUserIn(login.trim(), password.trim(), dCGUAccepte);
    }
  };

  onChangeCgu = (evt) => {
    const {
      target: { id, value, checked },
    } = evt;
    const { errors } = this.state;
    const nextErrors = { ...errors };
    if (nextErrors[id]) {
      delete nextErrors[id];
    }

    this.setState({
      [id]: checked ? value : null,
      errors: nextErrors,
    });
  };

  render() {
    const { isAuthenticated, layout } = this.props;
    const { login, password, errors } = this.state;
    if (isAuthenticated) {
      return <Redirect to="/tableauDeBord" />;
    }

    return (
      <Screen>
        <FormWrapper>
          <Logo src={logo} alt="ADS" />

          <Introduction>
            Bienvenue dans votre interface d&apos;administration{" "}
            <strong>{process.env.REACT_APP_NAME}</strong> !
            <br />
            Pour commencer, veuillez vous connecter avec vos identifiants.
          </Introduction>

          <Form onSubmit={this.checkForm}>
            <Form.Input
              label="Identifiant&nbsp;:"
              id="login"
              placeholder="Identifiant"
              value={login}
              onChange={this.onChange}
              error={errors.login}
            />
            <PasswordInput
              label="Mot de passe :"
              type="password"
              id="password"
              placeholder="Mot de passe"
              password={password}
              onChange={this.onChange}
              error={errors.password ? { content: errors.password, pointing: "below" } : false}
            />
            {layout !== "admin" && (
              <Form.Checkbox
                label={
                  <label htmlFor="dCGUAccepte">
                    J'accepte les{" "}
                    <Link
                      to={
                        process.env.REACT_APP_DEMO === "1"
                          ? "#"
                          : "/conditions-generales-utilisation"
                      }
                    >
                      Conditions Générales d’Utilisation
                    </Link>
                  </label>
                }
                id="dCGUAccepte"
                value={DateTime.local()}
                onChange={this.onChangeCgu}
                error={errors.dCGUAccepte}
              />
            )}
            <ConnexionField style={{ marginTop: 10 }}>
              <ConnexionFormSubmit style={{ marginTop: 10 }} primary onClick={this.checkForm}>
                Valider
              </ConnexionFormSubmit>
            </ConnexionField>
            {errors.signin && (
              <Message fluid negative>
                {errors.signin}
              </Message>
            )}
            <Link to="/mot-de-passe-oublie">Mot de passe oublié</Link>
          </Form>
        </FormWrapper>
      </Screen>
    );
  }
}

Connexion.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  signInError: PropTypes.string,
  layout: PropTypes.string.isRequired,
  dispatchSignUserIn: PropTypes.func.isRequired,
};

Connexion.defaultProps = {
  signInError: null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.data,
  signInError: state.user.signInError,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSignUserIn: (sLogin, sPassword, dCGUAccepte) => {
    dispatch(signUserIn(sLogin, sPassword, dCGUAccepte));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Connexion);
