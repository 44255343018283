import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import Username from "./Username";
import BtnSignOut from "./BtnSignOut";

const Wrapper = styled.div`
  float: right;
  padding: 0 42px 0 0;
  line-height: 50px;
`;

const User = ({ user }) => (
  <Wrapper>
    <Username>{`${user.sPrenom} ${user.sNom.toUpperCase()}`}</Username>
    <BtnSignOut />
  </Wrapper>
);

User.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(User);
