import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Grid, Form, Input, Icon, Message, Card, Loader } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import { addProduit } from "../../../../redux/actions/panier";
import { addFavoris, deleteFavoris } from "../../../../redux/actions/favoris";

import Layout from "../../../../components/Front/Layout";
import Footer from "../../../../components/Front/Layout/Footer";
import Submit from "../../../../components/Core/Btn/Submit";

import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbRightLink,
} from "../../../../components/Core/Breadcrumb";

import { importImg, setVignetteImg } from "../../../../utils";

import placeholder from "../../../../ui/assets/img/placeholder.svg";
import favoriteSrc from "../../../../ui/assets/img/favorite.svg";

const Product = styled(Link)`
  position: relative;
  display: block;
  padding: 15px;
  background-color: ${(props) => props.theme.background.paper};
  border: 1px solid ${(props) => props.theme.border.light};
  border-radius: 4px;

  &:hover {
    box-shadow: 0 0 20px ${(props) => props.theme.background.dark};
  }

  &:hover .action {
    background: ${(props) => props.theme.button.primary.hoverBackground};
  }
`;

const Title = styled.span`
  display: block;
  padding: 0 0 15px;
  font-size: 14px;
  font-weight: bold;
  height: 35px;
  color: ${(props) => props.theme.text.primary};
`;

const Vignette = styled.img`
  display: block;
  max-width: 100%;
  height: 110px;
  object-fit: contain;
  width: ${(props) => (props.placeholder ? "50%" : "100%")};
  margin-left: ${(props) => (props.placeholder ? "25%" : "0")};
  margin-right: ${(props) => (props.placeholder ? "25%" : "0")};
`;

const Action = styled.span`
  display: block;
  margin: 15px -15px -15px;
  padding: 15px 0;
  text-align: center;
  color: ${(props) => props.theme.button.primary.text};
  background: ${(props) => props.theme.button.primary.background};
  transition: all 0.25s ease;
`;

const FavoriteIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  background-image: url("${favoriteSrc}");
  background-size: 40px 40px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

const Picture = styled.img`
  display: block;
  margin-left: ${(props) => (props.placeholder ? "25%" : "0")};
  margin-right: ${(props) => (props.placeholder ? "25%" : "0")};
  height: auto;
  width: ${(props) => (props.placeholder ? "50%" : "100%")};
  max-width: 100%;
  border: none;
`;

const ConteneurTarif = styled.div`
  margin: 0 auto;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  width: 48%;
`;

class Produit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      produit: {},
      tProduitAssocie: [],
      formFields: {},
      iQuantite: null,
      error: null,
      success: null,
      loading: true,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    window.scrollTo(0, 0);
    if (params.sousfamille !== "produit") {
      this.getProduitSousFamille(
        decodeURIComponent(params.produit).replace(/"/g, "'"),
        decodeURIComponent(params.sousfamille)
      );
    } else {
      this.getProduit(
        decodeURIComponent(params.produit).replace(/"/g, "'"),
        decodeURIComponent(params.famille)
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      tPanier,
      match: { params },
    } = this.props;
    const { produit } = this.state;
    if (
      prevProps.tPanier !== tPanier &&
      tPanier.filter((p) => p.produit.iPKProduit === produit.iPKProduit)[0]
    ) {
      this.setState({ success: "Produit bien ajouté au panier", error: null });
    }
    if (params !== prevProps.match.params) {
      if (params.sousfamille !== "produit") {
        this.getProduitSousFamille(
          decodeURIComponent(params.produit).replace(/"/g, "'"),
          decodeURIComponent(params.sousfamille)
        );
      } else {
        this.getProduit(
          decodeURIComponent(params.produit).replace(/"/g, "'"),
          decodeURIComponent(params.famille)
        );
      }
    }
  }

  getProduit = (sLibelle, sLibelleFamille) => {
    const { user } = this.props;
    const GET_PRODUIT = gql`
      {
        getProduit(sLibelle: "${sLibelle}", sLibelleFamille: "${sLibelleFamille}", iFKCatalogue: ${user.iFKCatalogue}, iPKUtilisateur: ${user.iPKUtilisateur}) {
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          sCodeNatureProduit
          sImgPath
          bInactif
          bAssocie
          iFKCatFamille
          iQuantite
          sPKSousFamille
          sFields
          iFKMatProduit
          tDocument {
            iPKCatCatalogueProduitDocument
            sLibelle
            sURL
            iFKDocument
            iFKCatCatalogueProduit
            bInactif
          }
          caractTechnique {
            iCPMatProduitCaractTechnique
            iCPParTypeCaracteristiqueTech
            sPKTypeCaracteristique
            sLibelle
            sValeur
            sTypeDonnee
          }
          tTarif {
            iPKTarif
            sCodeTypeAcquisition
            mTarifHT
            mTarifTTC
          }
        }
      }
    `;
    fetchApollo(GET_PRODUIT).then((response) => {
      const produit = response.data.getProduit;

      if (produit) {
        this.getProduitsAssocie(produit.iFKMatProduit);
        this.setState({
          produit,
          loading: false,
        });
      }
    });
  };

  getProduitSousFamille = (sLibelle, sLibelleFamille) => {
    const { user } = this.props;
    const GET_PRODUIT = gql`
      {
        getProduitSousFamille(sLibelle: "${sLibelle}", sLibelleFamille: "${sLibelleFamille}", iFKCatalogue: ${user.iFKCatalogue}, iPKUtilisateur: ${user.iPKUtilisateur}) {
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          sCodeNatureProduit
          sImgPath
          bInactif
          bAssocie
          iFKCatFamille
          iQuantite
          sPKSousFamille
          sFields
          tDocument {
            iPKCatCatalogueProduitDocument
            sLibelle
            sURL
            iFKDocument
            iFKCatCatalogueProduit
            bInactif
          }
          caractTechnique {
            iCPMatProduitCaractTechnique
            iCPParTypeCaracteristiqueTech
            sPKTypeCaracteristique
            sLibelle
            sValeur
            sTypeDonnee
          }
          tTarif {
            iPKTarif
            sCodeTypeAcquisition
            mTarifHT
            mTarifTTC
          }
        }
      }
    `;
    fetchApollo(GET_PRODUIT).then((response) => {
      const produit = response.data.getProduitSousFamille;

      if (produit) {
        this.setState({
          produit,
          loading: false,
        });
      }
    });
  };

  getProduitsAssocie = (iFKMatProduit) => {
    const { user } = this.props;
    const GET_PRODUIT = gql`
        {
          getProduitAssocie(iFKMatProduit: ${iFKMatProduit}, iFKCatalogue: ${user.iFKCatalogue}, iPKUtilisateur: ${user.iPKUtilisateur}) {
            iPKProduit
            sLibelle
            sRefProduit
            sInformation
            sCodeNatureProduit
            sImgPath
            bInactif
            bAssocie
            iFKCatFamille
            iQuantite
            sLibelleFamille
            sLibelleSousFamille
            sFields
            tDocument {
              iPKCatCatalogueProduitDocument
              sLibelle
              sURL
              iFKDocument
              iFKCatCatalogueProduit
              bInactif
            }
            caractTechnique {
              iCPMatProduitCaractTechnique
              iCPParTypeCaracteristiqueTech
              sPKTypeCaracteristique
              sLibelle
              sValeur
              sTypeDonnee
            }
            tTarif {
              iPKTarif
              sCodeTypeAcquisition
              mTarifHT
              mTarifTTC
            }
          }
        }
      `;
    fetchApollo(GET_PRODUIT)
      .then((response) => {
        const tProduitAssocie = response.data.getProduitAssocie;

        if (tProduitAssocie) {
          this.setState({
            tProduitAssocie,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  handleChange = (evt, data) => {
    if (
      Number.isInteger(Number(data.value)) &&
      Number(data.value) >= 0 &&
      Number(data.value) < Number(process.env.REACT_APP_LIMITE_QTE)
    ) {
      this.setState({ [data.id]: Number(data.value) });
    } else {
      this.setState(
        {
          error:
            Number(data.value) >= Number(process.env.REACT_APP_LIMITE_QTE)
              ? "La quantité saisie est trop importante"
              : "Vous ne pouvez pas mettre de quantité négative",
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null,
            });
          }, 3000);
        }
      );
    }
  };

  handleChangeField = (evt, data) => {
    const { formFields } = this.state;
    this.setState({ formFields: { ...formFields, [data.id]: data.value } });
  };

  addToPanier = () => {
    const { dispatch, user } = this.props;
    const { produit, iQuantite } = this.state;
    if (iQuantite < 1) {
      this.setState({ error: "Vous devez saisir une quantité", success: null });
    } else {
      dispatch(
        addProduit(produit.iPKProduit, user.iPKUtilisateur, iQuantite, produit.sFields, "", false)
      );
    }
  };

  addFavoris = (iFKProduit) => {
    const { dispatch, user } = this.props;
    dispatch(addFavoris(iFKProduit, user.iPKUtilisateur));
  };

  deleteFavoris = (iFKProduit) => {
    const { dispatch, user } = this.props;
    dispatch(deleteFavoris(iFKProduit, user.iPKUtilisateur));
  };

  render() {
    const {
      tFavoris,
      match: { params },
      user,
    } = this.props;
    const { loading, produit, tProduitAssocie, iQuantite, error, success } = this.state;

    let imgProduct;
    if (produit && produit.tDocument?.length > 0) {
      imgProduct = produit.tDocument[0];
    }

    return (
      <Layout
        sideBarRight={null}
        mainPane={
          <Grid stackable container doubling>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Breadcrumb>
                  <BreadcrumbLink to="/">
                    <Icon name="home" />
                  </BreadcrumbLink>
                  <BreadcrumbLink to="/catalogue">Catalogue</BreadcrumbLink>
                  <BreadcrumbLink to={`/catalogue/${params.famille}`}>
                    {decodeURIComponent(params.famille)}
                  </BreadcrumbLink>
                  {params.sousfamille !== "produit" && (
                    <BreadcrumbLink to={`/catalogue/${params.famille}/${params.sousfamille}`}>
                      {decodeURIComponent(params.sousfamille)}
                    </BreadcrumbLink>
                  )}
                  {decodeURIComponent(params.produit)}
                  <BreadcrumbRightLink to="/favoris">
                    <Icon name="star" /> Mes favoris
                  </BreadcrumbRightLink>
                </Breadcrumb>

                <h1>{decodeURIComponent(params.produit)}</h1>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Card fluid>
                  <Card.Content>
                    <p>Réf. {produit.sRefProduit}</p>
                    <p>{produit.sInformation}</p>
                    <hr />
                    {produit.caractTechnique && produit.caractTechnique.length > 0 && (
                      <>
                        <p style={{ fontWeight: "bold", fontSize: 18 }}>Caractéristiques</p>
                        <ul>
                          {produit.caractTechnique.map((ct) => (
                            <li>
                              {ct.sLibelle} {ct.sValeur}
                            </li>
                          ))}
                        </ul>
                        <hr />
                      </>
                    )}
                    {produit.tTarif &&
                    user.bTarif &&
                    produit.tTarif.map((t) => t.sCodeTypeAcquisition === "L").length > 0 ? (
                      <div style={{ textAlign: "center" }}>
                        {produit.sCodeNatureProduit === "M" &&
                          produit.tTarif.map(
                            (t) =>
                              t.sCodeTypeAcquisition === "L" && (
                                <ConteneurTarif>
                                  <b style={{ fontSize: 18 }}>
                                    {t.sCodeTypeAcquisition === "L" ? "Location par jour" : "Vente"}
                                  </b>
                                  <br />
                                  <b>HT :</b> {t.mTarifHT} €
                                  <br />
                                  <b>TTC :</b> {t.mTarifTTC} €
                                  <br />
                                </ConteneurTarif>
                              )
                          )}
                        {/*
                        * Consommables
                        produit.sCodeNatureProduit === "C" &&
                          produit.tTarif.map(
                            t =>
                              t.sCodeTypeAcquisition !== "L" && (
                                <ConteneurTarif>
                                  <b style={{ fontSize: 18 }}>
                                    {t.sCodeTypeAcquisition === "L" ? "Location" : "Vente"}
                                  </b>
                                  <br />
                                  <b>HT:</b> {t.mTarifHT} €
                                  <br />
                                  <b>TTC:</b> {t.mTarifTTC} €
                                  <br />
                                </ConteneurTarif>
                              )
                              )
                          */}
                        <div style={{ clear: "both" }} />
                        <hr />
                      </div>
                    ) : (
                      ""
                    )}
                    <Form>
                      <Form.Group inline>
                        <Form.Field width={3}>
                          <Input
                            id="iQuantite"
                            onChange={this.handleChange}
                            type="number"
                            min="0"
                            value={iQuantite}
                            placeholder="Qté"
                          />
                        </Form.Field>
                        <Form.Field>
                          <Submit onClick={this.addToPanier}>Ajouter au panier</Submit>
                          {tFavoris.filter(
                            (f) => f.iFKProduit === parseInt(produit.iPKProduit, 10)
                          )[0] ? (
                            <Submit onClick={() => this.deleteFavoris(produit.iPKProduit)}>
                              Retirer favoris
                            </Submit>
                          ) : (
                            <Submit onClick={() => this.addFavoris(produit.iPKProduit)}>
                              Ajouter aux favoris
                            </Submit>
                          )}
                        </Form.Field>
                      </Form.Group>
                      {error && <Message negative>{error}</Message>}
                      {success && <Message positive>{success}</Message>}
                    </Form>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                {loading && <Loader active />}
                <figure style={{ marginTop: 0 }}>
                  <Loader active={imgProduct} id={`loader-${produit.iPKProduit}`} />
                  <Picture
                    placeholder={!produit.sImgPath && !imgProduct}
                    id={`vignette-${produit.iPKProduit}`}
                    src={setVignetteImg(produit)}
                    alt=""
                  />
                  {(produit.sImgPath || imgProduct) && (
                    <figcaption
                      style={{
                        position: "relative",
                        bottom: 30,
                        textAlign: "center",
                        fontStyle: "italic",
                        fontSize: 12,
                      }}
                    >
                      Photo non contractuelle
                    </figcaption>
                  )}
                </figure>
              </Grid.Column>
            </Grid.Row>
            {tProduitAssocie.filter((pa) => pa.sLibelle).length > 0 && (
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <h3>Produits liés</h3>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns={5}>
              {tProduitAssocie.map(
                (pa) =>
                  pa.sLibelle && (
                    <Grid.Column style={{ marginBottom: 30 }}>
                      <Product
                        style={{ maxHeight: 350 }}
                        to={`/catalogue/${encodeURIComponent(pa.sLibelleFamille)}${
                          pa.sLibelleSousFamille ? `/${encodeURIComponent(pa.sLibelleFamille)}` : ""
                        }/produit/${encodeURIComponent(pa.sLibelle)}`}
                      >
                        {tFavoris.filter(
                          (f) => f.iFKProduit === parseInt(pa.iPKProduit, 10)
                        )[0] && <FavoriteIcon />}
                        <Title style={{ height: 75 }}>{pa.sLibelle}</Title>
                        <figure>
                          <Loader
                            active={pa.sImgPath || pa.tDocument?.length > 0}
                            id={`loader-${pa.iPKProduit}`}
                          />
                          <Vignette
                            style={{ maxHeight: 170 }}
                            placeholder={!pa.sImgPath && pa.tDocument?.length === 0}
                            id={`vignette-${pa.iPKProduit}`}
                            src={setVignetteImg(produit)}
                            alt=""
                          />
                          {pa.sImgPath ||
                            (pa.tDocument?.length > 0 && (
                              <figcaption
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  fontStyle: "italic",
                                  fontSize: 12,
                                }}
                              >
                                Photo non contractuelle
                              </figcaption>
                            ))}
                        </figure>
                        <Action className="action">Voir la fiche produit</Action>
                      </Product>
                    </Grid.Column>
                  )
              )}
            </Grid.Row>
          </Grid>
        }
        footerPane={Footer}
      />
    );
  }
}

Produit.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  tPanier: PropTypes.arrayOf(PropTypes.any).isRequired,
  tFavoris: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.data,
  tPanier: state.panier.liste,
  tFavoris: state.favoris.liste,
});

export default connect(mapStateToProps)(Produit);
