import styled from "styled-components";

const FormWrapper = styled.div`
  margin: 40px auto 0;
  padding: 20px;
  width: 365px;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.light};
`;

export default FormWrapper;
