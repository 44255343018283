import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_CATALOGUE_SUCCESS,
  GET_CATALOGUE_FAILURE,
  ADD_CATALOGUE_SUCCESS,
  ADD_CATALOGUE_FAILURE,
  EDIT_CATALOGUE_SUCCESS,
  EDIT_CATALOGUE_FAILURE,
  DELETE_CATALOGUE_SUCCESS,
  DELETE_CATALOGUE_FAILURE,
  LISTE_CATALOGUE_SUCCESS,
  LISTE_CATALOGUE_FAILURE,
  LISTE_FAMILLE_CATALOGUE_REQUEST,
  LISTE_FAMILLE_CATALOGUE_SUCCESS,
  LISTE_FAMILLE_CATALOGUE_FAILURE,
  IMPORT_FAMILLE_CATALOGUE_FAILURE,
  IMPORT_FAMILLE_CATALOGUE_SUCCESS,
  LISTE_ISADOM_FAMILLE_CATALOGUE_SUCCESS,
  LISTE_ISADOM_FAMILLE_CATALOGUE_FAILURE
} from "../constants/ActionTypes";

export function* getCatalogue({ payload: { iPKCatalogue } }) {
  try {
    const getCatalogueRequest = gql`
      {
        getCatalogue(iPKCatalogue: ${iPKCatalogue}) {
          iPKCatalogue
          sLibelle
          iFKClient
          sLibelleClient
        }
      }
    `;
    const response = yield call(fetchApollo, getCatalogueRequest);
    const catalogue = response.data.getCatalogue;

    if (catalogue !== null) {
      yield put({ type: GET_CATALOGUE_SUCCESS, payload: catalogue });
      yield put({
        type: LISTE_FAMILLE_CATALOGUE_REQUEST,
        payload: { iPKCatalogue: catalogue.iPKCatalogue }
      });
    } else {
      yield put({
        type: GET_CATALOGUE_FAILURE,
        payload: "Aucun catalogue trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: GET_CATALOGUE_FAILURE, payload: message });
  }
}

export function* addCatalogue({ payload: { catalogue } }) {
  try {
    const CATALOGUE_REQUEST = gql`mutation {
        addCatalogue(catalogue: ${JSON.stringify(JSON.stringify(catalogue))}) {
          iPKCatalogue
          sLibelle
          iFKClient
        }
      }`;
    const response = yield call(fetchApollo, CATALOGUE_REQUEST);
    const savedCatalogue = response.data.addCatalogue;

    if (savedCatalogue !== null) {
      yield put({ type: ADD_CATALOGUE_SUCCESS, payload: savedCatalogue });
    } else {
      yield put({
        type: ADD_CATALOGUE_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_CATALOGUE_FAILURE, payload: message });
  }
}

export function* editCatalogue({ payload: { catalogue } }) {
  try {
    const CATALOGUE_REQUEST = gql`mutation {
        editCatalogue(catalogue: ${JSON.stringify(JSON.stringify(catalogue))}) {
          iPKCatalogue
          sLibelle
          iFKClient
        }
      }`;
    const response = yield call(fetchApollo, CATALOGUE_REQUEST);
    const savedCatalogue = response.data.editCatalogue;

    if (savedCatalogue !== null) {
      yield put({ type: EDIT_CATALOGUE_SUCCESS, payload: savedCatalogue });
    } else {
      yield put({
        type: EDIT_CATALOGUE_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_CATALOGUE_FAILURE, payload: message });
  }
}

export function* deleteCatalogue({ payload: { iPKCatalogue } }) {
  try {
    const CATALOGUE_REQUEST = gql`mutation {
        deleteCatalogue(iPKCatalogue: ${iPKCatalogue}) {
          iPKCatalogue
        }
      }`;
    const response = yield call(fetchApollo, CATALOGUE_REQUEST);
    const deletedCatalogue = response.data.deleteCatalogue;

    if (deletedCatalogue !== null) {
      yield put({ type: DELETE_CATALOGUE_SUCCESS, payload: deletedCatalogue });
    } else {
      yield put({
        type: DELETE_CATALOGUE_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_CATALOGUE_FAILURE, payload: message });
  }
}

export function* getCatalogues() {
  try {
    const listCatalogue = gql`
      {
        listCatalogue {
          iPKCatalogue
          sLibelle
        }
      }
    `;
    const response = yield call(fetchApollo, listCatalogue);
    const catalogues = response.data.listCatalogue;

    if (catalogues !== null) {
      yield put({ type: LISTE_CATALOGUE_SUCCESS, payload: catalogues });
    } else {
      yield put({
        type: LISTE_CATALOGUE_FAILURE,
        payload: "Aucune famille trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: LISTE_CATALOGUE_FAILURE, payload: message });
  }
}

export function* getCataloguesWithoutClient() {
  try {
    const listCatalogue = gql`
      {
        listCatalogueWithoutClient {
          iPKCatalogue
          sLibelle
        }
      }
    `;
    const response = yield call(fetchApollo, listCatalogue);
    const catalogues = response.data.listCatalogueWithoutClient;

    if (catalogues !== null) {
      yield put({ type: LISTE_CATALOGUE_SUCCESS, payload: catalogues });
    } else {
      yield put({
        type: LISTE_CATALOGUE_FAILURE,
        payload: "Aucune famille trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: LISTE_CATALOGUE_FAILURE, payload: message });
  }
}

export function* getFamillesCatalogue({ payload: { iPKCatalogue } }) {
  try {
    const listFamillesCatalogue = gql`
      {
        listFamilleCatalogue(iPKCatalogue: ${iPKCatalogue}) {
          sPKFamille
          sLibelle
          tSousFamilleCatalogue {
            sPKSousFamille
            sLibelle
            tProduit {
              iPKProduit
              sLibelle
              sRefProduit
              sInformation
              bInactif
              iPKCommandeLigne
              iPKCommandeTypeLigne
              iPKMatPanier
            }
          }
          tProduit {
            iPKProduit
            sLibelle
            sRefProduit
            sInformation
            bInactif
            iPKCommandeLigne
            iPKCommandeTypeLigne
            iPKMatPanier
          }
        }
      }
    `;
    const response = yield call(fetchApollo, listFamillesCatalogue);
    const famillesCatalogue = response.data.listFamilleCatalogue;

    if (famillesCatalogue !== null) {
      yield put({
        type: LISTE_FAMILLE_CATALOGUE_SUCCESS,
        payload: famillesCatalogue
      });
    } else {
      yield put({
        type: LISTE_FAMILLE_CATALOGUE_FAILURE,
        payload: "Aucune famille trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: LISTE_FAMILLE_CATALOGUE_FAILURE, payload: message });
  }
}

export function* importFamillesCatalogue({ payload: { treeItem, iPKCatalogue } }) {
  try {
    const importFamillesCatalogueSQL = gql`mutation {
        importFamilleCatalogue(treeItem: ${JSON.stringify(
          JSON.stringify(treeItem)
        )}, iPKCatalogue: ${iPKCatalogue} ) {
          iResultat
        }
      }
    `;
    const response = yield call(fetchApollo, importFamillesCatalogueSQL);
    const famillesCatalogue = response.data.importFamilleCatalogue;

    if (famillesCatalogue !== null) {
      yield put({
        type: IMPORT_FAMILLE_CATALOGUE_SUCCESS,
        payload: "Aucune famille trouvé"
      });
    } else {
      yield put({
        type: IMPORT_FAMILLE_CATALOGUE_FAILURE,
        payload: "Aucune famille trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: IMPORT_FAMILLE_CATALOGUE_FAILURE, payload: message });
  }
}

export function* getIsadomFamillesCatalogue() {
  try {
    const listIsadomFamilleCatalogue = gql`
      {
        listIsadomFamilleCatalogue {
          sPKFamille
          sLibelle
          tSousFamilleCatalogue {
            sPKSousFamille
            sLibelle
            tProduit {
              iPKProduit
              sLibelle
              sRefProduit
              sInformation
              bInactif
            }
          }
          tProduit {
            iPKProduit
            sLibelle
            sRefProduit
            sInformation
            bInactif
          }
        }
      }
    `;
    const response = yield call(fetchApollo, listIsadomFamilleCatalogue);
    const isadomFamilleCatalogue = response.data.listIsadomFamilleCatalogue;

    if (isadomFamilleCatalogue !== null) {
      yield put({
        type: LISTE_ISADOM_FAMILLE_CATALOGUE_SUCCESS,
        payload: isadomFamilleCatalogue
      });
    } else {
      yield put({
        type: LISTE_ISADOM_FAMILLE_CATALOGUE_FAILURE,
        payload: "Aucune famille trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({
      type: LISTE_ISADOM_FAMILLE_CATALOGUE_FAILURE,
      payload: message
    });
  }
}
