import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { DateTime } from "luxon";
import setDate from "date-fns/setDate";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import {
  Grid,
  Table,
  Input,
  Checkbox,
  Confirm,
  Modal,
  Button,
  Form,
  Card,
  Icon,
  Popup,
  Message
} from "semantic-ui-react";

import DatePicker from "react-datepicker";

import { getCookie } from '../../../../utils';

import { fetchApollo } from "../../../../graphql/apolloClient";

import Layout from "../../../../components/Front/Layout";

const IconPrint = styled(Icon)`
  display: inline-block;
  cursor: pointer;
  font-size: 0.8em !important;
`;

class CommandeShow extends React.Component {


  autoSaveCmd;

  autoSaveCmdLine;

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      tCommandeLigne: [],
      showConfirmDelete: false,
      commande: {},
      message: null,
      error: null,
      isOpen: false
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { iPKCommande }
      }
    } = this.props;
    this.getCommande(iPKCommande);
    this.getProductsByCommande(iPKCommande);
  }

  getCommande = iPKCommande => {
    const GET_COMMANDE = gql`
      {
        getCommande(iPKCommande: ${iPKCommande}) {
          iPKCommande
          iEtat
          dInsert
          bPriorite
          dLivraison
          sConsigne
          files {
            name
            path
          }
        }
      }
    `;
    fetchApollo(GET_COMMANDE).then(response => {
      const commande = response.data.getCommande;
      if (commande) {
        const currentDate = new Date();
        const tz = Math.abs(currentDate.getTimezoneOffset() / 60);
        this.setState({
          commande: {
            ...commande,
            dLivraison:
              Number(commande.dLivraison) > 0
                ? DateTime.fromMillis(Number(commande.dLivraison))
                  .toUTC()
                  .setZone(`UTC+${tz}`, { keepLocalTime: true })
                  .toMillis()
                : ""
          }
        });
        document.title += ` - Commande N°${commande.iPKCommande}`;
      }
    });
  };

  deleteCommandeLigne = () => {
    const { tCommandeLigne, iPKCommandeLigneDelete } = this.state;
    if (tCommandeLigne?.length > 1) {
      const DELETE_COMMANDE_LIGNE = gql`mutation
    {
      deleteCommandeLigne(iPKCommandeLigne: ${iPKCommandeLigneDelete}) {
        iPKCommandeLigne
      }
    }
  `;
      fetchApollo(DELETE_COMMANDE_LIGNE).then(response => {
        const commandeLigne = response.data.deleteCommandeLigne;
        if (commandeLigne) {
          if (
            tCommandeLigne.filter(cl => cl.iPKCommandeLigne !== commandeLigne.iPKCommandeLigne)
              .length === 0
          ) {
            window.history.go(-1);
          } else {
            this.setState({
              tCommandeLigne: tCommandeLigne.filter(
                cl => cl.iPKCommandeLigne !== commandeLigne.iPKCommandeLigne
              )
            });
          }
        }
      });
    } else {
      this.setState(
        { error: "vous ne pouvez pas supprimer le dernier produit, il faut annuler la commande." },
        () => {
          setTimeout(() => {
            this.setState({
              error: null
            });
          }, 3000);
        }
      );
    }
  };

  getProductsByCommande = iPKCommande => {
    const GET_PRODUCTS = gql`
      {
        listProductsByCommande(iPKCommande: ${iPKCommande}) {
          iPKCommandeLigne
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          iQuantite
          sBeneficiaire
          bPriorite
          sFields
        }
      }
    `;
    fetchApollo(GET_PRODUCTS).then(response => {
      const tCommandeLigne = response.data.listProductsByCommande;
      if (tCommandeLigne) {
        this.setState({
          tCommandeLigne
        });
      }
    });
  };

  editCommandeLigne = iPKCommandeLigne => {
    const { formData, tCommandeLigne } = this.state;
    const GET_PRODUCTS = gql`mutation
      {
        editCommandeLigne(commandeLigne: ${JSON.stringify(
      JSON.stringify(formData[iPKCommandeLigne])
    )}) {
          iPKCommandeLigne
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          iQuantite
          sBeneficiaire
          bPriorite
        }
      }
    `;
    fetchApollo(GET_PRODUCTS).then(response => {
      const commandeLigne = response.data.editCommandeLigne;
      if (tCommandeLigne) {
        this.setState(
          {
            tCommandeLigne: tCommandeLigne.map(cl =>
              cl.iPKCommandeLigne === commandeLigne.iPKCommandeLigne
                ? {
                  ...cl,
                  sBeneficiaire: commandeLigne.sBeneficiaire,
                  iQuantite: commandeLigne.iQuantite,
                  bPriorite: commandeLigne.bPriorite
                }
                : cl
            ),
            formData: {}
          },
          () => {
            clearTimeout(this.autoSaveCmdLine);
            this.autoSaveCmdLine = setTimeout(() => {
              this.setState({ message: "Votre commande a bien été modifiée" }, () => {
                setTimeout(() => {
                  this.setState({
                    message: null
                  });
                }, 3000);
              });
            }, 1000);
          }
        );
      }
    });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, 2500);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  handleChange = (evt, data) => {
    const { formData } = this.state;
    if (data.name === "sBeneficiaire") {
      const regex = /^[a-zA-Z0-9À-ÿ\u00f1\u00d1 ]*$/gm;
      const result = regex.test(data.value);
      if (result === false) {
        return null;
      }
    }
    this.setState(
      {
        formData: {
          [data.ipkcommandeligne]: {
            ...formData[data.ipkcommandeligne],
            [data.name]:
              data.name === "sBeneficiaire"
                ? data.value
                  ? data.value.toUpperCase()
                  : ""
                : data.value,
            iPKCommandeLigne: data.ipkcommandeligne
          }
        }
      },
      () => {
        this.editCommandeLigne(data.ipkcommandeligne);
      }
    );
  };

  handleChangeQte = (evt, data) => {
    const { formData } = this.state;
    if (
      Number.isInteger(Number(data.value)) &&
      Number(data.value) >= 0 &&
      Number(data.value) < Number(process.env.REACT_APP_LIMITE_QTE)
    ) {
      this.setState(
        {
          formData: {
            [data.ipkcommandeligne]: {
              ...formData[data.ipkcommandeligne],
              [data.name]: Number(data.value),
              iPKCommandeLigne: data.ipkcommandeligne
            }
          }
        },
        () => {
          this.editCommandeLigne(data.ipkcommandeligne);
        }
      );
    } else {
      this.setState(
        {
          error:
            Number(data.value) >= Number(process.env.REACT_APP_LIMITE_QTE)
              ? "La quantité saisie est trop importante"
              : "Vous ne pouvez pas mettre de quantité négative"
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null
            });
          }, 3000);
        }
      );
    }
  };

  handleChangeLivraison = date => {
    const { commande } = this.state;
    this.setState(
      { commande: { ...commande, dLivraison: DateTime.fromJSDate(date).toMillis() } },
      () => {
        clearTimeout(this.autoSaveCmd);
        this.autoSaveCmd = setTimeout(() => {
          this.handleSubmit();
        }, 1000);
      }
    );
  };

  handleChangeConsigne = (evt, data) => {
    const { commande } = this.state;
    this.setState({ commande: { ...commande, sConsigne: data.value } }, () => {
      clearTimeout(this.autoSaveCmd);
      this.autoSaveCmd = setTimeout(() => {
        this.handleSubmit();
      }, 1000);
    });
  };

  handleSubmit = () => {
    const { commande } = this.state;
    const commandeEdit = {
      ...commande,
      dLivraison:
        typeof commande.dLivraison === "number"
          ? DateTime.fromMillis(commande.dLivraison).toFormat("yyyy-MM-dd HH:mm")
          : null
    };
    const GET_COMMANDE = gql`mutation
      {
        editCommande(commande: ${JSON.stringify(JSON.stringify(commandeEdit))}) {
          iPKCommande
          iEtat
          dInsert
          bPriorite
          dLivraison
        }
      }
    `;
    fetchApollo(GET_COMMANDE).then(response => {
      const resultCommande = response.data.editCommande;

      if (resultCommande) {
        const currentDate = new Date();
        const tz = Math.abs(currentDate.getTimezoneOffset() / 60);
        this.setState(
          {
            commande: {
              ...resultCommande,
              dLivraison:
                Number(resultCommande.dLivraison) > 0
                  ? DateTime.fromMillis(Number(resultCommande.dLivraison))
                    .toUTC()
                    .setZone(`UTC+${tz}`, { keepLocalTime: true })
                    .toMillis()
                  : ""
            },
            message: "Votre commande a bien été modifiée"
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: null
              });
            }, 3000);
          }
        );
      }
    });
  };

  downloadFile = (e, documentPath) => {
    e.preventDefault();
    e.stopPropagation();

    const token = getCookie("jwtToken");
    fetch(`${process.env.REACT_APP_GRAPHQL_URL}/document/${documentPath}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': `Bearer ${token}`
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          documentPath,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  render() {
    const { tCommandeLigne, showConfirmDelete, commande, isOpen, message, error } = this.state;
    tCommandeLigne.sort((a, b) => {
      if (a.sLibelle < b.sLibelle) {
        return -1;
      }
      if (a.sLibelle > b.sLibelle) {
        return 1;
      }
      return 0;
    });
    const currentDate = new Date();
    const tz = Math.abs(currentDate.getTimezoneOffset() / 60);
    return (
      <Layout
        mainPane={
          <>
            <Grid stackable container doubling>
              <Grid.Row columns={1}>
                <Form style={{ width: "100%" }}>
                  <Grid.Column>
                    <h1>
                      Commande N°{commande.iPKCommande}{" "}
                      <span style={{ fontSize: 17, color: "grey", fontStyle: "italic" }}>
                        Le{" "}
                        {DateTime.fromMillis(parseInt(commande.dInsert, 10))
                          .toUTC()
                          .setZone(`UTC+${tz}`, { keepLocalTime: true })
                          .toFormat("dd/MM/yyyy à HH:mm")}
                      </span>
                      <Popup
                        content="Imprimer"
                        position="top center"
                        open={isOpen}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                        trigger={
                          <span style={{ float: "right" }}>
                            <IconPrint
                              className="noPrint"
                              name="print"
                              onClick={() => {
                                this.setState({ isOpen: false }, () => {
                                  window.print();
                                });
                              }}
                            />
                          </span>
                        }
                      />
                    </h1>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Libellé</Table.HeaderCell>
                          <Table.HeaderCell>Référence</Table.HeaderCell>
                          <Table.HeaderCell>Quantité</Table.HeaderCell>
                          <Table.HeaderCell>Service - Chambre - Bénéficiaire</Table.HeaderCell>
                          <Table.HeaderCell>Informations complémentaires</Table.HeaderCell>
                          <Table.HeaderCell>Urgent</Table.HeaderCell>
                          {commande.iEtat < 1 && <Table.HeaderCell className="noPrint" />}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {tCommandeLigne.map(p => (
                          <Table.Row>
                            <Table.Cell>
                              <p className="labelPrint inputPrint">{p.sLibelle}</p>
                            </Table.Cell>
                            <Table.Cell>{p.sRefProduit}</Table.Cell>
                            <Table.Cell>
                              {commande.iEtat === 0 ? (
                                <>
                                  <Input
                                    id={`iQuantite${p.iPKProduit}`}
                                    type="number"
                                    min="0"
                                    ipkcommandeligne={p.iPKCommandeLigne}
                                    name="iQuantite"
                                    onChange={this.handleChangeQte}
                                    value={p.iQuantite}
                                    className="noPrint"
                                  />
                                  <p className="goPrint">{p.iQuantite}</p>
                                </>
                              ) : (
                                p.iQuantite
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {commande.iEtat === 0 ? (
                                <span {...(p.sBeneficiaire && { "data-tooltip": p.sBeneficiaire })}>
                                  <Input
                                    type="text"
                                    id={`sBeneficiaire${p.iPKCommandeLigne}`}
                                    ipkcommandeligne={p.iPKCommandeLigne}
                                    name="sBeneficiaire"
                                    onChange={this.handleChange}
                                    value={p.sBeneficiaire}
                                    className="noPrint"
                                  />
                                  <p className="goPrint">{p.sBeneficiaire}</p>
                                </span>
                              ) : (
                                p.sBeneficiaire
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {" "}
                              {p.sFields && p.sFields !== "null"
                                ? Object.keys(JSON.parse(p.sFields)).map(field => (
                                  <>
                                    {field}: {JSON.parse(p.sFields)[field]}{" "}
                                  </>
                                ))
                                : ""}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <Checkbox
                                id={`bPriorite${p.iPKCommandeLigne}`}
                                disabled={commande.iEtat !== 0}
                                ipkcommandeligne={p.iPKCommandeLigne}
                                name="bPriorite"
                                onChange={this.handleChange}
                                value={!p.bPriorite}
                                checked={p.bPriorite}
                              />
                            </Table.Cell>
                            {commande.iEtat === 0 && (
                              <Table.Cell className="noPrint">
                                <span data-tooltip="Supprimer">
                                  <Icon
                                    onClick={() => {
                                      this.setState({
                                        iPKCommandeLigneDelete: p.iPKCommandeLigne,
                                        showConfirmDelete: true
                                      });
                                    }}
                                    style={{ cursor: "pointer" }}
                                    name="trash"
                                  />
                                </span>
                              </Table.Cell>
                            )}
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                    {commande.files && commande.files.length > 0 &&
                      <Card fluid>
                        <Card.Content>
                          <Card.Header style={{ fontSize: 14 }}>Pièce(s) jointe(s)</Card.Header>
                        </Card.Content>
                        <Card.Content>
                          {commande.files.map(
                            file => (
                              <Button
                                icon="download"
                                as="a"
                                label={{
                                  basic: true,
                                  content: file.name
                                }}
                                onClick={(e) => this.downloadFile(e, file.path)}
                                labelPosition="right"
                                href=''
                              />
                            )
                          )}
                        </Card.Content>
                      </Card>
                    }
                    <Card fluid>
                      <Card.Content>
                        <Form.Field>
                          <Form.TextArea
                            id="sConsigne"
                            className="noPrint"
                            disabled={commande.iEtat !== 0 || tCommandeLigne.length === 0}
                            onChange={this.handleChangeConsigne}
                            label="Consignes particulières de livraison"
                            placeholder="Consignes particulières de livraison"
                            value={commande.sConsigne}
                          />
                          <div className="goPrint">
                            <b>Consignes particulières de livraison</b>
                            <p>{commande.sConsigne}</p>
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <label className="noPrint" htmlFor="dLivraison">
                            Date de livraison souhaitée
                          </label>
                          <DatePicker
                            id="dLivraison"
                            className="noPrint"
                            disabled={commande.iEtat !== 0 || tCommandeLigne.length === 0}
                            selected={parseInt(commande.dLivraison, 10)}
                            onChange={date => this.handleChangeLivraison(date)}
                            locale="fr"
                            timeFormat="HH:mm"
                            showTimeSelect
                            showMonthDropdown
                            showYearDropdown
                            timeCaption="Heure"
                            minDate={
                              currentDate.getHours() >= 12
                                ? setDate(currentDate, currentDate.getDate() + 1)
                                : currentDate
                            }
                            minTime={
                              new Date(commande.dLivraison).getDate() === currentDate.getDate() ||
                                !commande.dLivraison
                                ? currentDate.getHours() >= 12
                                  ? setHours(setMinutes(new Date(), 0), 8)
                                  : new Date()
                                : setHours(setMinutes(new Date(), 0), 8)
                            }
                            maxTime={setHours(setMinutes(currentDate, 0), 20)}
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy - HH:mm"
                          />
                          <div className="goPrint">
                            <b>Date de livraison souhaitée</b>
                            <p>
                              {commande.dLivraison &&
                                DateTime.fromMillis(parseInt(commande.dLivraison, 10)).toFormat(
                                  "dd/MM/yyyy - HH:mm"
                                )}
                            </p>
                          </div>
                        </Form.Field>
                        <span style={{ clear: "both" }} />
                      </Card.Content>
                    </Card>
                    {message && (
                      <Message fluid positive>
                        {message}
                      </Message>
                    )}
                    {error && (
                      <Message fluid negative>
                        {error}
                      </Message>
                    )}
                  </Grid.Column>
                </Form>
              </Grid.Row>
            </Grid>
            <Modal
              size="tiny"
              onClose={() => this.setState({ showConfirmDelete: false })}
              open={showConfirmDelete && tCommandeLigne.length === 1}
            >
              <Modal.Content>
                Vous ne pouvez pas supprimer le dernier produit, il faut annuler la commande.
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({ showConfirmDelete: false })} primary>
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
            <Confirm
              open={showConfirmDelete && tCommandeLigne.length > 1}
              content={
                <div className="content">Etes-vous sûr de vouloir supprimer cette ligne ?</div>
              }
              cancelButton="Annuler"
              confirmButton="Confirmer"
              onCancel={() => this.setState({ showConfirmDelete: false })}
              onConfirm={() =>
                this.setState({ showConfirmDelete: false }, () => this.deleteCommandeLigne())
              }
            />
          </>
        }
      />
    );
  }
}

CommandeShow.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CommandeShow;
