import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Form, Message, Card } from "semantic-ui-react";

import { addClient, editClient } from "../../../redux/actions/client";

import BtnSubmit from "../../Core/Btn/Submit";

import "react-sortable-tree/style.css";

const FormTitle = styled.div`
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

const CustomBtnSubmit = styled(BtnSubmit)`
  margin-top: 21px;
`;

class FormClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sLibelle: props.client ? props.client.sLibelle : "",
      errors: {},
      disabled: true,
      message: null
    };
  }

  handleChange = (evt, data) => {
    const { errors } = this.state;
    const error = { ...errors, [data.id]: false };
    this.setState({ [data.id]: data.value, error, disabled: false });
  };

  handleSubmit = e => {
    const { dispatch, client } = this.props;
    const { sLibelle } = this.state;
    e.stopPropagation();
    e.preventDefault();
    const errors = {};
    if (!sLibelle) {
      errors.sLibelle = "Champ obligatoire";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      if (client) {
        dispatch(editClient({ ...this.state, iPKClient: client.iPKClient }));
        this.setState({
          disabled: true,
          message: "Le client a bien été modifié"
        });
      } else {
        dispatch(addClient(this.state));
        this.setState({
          sLibelle: "",
          disabled: true,
          message: "Le client a bien été créé"
        });
      }
      setTimeout(() => {
        this.setState({
          message: null
        });
      }, 3000);
    }
  };

  render() {
    const { client } = this.props;
    const { sLibelle, errors, message, disabled } = this.state;

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <FormTitle>{client ? "Modification du client" : "Création d'un client"}</FormTitle>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form onSubmit={this.handleSubmit}>
            {client ? (
              <>
                <Form.Group>
                  <Form.Input
                    id="sLibelle"
                    label="Nom"
                    value={sLibelle}
                    placeholder="Nom"
                    onChange={this.handleChange}
                    error={
                      errors.sLibelle ? { content: errors.sLibelle, pointing: "below" } : false
                    }
                  />
                  <CustomBtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
                    Enregistrer
                  </CustomBtnSubmit>{" "}
                </Form.Group>
                {message && (
                  <Message positive fluid>
                    {message}
                  </Message>
                )}
                <p>
                  <b>Catalogue</b>
                  <br />
                  <Link to={`/catalogues/${client.iFKCatalogue}`}>{client.sLibelleCatalogue}</Link>
                </p>
              </>
            ) : (
              <>
                <Form.Field>
                  <Form.Input
                    id="sLibelle"
                    label="Nom"
                    value={sLibelle}
                    placeholder="Nom"
                    onChange={this.handleChange}
                    error={
                      errors.sLibelle ? { content: errors.sLibelle, pointing: "below" } : false
                    }
                  />
                </Form.Field>
                {message && (
                  <Message positive fluid>
                    {message}
                  </Message>
                )}
                <BtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
                  Enregistrer
                </BtnSubmit>{" "}
              </>
            )}
          </Form>
        </Card.Content>
      </Card>
    );
  }
}

FormClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
  client: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  error: state.client.error,
  tCatalogue: state.catalogue.liste
});

export default connect(mapStateToProps)(FormClient);
