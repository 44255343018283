import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import { Form, Icon, Input } from "semantic-ui-react";

import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";

import LayoutAdmin from "../../../../components/Admin/Layout";
import FormUtilisateur from "../../../../components/Admin/Utilisateur/Form";
import FormGroupe from "../../../../components/Admin/Groupe/Form";
import FormClient from "../../../../components/Admin/Client/Form";

import BtnSubmit from "../../../../components/Core/Btn/Submit";

import { getClients } from "../../../../redux/actions/client";
import { getCataloguesWithoutClient } from "../../../../redux/actions/catalogue";
import { getGroups, changeUserGroup } from "../../../../redux/actions/group";

const ArianeLink = styled(Link)`
  color: ${(props) => props.theme.colors.title.link};
  &:hover {
    color: ${(props) => props.theme.colors.title.link} !important;
    text-decoration: underline !important;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.button.primary.background};
  font-size: 1.2em !important;
`;

const CustomSortableTree = styled(SortableTree)`
  margin-top: 5px;
  .rst__rowWrapper {
    padding: 3px 3px 3px 0;
    cursor: pointer;
  }
  .rst__rowSearchMatch {
    outline: none !important;
    color: white !important;
    .rst__rowContents {
      background-color: ${(props) => props.theme.button.primary.background} !important;
      i {
        color: white !important;
      }
    }
  }
`;

class ClientShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanel: null,
      nbSearch: null,
      sKeywordSearch: "",
      client: {},
      disabledTree: true,
      node: {},
    };
    this.iPKChildren = {};
    this.children = {};
    this.treeGroupes = [];
  }

  componentDidMount() {
    const {
      dispatch,
      match: { params },
      clients,
    } = this.props;

    if (clients.length === 0) {
      dispatch(getClients());
    } else {
      const client = clients.filter((c) => c.iPKClient === params.iPKClient)[0];
      const rightPanel = [];
      rightPanel.push(<FormClient key="formClient" client={client} />);
      this.setState(
        {
          client,
          rightPanel,
        },
        () => {
          dispatch(getGroups(client.iPKClient));
        }
      );
    }
    dispatch(getCataloguesWithoutClient());
  }

  async componentDidUpdate(prevProps) {
    const {
      dispatch,
      clients,
      groupes,
      match: { params },
    } = this.props;
    const { sKeywordSearch } = this.state;

    if (prevProps.match.params !== params || prevProps.clients !== clients) {
      const client = clients.filter((c) => c.iPKClient === params.iPKClient)[0];
      if (client) {
        const rightPanel = [];
        rightPanel.push(<FormClient key="formClient" client={client} />);
        this.setState(
          {
            client,
            rightPanel,
          },
          () => {
            dispatch(getGroups(client.iPKClient));
          }
        );
      }
    }
    if (prevProps.groupes !== groupes) {
      const treeGroupes = await this.createTree(groupes);
      if (treeGroupes) {
        this.setState({
          groupes: treeGroupes.filter((g) => g.level === 0),
          sKeywordSearch: prevProps.groupes.length > groupes.length ? "" : sKeywordSearch,
        });
      }
    }
  }

  createTree = async (groupes, level = 4) => {
    if (level >= 0) {
      const parents = _.orderBy(
        groupes.filter((g) => g.level === level),
        ["title"],
        ["asc"]
      );
      parents.forEach((p) => {
        p.title = (
          <>
            <StyledIcon name="group" /> {p.title} {p.iFKAnnAnnuaire ? " (is@dom)" : ""}
          </>
        );
        const childs = groupes.filter((c) => parseInt(p.iPKGroupe, 10) === c.iFKGroupeParent);
        p.children = p.children.concat(childs);
        p.children.forEach((c) => {
          if (c.iPKUtilisateur) {
            c.title = (
              <div className={c.bInactif ? "inactif" : ""}>
                <Icon
                  name={c.iRole === 1 || c.iRole === 2 ? "shield" : "user"}
                  color={c.bInactif ? "grey" : "orange"}
                />{" "}
                {c.title} {c.sPrenom}
                {c.iFKAnnAnnuaire ? ` (is@dom)` : ""}
              </div>
            );
          }
        });
      });

      this.treeGroupes.push(parents);

      this.createTree(_.orderBy(groupes, ["title"], ["asc"]), level - 1);
    }

    return this.treeGroupes[this.treeGroupes.length - 1];
  };

  onClick = (node) => {
    const { client } = this.state;
    let sKeywordSearch;
    const rightPanel = [];
    rightPanel.push(<FormClient key="FormClient" client={client} />);
    if (node.type === "user") {
      rightPanel.push(
        <FormUtilisateur
          key="FormUtilisateur"
          selectNode={this.selectNode}
          iFKClient={client.iPKClient}
          user={node}
        />
      );
      sKeywordSearch = `${node.sNom} ${node.sPrenom || ""}`;
    } else {
      rightPanel.push(
        <FormGroupe
          key="FormGroupe"
          selectNode={this.selectNode}
          iFKClient={client.iPKClient}
          group={node}
        />
      );
      sKeywordSearch = `${node.sLibelle}`;
    }
    this.setState({
      rightPanel,
      sKeywordSearch,
      node,
    });
  };

  showCreateForm = (type, node) => {
    const { client } = this.state;
    const rightPanel = [];
    rightPanel.push(<FormClient key="FormClient" client={client} />);
    if (type === "user") {
      rightPanel.push(
        <FormUtilisateur
          key="FormUtilisateur"
          selectNode={this.selectNode}
          iFKClient={parseInt(client.iPKClient, 10)}
          user={{ iFKGroupe: parseInt(node.iPKGroupe, 10) || node.iFKGroupe }}
        />
      );
    } else {
      rightPanel.push(
        <FormGroupe
          key="FormGroupe"
          selectNode={this.selectNode}
          iFKClient={client.iPKClient}
          group={{}}
        />
      );
    }
    this.setState({
      rightPanel,
    });
  };

  selectNode = (sLibelle) => {
    setTimeout(() => {
      this.setState({ sKeywordSearch: sLibelle });
    }, 150);
  };

  generateNodeProps = (obj) => {
    return { onClick: () => this.onClick(obj.node) };
  };

  onChange = (treeData) => {
    this.setState({ groupes: treeData });
  };

  canDrop = (obj) => {
    return (
      (obj.node.type === "user" && obj.nextParent && obj.nextParent.type === "group") ||
      (obj.node.type === "group" &&
        ((obj.nextParent && obj.nextParent.type === "group") || !obj.nextParent))
    );
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { groupes } = this.state;
    dispatch(changeUserGroup(groupes));
    this.setState({ disabledTree: true });
  };

  handleChangeSearch = (evt, data) => {
    this.setState({ sKeywordSearch: data.value });
  };

  render() {
    const {
      rightPanel,
      groupes,
      client,
      disabledTree,
      nbSearch,
      sKeywordSearch,
      node,
    } = this.state;
    const link = <ArianeLink to="/clients">Gestion clients</ArianeLink>;

    return (
      <LayoutAdmin linkTitle={link} title={client.sLibelle} rightPanel={rightPanel}>
        <>
          <BtnSubmit onClick={() => this.showCreateForm("group")}>Ajouter un groupe</BtnSubmit>
          <BtnSubmit onClick={() => this.showCreateForm("user", node)}>
            Ajouter un utilisateur
          </BtnSubmit>
          {!disabledTree && (
            <BtnSubmit onClick={this.handleSubmit} type="submit">
              Sauvegarder la hiérarchie
            </BtnSubmit>
          )}
          <Input
            id="sKeywordSearch"
            type="text"
            icon="search"
            iconPosition="left"
            autoComplete="off"
            style={{ width: 290, height: 42 }}
            value={sKeywordSearch}
            placeholder="Rechercher un groupe ou un utilisateur"
            onChange={this.handleChangeSearch}
          />
          {nbSearch !== 0 && (
            <span style={{ marginLeft: 10, fontWeight: "bold" }}>
              {nbSearch} Résultat{nbSearch > 1 ? "s" : ""}
            </span>
          )}
          <Form onSubmit={this.handleSubmit}>
            {groupes && groupes.length > 0 && (
              <div style={{ height: window.innerHeight - 210 }}>
                <CustomSortableTree
                  rowHeight={40}
                  searchFocusOffset={1}
                  onlyExpandSearchedNodes={false}
                  slideRegionSize={window.innerHeight - 210}
                  searchMethod={(search) => {
                    const sSearch = search.searchQuery
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "");
                    let sNomPrenon = null;
                    if (search.node.sPrenom && search.node.sNom) {
                      sNomPrenon = `${search.node.sNom} ${search.node.sPrenom}`;
                    }
                    return (
                      (search.searchQuery &&
                        search.node.sLibelle &&
                        search.node.sLibelle
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .includes(sSearch)) ||
                      (search.searchQuery &&
                        search.node.sNom &&
                        search.node.sNom
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .includes(sSearch)) ||
                      (search.searchQuery &&
                        search.node.sPrenom &&
                        search.node.sPrenom
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .includes(sSearch)) ||
                      (search.searchQuery &&
                        sNomPrenon &&
                        sNomPrenon
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .includes(sSearch))
                    );
                  }}
                  isVirtualized={false}
                  searchQuery={sKeywordSearch}
                  canDrop={this.canDrop}
                  searchFinishCallback={(matches) => {
                    this.setState({ nbSearch: matches.length });
                  }}
                  onMoveNode={() => this.setState({ disabledTree: false })}
                  generateNodeProps={this.generateNodeProps}
                  treeData={groupes}
                  onChange={(treeData) => this.onChange(treeData)}
                />
              </div>
            )}
          </Form>
        </>
      </LayoutAdmin>
    );
  }
}

ClientShow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.any).isRequired,
  groupes: PropTypes.arrayOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  clients: state.client.liste,
  groupes: state.group.liste,
  tCatalogue: state.catalogue.liste,
});

export default connect(mapStateToProps)(ClientShow);
