import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_PANIER_SUCCESS,
  GET_PANIER_FAILURE,
  ADD_PANIER_SUCCESS,
  ADD_PANIER_FAILURE,
  EDIT_PANIER_SUCCESS,
  EDIT_PANIER_FAILURE,
  DELETE_PANIER_SUCCESS,
  DELETE_PANIER_FAILURE,
  VALIDE_PANIER_SUCCESS,
  VALIDE_PANIER_FAILURE
} from "../constants/ActionTypes";

export function* getPanier() {
  try {
    const getPanierRequest = gql`
      {
        getPanier {
          iPKMatPanier
          iFKUtilisateur
          produit {
            iPKProduit
            sLibelle
            sRefProduit
            sInformation
            sCodeNatureProduit
            sBeneficiaire
            bPriorite
            sImgPath
            bInactif
            iFKCatFamille
            iQuantite
          }
          sFields
          iQteProduit
        }
      }
    `;
    const response = yield call(fetchApollo, getPanierRequest);
    const panier = response.data.getPanier;

    if (panier !== null) {
      yield put({ type: GET_PANIER_SUCCESS, payload: panier });
    } else {
      yield put({
        type: GET_PANIER_FAILURE,
        payload: "Aucun panier trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: GET_PANIER_FAILURE, payload: message });
  }
}

export function* addPanier({
  payload: { iPKProduit, iPKUtilisateur, iQteProduit, sFields, sBeneficiaire, bPriorite }
}) {
  try {
    const ADD_PANIER_REQUEST = gql`mutation {
        addPanier(
          iPKProduit: ${iPKProduit},
          iQteProduit: ${iQteProduit},
          sBeneficiaire: "${sBeneficiaire}",
          sFields: ${
            typeof sFields === "object"
              ? JSON.stringify(JSON.stringify(sFields))
              : JSON.stringify(sFields)
          }
          bPriorite: ${bPriorite}
        ) {
            iPKMatPanier
            iFKUtilisateur
            produit {
              iPKProduit
              sLibelle
              sRefProduit
              sInformation
              bPriorite
              sBeneficiaire
              sCodeNatureProduit
              sImgPath
              bInactif
              iFKCatFamille
              iQuantite
              sFields
            }
            iQteProduit
        }
      }`;
    const response = yield call(fetchApollo, ADD_PANIER_REQUEST);
    const savedPanier = response.data.addPanier;

    if (savedPanier !== null) {
      yield put({ type: ADD_PANIER_SUCCESS, payload: savedPanier });
    } else {
      yield put({
        type: ADD_PANIER_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_PANIER_FAILURE, payload: message });
  }
}

export function* editPanier({ payload: { iPKMatPanier, iQteProduit } }) {
  try {
    const PANIER_REQUEST = gql`mutation {
        editPanier(iPKMatPanier: ${iPKMatPanier}, iQteProduit: ${iQteProduit}) {
          iPKMatPanier
            iFKUtilisateur
            produit {
              iPKProduit
              sLibelle
              sRefProduit
              sInformation
              sCodeNatureProduit
              sImgPath
              bInactif
              iFKCatFamille
              iQuantite
            }
            iQteProduit
        }
      }`;
    const response = yield call(fetchApollo, PANIER_REQUEST);
    const savedPanier = response.data.editPanier;

    if (savedPanier !== null) {
      yield put({ type: EDIT_PANIER_SUCCESS, payload: savedPanier });
    } else {
      yield put({
        type: EDIT_PANIER_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_PANIER_FAILURE, payload: message });
  }
}

export function* deletePanier({ payload: { iPKMatPanier } }) {
  try {
    const PANIER_REQUEST = gql`mutation {
        deletePanier(iPKMatPanier: ${iPKMatPanier}) {
          iPKMatPanier
        }
      }`;
    const response = yield call(fetchApollo, PANIER_REQUEST);
    const deletedPanier = response.data.deletePanier;

    if (deletedPanier !== null) {
      yield put({ type: DELETE_PANIER_SUCCESS, payload: deletedPanier });
    } else {
      yield put({
        type: DELETE_PANIER_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_PANIER_FAILURE, payload: message });
  }
}

export function* validePanier({ payload: { panier } }) {
  try {
    const ADD_PANIER_REQUEST = gql`mutation {
        validePanier(panier: ${JSON.stringify(JSON.stringify(panier))}) {
            iResultat
        }
      }`;
    const response = yield call(fetchApollo, ADD_PANIER_REQUEST);
    const savedPanier = response.data.validePanier;

    if (savedPanier !== null) {
      yield put({ type: VALIDE_PANIER_SUCCESS, payload: savedPanier });
    } else {
      yield put({
        type: VALIDE_PANIER_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: VALIDE_PANIER_FAILURE, payload: message });
  }
}
