import {
  GET_CATALOGUE_SUCCESS,
  GET_CATALOGUE_FAILURE,
  ADD_CATALOGUE_SUCCESS,
  ADD_CATALOGUE_FAILURE,
  EDIT_CATALOGUE_SUCCESS,
  EDIT_CATALOGUE_FAILURE,
  DELETE_CATALOGUE_SUCCESS,
  DELETE_CATALOGUE_FAILURE,
  LISTE_CATALOGUE_SUCCESS,
  LISTE_CATALOGUE_FAILURE,
  LISTE_FAMILLE_CATALOGUE_SUCCESS,
  LISTE_FAMILLE_CATALOGUE_FAILURE,
  IMPORT_FAMILLE_CATALOGUE_SUCCESS,
  IMPORT_FAMILLE_CATALOGUE_FAILURE,
  LISTE_ISADOM_FAMILLE_CATALOGUE_SUCCESS,
  LISTE_ISADOM_FAMILLE_CATALOGUE_FAILURE
} from "../constants/ActionTypes";

const initialState = {
  courant: {},
  liste: [],
  tFamille: [],
  tIsadomFamille: [],
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CATALOGUE_SUCCESS: {
      return { ...state, courant: payload, error: null };
    }
    case GET_CATALOGUE_FAILURE: {
      return { ...state, error: payload };
    }
    case ADD_CATALOGUE_SUCCESS: {
      return { ...state, liste: [...state.liste, payload], error: null };
    }
    case ADD_CATALOGUE_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case EDIT_CATALOGUE_SUCCESS: {
      return { ...state, courant: payload, error: null };
    }
    case EDIT_CATALOGUE_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case DELETE_CATALOGUE_SUCCESS: {
      return {
        ...state,
        liste: state.liste.filter(c => c.iPKCatalogue !== payload.iPKCatalogue),
        error: null
      };
    }
    case DELETE_CATALOGUE_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case LISTE_CATALOGUE_SUCCESS: {
      return { ...state, liste: payload, error: null };
    }
    case LISTE_CATALOGUE_FAILURE: {
      return { ...state, error: payload };
    }
    case LISTE_FAMILLE_CATALOGUE_SUCCESS: {
      return { ...state, tFamille: payload, error: null };
    }
    case LISTE_FAMILLE_CATALOGUE_FAILURE: {
      return { ...state, error: payload };
    }
    case IMPORT_FAMILLE_CATALOGUE_SUCCESS: {
      return { ...state, success: true, error: null };
    }
    case IMPORT_FAMILLE_CATALOGUE_FAILURE: {
      return { ...state, error: payload };
    }
    case LISTE_ISADOM_FAMILLE_CATALOGUE_SUCCESS: {
      return { ...state, tIsadomFamille: payload, error: null };
    }
    case LISTE_ISADOM_FAMILLE_CATALOGUE_FAILURE: {
      return { ...state, error: payload };
    }
    default:
      return state;
  }
};
