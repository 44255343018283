export const USER_SIGN_IN_REQUEST = "USER_SIGN_IN_REQUEST";
export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS";
export const USER_SIGN_IN_FAILURE = "USER_SIGN_IN_FAILURE";

export const USER_SIGN_OUT_REQUEST = "USER_SIGN_OUT_REQUEST";
export const USER_SIGN_OUT_SUCCESS = "USER_SIGN_OUT_SUCCESS";
export const USER_SIGN_OUT_FAILURE = "USER_SIGN_OUT_FAILURE";

export const SET_USER = "SET_USER";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const SEARCH_USER_REQUEST = "SEARCH_USER_REQUEST";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";

export const CLEAR_LIST_SEARCH_USER_REQUEST = "CLEAR_LIST_SEARCH_USER_REQUEST";
export const CLEAR_LIST_SEARCH_USER_SUCCESS = "CLEAR_LIST_SEARCH_USER_SUCCESS";

export const LISTE_CONTACT_REQUEST = "LISTE_CONTACT_REQUEST";
export const LISTE_CONTACT_SUCCESS = "LISTE_CONTACT_SUCCESS";
export const LISTE_CONTACT_FAILURE = "LISTE_CONTACT_FAILURE";

export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

export const EDIT_CONTACT_REQUEST = "EDIT_CONTACT_REQUEST";
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
export const EDIT_CONTACT_FAILURE = "EDIT_CONTACT_FAILURE";

export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";

export const LISTE_CLIENT_REQUEST = "LISTE_CLIENT_REQUEST";
export const LISTE_CLIENT_WITHOUT_CATALOGUE_REQUEST = "LISTE_CLIENT_WITHOUT_CATALOGUE_REQUEST";
export const LISTE_CLIENT_SUCCESS = "LISTE_CLIENT_SUCCESS";
export const LISTE_CLIENT_FAILURE = "LISTE_CLIENT_FAILURE";

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILURE = "EDIT_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const FUSION_CLIENT_REQUEST = "FUSION_CLIENT_REQUEST";
export const FUSION_CLIENT_SUCCESS = "FUSION_CLIENT_SUCCESS";
export const FUSION_CLIENT_FAILURE = "FUSION_CLIENT_FAILURE";

export const GET_GROUP_REQUEST = "GET_GROUP_REQUEST";
export const GET_GROUP_BY_USER_REQUEST = "GET_GROUP_BY_USER_REQUEST";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAILURE = "GET_GROUP_FAILURE";

export const ADD_GROUP_REQUEST = "ADD_GROUP_REQUEST";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILURE = "ADD_GROUP_FAILURE";

export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAILURE = "EDIT_GROUP_FAILURE";

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";

export const SEARCH_GROUP_REQUEST = "SEARCH_GROUP_REQUEST";
export const SEARCH_GROUP_SUCCESS = "SEARCH_GROUP_SUCCESS";
export const SEARCH_GROUP_FAILURE = "SEARCH_GROUP_FAILURE";

export const CHANGE_USER_GROUP_REQUEST = "CHANGE_USER_GROUP_REQUEST";
export const CHANGE_USER_GROUP_SUCCESS = "CHANGE_USER_GROUP_SUCCESS";
export const CHANGE_USER_GROUP_FAILURE = "CHANGE_USER_GROUP_FAILURE";

export const LISTE_CATALOGUE_REQUEST = "LISTE_CATALOGUE_REQUEST";
export const LISTE_CATALOGUE_WITHOUT_CLIENT_REQUEST = "LISTE_CATALOGUE_WITHOUT_CLIENT_REQUEST";
export const LISTE_CATALOGUE_SUCCESS = "LISTE_CATALOGUE_SUCCESS";
export const LISTE_CATALOGUE_FAILURE = "LISTE_CATALOGUE_FAILURE";

export const GET_CATALOGUE_REQUEST = "GET_CATALOGUE_REQUEST";
export const GET_CATALOGUE_SUCCESS = "GET_CATALOGUE_SUCCESS";
export const GET_CATALOGUE_FAILURE = "GET_CATALOGUE_FAILURE";

export const ADD_CATALOGUE_REQUEST = "ADD_CATALOGUE_REQUEST";
export const ADD_CATALOGUE_SUCCESS = "ADD_CATALOGUE_SUCCESS";
export const ADD_CATALOGUE_FAILURE = "ADD_CATALOGUE_FAILURE";

export const EDIT_CATALOGUE_REQUEST = "EDIT_CATALOGUE_REQUEST";
export const EDIT_CATALOGUE_SUCCESS = "EDIT_CATALOGUE_SUCCESS";
export const EDIT_CATALOGUE_FAILURE = "EDIT_CATALOGUE_FAILURE";

export const DELETE_CATALOGUE_REQUEST = "DELETE_CATALOGUE_REQUEST";
export const DELETE_CATALOGUE_SUCCESS = "DELETE_CATALOGUE_SUCCESS";
export const DELETE_CATALOGUE_FAILURE = "DELETE_CATALOGUE_FAILURE";

export const LISTE_FAMILLE_CATALOGUE_REQUEST = "LISTE_FAMILLE_CATALOGUE_REQUEST";
export const LISTE_FAMILLE_CATALOGUE_SUCCESS = "LISTE_FAMILLE_CATALOGUE_SUCCESS";
export const LISTE_FAMILLE_CATALOGUE_FAILURE = "LISTE_FAMILLE_CATALOGUE_FAILURE";

export const IMPORT_FAMILLE_CATALOGUE_REQUEST = "IMPORT_FAMILLE_CATALOGUE_REQUEST";
export const IMPORT_FAMILLE_CATALOGUE_SUCCESS = "IMPORT_FAMILLE_CATALOGUE_SUCCESS";
export const IMPORT_FAMILLE_CATALOGUE_FAILURE = "IMPORT_FAMILLE_CATALOGUE_FAILURE";

export const LISTE_ISADOM_FAMILLE_CATALOGUE_REQUEST = "LISTE_ISADOM_FAMILLE_CATALOGUE_REQUEST";
export const LISTE_ISADOM_FAMILLE_CATALOGUE_SUCCESS = "LISTE_ISADOM_FAMILLE_CATALOGUE_SUCCESS";
export const LISTE_ISADOM_FAMILLE_CATALOGUE_FAILURE = "LISTE_ISADOM_FAMILLE_CATALOGUE_FAILURE";

export const EDIT_FAMILLE_REQUEST = "EDIT_FAMILLE_REQUEST";
export const EDIT_FAMILLE_SUCCESS = "EDIT_FAMILLE_SUCCESS";
export const EDIT_FAMILLE_FAILURE = "EDIT_FAMILLE_FAILURE";

export const DELETE_FAMILLE_REQUEST = "DELETE_FAMILLE_REQUEST";
export const DELETE_FAMILLE_SUCCESS = "DELETE_FAMILLE_SUCCESS";
export const DELETE_FAMILLE_FAILURE = "DELETE_FAMILLE_FAILURE";

export const EDIT_PRODUIT_REQUEST = "EDIT_PRODUIT_REQUEST";
export const EDIT_PRODUIT_SUCCESS = "EDIT_PRODUIT_SUCCESS";
export const EDIT_PRODUIT_FAILURE = "EDIT_PRODUIT_FAILURE";

export const DELETE_PRODUIT_REQUEST = "DELETE_PRODUIT_REQUEST";
export const DELETE_PRODUIT_SUCCESS = "DELETE_PRODUIT_SUCCESS";
export const DELETE_PRODUIT_FAILURE = "DELETE_PRODUIT_FAILURE";

export const LISTE_COMMANDE_REQUEST = "LISTE_COMMANDE_REQUEST";
export const LISTE_COMMANDE_SUCCESS = "LISTE_COMMANDE_SUCCESS";
export const LISTE_COMMANDE_FAILURE = "LISTE_COMMANDE_FAILURE";

export const LISTE_COMMANDE_TYPE_REQUEST = "LISTE_COMMANDE_TYPE_REQUEST";
export const LISTE_COMMANDE_TYPE_SUCCESS = "LISTE_COMMANDE_TYPE_SUCCESS";
export const LISTE_COMMANDE_TYPE_FAILURE = "LISTE_COMMANDE_TYPE_FAILURE";

export const ADD_COMMANDE_TYPE_REQUEST = "ADD_COMMANDE_TYPE_REQUEST";
export const ADD_COMMANDE_TYPE_SUCCESS = "ADD_COMMANDE_TYPE_SUCCESS";
export const ADD_COMMANDE_TYPE_FAILURE = "ADD_COMMANDE_TYPE_FAILURE";

export const DELETE_COMMANDE_TYPE_REQUEST = "DELETE_COMMANDE_TYPE_REQUEST";
export const DELETE_COMMANDE_TYPE_SUCCESS = "DELETE_COMMANDE_TYPE_SUCCESS";
export const DELETE_COMMANDE_TYPE_FAILURE = "DELETE_COMMANDE_TYPE_FAILURE";

export const GET_ALL_COUNT_REQUEST = "GET_ALL_COUNT_REQUEST";
export const GET_ALL_COUNT_SUCCESS = "GET_ALL_COUNT_SUCCESS";
export const GET_ALL_COUNT_FAILURE = "GET_ALL_COUNT_FAILURE";

export const GET_ALL_COUNT_BY_USER_REQUEST = "GET_ALL_COUNT_BY_USER_REQUEST";
export const GET_ALL_COUNT_BY_USER_SUCCESS = "GET_ALL_COUNT_BY_USER_SUCCESS";
export const GET_ALL_COUNT_BY_USER_FAILURE = "GET_ALL_COUNT_BY_USER_FAILURE";

export const GET_PANIER_REQUEST = "GET_PANIER_REQUEST";
export const GET_PANIER_SUCCESS = "GET_PANIER_SUCCESS";
export const GET_PANIER_FAILURE = "GET_PANIER_FAILURE";

export const VALIDE_PANIER_REQUEST = "VALIDE_PANIER_REQUEST";
export const VALIDE_PANIER_SUCCESS = "VALIDE_PANIER_SUCCESS";
export const VALIDE_PANIER_FAILURE = "VALIDE_PANIER_FAILURE";

export const ADD_PANIER_REQUEST = "ADD_PANIER_REQUEST";
export const ADD_PANIER_SUCCESS = "ADD_PANIER_SUCCESS";
export const ADD_PANIER_FAILURE = "ADD_PANIER_FAILURE";

export const EDIT_PANIER_REQUEST = "EDIT_PANIER_REQUEST";
export const EDIT_PANIER_SUCCESS = "EDIT_PANIER_SUCCESS";
export const EDIT_PANIER_FAILURE = "EDIT_PANIER_FAILURE";

export const DELETE_PANIER_REQUEST = "DELETE_PANIER_REQUEST";
export const DELETE_PANIER_SUCCESS = "DELETE_PANIER_SUCCESS";
export const DELETE_PANIER_FAILURE = "DELETE_PANIER_FAILURE";

export const GET_FAVORIS_REQUEST = "GET_FAVORIS_REQUEST";
export const GET_FAVORIS_SUCCESS = "GET_FAVORIS_SUCCESS";
export const GET_FAVORIS_FAILURE = "GET_FAVORIS_FAILURE";

export const ADD_FAVORIS_REQUEST = "ADD_FAVORIS_REQUEST";
export const ADD_FAVORIS_SUCCESS = "ADD_FAVORIS_SUCCESS";
export const ADD_FAVORIS_FAILURE = "ADD_FAVORIS_FAILURE";

export const DELETE_FAVORIS_REQUEST = "DELETE_FAVORIS_REQUEST";
export const DELETE_FAVORIS_SUCCESS = "DELETE_FAVORIS_SUCCESS";
export const DELETE_FAVORIS_FAILURE = "DELETE_FAVORIS_FAILURE";
