// import jwtDecode from "jwt-decode";
import {
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,
  USER_SIGN_OUT_SUCCESS,
  USER_SIGN_OUT_FAILURE,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  CLEAR_LIST_SEARCH_USER_SUCCESS,
  LISTE_CONTACT_SUCCESS,
  LISTE_CONTACT_FAILURE,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE
} from "../constants/ActionTypes";

const initialAuthState = {
  isAuthenticated: false,
  data: {
    iPKUtilisateur: 0,
    iRole: -1,
    sNom: "Invité",
    sPrenom: "Invité",
    sEmail: ""
  },
  contactListe: [],
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case ADD_USER_SUCCESS: {
      return { ...state, error: null, success: payload.success };
    }
    case ADD_USER_FAILURE: {
      return {
        ...state,
        error: `${Math.floor(Math.random() * 10 + 1)} ${payload}`
      };
    }
    case EDIT_USER_SUCCESS: {
      return { ...state, error: null };
    }
    case EDIT_USER_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case DELETE_USER_SUCCESS: {
      return { ...state, error: null };
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case SEARCH_USER_SUCCESS: {
      return { ...state, searchListe: payload, error: null };
    }
    case CLEAR_LIST_SEARCH_USER_SUCCESS: {
      return { ...state, searchListe: [], error: null };
    }
    case SEARCH_USER_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case LISTE_CONTACT_SUCCESS: {
      return { ...state, contactListe: payload, error: null };
    }
    case LISTE_CONTACT_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case ADD_CONTACT_SUCCESS: {
      return {
        ...state,
        contactListe: [...state.contactListe, payload],
        error: null
      };
    }
    case ADD_CONTACT_FAILURE: {
      return {
        ...state,
        error: payload
      };
    }
    case EDIT_CONTACT_SUCCESS: {
      return {
        ...state,
        contactListe: state.contactListe.map(c =>
          c.iPKUtilisateur === payload.iPKUtilisateur ? payload : c
        ),
        error: null
      };
    }
    case EDIT_CONTACT_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case DELETE_CONTACT_SUCCESS: {
      return {
        ...state,
        contactListe: state.contactListe.filter(c => c.iPKUtilisateur !== payload.iPKUtilisateur),
        error: null
      };
    }
    case DELETE_CONTACT_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case USER_SIGN_IN_SUCCESS: {
      const data = {
        iPKUtilisateur: payload.iPKUtilisateur,
        iRole: payload.iRole,
        iFKGroupe: payload.iFKGroupe,
        sLibelleGroupe: payload.sLibelleGroupe,
        iFKCatalogue: payload.iFKCatalogue,
        sLibelleCatalogue: payload.sLibelleCatalogue,
        iFKClient: payload.iFKClient,
        sNom: payload.sNom || "",
        sPrenom: payload.sPrenom || "",
        sEmail: payload.sEmail || "",
        sJeton: payload.sJeton || "",
        dUpdateMdp: payload.dUpdateMdp || "",
        bTarif: payload.bTarif
      };
      return { ...state, isAuthenticated: true, data, error: null };
    }
    case USER_SIGN_IN_FAILURE: {
      return { ...initialAuthState, signInError: payload };
    }

    case USER_SIGN_OUT_SUCCESS: {
      return {
        isAuthenticated: false,
        data: {
          iPKUtilisateur: 0,
          iRole: -1,
          sNom: "Invité",
          sPrenom: "Invité",
          sEmail: ""
        },
        error: null
      };
    }
    case USER_SIGN_OUT_FAILURE: {
      return { ...initialAuthState, error: payload };
    }
    default:
      return state;
  }
};
