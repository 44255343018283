import React, { Component } from "react";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import sha1 from "js-sha1";
import styled from "styled-components";
import { Form, Segment, Icon, Message, Grid } from "semantic-ui-react";
import { DateTime } from "luxon";
import { connect } from "react-redux";
import { generatePassword, checkPassword } from "../../../utils";

import { fetchApollo } from "../../../graphql/apolloClient";

import PasswordInput from "../../Core/Form/Input/PasswordInput";

import Field from "../../Core/Form/Field";
import ErrorNotice from "../../Core/Form/ErrorNotice";
import Submit from "../../Core/Btn/Submit";

const ConnexionField = styled(Field)`
  display: block;

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    display: inline-flex;
    width: 100%;
  }
`;

const SavePassword = styled(Submit)`
  margin-top: 4px;
`;

const TinyPasswordInput = styled(PasswordInput)`
  width: 230px !important;
`;

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sOldPassword: "",
      success: "",
      sPassword: "",
      sConfirmPassword: "",
      disabled: true,
      errors: {}
    };
  }

  onChange = evt => {
    const {
      target: { id, value }
    } = evt;

    this.setState(prevState => ({
      [id]: value,
      disabled: false,
      errors: Object.values(prevState.errors).filter(error => error.id !== id)
    }));
  };

  generatePassword = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    const { errors } = this.state;
    const sPassword = generatePassword();
    this.setState({
      sPassword,
      errors: { ...errors, sPassword: false },
      disabled: false
    });
  };

  changePassword = (sOldPassword, sPassword) => {
    const { user } = this.props;
    const errors = {};
    const EDIT_MDP = gql`mutation {
      changePassword(iPKUtilisateur: ${user.iPKUtilisateur}, sOldPassword: "${sha1(
      sOldPassword
    )}", sPassword: "${sPassword}") {
        iPKUtilisateur
        error
      }
    }`;

    fetchApollo(EDIT_MDP).then(response => {
      const userSaved = response.data.changePassword;
      if (userSaved && !userSaved.error) {
        this.setState({
          success:
            "Votre mot de passe a bien été mis à jour, vous allez recevoir un mail contenant ce dernier",
          sOldPassword: "",
          sPassword: "",
          sConfirmPassword: ""
        });
      } else {
        if (userSaved && userSaved.error) {
          errors.sPassword = userSaved.error;
        } else {
          errors.sOldPassword = "Votre mot de passe ne correspond pas";
        }
        this.setState({ errors });
      }
    });
  };

  handleSubmit = () => {
    const { sOldPassword, sPassword, sConfirmPassword } = this.state;
    const errors = {};

    if (!sOldPassword) {
      errors.sOldPassword = "Champ obligatoire";
    }
    if (!sPassword) {
      errors.sPassword = "Champ obligatoire";
    }
    if (!sConfirmPassword) {
      errors.sConfirmPassword = "Champ obligatoire";
    }
    if (sPassword !== sConfirmPassword) {
      errors.sConfirmPassword = "Mot de passe différent";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      if (checkPassword(sPassword).error) {
        errors.sPassword = checkPassword(sPassword).error;
      }
      if (Object.keys(errors).length > 0) {
        this.setState({ errors });
      } else {
        this.changePassword(sOldPassword, sPassword);
      }
    }
  };

  render() {
    const { user, width } = this.props;
    const { sOldPassword, sPassword, sConfirmPassword, errors, success, disabled } = this.state;
    const today = DateTime.local();
    const dUpdateMdp = DateTime.fromISO(user.dUpdateMdp).setLocale("fr-FR");
    const monthDiff = Math.round(today.diff(dUpdateMdp, ["months"]).toObject().months);
    return (
      <Segment style={{ width }}>
        <Grid stackable container doubling>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form onSubmit={this.handleSubmit}>
                <>
                  {monthDiff >= 5 && (
                    <div style={{ marginBottom: 15 }}>
                      <p>
                        <Icon
                          style={{ marginTop: "-2px" }}
                          name="warning sign"
                          color="red"
                          size="large"
                        />
                        Vous devez changer votre mot de passe avant le{" "}
                        <b>{dUpdateMdp.plus({ months: 6 }).toFormat("dd/MM/yyyy")}</b>
                      </p>
                    </div>
                  )}
                  <TinyPasswordInput
                    label="Ancien mot de passe"
                    id="sOldPassword"
                    error={
                      errors.sOldPassword
                        ? { content: errors.sOldPassword, pointing: "below" }
                        : false
                    }
                    password={sOldPassword}
                    onChange={this.onChange}
                  />
                  <TinyPasswordInput
                    label="Nouveau mot de passe"
                    id="sPassword"
                    error={
                      errors.sPassword ? { content: errors.sPassword, pointing: "below" } : false
                    }
                    generatePassword={this.generatePassword}
                    password={sPassword}
                    onChange={this.onChange}
                  />
                  <TinyPasswordInput
                    label="Confirmation du mot de passe"
                    id="sConfirmPassword"
                    error={
                      errors.sConfirmPassword
                        ? { content: errors.sConfirmPassword, pointing: "below" }
                        : false
                    }
                    password={sConfirmPassword}
                    onChange={this.onChange}
                  />
                  <ConnexionField>
                    <SavePassword
                      style={{ marginTop: 4 }}
                      disabled={disabled || process.env.REACT_APP_DEMO === "1"}
                      primary
                      onClick={this.handleSubmit}
                    >
                      Valider
                    </SavePassword>
                  </ConnexionField>
                </>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <div style={{ lineHeight: "26px" }}>
                <h4>Le mot de passe doit contenir :</h4>
                <ul style={{ marginLeft: 15, padding: 0 }}>
                  <li>8 caractères</li>
                  <li>Un chiffre</li>
                  <li>Une lettre minuscule</li>
                  <li>Une lettre majuscule</li>
                  <li>Un charactère spécial</li>
                </ul>
              </div>
            </Grid.Column>
            {process.env.REACT_APP_DEMO === "1" && (
              <i style={{ margin: "0 auto" }}>Fonctionnalitée désactivée pour la démo</i>
            )}
            {success && (
              <Message style={{ marginTop: 10 }} positive>
                <p>{success}</p>
              </Message>
            )}
            {errors.signin && <ErrorNotice>{errors.signin}</ErrorNotice>}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

ChangePasswordForm.defaultProps = {
  width: ""
};

ChangePasswordForm.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  width: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user.data,
  signInError: state.user.error
});

export default connect(mapStateToProps)(ChangePasswordForm);
