import styled from "styled-components";

const H1 = styled.h1`
  margin: 0;
  padding: 20px 0 10px;
  font-size: 24px;
  color: ${props => props.theme.text.primary};
`;

export default H1;
