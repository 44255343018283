import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import _ from "lodash";

import { Form, Icon, List, Message, Card } from "semantic-ui-react";

import BtnSubmit from "../../Core/Btn/Submit";
import BtnEdit from "../../Core/Btn/Edit";

import { checkIsValideEmail } from "../../../utils";

import { addUser, editUser, searchUser, clearListSearchUser } from "../../../redux/actions/user";

import "react-sortable-tree/style.css";

const SearchList = styled(List)`
  background: #ffffff;
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

const CheckboxBactif = styled(Form.Checkbox)`
  margin-top: 15px !important;
`;

const BtnEditRight = styled(BtnEdit)`
  float: right;
`;

const options = [
  { key: "m", text: "Monsieur", value: "M" },
  { key: "f", text: "Madame", value: "Mme" },
  { key: "o", text: "Autre", value: "Autre" },
];

class FormUtilisateur extends Component {
  constructor(props) {
    super(props);
    const { user, iFKClient } = props;
    this.state = {
      iFKClient,
      iPKUtilisateur: user.iPKUtilisateur || null,
      sNom: user.sNom || "",
      sPrenom: user.sPrenom || "",
      sCodeCivilite: user.sCodeCivilite || "",
      iFKAnnAnnuaire: user.iFKAnnAnnuaire || null,
      sEmail: user.sEmail || "",
      sWebLogin: user.WebLogin || "",
      iFKGroupe: user.iFKGroupe || "",
      isAdmin: user.iRole && user.iRole === 2,
      bInactif: user.bInactif || false,
      bTarif: user.bTarif || false,
      sPassword: "",
      changePassword: false,
      activeIndex: 0,
      errors: {},
      error: null,
      showConfirmDelete: false,
      loading: false,
      disabled: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { user, iFKClient, error } = this.props;
    if (prevProps.user !== user) {
      const {
        user: {
          iPKUtilisateur,
          sNom,
          sPrenom,
          sWebLogin,
          sCodeCivilite,
          iFKAnnAnnuaire,
          iFKAnnEtablissement,
          sEmail,
          iFKGroupe,
          iRole,
          bInactif,
          bTarif,
        },
      } = this.props;
      this.setState({
        iFKClient,
        iPKUtilisateur: iPKUtilisateur || "",
        sNom: sNom || "",
        sPrenom: sPrenom || "",
        sWebLogin: sWebLogin || "",
        sCodeCivilite: sCodeCivilite || "",
        iFKAnnAnnuaire: iFKAnnAnnuaire || "",
        iFKAnnEtablissement: iFKAnnEtablissement || "",
        sEmail: sEmail || "",
        iFKGroupe: iFKGroupe || "",
        isAdmin: iRole && iRole === 2,
        bInactif: bInactif || false,
        bTarif: bTarif || false,
        changePassword: false,
        errors: {},
        error: null,
        loading: false,
        disabled: true,
      });
    }
    if (prevProps.error !== error && error) {
      this.setState({ error });
    }
  }

  clearForm = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { iFKClient } = this.props;
    this.setState({
      iFKClient,
      iPKUtilisateur: "",
      sNom: "",
      sPrenom: "",
      sCodeCivilite: "",
      iFKAnnAnnuaire: "",
      sEmail: "",
      iFKGroupe: "",
      isAdmin: false,
      bInactif: false,
      bTarif: false,
      errors: {},
      error: null,
      loading: false,
      disabled: false,
    });
  };

  handleChangePassword = (evt, data) => {
    const { errors } = this.state;
    this.setState({
      [data.id]: data.value,
      changePassword: true,
      errors: { ...errors, [data.id]: false },
      disabled: false,
    });
  };

  handleChange = (evt, data) => {
    const { errors } = this.state;
    this.setState({
      [data.id]: data.value,
      errors: { ...errors, [data.id]: false },
      disabled: false,
    });
  };

  handleCheckIsAdmin = () => {
    const { isAdmin } = this.state;
    this.setState({
      isAdmin: !isAdmin,
      disabled: false,
    });
  };

  handleCheckInactif = () => {
    const { bInactif } = this.state;
    this.setState({
      bInactif: !bInactif,
      disabled: false,
    });
  };

  handleCheckTarif = () => {
    const { bTarif } = this.state;
    this.setState({
      bTarif: !bTarif,
      disabled: false,
    });
  };

  handleSubmit = (e) => {
    const { dispatch, selectNode } = this.props;
    const {
      iPKUtilisateur,
      sNom,
      sPrenom,
      sCodeCivilite,
      sEmail,
      iFKGroupe,
      iFKAnnAnnuaire,
    } = this.state;
    e.stopPropagation();
    e.preventDefault();
    const errors = {};
    if (!iFKAnnAnnuaire) {
      if (!sNom) {
        errors.sNom = "Champ obligatoire";
      }
      if (!sPrenom) {
        errors.sPrenom = "Champ obligatoire";
      }
      if (!sCodeCivilite) {
        errors.sCodeCivilite = "Champ obligatoire";
      }
      if (!sEmail) {
        errors.sEmail = "Champ obligatoire";
      } else if (!checkIsValideEmail(sEmail)) {
        errors.sEmail = "Email non valide";
      }
    }
    if (!iFKGroupe) {
      errors.iFKGroupe = "Champ obligatoire";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      if (iPKUtilisateur) {
        dispatch(editUser(this.state));
      } else {
        dispatch(addUser({ ...this.state, bAdminCreate: true }));
        this.setState({
          iPKUtilisateur: "",
          iFKAnnAnnuaire: "",
          sNom: "",
          sPrenom: "",
          sCodeCivilite: "",
          sPassword: "",
          sEmail: "",
          iFKGroupe: "",
          disabled: true,
          loading: false,
          isAdmin: false,
          bInactif: false,
          bTarif: false,
          imported: false,
          error: null,
        });
      }
      this.setState({ disabled: true, imported: false }, () => {
        selectNode(`${sNom || ""} ${sPrenom || ""}`);
      });
    }
  };

  handleSearchSubmit = () => {
    const { dispatch } = this.props;
    const { sNom, sPrenom, sCodeAnnuaire } = this.state;
    const errors = {};
    if (!sNom && !sPrenom && !sCodeAnnuaire) {
      errors.sNom = "Champ obligatoire";
      errors.sPrenom = "Champ obligatoire";
      errors.sCodeAnnuaire = "Champ obligatoire";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ loading: true, errors: {} }, () => {
        dispatch(searchUser(this.state));
        setTimeout(() => {
          this.setState({ loading: false });
        }, 500);
      });
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex, error: null });
  };

  selectUser = (user) => {
    const { dispatch } = this.props;

    dispatch(clearListSearchUser({}));
    this.setState({
      sNom: user.sNom || "",
      sPrenom: user.sPrenom || "",
      sCodeCivilite: user.sCodeCivilite || "",
      sPassword: user.sMotDePasse || "",
      iFKAnnAnnuaire: user.iFKAnnAnnuaire || "",
      isAdmin: user.iRole === 2,
      sEmail: user.sEmail || "",
      activeIndex: 0,
      imported: true,
    });
  };

  render() {
    const { groupes, searchList } = this.props;
    const {
      iPKUtilisateur,
      sNom,
      sPrenom,
      sCodeCivilite,
      sEmail,
      iFKGroupe,
      errors,
      disabled,
      isAdmin,
      bInactif,
      bTarif,
      iFKAnnAnnuaire,
      imported,
      error,
    } = this.state;

    return (
      <Card fluid style={{ backgroundColor: bInactif ? "lightgrey" : "" }}>
        <Card.Content>
          <Card.Header>
            <Title>{iPKUtilisateur ? "Modification" : "Création"} d&apos;un utilisateur</Title>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form onSubmit={this.handleSubmit}>
            {!iFKAnnAnnuaire || imported ? (
              <>
                <Form.Group>
                  <Form.Select
                    id="sCodeCivilite"
                    label="Civilité"
                    style={{ minWidth: 90 }}
                    width={4}
                    error={errors.sCodeCivilite ? errors.sCodeCivilite : false}
                    value={sCodeCivilite}
                    options={options}
                    placeholder="Civilité"
                    disabled={!!iFKAnnAnnuaire}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    id="sPrenom"
                    label="Prénom"
                    error={errors.sPrenom ? errors.sPrenom : false}
                    value={sPrenom}
                    width={6}
                    placeholder="Prénom"
                    disabled={!!iFKAnnAnnuaire}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    id="sNom"
                    label="Nom"
                    error={errors.sNom ? errors.sNom : false}
                    value={sNom}
                    width={6}
                    placeholder="Nom"
                    autoComplete="off"
                    disabled={!!iFKAnnAnnuaire}
                    onChange={this.handleChange}
                  />
                  {!iPKUtilisateur && (
                    <span
                      data-tooltip="Rechercher un utilisateur dans ISADOM"
                      data-position="left center"
                    >
                      <Icon
                        style={{ marginTop: 21, background: "white", fontSize: 20 }}
                        name="search"
                        onClick={this.handleSearchSubmit}
                        circular
                        link
                      />
                    </span>
                  )}
                </Form.Group>
                <Form.Input
                  id="sEmail"
                  label="Email"
                  error={errors.sEmail ? errors.sEmail : false}
                  value={sEmail}
                  autoComplete="off"
                  placeholder="Email"
                  disabled={!!iFKAnnAnnuaire}
                  onChange={this.handleChange}
                />
              </>
            ) : (
              <Card fluid>
                <Card.Content>
                  <Card.Header style={{ fontSize: 16 }}>
                    Utilisateur importé depuis Is@Dom
                  </Card.Header>
                </Card.Content>
                <Card.Content>
                  {sNom && (
                    <p>
                      <b>Nom:</b> {sNom}
                    </p>
                  )}
                  {sPrenom && (
                    <p>
                      <b>Prenom:</b> {sPrenom}
                    </p>
                  )}
                  {sEmail && (
                    <p>
                      <b>Email:</b> {sEmail}
                    </p>
                  )}
                </Card.Content>
              </Card>
            )}
            <Form.Select
              fluid
              id="iFKGroupe"
              label="Groupe"
              error={errors.iFKGroupe ? errors.iFKGroupe : false}
              value={iFKGroupe}
              options={_.orderBy(groupes, ["sLibelle"], ["asc"]).map((g) => ({
                key: parseInt(g.iPKGroupe, 10),
                text: g.sLibelle,
                value: parseInt(g.iPKGroupe, 10),
              }))}
              placeholder="Groupe"
              onChange={this.handleChange}
            />
            <Form.Group>
              <CheckboxBactif
                id="isAdmin"
                label="Gestionnaire"
                checked={!!isAdmin}
                onChange={this.handleCheckIsAdmin}
              />
              <CheckboxBactif
                id="bTarif"
                label="Accès aux tarifs"
                checked={bTarif}
                onChange={this.handleCheckTarif}
              />
            </Form.Group>
            <BtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
              Enregistrer
            </BtnSubmit>{" "}
            <BtnSubmit onClick={this.clearForm}>Annuler</BtnSubmit>
            <BtnEditRight id="bInactif" onClick={this.handleCheckInactif}>
              {!bInactif ? "Désactiver" : "Activer"}
            </BtnEditRight>
            {error && (
              <Message negative>
                <Message.Header>Utilisateur non enregistré</Message.Header>
                <p>{error.substring(2, error.length)}</p>
              </Message>
            )}
          </Form>
          {!iPKUtilisateur && (
            <>
              {searchList && (
                <SearchList selection divided verticalAlign="middle">
                  {searchList.map((user) => (
                    <List.Item onClick={() => this.selectUser(user)} key={user.iFKAnnAnnuaire}>
                      <List.Content>
                        <List.Header>
                          {user.sNom} {user.sPrenom} ({user.sLibelleGroupe})
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  ))}
                </SearchList>
              )}
            </>
          )}
        </Card.Content>
      </Card>
    );
  }
}

FormUtilisateur.defaultProps = {
  error: "",
};

FormUtilisateur.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iFKClient: PropTypes.number.isRequired,
  selectNode: PropTypes.func.isRequired,
  searchList: PropTypes.arrayOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.string,
  groupes: PropTypes.arrayOf(PropTypes.any).isRequired,
};

FormUtilisateur.defaultProps = {
  searchList: [],
};

const mapStateToProps = (state) => ({
  groupes: state.group.liste,
  searchList: state.user.searchListe,
  error: state.user.error,
});

export default connect(mapStateToProps)(FormUtilisateur);
