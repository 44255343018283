import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { DateTime } from "luxon";

import TopBar from "./TopBar";
import SideBarRight from "./SideBarRight";
import SideBarLeft from "./SideBarLeft";
import Main from "./Main";
import Footer from "./Footer";

import ModalChangePassword from "../../Admin/Utilisateur/ModalChangePassword";

const Wrapper = styled.div`
  padding: 70px 0 0;
  background: linear-gradient(
    ${props => props.theme.background.light},
    ${props => props.theme.background.default}
  );
`;

const Layout = ({ sideBarLeft, sideBarRight, mainPane, user }) => {
  const today = DateTime.local();
  const dUpdateMdp = DateTime.fromISO(user.dUpdateMdp).setLocale("fr-FR");
  const monthDiff = Math.round(today.diff(dUpdateMdp, ["months"]).toObject().months);
  return (
    <Wrapper>
      <TopBar />
      {/* <SearchBar /> */}
      {monthDiff >= 5 && <ModalChangePassword />}
      <Main sidebars={sideBarLeft !== null || sideBarRight !== null}>
        <SideBarRight show={sideBarRight !== null}>{sideBarRight}</SideBarRight>
        <SideBarLeft show={sideBarLeft !== null}>{sideBarLeft}</SideBarLeft>
        {mainPane}
      </Main>
      <Footer />
    </Wrapper>
  );
};

Layout.propTypes = {
  sideBarLeft: PropTypes.node,
  sideBarRight: PropTypes.node,
  mainPane: PropTypes.node
};

Layout.defaultProps = {
  sideBarLeft: null,
  sideBarRight: null,
  mainPane: null
};

Layout.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Layout);
