import {
  GET_ALL_COUNT_REQUEST,
  GET_ALL_COUNT_BY_USER_REQUEST
} from "../constants/ActionTypes";

export const getAllCount = () => ({
  type: GET_ALL_COUNT_REQUEST,
  payload: null
});

export const getAllCountByUser = iPKUtilisateur => ({
  type: GET_ALL_COUNT_BY_USER_REQUEST,
  payload: { iPKUtilisateur }
});
