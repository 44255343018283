import { createGlobalStyle } from "styled-components";

import UniversCondensed from "../fonts/Univers-Condensed.otf";
import UniversCondensedBold from "../fonts/Univers-Condensed-Bold.otf";
import Futura from "../fonts/Futura-LT-Book.otf";
import DaxRegular from "../fonts/Dax-Regular.ttf";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "UniversCondensed";
    src: url(${UniversCondensed});
  }

  @font-face {
    font-family: "UniversCondensedBold";
    src: url(${UniversCondensedBold});
  }

  @font-face {
    font-family: "Futura";
    src: url(${Futura});
  }

  @font-face {
    font-family: "DaxRegular";
    src: url(${DaxRegular});
  }

  @media print {
    .noPrint {
      display: none !important;
    }
    .goPrint {
      display: block !important;
    }
    .labelPrint {
      width: 150px;
    }
    .inputPrint {
      border: 0;
      padding: 0;
      overflow: visible;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  input::placeholder, .default.text {
    color: rgb(0 0 0 / 60%) !important;
  }

  .goPrint {
    display: none;
  }

  .rgpdTable {
    width: 100%;
  }

  .rgpdTable thead {
    text-align: center;
  }

  .rgpdTable td {
    border: 1px solid black;
    padding: 5px;
  }

  strong {
    font-size: 15px;
  }

  body {
    font-family: "Futura";
  }

  h1 {
    font-family: "DaxRegular"
  }

  .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
    opacity: .80 !important;
  }

  .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    opacity: .90 !important;
  }

  .ui.dimmer {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: rgba(0,0,0,.5) !important;
}
`;

export default GlobalStyle;
