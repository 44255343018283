import styled from "styled-components";

const Logo = styled.img`
  display: block;
  width: auto;
  margin: 0 auto;
  height: 100%;
  max-height ${process.env.REACT_APP_THEME === 'ARAIR' ? '150px' : '100px'};
`;

export default Logo;
