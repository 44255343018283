import React, { Component } from "react";
import { Tab } from "semantic-ui-react";

import LayoutAdmin from "../../../../components/Admin/Layout";

import FormMessagerie from "../../../../components/Admin/Parametres/Messagerie/Form";
import FormPublicite from "../../../../components/Admin/Parametres/Publicite/Form";
import FormTelephone from "../../../../components/Admin/Parametres/Telephone/Form";
import ImportForm from "../../../../components/Admin/Utilisateur/ImportForm";

class Parametre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanel: null,
      selectedField: "",
      fields: {}
    };
  }

  loadImportForm = type => {
    this.setState({
      rightPanel: <ImportForm selectUser={this.selectUser} type={type} />,
      selectedField: type
    });
  };

  selectUser = (user, field) => {
    this.setState({
      fields: { [field]: user }
    });
  };

  handleTabChange = (e, data) => {
    this.setState({ ...data, rightPanel: "close", selectedField: "" });
  };

  render() {
    const { rightPanel, fields, selectedField } = this.state;
    const panes = [
      {
        menuItem: "Messagerie",
        render: () => (
          <Tab.Pane attached>
            <FormMessagerie
              selectedField={selectedField}
              fields={fields}
              loadImportForm={this.loadImportForm}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: "Publicité",
        render: () => (
          <Tab.Pane>
            <FormPublicite />
          </Tab.Pane>
        )
      },
      {
        menuItem: "Numéro de téléphone",
        render: () => (
          <Tab.Pane>
            <FormTelephone />
          </Tab.Pane>
        )
      }
    ];
    return (
      <LayoutAdmin title="Paramètrage de l'application" rightPanel={rightPanel}>
        <Tab panes={panes} onTabChange={this.handleTabChange} />
      </LayoutAdmin>
    );
  }
}

export default Parametre;
