import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_FAVORIS_SUCCESS,
  GET_FAVORIS_FAILURE,
  ADD_FAVORIS_SUCCESS,
  ADD_FAVORIS_FAILURE,
  DELETE_FAVORIS_SUCCESS,
  DELETE_FAVORIS_FAILURE
} from "../constants/ActionTypes";

export function* getFavoris() {
  try {
    const favoris = gql`
      {
        getFavoris {
          iPKProduitUtilisateur
          iFKProduit
          iFKUtilisateur
        }
      }
    `;
    const response = yield call(fetchApollo, favoris);
    const tFavoris = response.data.getFavoris;

    if (tFavoris !== null) {
      yield put({ type: GET_FAVORIS_SUCCESS, payload: tFavoris });
    } else {
      yield put({
        type: GET_FAVORIS_FAILURE,
        payload: "Aucun favoris trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: GET_FAVORIS_FAILURE, payload: message });
  }
}

export function* addFavoris({ payload: { iFKProduit, iFKUtilisateur } }) {
  try {
    const ADD_FAVORIS_REQUEST = gql`mutation
    {
      addFavoris(iFKProduit: ${iFKProduit}, iFKUtilisateur: ${iFKUtilisateur}) {
        iPKProduitUtilisateur
        iFKProduit
        iFKUtilisateur
      }
    }`;
    const response = yield call(fetchApollo, ADD_FAVORIS_REQUEST);
    const savedFavoris = response.data.addFavoris;
    if (savedFavoris !== null) {
      yield put({ type: ADD_FAVORIS_SUCCESS, payload: savedFavoris });
    } else {
      yield put({
        type: ADD_FAVORIS_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_FAVORIS_FAILURE, payload: message });
  }
}

export function* deleteFavoris({ payload: { iFKProduit, iFKUtilisateur } }) {
  try {
    const FAVORIS_REQUEST = gql`mutation {
      deleteFavoris(iFKProduit: ${iFKProduit}, iFKUtilisateur: ${iFKUtilisateur}) {
        iPKProduitUtilisateur
        iFKProduit
        iFKUtilisateur
      }
      }`;
    const response = yield call(fetchApollo, FAVORIS_REQUEST);
    const deletedFavoris = response.data.deleteFavoris;

    if (deletedFavoris !== null) {
      yield put({ type: DELETE_FAVORIS_SUCCESS, payload: deletedFavoris });
    } else {
      yield put({
        type: DELETE_FAVORIS_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_FAVORIS_FAILURE, payload: message });
  }
}
