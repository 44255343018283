import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import BtnToggle from "./BtnToggle";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: ${props => (props.opened ? "0px" : "-260px")};
  z-index: 50;
  padding: 0 40px 0 0;
  width: 300px;
  height: 98.35%;
  background: ${props => props.theme.border.dark};
  transition: all 0.25s ease;
`;

class SideBarLeft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

  handleBtnToggleClicked = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ opened: !prevState.opened }));
  };

  render() {
    const { show, children } = this.props;
    const { opened } = this.state;

    return show ? (
      <Wrapper opened={opened}>
        <BtnToggle opened={opened} onClick={this.handleBtnToggleClicked} />{" "}
        {children}
      </Wrapper>
    ) : null;
  }
}

SideBarLeft.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node
};

SideBarLeft.defaultProps = {
  children: null
};

export default SideBarLeft;
