import {
  GET_GROUP_REQUEST,
  GET_GROUP_BY_USER_REQUEST,
  ADD_GROUP_REQUEST,
  EDIT_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  CHANGE_USER_GROUP_REQUEST,
  SEARCH_GROUP_REQUEST
} from "../constants/ActionTypes";

export const getGroups = iFKClient => ({
  type: GET_GROUP_REQUEST,
  payload: { iFKClient }
});

export const getGroupsByUser = iPKUtilisateur => ({
  type: GET_GROUP_BY_USER_REQUEST,
  payload: { iPKUtilisateur }
});

export const addGroup = group => ({
  type: ADD_GROUP_REQUEST,
  payload: { group }
});

export const editGroup = group => ({
  type: EDIT_GROUP_REQUEST,
  payload: { group }
});

export const deleteGroup = iPKGroupe => ({
  type: DELETE_GROUP_REQUEST,
  payload: { iPKGroupe }
});

export const searchGroup = group => ({
  type: SEARCH_GROUP_REQUEST,
  payload: { group }
});

export const changeUserGroup = groups => ({
  type: CHANGE_USER_GROUP_REQUEST,
  payload: { groups }
});
