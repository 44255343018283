import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import styled from "styled-components";
import { Card } from "semantic-ui-react";

const DemandeCard = styled(Card)`
  border: none !important;
  box-shadow: none !important;

  @media not print {
    .content {
      padding: 0 !important;
    }
    #demande-title {
      visibility: hidden;
      height: 0;
      margin: 0;
    }
  }

  @media print {
    #demande-title {
      visibility: show;
    }
  }
`;

class DemandeShow extends React.Component {
  render() {
    const { tEtat, type, materiel } = this.props;
    return (
      <DemandeCard fluid>
        <Card.Content>
          <h2 id="demande-title">Demande de {type}</h2>
          <p>
            Objet : <b>{materiel[`sObjet${type}`]}</b>
          </p>
          <p>
            Message : <b>{materiel[`sCommentaire${type}`]}</b>
          </p>
          <p>
            Etat de la demande :{" "}
            <b>{tEtat[materiel[`iEtat${type}`]] || "En attente de validation"}</b>
          </p>
          {type === "Retour" && materiel.dRetour && (
            <p>
              Date de fin de prise en charge (correspondra à la date d'arrêt de facturation) :{" "}
              <b>
                {DateTime.fromMillis(parseInt(materiel.dRetour, 10)) &&
                  DateTime.fromMillis(parseInt(materiel.dRetour, 10)).toFormat("dd/MM/yyyy")}
              </b>
            </p>
          )}
        </Card.Content>
      </DemandeCard>
    );
  }
}

DemandeShow.propTypes = {
  materiel: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  tEtat: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default DemandeShow;
