import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Table, Confirm, Checkbox, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";

import LayoutAdmin from "../../../../components/Admin/Layout";
import FormClient from "../../../../components/Admin/Client/Form";

import BtnSubmit from "../../../../components/Core/Btn/Submit";
import BtnEdit from "../../../../components/Core/Btn/Edit";
import BtnDelete from "../../../../components/Core/Btn/Delete";

import { getClients, deleteClient, fusionClient } from "../../../../redux/actions/client";

class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanel: null,
      showConfirmDelete: false,
      clientSelected: [],
      disabled: true,
      error: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getClients());
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (prevProps.error !== error && error) {
      this.setState({ error });
    }
  }

  handleClick = () => {
    this.setState({
      rightPanel: <FormClient />,
    });
  };

  deleteClient = () => {
    const { dispatch } = this.props;
    const { showConfirmDelete } = this.state;
    dispatch(deleteClient(showConfirmDelete));
    this.setState({ showConfirmDelete: false });
  };

  handleChange = (evt, data) => {
    const { clientSelected } = this.state;
    if (data.checked) {
      this.setState({
        clientSelected: [...clientSelected, data.client],
        disabled: [...clientSelected, data.client].length < 2,
      });
    } else {
      const disabled =
        clientSelected.filter((cs) => cs.iPKClient !== data.client.iPKClient).length < 2;
      this.setState({
        clientSelected: clientSelected.filter((cs) => cs.iPKClient !== data.client.iPKClient),
        disabled,
      });
    }
  };

  createClient = () => {
    const { dispatch } = this.props;
    const { clientSelected } = this.state;
    dispatch(fusionClient("Nouveau client", clientSelected));
  };

  render() {
    const { clients } = this.props;
    const { rightPanel, showConfirmDelete, disabled, error } = this.state;
    return (
      <LayoutAdmin title="Gestion des clients" rightPanel={rightPanel}>
        <>
          {error && (
            <Message negative>
              <Message.Header>Utilisateur non enregistré</Message.Header>
              <p>{error.substring(2, error.length)}</p>
            </Message>
          )}
          <Table compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Nombre de groupes</Table.HeaderCell>
                <Table.HeaderCell>Catalogue</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {clients.length === 0 && (
                <Table.Row>
                  <Table.Cell style={{ textAlign: "center" }} colSpan="5">
                    Aucun client trouvé
                  </Table.Cell>
                </Table.Row>
              )}
              {clients.map((c) => {
                return (
                  <Table.Row key={c.iPKClient}>
                    <Table.Cell style={{ width: 35 }}>
                      <Checkbox client={c} onChange={this.handleChange} />
                    </Table.Cell>
                    <Table.Cell width={5}>{c.sLibelle}</Table.Cell>
                    <Table.Cell width={2}>{c.iNbGroupe}</Table.Cell>
                    <Table.Cell width={5}>
                      <Link to={`/catalogues/${c.iFKCatalogue}`}>{c.sLibelleCatalogue}</Link>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <Link to={`/clients/${c.iPKClient}`}>
                        <BtnEdit onClick={() => {}} floated="left" primary size="small">
                          Modifier
                        </BtnEdit>
                      </Link>{" "}
                      <span
                        {...(c.iNbGroupe !== 0 && {
                          "data-tooltip": "Ne peut être supprimé si possède des groupes."
                        })}
                      >
                        <BtnDelete
                          onClick={() => this.setState({ showConfirmDelete: c.iPKClient })}
                          floated="left"
                          alert
                          size="small"
                          disabled={c.iNbGroupe !== 0}
                        >
                          Supprimer
                        </BtnDelete>
                      </span>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>

            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="5">
                  <BtnSubmit onClick={this.handleClick} primary size="small">
                    Ajouter un client
                  </BtnSubmit>
                  <BtnSubmit disabled={disabled} onClick={this.createClient} primary size="small">
                    Fusionner les clients à partir de la selection
                  </BtnSubmit>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Confirm
            open={!!showConfirmDelete}
            content="Etes-vous sûr de vouloir supprimer ce client ?"
            cancelButton="Annuler"
            confirmButton="Confirmer"
            onCancel={() => this.setState({ showConfirmDelete: false })}
            onConfirm={(e) => this.deleteClient(e)}
          />
        </>
      </LayoutAdmin>
    );
  }
}

Client.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  clients: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  clients: state.client.liste,
  error: state.client.error,
});

export default connect(mapStateToProps)(Client);
