import React from "react";
import PropTypes from "prop-types";
import { Feed, Card, Grid, Radio } from "semantic-ui-react";
import styled from "styled-components";

const BtnSubmit = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.button.primary.text};
  background: ${props => props.theme.button.primary.background};
  margin-right: 10px;
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.button.primary.hoverBackground};
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
    &:hover {
      background: ${props => props.theme.button.primary.background};
    }
  }
`;

const CustomCardHeader = styled(Card.Header)`
  font-size: 16px !important;
  color: ${props => props.theme.button.primary.background} !important
  ;
`;

class Grille extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tChecked: [],
      tGrille: {},
      iScore: null
    };
  }

  componentDidMount() {
    const { tGrille } = this.props;
    const parsedGrille = {};
    tGrille.forEach(element => {
      if (!parsedGrille[element.sTheme]) {
        parsedGrille[element.sTheme] = {};
      }
      if (!parsedGrille[element.sTheme][element.sItem]) {
        parsedGrille[element.sTheme][element.sItem] = {};
      }
      if (element.sReponse !== null) {
        parsedGrille[element.sTheme][element.sItem][element.sReponse] = element;
      }
    });
    this.setState({
      tGrille: parsedGrille
    });
  }

  handleChange = (evt, data) => {
    const { tChecked } = this.state;
    this.setState({
      tChecked: {
        ...tChecked,
        [data.theme]: { ...tChecked[data.theme], [data.question]: data.value }
      }
    });
  };

  checkScore = () => {
    const { tChecked } = this.state;
    let iScore = 0;
    Object.keys(tChecked).forEach(key => {
      Object.keys(tChecked[key]).forEach(key2 => {
        iScore += tChecked[key][key2].iScore;
      });
    });
    this.setState({ iScore });
  };

  render() {
    const { tGrille, tChecked, iScore } = this.state;
    const { sMessage } = this.props;
    return (
      <Grid stackable container doubling>
        {Object.keys(tGrille).map(key => (
          <Card key={key} style={{ margin: 10 }}>
            <Card.Content style={{ maxHeight: 55 }}>
              <CustomCardHeader>{key}</CustomCardHeader>
            </Card.Content>
            <Card.Content>
              <Feed>
                {Object.keys(tGrille[key]).map(key2 => (
                  <Feed.Event key={key2}>
                    <Feed.Content>
                      <label htmlFor={key2}>{key2}</label>
                      <br />
                      {Object.keys(tGrille[key][key2]).map(key3 => {
                        if (Number.isNaN(Number(Object.keys(tGrille[key][key2])[0]))) {
                          return (
                            <div key={key + key2}>
                              <Radio
                                label={key3}
                                onChange={this.handleChange}
                                theme={key}
                                question={key2}
                                name={key + key2}
                                id={key + key2 + key3}
                                value={tGrille[key][key2][key3]}
                                checked={
                                  tChecked[key] && tChecked[key][key2] === tGrille[key][key2][key3]
                                }
                              />
                            </div>
                          );
                        }
                        return (
                          <div
                            key={key + key2}
                            style={{ float: "left", margin: 3, textAlign: "center" }}
                          >
                            <label style={{ cursor: "pointer" }} htmlFor={key3}>
                              {key3}
                            </label>
                            <div>
                              <Radio
                                onChange={this.handleChange}
                                theme={key}
                                question={key2}
                                name={key + key2}
                                id={key + key2 + key3}
                                value={tGrille[key][key2][key3]}
                                checked={
                                  tChecked[key] && tChecked[key][key2] === tGrille[key][key2][key3]
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Feed.Content>
                  </Feed.Event>
                ))}
              </Feed>
            </Card.Content>
          </Card>
        ))}
        <p style={{ width: "100%", marginTop: 10 }}>
          <BtnSubmit type="button" onClick={this.checkScore}>
            Valider
          </BtnSubmit>
        </p>
        {iScore && iScore >= 2 ? (
          <Card fluid>
            <Card.Content>
              <p style={{ width: "100%" }}>
                Votre score est de <b>{iScore}</b>
              </p>
              <p>
                <b>{sMessage}</b>
              </p>
            </Card.Content>
          </Card>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

Grille.propTypes = {
  tGrille: PropTypes.objectOf(PropTypes.any).isRequired,
  sMessage: PropTypes.string.isRequired
};

export default Grille;
