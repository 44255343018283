import {
  EDIT_PRODUIT_REQUEST,
  DELETE_PRODUIT_REQUEST
} from "../constants/ActionTypes";

export const editProduit = produit => ({
  type: EDIT_PRODUIT_REQUEST,
  payload: { produit }
});

export const deleteProduit = iPKProduit => ({
  type: DELETE_PRODUIT_REQUEST,
  payload: { iPKProduit }
});
