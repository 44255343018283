import React, { Component } from "react";
import { Header, Modal } from "semantic-ui-react";

import ChangePasswordForm from "./ChangePasswordForm";

export default class ModalChangePassword extends Component {
  first = true;

  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    const { modalOpen } = this.state;
    if (!localStorage.getItem("modalOpened") && modalOpen === false) {
      this.setState({ modalOpen: true });
      localStorage.setItem("modalOpened", true);
    }
    return (
      <Modal open={modalOpen} onClose={this.handleClose} closeIcon size="tiny">
        <Header icon="shield" content="Changement du mot de passe" />
        <Modal.Content>
          <ChangePasswordForm />
        </Modal.Content>
      </Modal>
    );
  }
}
