import {
  GET_PANIER_REQUEST,
  VALIDE_PANIER_REQUEST,
  ADD_PANIER_REQUEST,
  EDIT_PANIER_REQUEST,
  DELETE_PANIER_REQUEST
} from "../constants/ActionTypes";

export const getPanier = () => ({
  type: GET_PANIER_REQUEST,
  payload: {}
});

export const validePanier = panier => ({
  type: VALIDE_PANIER_REQUEST,
  payload: { panier }
});

export const addProduit = (
  iPKProduit,
  iPKUtilisateur,
  iQteProduit,
  sFields,
  sBeneficiaire,
  bPriorite
) => ({
  type: ADD_PANIER_REQUEST,
  payload: {
    iPKProduit,
    iPKUtilisateur,
    iQteProduit,
    sFields,
    sBeneficiaire,
    bPriorite
  }
});

export const editProduit = (iPKMatPanier, iQteProduit) => ({
  type: EDIT_PANIER_REQUEST,
  payload: { iPKMatPanier, iQteProduit }
});

export const deleteProduit = iPKMatPanier => ({
  type: DELETE_PANIER_REQUEST,
  payload: { iPKMatPanier }
});
