import styled from "styled-components";

const Screen = styled.div`
  padding-top: 40px;
  min-height: 100vh;
  width: 100%;
  background: ${props => props.theme.background.light};
`;

export default Screen;
