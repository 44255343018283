import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_GROUP_REQUEST,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  CHANGE_USER_GROUP_SUCCESS,
  CHANGE_USER_GROUP_FAILURE,
  SEARCH_GROUP_SUCCESS,
  SEARCH_GROUP_FAILURE
} from "../constants/ActionTypes";

export function* getGroups({ payload: { iFKClient } }) {
  try {
    const GROUP = gql`{
        listGroup(iFKClient: ${iFKClient}) {
          iPKGroupe
          iFKGroupeParent
          sLibelle
          title
          type
          iFKClient
          level
          iFKAnnAnnuaire
          sFKTypeTiers
          children {
            iPKUtilisateur
            title
            type
            iRole
            sCodeCivilite
            sNom
            sPrenom
            sEmail
            iFKAnnAnnuaire
            iFKGroupe
            sCodeAnnuaire
            bInactif
            bTarif
          }
        }
      }`;
    const response = yield call(fetchApollo, GROUP);
    const groups = response.data.listGroup;

    if (groups !== null) {
      yield put({ type: GET_GROUP_SUCCESS, payload: groups });
    } else {
      yield put({
        type: GET_GROUP_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: GET_GROUP_FAILURE, payload: message });
  }
}

export function* getGroupsByUser({ payload: { iPKUtilisateur } }) {
  try {
    const GROUP = gql`{
        listGroupByUser(iPKUtilisateur: ${iPKUtilisateur}) {
          iPKGroupe
          iFKGroupeParent
          sLibelle
          title
          type
          iFKClient
          level
          iFKAnnAnnuaire
        }
      }`;
    const response = yield call(fetchApollo, GROUP);
    const groups = response.data.listGroupByUser;

    if (groups !== null) {
      yield put({ type: GET_GROUP_SUCCESS, payload: groups });
    } else {
      yield put({
        type: GET_GROUP_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: GET_GROUP_FAILURE, payload: message });
  }
}

export function* addGroup({ payload: { group } }) {
  try {
    const GROUP_REQUEST = gql`mutation {
        addGroup(group: ${JSON.stringify(JSON.stringify(group))}) {
          iPKGroupe
          iFKGroupeParent
          sLibelle
          title
          type
          iFKClient
          level
          children {
            iPKUtilisateur
            title
            type
            iRole
            sCodeCivilite
            sNom
            sPrenom
            sEmail
            iFKGroupe
          }
        }
      }`;
    const response = yield call(fetchApollo, GROUP_REQUEST);
    const savedGroup = response.data.addGroup;

    if (savedGroup !== null) {
      if (savedGroup.iPKGroupe) {
        yield put({ type: ADD_GROUP_SUCCESS, payload: savedGroup });
        yield put({
          type: GET_GROUP_REQUEST,
          payload: { iFKClient: savedGroup.iFKClient }
        });
      } else {
        yield put({
          type: ADD_GROUP_FAILURE,
          payload: "Le groupe existe déjà"
        });
      }
    } else {
      yield put({
        type: ADD_GROUP_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_GROUP_FAILURE, payload: message });
  }
}

export function* editGroup({ payload: { group } }) {
  try {
    const GROUP_REQUEST = gql`mutation {
        editGroup(group: ${JSON.stringify(JSON.stringify(group))}) {
          iPKGroupe
          iFKGroupeParent
          sLibelle
          title
          type
          iFKClient
          level
          children {
            iPKUtilisateur
            title
            type
            iRole
            sCodeCivilite
            sNom
            sPrenom
            sEmail
            iFKGroupe
          }
        }
      }`;
    const response = yield call(fetchApollo, GROUP_REQUEST);
    const savedGroup = response.data.editGroup;

    if (savedGroup !== null) {
      yield put({ type: EDIT_GROUP_SUCCESS, payload: savedGroup });
      yield put({
        type: GET_GROUP_REQUEST,
        payload: { iFKClient: savedGroup.iFKClient }
      });
    } else {
      yield put({
        type: EDIT_GROUP_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_GROUP_FAILURE, payload: message });
  }
}

export function* deleteGroup({ payload: { iPKGroupe } }) {
  try {
    const GROUP_REQUEST = gql`mutation {
        deleteGroup(iPKGroupe: ${iPKGroupe}) {
          iFKClient
        }
      }`;
    const response = yield call(fetchApollo, GROUP_REQUEST);
    const deletedGroup = response.data.deleteGroup;

    if (deletedGroup !== null) {
      yield put({ type: DELETE_GROUP_SUCCESS, payload: deletedGroup });
      yield put({
        type: GET_GROUP_REQUEST,
        payload: { iFKClient: deletedGroup.iFKClient }
      });
    } else {
      yield put({
        type: DELETE_GROUP_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_GROUP_FAILURE, payload: message });
  }
}

export function* changeUserGroup({ payload: { groups } }) {
  try {
    const GROUP_REQUEST = gql`mutation {
      changeUserGroup(groups: ${JSON.stringify(JSON.stringify(groups))}) {
          iFKClient
        }
      }`;
    const response = yield call(fetchApollo, GROUP_REQUEST);
    const savedGroups = response.data.changeUserGroup;

    if (savedGroups !== null) {
      yield put({ type: CHANGE_USER_GROUP_SUCCESS, payload: savedGroups });
      yield put({
        type: GET_GROUP_REQUEST,
        payload: { iFKClient: savedGroups.iFKClient }
      });
    } else {
      yield put({
        type: CHANGE_USER_GROUP_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: CHANGE_USER_GROUP_FAILURE, payload: message });
  }
}

export function* searchGroup({ payload: { group } }) {
  try {
    const GROUP_REQUEST = gql`{
      searchGroup(group: ${JSON.stringify(JSON.stringify(group))}) {
          iPKGroupe
          iFKClient
          iFKAnnAnnuaire
          title
          type
        }
      }`;
    const response = yield call(fetchApollo, GROUP_REQUEST);
    const listGroup = response.data.searchGroup;

    if (listGroup !== null) {
      yield put({ type: SEARCH_GROUP_SUCCESS, payload: listGroup });
    } else {
      yield put({
        type: SEARCH_GROUP_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: SEARCH_GROUP_FAILURE, payload: message });
  }
}
