import {
  EDIT_FAMILLE_REQUEST,
  DELETE_FAMILLE_REQUEST
} from "../constants/ActionTypes";

export const editFamille = famille => ({
  type: EDIT_FAMILLE_REQUEST,
  payload: { famille }
});

export const deleteFamille = iPKCatFamille => ({
  type: DELETE_FAMILLE_REQUEST,
  payload: { iPKCatFamille }
});
