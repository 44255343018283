import React from "react";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";
import { Card, Form } from "semantic-ui-react";
import BtnSubmit from "../../Core/Btn/Submit";

import { fetchApollo } from "../../../graphql/apolloClient";
import { getAllCount } from "../../../redux/actions/count";

const Title = styled.span`
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

class RetourShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iEtat: null,
      disabled: true
    };
  }

  componentDidUpdate(prevProps) {
    const { retour } = this.props;
    if (prevProps.retour !== retour) {
      this.setState({
        iEtat: null,
        disabled: true
      });
    }
  }

  handleChange = (evt, data) => {
    this.setState({
      [data.id]: data.value,
      disabled: false
    });
  };

  handleSubmit = () => {
    const { retour, editRetourList, dispatch } = this.props;
    const { iEtat } = this.state;
    const EDIT_RETOUR = gql`mutation {
      editRetour(iPKRetour: ${retour.iPKRetour}, iEtat: ${iEtat}) {
        iPKRetour
        iEtat
      }
    }`;

    fetchApollo(EDIT_RETOUR).then(response => {
      const retourSaved = response.data.editRetour;
      this.setState(
        {
          iEtat: retourSaved.iEtat
        },
        () => {
          editRetourList(retourSaved);
          dispatch(getAllCount());
        }
      );
    });
  };

  render() {
    const { retour, user } = this.props;
    const { iEtat, disabled } = this.state;
    const tEtatOption = [
      {
        key: "En attente de validation",
        text: "En attente de validation",
        value: 0
      },
      { key: "En cours", text: "En cours", value: 1 },
      { key: "Validé", text: "Validé", value: 2 },
      { key: "Annulée", text: "Annulée", value: 3 }
    ];

    return (
      <div>
        <Title>Détail de la demande de retour</Title>
        <Card fluid>
          <Card.Content>
            <p>
              Demande de retour effectué par <strong>{retour.sPatient}</strong>
            </p>
            <p>
              Date :{" "}
              <strong>
                {DateTime.fromMillis(parseInt(retour.dInsert, 10)).toFormat("dd/MM/yyyy")}
              </strong>
            </p>
            <p>
              Produit :{" "}
              <strong>
                {retour.sRefProduit} - {retour.sLibelleProduit}
              </strong>
            </p>
            <p>
              Numéro de produit : <strong>{retour.sRefProduitIsadom}</strong>
            </p>
            <p>
              Numéro de série : <strong>{retour.sNumeroSerie}</strong>
            </p>
            <p>
              Objet : <strong>{retour.sObjet}</strong>
            </p>
            <p>
              Commentaire : <strong>{retour.sCommentaire}</strong>
            </p>
            {retour.dRetour && (
              <p>
                Date de retour souhaité:{" "}
                <b>{DateTime.fromMillis(parseInt(retour.dRetour, 10)).toFormat("dd/MM/yyyy")}</b>
              </p>
            )}
          </Card.Content>
        </Card>
        {user.iRole === 1 && (
          <>
            <Form.Select
              fluid
              value={iEtat || retour.iEtat}
              id="iEtat"
              label="Modifier le status du retour"
              options={tEtatOption}
              onChange={this.handleChange}
            />
            <br />
            <BtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
              Enregistrer
            </BtnSubmit>{" "}
          </>
        )}
      </div>
    );
  }
}

RetourShow.propTypes = {
  retour: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  editRetourList: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  commandes: state.commande.liste,
  user: state.user.data,
  error: state.client.error
});

export default connect(mapStateToProps)(RetourShow);
