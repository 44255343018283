import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  LISTE_COMMANDE_SUCCESS,
  LISTE_COMMANDE_FAILURE
} from "../constants/ActionTypes";

export default function* getCommandes() {
  try {
    const listCommande = gql`
      {
        listCommande {
          iPKCommande
          iEtat
          dInsert
          dLivraison
          sConsigne
          bPriorite
          files {
            name
            path
          }
          utilisateur {
            sNom
            sPrenom
            sEmail
            sLibelleGroupe
          }
        }
      }
    `;
    const response = yield call(fetchApollo, listCommande);
    const commandes = response.data.listCommande;

    if (commandes !== null) {
      yield put({ type: LISTE_COMMANDE_SUCCESS, payload: commandes });
    } else {
      yield put({
        type: LISTE_COMMANDE_FAILURE,
        payload: "Aucun client trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: LISTE_COMMANDE_FAILURE, payload: message });
  }
}
