import React from "react";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import styled from "styled-components";
import { Card, Form } from "semantic-ui-react";
import BtnSubmit from "../../Core/Btn/Submit";

import { fetchApollo } from "../../../graphql/apolloClient";
import { getAllCount } from "../../../redux/actions/count";

const Title = styled.span`
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

class DevisShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iEtat: null,
      disabled: true
    };
  }

  componentDidUpdate(prevProps) {
    const { devis } = this.props;
    if (prevProps.devis !== devis) {
      this.setState({
        iEtat: null,
        disabled: true
      });
    }
  }

  handleChange = (evt, data) => {
    this.setState({
      [data.id]: data.value,
      disabled: false
    });
  };

  handleSubmit = () => {
    const { devis, editDevisList, dispatch } = this.props;
    const { iEtat } = this.state;
    const EDIT_DEVIS = gql`mutation {
      editDevis(iPKDevis: ${devis.iPKDevis}, iEtat: ${iEtat}) {
        iPKDevis
        iEtat
      }
    }`;

    fetchApollo(EDIT_DEVIS).then(response => {
      const devisSaved = response.data.editDevis;
      this.setState(
        {
          iEtat: devisSaved.iEtat
        },
        () => {
          editDevisList(devisSaved);
          dispatch(getAllCount());
        }
      );
    });
  };

  render() {
    const { devis, user } = this.props;
    const { iEtat, disabled } = this.state;
    const tEtatOption = [
      {
        key: "En attente de validation",
        text: "En attente de validation",
        value: 0
      },
      { key: "En cours", text: "En cours", value: 1 },
      { key: "Validé", text: "Validé", value: 2 },
      { key: "Annulée", text: "Annulée", value: 3 }
    ];

    return (
      <div>
        <Title>Détail de la demande de devis</Title>
        <Card fluid>
          <Card.Content>
            <p>
              Effectué par <strong>{devis.sPatient}</strong>
            </p>
            <p>
              Date :{" "}
              <strong>
                {DateTime.fromMillis(parseInt(devis.dInsert, 10)).toFormat("dd/MM/yyyy")}
              </strong>
            </p>
            <p>
              Description du besoin : <strong>{devis.sCommentaire}</strong>
            </p>
          </Card.Content>
        </Card>
        {user.iRole === 1 && (
          <>
            <Form.Select
              fluid
              value={iEtat || devis.iEtat}
              id="iEtat"
              label="Modifier le status du devis"
              options={tEtatOption}
              onChange={this.handleChange}
            />
            <br />
            <BtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
              Enregistrer
            </BtnSubmit>{" "}
          </>
        )}
      </div>
    );
  }
}

DevisShow.propTypes = {
  devis: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  editDevisList: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  error: state.client.error
});

export default connect(mapStateToProps)(DevisShow);
