import React from "react";
import LayoutAdmin from "../../../components/Admin/Layout";
import ChangePasswordForm from "../../../components/Admin/Utilisateur/ChangePasswordForm";

const ChangePassword = () => {
  return (
    <LayoutAdmin title="Changement du mot de passe">
      <ChangePasswordForm width="50%" />
    </LayoutAdmin>
  );
};

export default ChangePassword;
