import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import gsap from "gsap";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Link } from "react-router-dom";
import "react-reflex/styles.css";
import { connect } from "react-redux";
import { DateTime } from "luxon";

import Screen from "../../Core/Screen";
import TopBar from "./TopBar";
import Pane from "./Pane";
import Header from "./Header";
import PaneToggleButton from "../../Core/Btn/Toggle";

import ModalChangePassword from "../../Admin/Utilisateur/ModalChangePassword";

import H1 from "../../Core/Title/H1";

import LeftPanel from "./Panel/LeftPanel";

const logo = require(`../../../ui/assets/img/${process.env.REACT_APP_LOGO}`).default;

const FullScreen = styled(Screen)`
  padding: 0;
`;
const MainTitle = styled(H1)`
  line-height: 40px;
  color: ${(props) => props.theme.colors.title.main};
`;
const VerticalSplitter = styled.div`
  border-right: 1px solid #a29999;
`;

const CustomReflexTopBar = styled(ReflexElement)`
  background: ${process.env.REACT_APP_THEME === "ARAIR" ? `url(${logo})` : ""}, white;
  background-repeat: no-repeat;
  background-size: auto 40px;
  background-position: 40px;
`;

class LayoutAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,

      topBarPane: {
        size: 50,
      },

      filterPane: {
        minSize: 300,
        maxSize: 300,
        size: 300,
        opened: true,
      },

      headerPane: {
        size: 80,
      },

      mainPane: {
        size: window.innerWidth - 44,
      },

      detailsPane: {
        minSize: 40,
        maxSize: 800,
        size: 40,
        opened: false,
      },
    };
  }

  componentDidMount = () => {
    this.mounted = true;
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
  };

  componentDidUpdate = (prevProps) => {
    const { rightPanel } = this.props;
    if (prevProps.rightPanel !== rightPanel && rightPanel) {
      if (rightPanel === "close") {
        this.closeRightPane("detailsPane");
      } else {
        this.openRightPane("detailsPane", rightPanel.width);
      }
    }
  };

  componentWillUnmount() {
    this.mounted = false;

    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  onFilterPaneStopResize = (event) => {
    const { filterPane } = this.state;
    const { minSize, maxSize } = filterPane;
    const size = event.domElement.clientWidth;

    if (size <= minSize) {
      this.setState((prevState) => ({
        filterPane: { ...prevState.filterPane, size: minSize, opened: false },
      }));
    } else if (size > minSize) {
      this.setState((prevState) => ({
        filterPane: { ...prevState.filterPane, size: maxSize, opened: true },
      }));
    }
  };

  onBtnToggleFilterPaneClick = (event) => {
    event.preventDefault();

    const { filterPane } = this.state;

    if (filterPane.opened) {
      this.closeLeftPane("filterPane");
    } else {
      this.openLeftPane("filterPane");
    }
  };

  onBtnToggleDetailsPaneClick = (event) => {
    event.preventDefault();

    const { detailsPane } = this.state;

    if (detailsPane.opened) {
      this.closeRightPane("detailsPane");
    } else {
      this.openRightPane("detailsPane");
    }
  };

  updateDimensions = () => {
    if (this.mounted) {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  };

  openLeftPane = (paneId) => {
    const {
      [paneId]: { size, maxSize },
    } = this.state;
    const counter = { var: size };

    gsap.to(counter, 0.5, {
      var: maxSize,
      onUpdate: () =>
        this.setState((prevState) => ({
          [paneId]: {
            ...prevState[paneId],
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) === maxSize,
          },
        })),
    });
  };

  closeLeftPane = (paneId) => {
    const {
      [paneId]: { size, minSize, maxSize },
    } = this.state;

    const counter = { var: size };
    gsap.to(counter, 0.5, {
      var: minSize,
      onUpdate: () => {
        this.setState((prevState) => ({
          [paneId]: {
            ...prevState[paneId],
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) === maxSize,
          },
          mainPane: { ...prevState.mainPane, size: prevState.mainPane.maxSize },
        }));
      },
    });
  };

  openRightPane = (paneId, width = 600) => {
    const {
      [paneId]: { size },
    } = this.state;
    const counter = { var: size };

    gsap.to(counter, 0.5, {
      var: width,
      onUpdate: () => {
        this.setState((prevState) => ({
          mainPane: {
            ...prevState.mainPane,
            size: window.innerWidth - (prevState.filterPane.size + counter.var + 4),
          },
        }));
      },
      onComplete: () => {
        this.setState({
          detailsPane: {
            minSize: 40,
            maxSize: (window.innerWidth - 100) / 2,
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) === width,
          },
        });
      },
    });
  };

  closeRightPane = (paneId) => {
    const {
      [paneId]: { size, minSize, maxSize },
    } = this.state;

    const counter = { var: size };
    gsap.to(counter, 0.5, {
      var: minSize,
      onUpdate: () => {
        this.setState((prevState) => ({
          mainPane: {
            ...prevState.mainPane,
            size: window.innerWidth - (prevState.filterPane.size + counter.var + 4),
          },
        }));
      },
      onComplete: () => {
        this.setState({
          detailsPane: {
            minSize: 40,
            maxSize: (window.innerWidth - 100) / 2,
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) === maxSize,
          },
        });
      },
    });
  };

  render() {
    const { width, height, topBarPane, filterPane, mainPane, headerPane, detailsPane } = this.state;
    const { children, title, linkTitle, rightPanel, user } = this.props;
    const today = DateTime.local();
    const dUpdateMdp = DateTime.fromISO(user.dUpdateMdp).setLocale("fr-FR");
    const monthDiff = Math.round(today.diff(dUpdateMdp, ["months"]).toObject().months);
    return (
      <FullScreen>
        {monthDiff >= 5 && <ModalChangePassword />}
        <ReflexContainer
          windowResizeAware
          orientation="horizontal"
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <CustomReflexTopBar size={topBarPane.size}>
            <TopBar>
              <Link to="/deconnexion">Déconnexion</Link>
            </TopBar>
          </CustomReflexTopBar>

          <ReflexElement>
            <ReflexContainer orientation="vertical">
              <ReflexElement
                minSize={filterPane.minSize}
                maxSize={filterPane.maxSize}
                size={filterPane.size}
                opened={filterPane.opened}
                onStartResize={this.onFilterPaneStopResize}
              >
                {filterPane.opened && <LeftPanel />}
              </ReflexElement>

              <VerticalSplitter />

              <ReflexElement flex={1}>
                <ReflexContainer orientation="horizontal">
                  <ReflexElement size={headerPane.size}>
                    <Header>
                      <Pane>
                        <MainTitle>
                          {linkTitle} {linkTitle && " / "} {title}
                        </MainTitle>
                      </Pane>
                    </Header>
                  </ReflexElement>
                  <ReflexElement>
                    <ReflexContainer orientation="vertical">
                      <ReflexElement size={mainPane.size}>
                        <Pane>{children}</Pane>
                      </ReflexElement>
                      <ReflexSplitter />
                      {((rightPanel && rightPanel.renderHtml) || rightPanel) && (
                        <ReflexElement
                          minSize={detailsPane.minSize}
                          maxSize={detailsPane.maxSize}
                          size={detailsPane.size}
                          opened={detailsPane.opened}
                        >
                          <Pane>
                            <>
                              <PaneToggleButton
                                dir="right"
                                opened={detailsPane.opened}
                                onClick={this.onBtnToggleDetailsPaneClick}
                              />
                              <div style={{ marginLeft: 25 }}>
                                {detailsPane.opened &&
                                  rightPanel !== "close" &&
                                  ((rightPanel && rightPanel.renderHtml) || rightPanel)}
                              </div>
                            </>
                          </Pane>
                        </ReflexElement>
                      )}
                    </ReflexContainer>
                  </ReflexElement>
                </ReflexContainer>
              </ReflexElement>
            </ReflexContainer>
          </ReflexElement>
        </ReflexContainer>
      </FullScreen>
    );
  }
}

LayoutAdmin.defaultProps = {
  title: "",
  linkTitle: null,
  rightPanel: null,
};

LayoutAdmin.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  linkTitle: PropTypes.objectOf(PropTypes.any),
  rightPanel: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps)(LayoutAdmin);
