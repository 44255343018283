import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;
  margin: 0 0 0 -160px;
  padding: 20px;
  width: 320px;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.light};
`;

export default Wrapper;
