import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";

import LayoutAdmin from "../../../../components/AdminClient/Layout";
import InterventionShow from "../../../../components/Admin/Intervention/show";

class Intervention extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tIntervention: [],
      selectedIntervention: null
    };
  }

  componentDidMount() {
    this.getInterventions();
  }

  selectIntervention = data => {
    this.setState({
      selectedIntervention: data,
      rightPanel: {
        width: 400,
        renderHtml: <InterventionShow intervention={data} />
      }
    });
  };

  getInterventions = () => {
    const {
      user,
      match: {
        params: { iPKIntervention }
      }
    } = this.props;
    const GET_INTERVENTIONS = gql`
      {
        listInterventionByUser (iPKUtilisateur: ${user.iPKUtilisateur}) {
          iPKIntervention
          dInsert
          sRefProduit
          sLibelleProduit
          sObjet
          sCommentaire
          sPatient
          sGroupe
          iEtat
          sRefProduitIsadom
        }
      }
    `;
    fetchApollo(GET_INTERVENTIONS).then(response => {
      const tIntervention = response.data.listInterventionByUser;
      if (tIntervention) {
        this.setState(
          {
            tIntervention
          },
          () => {
            if (iPKIntervention) {
              const intervention = tIntervention.filter(
                i => i.iPKIntervention === iPKIntervention
              )[0];
              this.selectIntervention(intervention);
            }
          }
        );
      }
    });
  };

  render() {
    const { rightPanel, tIntervention, selectedIntervention } = this.state;

    return (
      <LayoutAdmin title="Gestion des demandes d'intervention SAV" rightPanel={rightPanel}>
        <FilteredTable
          type="intervention"
          fields={{
            iPKIntervention: "N° d'intervention",
            dInsert: "Date",
            sRefProduitIsadom: "N° de produit",
            sRefProduit: "Produit",
            sObjet: "Objet",
            sPatient: "Contact",
            sGroupe: "Groupe",
            iEtat: "Etat"
          }}
          items={tIntervention}
          selectItem={this.selectIntervention}
          selectedItem={selectedIntervention}
        />
      </LayoutAdmin>
    );
  }
}

Intervention.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Intervention);
