import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { setAuthorizationToken, getCookie, setCookie, deleteCookie } from "../../utils";
import { client, fetchApollo } from "../../graphql/apolloClient";

import {
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,
  USER_SIGN_OUT_SUCCESS,
  USER_SIGN_OUT_FAILURE,
  GET_GROUP_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  CLEAR_LIST_SEARCH_USER_SUCCESS,
  LISTE_CONTACT_SUCCESS,
  LISTE_CONTACT_FAILURE,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  GET_FAVORIS_REQUEST,
  GET_PANIER_REQUEST,
} from "../constants/ActionTypes";

export function* addUser({ payload: { user } }) {
  try {
    const USER_REQUEST = gql`mutation {
        addUser(user: ${JSON.stringify(JSON.stringify(user))}) {
          iPKUtilisateur
          iFKClient
          title
          type
          iRole
          bTarif
          sCodeCivilite
          sNom
          sPrenom
          sEmail
          iFKGroupe
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const savedUser = response.data.addUser;

    if (savedUser !== null) {
      if (savedUser.iPKUtilisateur) {
        yield put({
          type: ADD_USER_SUCCESS,
          payload: {
            ...savedUser,
            success:
              "Votre demande de création a bien été prise en compte. Merci de vérifier votre boite email.",
          },
        });
      } else {
        yield put({
          type: ADD_USER_FAILURE,
          payload: "L'utilisateur est connu, veuillez contacter votre stucture.",
        });
      }
      yield put({
        type: GET_GROUP_REQUEST,
        payload: { iFKClient: savedUser.iFKClient },
      });
    } else {
      yield put({
        type: ADD_USER_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_USER_FAILURE, payload: message });
  }
}

export function* editUser({ payload: { user } }) {
  try {
    const USER_REQUEST = gql`mutation {
        editUser(user: ${JSON.stringify(JSON.stringify(user))}) {
          iPKUtilisateur
          iFKClient
          title
          type
          iRole
          bTarif
          sCodeCivilite
          sNom
          sPrenom
          sEmail
          iFKGroupe
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const savedUser = response.data.editUser;

    if (savedUser !== null) {
      yield put({ type: EDIT_USER_SUCCESS, payload: savedUser });
      yield put({
        type: GET_GROUP_REQUEST,
        payload: { iFKClient: savedUser.iFKClient },
      });
    } else {
      yield put({
        type: EDIT_USER_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_USER_FAILURE, payload: message });
  }
}

export function* deleteUser({ payload: { iPKUtilisateur, iFKClient } }) {
  try {
    const USER_REQUEST = gql`mutation {
        deleteUser(iPKUtilisateur: ${iPKUtilisateur}, iFKClient: ${iFKClient}) {
          iPKUtilisateur
          iFKClient
          title
          type
          iRole
          sCodeCivilite
          sNom
          sPrenom
          sEmail
          iFKGroupe
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const deletedUser = response.data.deleteUser;

    if (deletedUser !== null) {
      yield put({ type: DELETE_USER_SUCCESS, payload: deletedUser });
      yield put({
        type: GET_GROUP_REQUEST,
        payload: { iFKClient: deletedUser.iFKClient },
      });
    } else {
      yield put({
        type: DELETE_USER_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_USER_FAILURE, payload: message });
  }
}

export function* searchUser({ payload: { user } }) {
  try {
    const USER_REQUEST = gql`{
      searchUser(user: ${JSON.stringify(JSON.stringify(user))}) {
          iPKUtilisateur
          iFKClient
          title
          type
          iRole
          sCodeCivilite
          sNom
          sPrenom
          sMotDePasse
          iFKAnnAnnuaire
          iFKAnnEtablissement
          sLibelleGroupe
          sEmail
          iFKGroupe
          sCodeAnnuaire
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const listUser = response.data.searchUser;

    if (listUser !== null) {
      yield put({ type: SEARCH_USER_SUCCESS, payload: listUser });
    } else {
      yield put({
        type: SEARCH_USER_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: SEARCH_USER_FAILURE, payload: message });
  }
}

export function* clearListSearchUser() {
  yield put({ type: CLEAR_LIST_SEARCH_USER_SUCCESS, payload: null });
}

export function* setUser({ payload: { user } }) {
  yield put({ type: USER_SIGN_IN_SUCCESS, payload: user });
}

export function* getContactsByUser({ payload: { iPKUtilisateur } }) {
  try {
    const USER_REQUEST = gql`{
      getContactsByUser(iPKUtilisateur: ${iPKUtilisateur}) {
          iPKUtilisateur
          iFKAnnAnnuaire
          sCodeAnnuaire
          sLibelleGroupe
          iFKClient
          title
          type
          iRole
          bTarif
          bInactif
          sCodeCivilite
          sNom
          sPrenom
          sEmail
          iFKGroupe
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const contacts = response.data.getContactsByUser;

    if (contacts !== null) {
      yield put({ type: LISTE_CONTACT_SUCCESS, payload: contacts });
    } else {
      yield put({
        type: LISTE_CONTACT_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: LISTE_CONTACT_FAILURE, payload: message });
  }
}

export function* addContact({ payload: { user } }) {
  try {
    const USER_REQUEST = gql`mutation {
        addUser(user: ${JSON.stringify(JSON.stringify(user))}) {
          iPKUtilisateur
          iFKClient
          iFKCatalogue
          iFKGroupe
          title
          type
          iRole
          bTarif
          sCodeCivilite
          sLibelleGroupe
          iFKAnnAnnuaire
          sCodeAnnuaire
          sNom
          sPrenom
          sEmail
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const savedUser = response.data.addUser;

    if (savedUser !== null) {
      if (savedUser.iPKUtilisateur) {
        yield put({
          type: ADD_CONTACT_SUCCESS,
          payload: savedUser,
        });
      } else {
        yield put({
          type: ADD_CONTACT_FAILURE,
          payload: "Ce mail est déjà utilisé.",
        });
      }
    } else {
      yield put({
        type: ADD_CONTACT_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_CONTACT_FAILURE, payload: message });
  }
}

export function* editContact({ payload: { user } }) {
  try {
    const USER_REQUEST = gql`mutation {
        editUser(user: ${JSON.stringify(JSON.stringify(user))}) {
          iPKUtilisateur
          iFKClient
          iFKCatalogue
          iFKGroupe
          title
          type
          iRole
          bTarif
          bInactif
          sLibelleGroupe
          sCodeCivilite
          iFKAnnAnnuaire
          sCodeAnnuaire
          sNom
          sPrenom
          sEmail
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const savedUser = response.data.editUser;

    if (savedUser !== null) {
      yield put({ type: EDIT_CONTACT_SUCCESS, payload: savedUser });
    } else {
      yield put({
        type: EDIT_CONTACT_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_CONTACT_FAILURE, payload: message });
  }
}

export function* deleteContact({ payload: { iPKUtilisateur } }) {
  try {
    const USER_REQUEST = gql`mutation {
        deleteUser(iPKUtilisateur: ${iPKUtilisateur}) {
          iPKUtilisateur
          iFKClient
          title
          type
          iRole
          sCodeCivilite
          sNom
          sPrenom
          sEmail
          iFKGroupe
        }
      }`;
    const response = yield call(fetchApollo, USER_REQUEST);
    const deletedUser = response.data.deleteUser;

    if (deletedUser !== null) {
      yield put({ type: DELETE_CONTACT_SUCCESS, payload: deletedUser });
    } else {
      yield put({
        type: DELETE_CONTACT_FAILURE,
        payload: "Une erreur est survenue",
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_CONTACT_FAILURE, payload: message });
  }
}

export function* signUserIn({ payload: { sEmail, sMotDePasse, dCGUAccepte } }) {
  if (!navigator.onLine) {
    // console.log("TODO: USER_SIGN_IN_REQUEST Offline");
    yield put({
      type: USER_SIGN_IN_FAILURE,
      payload: "Pas de connexion au réseau",
    });
  } else {
    try {
      const AUTORISE = gql`{
        autoriseUtilisateur(sEmail: "${sEmail}", sMotDePasse: "${sMotDePasse}", dCGUAccepte: "${dCGUAccepte}") {
          iPKUtilisateur
          iFKGroupe
          iFKClient
          iFKGroupe
          sLibelleGroupe
          iFKCatalogue
          sLibelleCatalogue
          iRole
          sNom
          sPrenom
          sEmail
          sJeton
          dUpdateMdp
          dCGUAccepte
          bTarif
          error
        }
      }`;
      const response = yield call(fetchApollo, AUTORISE);
      const user = response.data.autoriseUtilisateur;

      if (user !== null && !user.error) {
        setAuthorizationToken(user.sJeton);
        setCookie(user.sJeton);
        yield put({
          type: GET_FAVORIS_REQUEST,
          payload: { iPKUtilisateur: user.iPKUtilisateur },
        });
        yield put({
          type: GET_PANIER_REQUEST,
          payload: { iPKUtilisateur: user.iPKUtilisateur },
        });
        yield put({ type: USER_SIGN_IN_SUCCESS, payload: user });
      } else {
        deleteCookie();

        yield put({
          type: USER_SIGN_IN_FAILURE,
          payload: user.error,
        });
      }
    } catch (error) {
      let { message } = error;

      switch (error.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Page not found";
          break;
        case 401:
          message = "Identifiants incorrects";
          break;
        default:
          break;
      }

      deleteCookie();
      yield put({ type: USER_SIGN_IN_FAILURE, payload: message.replace("GraphQL error: ", "") });
    }
  }
}

export function* signUserOut() {
  client.resetStore();

  if (getCookie("jwtToken")) {
    deleteCookie();
    yield put({
      type: USER_SIGN_OUT_SUCCESS,
      payload: {},
    });
  } else {
    yield put({
      type: USER_SIGN_OUT_FAILURE,
      payload: {},
    });
  }
}
