import React from "react";
import { Grid } from "semantic-ui-react";

import Layout from "../../../components/Front/Layout";

const CGU = () => {
  window.scrollTo(0, 0);
  return (
    <Layout
      mainPane={
        <Grid stackable container doubling>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h1>Conditions générales d&apos;utilisation de l&apos;outil de commande MAD</h1>
            </Grid.Column>
          </Grid.Row>
          {process.env.REACT_APP_THEME === "ARAIR" ? (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  Les conditions générales d&apos;utilisation de l'outil de commande MAD sont
                  disponibles sur demande à l'adresse{" "}
                  <a
                    href="mailto:mad@arair.fr?subject=Demande d'accès aux conditions générales d'utilisation de l'outil de commande MAD"
                    title="Conditions générales d'utilisation de l'outil de commande MAD"
                  >
                    mad@arair.fr
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  Les présentes Conditions Générales d&apos;Utilisation déterminent les règles
                  d&apos;accès à l&apos;application (ci-après l&apos;“Application”) accessible à
                  l&apos;adresse{" "}
                  <a
                    title="com@d"
                    href="https://comad.arair.fr/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    comad.arair.fr
                  </a>{" "}
                  et ses conditions d&apos;utilisation que tout utilisateur (ci-après
                  l&apos;“Utilisateur”) reconnaît avoir lu et accepté lors de sa première connexion
                  ou lors d&apos;une modification de celles-ci.
                </p>
                <p>
                  Veuillez trouver sur cet onglet les informations suivantes :
                  <br />- les mentions légales de l&apos;Application ;
                  <br />- les conditions générales d&apos;utilisation de l&apos;Application.
                </p>

                <h2>Mentions légales</h2>

                <h3>Éditeur de l&apos;Application :</h3>
                <p>
                  L&apos;Application est éditée par la société PHARMA DOM, société anonyme à conseil
                  d&apos;administration au capital de 4.320.000 €, enregistrée au RCS de Nanterre
                  sous le numéro 324 501 006 et dont le siège social est situé 10 avenue Aristide
                  Briand, 92220 Bagneux.
                  <br />
                  Téléphone : +33 (0)2 47 25 45 00
                  <br />
                  Directeur de la publication : Mme Fabienne CHATEL en tant que Directrice Générale
                  de la Société
                  <br />
                  Hébergement de l&apos;Application : L&apos;Application est hébergée par la société
                  Claranet, 2 rue Breguet, 75011 Paris,Téléphone : 01 70 13 70 00,
                  info@fr.clara.net.
                </p>

                <h2>Conditions Générales d&apos;Utilisation de l&apos;Application</h2>

                <h3>1. Définitions</h3>
                <p>
                  Les termes ci-dessous définis auront la signification suivante, qu&apos;ils soient
                  utilisés au singulier ou au pluriel :
                  <br />● “CGU” désigne les présentes conditions générales d&apos;utilisation ;
                  <br />● “Commande” désigne l&apos;acte effectué par l&apos;Utilisateur afin de se
                  voir livrer le dispositif médical contre paiement d&apos;un prix. Il peut
                  s&apos;agir de location et/ou d&apos;achat ;
                  <br />● “Compte” désigne l'espace mis à la disposition de l'Utilisateur inscrit
                  sur l&apos;Application, lui permettant d'accéder, après s'être identifié avec ses
                  Codes d'accès, à l&apos;Application ;
                  <br />● “Dispositif Médical” désigne tout dispositif médical, au sens de
                  l&apos;article L. 5211-1 du Code de la santé publique, en ce compris :
                  <br />- le matériel (Dispositif Médical Matériel) ;
                  <br />- les consommables nécessaires (Dispositif Médical Consommable).
                  <br />● “Données” désigne (i) l&apos;ensemble des informations ou données à
                  caractère personnel collectées par PHARMA DOM auprès de l&apos;Utilisateur et (ii)
                  l&apos;ensemble des informations ou données à caractère personnel qui sont
                  automatiquement collectées lors de l&apos;utilisation de l&apos;Application par un
                  Utilisateur notamment par l&apos;intermédiaire de cookies ;
                  <br />● “Échec d&apos;identification” désigne une tentative infructueuse
                  d&apos;authentification de l&apos;Utilisateur en vue de son accès à
                  l&apos;Application ;
                  <br />● “Fonctionnalités” désigne les différentes fonctionnalités proposées par
                  l&apos;Application ;
                  <br />● “ Politique de protection des données personnelles ” désigne le document
                  qui définit la manière dont PHARMA DOM recueille, utilise, conserve et transfère
                  les données personnelles des Utilisateurs et de tout tiers dans le cadre de
                  l&apos;utilisation de l&apos;Application et accessible sous le lien suivant :{" "}
                  <a
                    href="https://www.arair.fr/politique-confidentialite-rgpd"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Politique de Protection des données personnelles.
                  </a>
                  <br />● “Terminal” désigne les différents supports par lesquels l&apos;Application
                  sera accessible. Les terminaux sont notamment les tablettes tactiles et les
                  micro-ordinateurs connectés à Internet ;
                  <br />● “Utilisateur” désigne la personne physique accédant à l&apos;Application
                  ou bénéficiant d&apos;un accès à l&apos;Application après la création d&apos;un
                  Compte dans les conditions prévues aux présentes CGU, habilitée à agir dans
                  l'intérêt et pour le compte de son établissement.
                </p>

                <h3>2 - Objet</h3>
                <p>
                  L&apos;Application est exclusivement destinée aux structures clientes et aux
                  structures souhaitant travailler avec la Société, sous son nom de marque ARAIR
                  Assistance (ci-après “la Société”) et domiciliées sur la zone géographique
                  d&apos;intervention de la Société , utilisatrices d&apos;un Terminal.
                </p>
                <p>
                  L&apos;utilisation de l&apos;Application est soumise aux dispositions légales en
                  vigueur ainsi qu&apos;aux présentes CGU. Ces CGU sont disponibles à tout moment
                  sur l&apos;Application.
                </p>
                <p>
                  L&apos;Application et les informations diffusées dans l&apos;Application ont
                  principalement pour objet de permettre à l&apos;Utilisateur de passer des
                  Commandes, depuis le Terminal, de Dispositifs Médicaux nécessaires à la prise en
                  charge de leurs patients proposés par la Société dans l&apos;Espace Commande de
                  l&apos;Application.
                </p>

                <h3>3 - Fonctionnalités de l&apos;Application</h3>
                <p>
                  Les principales Fonctionnalités de l&apos;Application sont les suivantes :
                  <br />- Consultation du catalogue de Dispositifs Médicaux proposés à
                  l&apos;Utilisateur par la Société;
                  <br />- Commande de Dispositifs Médicaux ;
                  <br />- Consultation des Commandes passées et en cours ;
                  <br />- Demande de devis et création, directement dans l&apos;Application, de
                  devis par l&apos;Utilisateur ;
                  <br />- Demande de dépannage ;
                  <br />- Demande de reprise des Dispositifs Médicaux (Matériel uniquement).
                </p>
                <p>
                  La Société se réserve la possibilité de modifier, interrompre, de manière
                  provisoire ou définitive, ces Fonctionnalités à tout moment, notamment dans le
                  cadre d&apos;opération de maintenance de l&apos;Application.
                </p>

                <h3>4 - Acceptation</h3>
                <p>
                  L&apos;acceptation des CGU est recueillie de manière expresse à la première
                  connexion de l&apos;Utilisateur à l&apos;Application.
                </p>
                <p>
                  En acceptant les CGU, l&apos;Utilisateur accepte sans limitation ni réserve,
                  l&apos;intégralité des CGU et reconnaît qu&apos;elles prévalent sur tout autre
                  accord conclu avec la Société dans le cadre de l&apos;utilisation de
                  l&apos;Application.{" "}
                </p>
                <p>
                  La Société se réserve la possibilité de modifier et/ou de mettre à jour les CGU
                  sans préavis. Le consentement de l&apos;Utilisateur sera recueilli à nouveau de
                  manière expresse.
                </p>

                <h3>5 - Conditions d&apos;accès à l&apos;Application</h3>

                <h4>5.1 - Modalités de création d&apos;un Compte</h4>
                <p>
                  L&apos;accès aux Fonctionnalités réservées aux Utilisateurs est conditionné à la
                  création d&apos;un Compte.
                </p>
                <p>
                  Lorsque l&apos;Utilisateur souhaite accéder à l&apos;Application, il doit demander
                  à la Société la création d&apos;un Compte structure (ci-après “Compte Structure”).
                  La Société lui fournit un mot de passe. A la première connexion, le changement du
                  mot de passe sera demandé.
                </p>
                <p>
                  Le Compte Structure permet au représentant légal d&apos;une personne morale
                  (Établissements sociaux et médico-sociaux, Établissements sanitaires...) ou toute
                  autre personne dûment habilitée par lui, d&apos;administrer les Comptes de ses
                  collaborateurs qu&apos;il aura autorisés à utiliser l'Application et ce sous sa
                  responsabilité (ci-après les “Comptes Associés”). Il est entendu que plusieurs
                  Comptes peuvent être créés pour un même établissement.
                </p>
                <p>
                  Le premier Compte Associé à un établissement est configuré par défaut en tant que
                  Compte Structure.
                </p>
                <p>
                  Les Comptes créés ultérieurement pour le même établissement ne seront ouverts que
                  sur validation du Compte Structure. L&apos;Utilisateur du Compte Structure
                  sollicite la Société pour obtenir un mot de passe pour chaque Compte Associé.
                </p>

                <h4>5.2 Accès à l&apos;Application</h4>
                <p>
                  L&apos;accès à l&apos;Application est soumis à la saisine de l&apos;identifiant du
                  Compte et du mot de passe associé au Compte.
                </p>
                <p>
                  En cas d&apos;oubli de mot de passe, il suffit à l&apos;Utilisateur de
                  réinitialiser son mot de passe en cliquant sur le lien dédié. Un courriel lui sera
                  adressé à l&apos;adresse de courrier électronique associé au Compte pour
                  réinitialiser son mot de passe.
                </p>
                <p>
                  Si le Compte n&apos;est pas utilisé pendant 6 mois, le Compte devient inactif.
                  L&apos;Utilisateur pourra contacter la Société pour le réactiver.{" "}
                </p>

                <h4>5.3 Sécurité d&apos;accès</h4>
                <p>
                  L&apos;accès au Compte est protégé notamment par un identifiant personnel et un
                  mot de passe associé composé de 8 caractères dont au moins une lettre minuscule,
                  une lettre majuscule, un chiffre et un symbole de la liste suivante ; @ # $% ^ & *
                  () + | ~ - = \ `{} []:"; '&gt; &lt; /!? (ci-après collectivement le “Code
                  d&apos;Accès”). Ce mot de passe devra être modifié tous les 6 mois.
                </p>
                <p>
                  Il est de la responsabilité de l&apos;Utilisateur de garder ses Codes d&apos;Accès
                  strictement confidentiels. La Société ne pourra être tenue responsable d&apos;une
                  divulgation des Codes d&apos;Accès par l&apos;Utilisateur, même involontaire, à un
                  tiers.
                </p>
                <p>
                  Lorsqu&apos;il se connecte à son Compte en utilisant le Code d&apos;Accès,
                  l&apos;Utilisateur déclenche l&apos;ouverture d&apos;une session qui prend fin au
                  moment où l&apos;Utilisateur se déconnecte. Par mesure de sécurité,
                  l&apos;Utilisateur doit bien veiller à se déconnecter de son Compte en cliquant
                  sur le bouton “Déconnexion” au moment de quitter l&apos;Application et de ne pas
                  sauvegarder ses Codes d&apos;Accès dans les paramètres de son navigateur. En cas
                  d&apos;inactivité sur l'Application, la session expire au bout de 30 minutes.
                </p>
                <p>
                  En cas de perte de son mot de passe, l&apos;Utilisateur peut demander l'envoi d'un
                  nouveau mot de passe directement via l&apos;Application en cliquant sur "mot de
                  passe oublié". La Société transmet alors à l&apos;Utilisateur, à l&apos;adresse
                  email indiquée par celui-ci lors de la validation de son inscription, un nouveau
                  mot de passe. En cas d&apos;usurpation, de perte ou de vol de Codes d&apos;Accès,
                  l&apos;Utilisateur doit immédiatement contacter la Société.
                </p>
                <p>
                  L&apos;Utilisateur accepte que son accès à l&apos;Application puisse être refusé
                  en cas d'Échec d&apos;Identification. Le cas échéant, l&apos;Utilisateur demande
                  l&apos;envoi d'un nouveau mot de passe selon les modalités décrites ci-avant
                  applicables en cas de perte de son mot de passe.
                </p>
                <p>
                  Après le troisième Échec d&apos;Identification consécutif, l&apos;accès à
                  l&apos;Application est bloqué pendant un délai de cinq (5) minutes.{" "}
                </p>

                <h4>5.4 Moyens d&apos;accès</h4>
                <p>
                  L&apos;Utilisateur fait son affaire personnelle de la mise en place des moyens
                  informatiques et de télécommunications permettant l'accès à l&apos;Application et
                  de l&apos;acquisition des connaissances nécessaires à l'utilisation d'Internet et
                  de l&apos;Application.
                </p>
                <p>
                  Les frais de connexion et d'équipement liés à l'accès à Internet, et à
                  l'utilisation de l&apos;Application sont à la charge exclusive de
                  l&apos;Utilisateur.
                </p>
                <p>
                  Par ailleurs, l&apos;Utilisateur reconnaît avoir sécurisé la(les) configuration(s)
                  informatique(s) qu&apos;il utilise pour accéder à l&apos;Application, notamment en
                  se dotant d&apos;un anti-virus ou en prévoyant le verrouillage automatique de
                  l&apos;accès à ses Terminaux dès lors que l&apos;Utilisateur n&apos;effectue
                  aucune action à partir du Terminal pendant plus de quinze (15) minutes.
                </p>
                <p>
                  L&apos;utilisation de l&apos;Application implique la connaissance et l'acceptation
                  des caractéristiques et des limites des technologies utilisées par l'Internet et
                  les technologies qui y sont liées, notamment en ce qui concerne les performances
                  techniques, les temps de réponse pour consulter, interroger ou transférer des
                  informations, les risques d'interruption, et plus généralement, les risques
                  inhérents à toute connexion et transmission, l'absence de protection de certaines
                  données contre des détournements éventuels et les risques de contamination par
                  d'éventuels virus circulant sur le réseau.
                </p>
                <h4>5.5 L&apos;espace personnel de l&apos;Utilisateur</h4>
                <p>
                  Cet espace est l&apos;espace d&apos;informations pratiques et spécifiques réservé
                  à tout Utilisateur de l&apos;Application qui est titulaire d&apos;un Compte.
                </p>
                <p>Il permet à l&apos;Utilisateur de modifier ses informations personnelles.</p>
                <p>
                  La Société se réserve le droit de contacter l&apos;Utilisateur en cas de
                  modifications de ses informations personnelles pour simple vérification.
                </p>
                <p>
                  En cas d&apos;impossibilité de contacter l&apos;Utilisateur et en cas
                  d&apos;anomalie voire incohérence lors de la phase de contrôle, la Société se
                  réserve le droit, sans préavis ni indemnité, de suspendre l&apos;accès à
                  l&apos;Application en cas d&apos;utilisation ou suspicion d&apos;utilisation
                  illicite ou frauduleuse du Compte.
                </p>
                <p>
                  L&apos;Utilisateur s&apos;engage à mettre à jour ses informations et à informer la
                  Société de toute modification de celles-ci.
                </p>
                <p>
                  Les informations personnelles sont soumises à la Politique de Protection des
                  données personnelles consultables sur le lien suivant :
                  <a
                    href="https://www.arair.fr/politique-confidentialite-rgpd"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Politique de protection des données personnelles
                  </a>
                  .
                </p>

                <h3>6. Comportement des Utilisateurs</h3>
                <p>
                  En accédant à l&apos;Application, l&apos;Utilisateur s&apos;engage à et garantit :
                  <br />● respecter toutes les lois et réglementation en vigueur dans le pays à
                  partir duquel il accède à l&apos;Application ;
                  <br />● communiquer des Données exactes, exhaustives et à jour ;
                  <br />● respecter sans réserve les CGU ;
                  <br />● être dûment habilité à utiliser l&apos;Application pour le compte de son
                  établissement.
                  <br />
                  <br />
                  En outre, l&apos;Utilisateur s&apos;interdit notamment de :
                  <br />● perturber ou interrompre le fonctionnement de l&apos;Application ou les
                  serveurs ou réseaux connectés à l&apos;Application ou enfreindre les exigences,
                  procédures, règles ou réglementations des réseaux connectés à l&apos;Application ;
                  <br />● essayer de porter atteinte à tout autre Utilisateur ou réseau, ce qui
                  comprend, sans exhaustivité aucune, le fait d&apos;exposer l&apos;Application à un
                  virus, de créer une saturation, d&apos;inonder le serveur, de saturer la
                  messagerie, ou encore de falsifier tout en-tête du paquet d&apos;informations du
                  protocole TCP/IP ou n'importe quelle partie des informations de l&apos;en-tête
                  figurant dans tout e-mail ;
                  <br />● accéder ou tenter d&apos;accéder à toutes informations et Données qui ne
                  lui sont pas destinées notamment en forçant les systèmes ou par tout autre moyen
                  ou entrer dans un serveur / un compte auquel l&apos;Utilisateur n&apos;est pas
                  autorisé à avoir accès ;
                  <br />● tenter de sonder, de scruter ou de tester la vulnérabilité de
                  l&apos;Application ou d'un réseau, ou tout autre système ou encore
                  d&apos;enfreindre les mesures de sécurité ou d'identification ;
                  <br />● usurper l&apos;identité d&apos;une autre personne et notamment d&apos;un
                  autre Utilisateur ;
                  <br />● mener une activité ou inciter une tierce personne à mener une activité
                  illégale ou toute autre activité qui porterait atteinte aux droits de la Société,
                  de ses partenaires, des Utilisateurs ou toute autre personne physique ou morale;
                  <br />● transmettre ou transférer (par quelque moyen que ce soit) des informations
                  ou logiciels dérivés de l&apos;Application notamment à d&apos;autres pays ou à
                  certains ressortissants étrangers en violation d&apos;une loi ou réglementation
                  nationale ou internationale ;
                  <br />● l&apos;emploi de tout moyen permettant de contourner les techniques
                  d&apos;identification mises en œuvre par la Société et nécessaires à la connexion
                  à l&apos;Application.
                </p>
                <p>
                  En reconnaissant la nature globale du réseau Internet, chaque Utilisateur
                  s&apos;engage à respecter toutes les règles et procédures locales et
                  internationales relatives au comportement en ligne et à un contenu acceptable et
                  notamment toutes les lois en vigueur concernant la transmission des données.
                </p>
                <p>
                  Tout programme, document ou support téléchargé ou obtenu par l&apos;Utilisateur
                  lors de l'utilisation de l&apos;Application, le sera sous la responsabilité de
                  l&apos;Utilisateur. L&apos;Utilisateur est seul responsable de toute perte ou tout
                  dommage comprenant notamment les dommages occasionnés à son système informatique
                  ou les pertes de données en résultant directement ou indirectement.
                </p>
                <h3>7 - Les Fonctionnalités proposées par l&apos;Application</h3>
                <p>
                  Une fois que l&apos;Utilisateur a saisi son identifiant et son mot de passe
                  associés au Compte, il accède à l&apos;Application et aux différentes
                  Fonctionnalités ci-dessous :
                </p>
                <h4>a- Consultation du catalogue de Dispositifs Médicaux</h4>
                <p>
                  L&apos;Utilisateur accède au catalogue des Dispositifs Médicaux proposés par la
                  Société à la vente et/ou à la location.
                </p>
                <h4>b - Commande de Dispositif Médical</h4>
                <p>
                  L&apos;Application propose à l&apos;Utilisateur de passer une Commande de
                  Dispositif Médical.
                </p>
                <p>
                  La Commande de Dispositif Médical n'entraîne aucune transaction sur
                  l&apos;Application qui n&apos;est pas une plateforme de vente à distance mais
                  propose simplement l&apos;initiation de la prise de Commande de Dispositif
                  Médical.
                </p>
                <p>
                  Les personnels de la Société accéderont à ces informations afin de pouvoir
                  organiser la préparation et la livraison du Dispositif Médical commandé dans
                  l&apos;établissement de l&apos;Utilisateur.
                </p>
                <p>
                  La Commande de Dispositif Médical est soumise aux conditions générales de location
                  et/ou de vente. Par exception à ce qui précède, dans le cas où l&apos;Utilisateur
                  a signé avec la Société une convention de mise à disposition de matériel, les
                  dispositions de cette convention prévaudront sur les dispositions des conditions
                  générales de location et/ou de vente
                </p>
                <p>b.1 Les Dispositifs Médicaux</p>
                <p>
                  Les Dispositifs Médicaux qui sont proposés à l&apos;Utilisateur sont ceux décrits
                  sur l&apos;Application au jour de la consultation de celle-ci.
                </p>
                <p>
                  La présentation des Dispositifs Médicaux sur l&apos;Application n&apos;indique pas
                  la disponibilité de ces Dispositifs Médicaux dans les stocks de la Société. À ce
                  titre, la Société ne saurait être tenue responsable de l&apos;annulation
                  d&apos;une Commande d&apos;un Dispositif Médical du fait de l&apos;épuisement des
                  stocks. La Société fait tout son possible pour proposer à l&apos;Utilisateur un
                  Dispositif Médical ayant les mêmes fonctionnalités et caractéristiques techniques
                  ou de niveau supérieur.
                </p>
                <p>
                  La Société apporte le plus grand soin dans la présentation et la description de
                  ces Dispositifs Médicaux pour satisfaire au mieux l&apos;information de
                  l&apos;Utilisateur. Il est toutefois possible que des erreurs puissent figurer sur
                  l&apos;Application, ce que l&apos;Utilisateur reconnaît et accepte.
                </p>
                <p>b.2 Les Commandes</p>
                <p>Etape 1 : La Commande de Dispositifs Médicaux</p>
                <p>- La Commande</p>
                <p>
                  Lorsque qu&apos;il a sélectionné le (les) Dispositif(s) Médical(aux) qu&apos;il
                  souhaite commander, l&apos;Utilisateur clique sur le bouton “ajouter au panier”.
                  Une fois la sélection faite, l&apos;Utilisateur clique sur le bouton “passer
                  commande”.
                </p>
                <p>
                  Un espace de commentaire est disponible pour chaque Commande, l&apos;Utilisateur
                  peut notamment y préciser des informations complémentaires relatives à la
                  livraison tel que service concerné ou numéro de chambre par exemple, dans le
                  respect de la Politique de Protection des données personnelles consultables sur le
                  lien suivant :
                  <a
                    href="https://www.arair.fr/politique-confidentialite-rgpd"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://www.arair.fr/politique-confidentialite-rgpd
                  </a>
                  . L&apos;Utilisateur peut en outre indiquer tout élément nécessaire pour faciliter
                  la préparation et la livraison des Dispositifs Médicaux. Ensuite,
                  l&apos;Utilisateur clique sur le bouton “valider la commande” lorsque celle-ci est
                  complète.
                </p>
                <p>Un récapitulatif de la Commande en pdf est envoyé à l&apos;Utilisateur avec :</p>
                <p>- La liste des Dispositifs Médicaux commandés et la quantité ;</p>
                <p>- L&apos;adresse de livraison.</p>
                <p>
                  La Commande est enregistrée une fois que l&apos;Utilisateur reçoit le
                  récapitulatif de la Commande.
                  <br />
                </p>
                <p>Etape 2 : Confirmation de Commande</p>
                <p>
                  Une fois que la Commande a été traitée par la Société, cette dernière informe
                  l&apos;Utilisateur par courrier électronique ou par téléphone.
                </p>
                <p>b.3 Le suivi de Commande</p>
                <p>
                  La Société traite la Commande et vérifie les informations de la Commande. Selon
                  les Dispositifs Médicaux commandés ou toute autre information de la Commande, un
                  chargé de clientèle de la Société peut être amené à appeler l&apos;Utilisateur en
                  cas de nécessité.
                </p>
                <p>
                  De même, si l&apos;Utilisateur a des questions à propos de sa Commande, il peut
                  appeler le Service Relation Client durant ses horaires d&apos;ouverture (du lundi
                  au vendredi de 8h30 à 18h30) dont les coordonnées figurent : un chargé de
                  clientèle sera en mesure de l&apos;informer à propos de sa Commande.
                  <br />
                </p>
                <p>Etape 3 : L&apos;annulation de la Commande</p>
                <p>
                  L&apos;Utilisateur peut annuler la Commande sur l&apos;Application tant que
                  l&apos;état de la Commande est “en cours de traitement”. Si la Commande est en
                  état “en cours de préparation”, “en cours de livraison” ou “livrée”,
                  l&apos;Utilisateur doit appeler la Société au Service Relation Client durant les
                  horaires d&apos;ouverture (du lundi au vendredi de 8h30 à 18h30) dont les
                  coordonnées figurent ci-après : mad.tours@arair.fr, pour l&apos;informer de
                  l&apos;annulation de la Commande. La Société est susceptible de facturer des frais
                  en cas d&apos;annulation abusive ou tardive à ses conditions tarifaires en vigueur
                  (frais de déplacement, frais de main d&apos;oeuvre, frais d&apos;envoi...).
                </p>
                <p>c - Consultation des Commandes</p>
                <p>
                  L&apos;historique des Commandes effectuées est disponible dans l&apos;Espace
                  Commandes de l&apos;Application.
                </p>
                <p>
                  Bien que l&apos;Utilisateur puisse également passer une Commande par téléphone ou
                  par mail, les Commandes ainsi réalisées n&apos;apparaissent pas dans
                  l&apos;historique des Commandes de l&apos;Application.
                </p>
                <p>d - Demande de devis</p>
                <p>
                  L&apos;Utilisateur a la possibilité soit d&apos;établir lui-même son devis soit de
                  faire une demande de devis en décrivant son besoin. Dans ce cas, un devis lui est
                  transmis par mail. Il est entendu que toute demande de devis n&apos;emporte ni
                  obligation de vente pour la Société ni obligation d&apos;achat pour
                  l&apos;Utilisateur.
                </p>
                <p>
                  La durée de validité du devis ainsi que les modalités d'acceptation du devis sont
                  communiquées à l&apos;Utilisateur par la Société.
                </p>
                <p>e - Demande de dépannage</p>
                <p>
                  L&apos;Utilisateur peut créer, dans l&apos;Application, une demande
                  d&apos;intervention sur les Dispositifs Médicaux fournis par la Société.
                  L&apos;Utilisateur devra renseigner le champ “description du problème”.
                </p>
                <p>
                  A réception de la demande et après traitement par la Société, cette dernière
                  informe l&apos;Utilisateur de la date de rendez-vous programmé pour le dépannage,
                  par mail ou par téléphone.
                </p>
                <p>f - Demande de reprise des Dispositifs Médicaux Matériel</p>
                <p>
                  L&apos;Utilisateur a la possibilité de demander la reprise des Dispositifs
                  Médicaux Matériel mis à disposition par la Société. L&apos;Utilisateur saisit une
                  date souhaitée pour le retour et la date de la demande est enregistrée par
                  l&apos;Application. A réception de la demande et après traitement par la Société,
                  cette dernière informe l&apos;Utilisateur de la date de rendez-vous programmé pour
                  la reprise des Dispositifs Médicaux, par mail ou par téléphone.
                </p>
                <h3>8. Propriété intellectuelle</h3>
                <p>
                  L&apos;Application contient des données et éléments protégés notamment par des
                  droits de propriété intellectuelle tels que le droit des marques, le droit
                  d&apos;auteur, etc.
                </p>
                <p>
                  Tous les éléments de l&apos;Application, et notamment les textes, éléments
                  graphiques, éléments sonores et audiovisuels, ses outils de suivi, ses
                  configurations, affichages et écrans, et plus généralement toutes les informations
                  apparaissant sur ou affiché(e)s par la Société en liaison avec ou contenu(e)s sur
                  l&apos;Application (ci-après le “Contenu”) sont protégé(e)s par les lois
                  applicables.
                </p>
                <p>
                  Sauf mention contraire, les droits de propriété intellectuelle liés au Contenu
                  composant l&apos;Application ainsi que l&apos;Application sont la propriété
                  exclusive de la Société ou d&apos;un de ses partenaires ayant consenti des droits
                  d&apos;utilisation à la Société.
                </p>
                <p>
                  L&apos;Utilisateur reconnaît qu&apos;aucune propriété ne lui est transmise, et
                  qu&apos;aucun droit ne lui est accordé, en dehors d&apos;un droit d&apos;usage
                  privé, non collectif et non exclusif de l&apos;Application conformément aux CGU.
                  Ce droit d&apos;usage est délivré à l&apos;Utilisateur, titulaire d&apos;un
                  Compte, à titre gratuit pour la France et pour la durée pendant laquelle
                  l&apos;Application est rendue accessible à l&apos;Utilisateur par la Société.
                </p>
                <p>
                  Toute reproduction et/ou toute utilisation de copies de l&apos;Application et/ou
                  de son Contenu réalisées à d&apos;autres fins que celles prévues à l&apos;article
                  FONCTIONNALITES et au présent article, de quelque manière que ce soit, et sous
                  quelque forme que ce soit, non expressément autorisée, est interdite.
                  <p>
                    En conséquence, sauf autorisation expresse et préalable de la Société,
                    l&apos;Utilisateur s&apos;engage à respecter les dispositions impératives
                    afférentes à son droit d&apos;utilisation de l&apos;Application et notamment les
                    règles suivantes :
                    <br />- ne pas louer, vendre, distribuer, sous-licencier, céder, transférer,
                    distribuer, publier, communiquer ou mettre à disposition de tiers de quelque
                    autre manière que ce soit tout ou partie de l&apos;Application en vue de la
                    création, de la conception ou de la réalisation d&apos;un autre système
                    d&apos;information ou d&apos;une autre solution matérielle et/ou logicielle,
                    dérivée ou entièrement nouvelle ;
                    <br />- ne pas utiliser l&apos;Application de quelque façon que ce soit aux fins
                    de conception, de réalisation, diffusion ou commercialisation de logiciels
                    similaires, équivalents ou de substitution ;
                    <br />- ne pas adapter, modifier, transformer, arranger l&apos;Application, pour
                    quelque raison que ce soit, notamment en vue de la création d'un logiciel dérivé
                    ou entièrement nouveau ;
                    <br />- ne pas mettre à disposition directe ou indirecte l&apos;un quelconque
                    des éléments constitutifs de l&apos;Application au bénéfice d&apos;un tiers ;
                    <br />- ne pas utiliser un robot, notamment d&apos;exploration (spider), une
                    application de recherche ou de récupération de sites Web ou tout autre moyen
                    permettant d&apos;extraire, réutiliser ou d&apos;indexer tout ou partie du
                    contenu de l&apos;Application.
                  </p>
                </p>
                <p>
                  De même, toute reproduction non autorisée des œuvres littéraires, photographiques
                  et plus généralement de toute œuvre susceptible d&apos;être protégée par le droit
                  de propriété intellectuelle, accessible par le biais de l&apos;Application est
                  interdite, conformément aux dispositions de l&apos;article L 122-4 du Code de la
                  propriété intellectuelle à moins que cette reproduction ne soit exclusivement
                  réservée à un usage strictement privé.
                </p>
                <p>
                  Toute reproduction, représentation, exploitation, de quelque manière que ce soit
                  et pour quelque destination que ce soit, de tout élément protégé de
                  l&apos;Application, et notamment de sa structure et/ou de son Contenu sans
                  l&apos;autorisation préalable et écrite de la Société, constitue un acte de
                  contrefaçon engageant la responsabilité civile et pénale de son auteur.
                </p>

                <h3>9. Désactivation/Suppression d&apos;un Compte</h3>
                <p>
                  ● en cas de non-respect des termes des CGU ou en cas de suspicion de non-respect
                  de ces termes,
                  <br />● en cas de déclaration de perte ou de vol des Codes d&apos;Accès,
                  <br />● en cas d'Échec d&apos;Identification,
                </p>
                <p>A tout moment et pour quelque raison que ce soit et notamment :</p>
                <p>
                  A sa seule discrétion et ce sans préjudice de toute action qu&apos;elle se réserve
                  le droit d&apos;exercer, la Société peut mettre en œuvre tout moyen permettant,
                  sans préavis d&apos;interrompre l&apos;accès de l&apos;Utilisateur à son Compte.
                </p>
                <p>
                  L&apos;Utilisateur est informé qu'en cas de suspension de son Compte, pour quelque
                  raison que ce soit, l&apos;accès aux Données depuis l'Application ne sera plus
                  possible. Chaque Utilisateur conserve toutefois ses droits relatifs à ses Données
                  conformément à la Politique de Protection des données personnelles consultables
                  sur le lien suivant :{" "}
                  <a
                    href="https://www.arair.fr/politique-confidentialite-rgpd"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Politique de protection des données personnelles
                  </a>
                  .
                </p>
                <p>
                  L&apos;Utilisateur peut également, de son côté, demander la suppression de son
                  Compte Structure à tout moment en contactant la Société par mail à arair@arair.fr.
                </p>
                <p>
                  L&apos;Utilisateur d&apos;un Compte Structure peut supprimer directement les
                  Comptes Associés.
                </p>

                <h3>10. Limites de garantie et de responsabilité</h3>

                <h4>10.1 Principes</h4>
                <p>
                  La responsabilité de la Société, des personnes impliquées dans la réalisation, le
                  développement ou la diffusion de l&apos;Application, y compris ses fournisseurs
                  d'information ou partenaires, ne saurait être recherchée au titre de
                  l&apos;utilisation que l&apos;Utilisateur fait des informations et services
                  disponibles ou proposés sur l&apos;Application.
                </p>
                <p>
                  La Société est susceptible de modifier, à tout moment et sans préavis, le contenu
                  de l&apos;Application.
                </p>
                <p>
                  Alors même que toutes les démarches nécessaires pour s'assurer de la fiabilité des
                  informations contenues dans l&apos;Application ont été effectuées, la Société ne
                  donne aucune garantie, explicite ou implicite et n&apos;assume aucune
                  responsabilité relative à la complétude, l'exhaustivité, l'exactitude du contenu
                  des informations et des services proposés par l&apos;Application ou relative à
                  l&apos;utilisation des informations accessibles dans l&apos;Application.
                </p>
                <p>
                  A l&apos;exception des données à caractère personnel pour lesquelles la Société
                  s&apos;engage à prendre toutes les mesures appropriées pour que les données
                  inexactes ou incomplètes au regard des finalités pour lesquelles elles sont
                  collectées et traitées soient effacées ou rectifiées, la Société ne peut être
                  tenue responsable ni de l&apos;inexactitude, ni des erreurs, ni des omissions
                  contenues dans l&apos;Application à quelque titre que ce soit, pour quelque
                  décision que ce soit.
                </p>
                <p>
                  La Société ne garantit en aucun cas la conformité et l&apos;adéquation de
                  l&apos;Application, services et informations qui y sont disponibles aux besoins
                  estimés par l&apos;Utilisateur.
                </p>

                <h4>10.2 Sécurité</h4>
                <p>
                  L&apos;Utilisateur reconnaît que, nonobstant tous les moyens mis en œuvre par la
                  Société, Internet, et/ou le réseau téléphonique, présente des spécificités
                  techniques qui impliquent l'impossibilité de garantir la continuité absolue de
                  l'accès à l&apos;Application et des temps de réponse, ainsi que la sécurité dans
                  la transmission des données.
                </p>
                <p>
                  En conséquence, l&apos;Utilisateur accepte d&apos;utiliser l&apos;Application sous
                  son entière responsabilité et reconnaît que les informations et services proposés
                  par l&apos;Application :
                  <br />- peuvent être interrompues pour des cas de force majeure, des situations
                  indépendantes de la volonté de la Société ou en raison de faits ne relevant pas de
                  sa responsabilité,
                  <br />- peuvent contenir des erreurs de nature technique ou humaine,
                  <br />- peuvent induire des pertes de données ponctuelles.
                </p>
                <p>
                  La Société, ni aucune des personnes impliquées dans la réalisation, le
                  développement ou la diffusion de l&apos;Application y compris ses fournisseurs
                  d'information ne peuvent être tenus responsables des dommages résultant de virus,
                  bug ou tout programme ou application incompatible avec votre Terminal, ni des
                  dommages subis du fait d'une panne, interruption ou erreur, évolution, remise en
                  état, contrôle, maintenance, problème technique, coupure du réseau téléphonique ou
                  des réseaux ou services liés, surcharge, négligence ou faute de tiers ou faute de
                  l&apos;Utilisateur.
                </p>
                <p>
                  La Société, ni aucune des personnes impliquées dans la réalisation, le
                  développement ou la diffusion de l&apos;Application y compris ses fournisseurs
                  d'information ne peuvent non plus être tenue responsable des dommages matériels ou
                  immatériels, directs ou indirects résultant de l'utilisation de l&apos;Application
                  ou encore, de l'impossibilité d'utiliser l&apos;Application (même si la Société a
                  été avisée de la possibilité de tels dommages).
                </p>
                <p>
                  Toutes les limitations de responsabilité concernent tout type de dommages directs
                  ou indirects, y compris la perte de données, la perte de bénéfices, perte de
                  clientèle, perte de Commande, perte d'image ou de marque, perte de chance,
                  l'interruption d'activité, ou tout autre préjudice commercial.
                </p>

                <h3>11. Dispositions générales</h3>

                <h4>11.1 Survivance </h4>
                <p>
                  Les stipulations des CGU qui par leur nature ou leur rédaction ont vocation à
                  continuer à s&apos;appliquer après la cessation de l&apos;utilisation de
                  l&apos;Application pour quelque cause que ce soit, et notamment les stipulations
                  des articles « Comportement de l&apos;Utilisateur », « Propriété intellectuelle »,
                  « Garantie/Responsabilité » et « Survivance » restent en vigueur à l&apos;issue de
                  cette utilisation.
                </p>

                <h4>11.2 Nullité – Titre</h4>
                <p>
                  Si une ou plusieurs stipulations des CGU sont tenues pour non valides ou déclarées
                  comme telles en application d'une loi, d'un règlement ou à la suite d'une décision
                  définitive d'une juridiction compétente, les autres stipulations garderont toute
                  leur force et portée.
                </p>
                <p>
                  En cas de difficultés d&apos;interprétation entre l&apos;un quelconque des titres
                  figurant en tête des clauses et l&apos;une quelconque des clauses, les titres
                  seront écartés et le contenu de la clause concernée prévaudra.
                </p>

                <h4>11.3 Mise à jour des CGU- Intégralité</h4>
                <p>
                  La Société se réserve la faculté de modifier les dispositions des présentes CGU à
                  tout moment.
                </p>
                <p>
                  Ces CGU constituent l'intégralité de l'accord entre l&apos;Utilisateur et la
                  Société concernant l'utilisation de l&apos;Application.
                </p>

                <h4>11.4 Droit applicable / juridiction compétente</h4>
                <p>
                  Les CGU sont régies par le droit français. Il en est ainsi pour les règles de
                  fond, comme pour les règles de forme.
                </p>
                <p>
                  EN CAS DE LITIGE SURVENANT EN LIEN AVEC LA VALIDITÉ, L'EXÉCUTION OU
                  L'INTERPRÉTATION DES CGU, COMPÉTENCE EXPRESSE EST ATTRIBUEE AUX TRIBUNAUX DE PARIS
                  NONOBSTANT PLURALITE DE DEFENDEURS, APPEL EN GARANTIE, MÊME POUR LES PROCEDURES
                  D'URGENCE OU LES PROCEDURES CONSERVATOIRES EN REFERE OU PAR REQUÊTE.
                </p>

                <h4>11.5 Preuve </h4>
                <p>
                  Les registres informatisés conservés dans les systèmes informatiques de
                  l&apos;Application dans des conditions raisonnables de sécurité, seront considérés
                  comme les preuves des communications et des différentes transmissions des
                  informations entre l&apos;Utilisateur et la Société.
                </p>
                <p>
                  L&apos;archivage des informations est effectué sur un support fiable et durable de
                  manière à correspondre à une copie fidèle et durable, conformément à
                  l&apos;article 1379 du Code civil.
                </p>
                <p>
                  L&apos;Utilisateur reconnaît et accepte, qu&apos;après son identification et
                  authentification, toute manifestation de volonté par le biais de
                  l&apos;utilisation des Fonctionnalités proposées au sein de l&apos;Application, et
                  en particulier (1) l&apos;émission de son consentement au titre de la collecte et
                  du traitement de ses données (2) l&apos;acceptation des présentes CGU et manifeste
                  son consentement en caractérisant sa preuve.
                </p>
                <br />
                <p>MAJ 1er avril 2023</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      }
    />
  );
};
export default CGU;
