import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BtnSubmit = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.button.primary.text};
  background: ${props => props.theme.button.primary.background};
  margin-right: 10px;
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.button.primary.hoverBackground};
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
    &:hover {
      background: ${props => props.theme.button.primary.background};
    }
  }
`;

const Submit = ({ className, children, disabled, onClick }) => {
  return (
    <BtnSubmit className={className} disabled={disabled} onClick={onClick}>
      {children}
    </BtnSubmit>
  );
};

Submit.propTypes = {
  className: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Submit.defaultProps = {
  className: "",
  disabled: false
};

export default Submit;
