import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: ${props => props.theme.colors.primary.main};
  text-transform: uppercase;
`;

const H1 = ({ children, className }) => (
  <Title className={className}>{children}</Title>
);

H1.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string
};

H1.defaultProps = {
  className: ""
};

export default H1;
