import React, { Component } from "react";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateTime } from "luxon";

import { Grid, Card, Icon, List } from "semantic-ui-react";

import { fetchApollo } from "../../../graphql/apolloClient";

import LayoutAdmin from "../../../components/AdminClient/Layout";

class TableauDeBordClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tIntervention: [],
      tRetour: [],
      tCommande: [],
      tDevis: []
    };
  }

  componentDidMount() {
    this.getInterventions();
    this.getRetours();
    this.getCommandes();
    this.getDevis();
  }

  getDevis = () => {
    const { user } = this.props;
    const GET_DEVIS = gql`
      {
        listLatestDevisByGroup (iFKUtilisateur: ${user.iPKUtilisateur}) {
          iPKDevis
          dInsert
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_DEVIS).then(response => {
      const tDevis = response.data.listLatestDevisByGroup;
      if (tDevis) {
        this.setState({
          tDevis
        });
      }
    });
  };

  getCommandes = () => {
    const { user } = this.props;
    const GET_COMMANDES = gql`
      {
        listLatestCommandeByGroup (iFKGroupe: ${user.iFKGroupe}) {
          iPKCommande
          iEtat
          dInsert
          bPriorite
          utilisateur {
            sNom
            sPrenom
            sEmail
            sLibelleGroupe
          }
        }
      }
    `;
    fetchApollo(GET_COMMANDES).then(response => {
      const tCommande = response.data.listLatestCommandeByGroup;
      if (tCommande) {
        this.setState({
          tCommande
        });
      }
    });
  };

  getInterventions = () => {
    const { user } = this.props;
    const GET_INTERVENTIONS = gql`
      {
        listLatestInterventionByUser (iPKUtilisateur: ${user.iPKUtilisateur}) {
          iPKIntervention
          dInsert
          sRefProduit
          sObjet
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_INTERVENTIONS).then(response => {
      const tIntervention = response.data.listLatestInterventionByUser;
      if (tIntervention) {
        this.setState({
          tIntervention
        });
      }
    });
  };

  getRetours = () => {
    const { user } = this.props;
    const GET_RETOURS = gql`
      {
        listLatestRetourByUser (iPKUtilisateur: ${user.iPKUtilisateur}) {
          iPKRetour
          dInsert
          sRefProduit
          sObjet
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_RETOURS).then(response => {
      const tRetour = response.data.listLatestRetourByUser;
      if (tRetour) {
        this.setState({
          tRetour
        });
      }
    });
  };

  render() {
    const {
      count: { nbCommande, nbDevis, nbSAV, nbRetour }
    } = this.props;
    const { tIntervention, tRetour, tCommande, tDevis } = this.state;
    return (
      <LayoutAdmin title="Tableau de bord">
        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column key="commandes">
              <Card fluid>
                <Card.Content as="a" href="/commandes" header="Commandes" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbCommande} commande{nbCommande > 1 ? "s" : ""} en attente
                  </p>
                  <List divided relaxed>
                    {tCommande.map(commande => (
                      <List.Item>
                        <List.Content>
                          <List.Header as="a" href={`/commandes/${commande.iPKCommande}`}>
                            N°{commande.iPKCommande} par {commande.utilisateur.sPrenom}{" "}
                            {commande.utilisateur.sNom}{" "}
                            {commande.bPriorite && (
                              <span data-tooltip="Commande urgente">
                                <Icon name="exclamation circle" color="red" />
                              </span>
                            )}
                          </List.Header>
                          <List.Description as="a" href={`/commandes/${commande.iPKCommande}`}>
                            Le{" "}
                            {DateTime.fromMillis(parseInt(commande.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column key="devis">
              <Card fluid>
                <Card.Content as="a" href="/devis" header="Devis" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbDevis} demande{nbDevis > 1 ? "s" : ""} de devis en attente
                  </p>
                  <List divided relaxed>
                    {tDevis.map(devis => (
                      <List.Item>
                        <List.Content>
                          <List.Header as="a" href={`/devis/${devis.iPKDevis}`}>
                            {devis.sCommentaire.slice(0, 50)}
                          </List.Header>
                          <List.Description as="a" href={`/devis/${devis.iPKDevis}`}>
                            Le{" "}
                            {DateTime.fromMillis(parseInt(devis.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column key="retours">
              <Card fluid>
                <Card.Content as="a" href="/retours" header="Demandes de retour" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbRetour} demande{nbRetour > 1 ? "s" : ""} de retour en attente
                  </p>
                  <List divided relaxed>
                    {tRetour.map(retour => (
                      <List.Item>
                        <List.Content>
                          <List.Header as="a" href={`/retours/${retour.iPKRetour}`}>
                            {retour.sObjet}
                          </List.Header>
                          <List.Description as="a" href={`/retours/${retour.iPKRetour}`}>
                            Le{" "}
                            {DateTime.fromMillis(parseInt(retour.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column key="interventions">
              <Card fluid>
                <Card.Content as="a" href="/interventions" header="Demandes SAV" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbSAV} demande{nbSAV > 1 ? "s" : ""} de SAV en attente
                  </p>
                  <List divided relaxed>
                    {tIntervention.map(intervention => (
                      <List.Item>
                        <List.Content>
                          <List.Header
                            as="a"
                            href={`/interventions/${intervention.iPKIntervention}`}
                          >
                            {intervention.sObjet}
                          </List.Header>
                          <List.Description
                            as="a"
                            href={`/interventions/${intervention.iPKIntervention}`}
                          >
                            Le{" "}
                            {DateTime.fromMillis(parseInt(intervention.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </LayoutAdmin>
    );
  }
}

TableauDeBordClient.propTypes = {
  count: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  clients: state.client.liste,
  user: state.user.data,
  count: state.count,
  error: state.client.error
});

export default connect(mapStateToProps)(TableauDeBordClient);
