import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { gql } from "apollo-boost";

import { Form, Confirm, Accordion, Loader, List, Message, Card } from "semantic-ui-react";

import { fetchApollo } from "../../../graphql/apolloClient";

import { editFamille, deleteFamille } from "../../../redux/actions/famille";

import BtnSubmit from "../../Core/Btn/Submit";
import BtnDelete from "../../Core/Btn/Delete";
import BtnEdit from "../../Core/Btn/Edit";

import "react-sortable-tree/style.css";

const FormTitle = styled.div`
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

const SearchList = styled(List)`
  background: #ffffff;
`;

const FamilleSubmit = styled(BtnSubmit)`
  margin-top: 15px;
`;

const BtnEditRight = styled(BtnEdit)`
  float: right;
  margin-top: 15px;
`;

class FormFamille extends Component {
  constructor(props) {
    super(props);
    delete props.famille.tProduit;
    delete props.famille.tSousFamilleCatalogue;
    this.state = {
      famille: props.famille,
      errors: {},
      disabled: true,
      message: null,
      showConfirmDelete: false,
      activeIndex: 0,
      searchList: [],
      loading: false,
      sCodeGrille: "",
      sLibelleGrille: ""
    };
  }

  componentDidUpdate(prevProps) {
    const { famille } = this.props;
    if (prevProps.famille !== famille) {
      delete famille.tProduit;
      delete famille.tSousFamilleCatalogue;
      this.setState({
        famille,
        disabled: true
      });
    }
  }

  handleChange = (evt, data) => {
    const { errors, famille } = this.state;
    const files = evt.target.files;
    const current = this;
    if (files) {
      const success = (content) => {
        current.setState({
          famille: {
            ...famille,
            file: {
              name: files[0].name,
              size: files[0].size,
              content,
              type: files[0].type
            }
          },
          disabled: false
        });
      };
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        success(event.target.result);
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      const error = { ...errors, [data.id]: false };
      this.setState({
        famille: { ...famille, [data.id]: data.value, error },
        disabled: false
      });
    }
  };

  handleCheck = () => {
    const { famille } = this.state;
    this.setState({
      famille: { ...famille, bInactif: !famille.bInactif },
      disabled: false
    });
  };

  handleSubmit = e => {
    const { dispatch, iPKCatalogue, reloadList } = this.props;
    const { famille } = this.state;
    e.stopPropagation();
    e.preventDefault();
    const errors = {};
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      dispatch(editFamille({ ...famille, iPKCatalogue }));
      this.setState({ message: "Famille bien enregistrée", disabled: true });
      setTimeout(() => {
        this.setState({ message: null });
      }, 3000);
      setTimeout(() => {
        reloadList();
      }, 500);
    }
  };

  handleClick = (e, titleProps) => {
    e.preventDefault();
    e.stopPropagation();

    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  deleteFamille = () => {
    const { dispatch, reloadList } = this.props;
    dispatch(deleteFamille(this.state.famille.iPKCatFamille));
    setTimeout(() => {
      reloadList(true);
    }, 500);
  };

  handleSearchChange = (evt, data) => {
    this.setState({ [data.id]: data.value, disabled: false });
  };

  handleSearchSubmit = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({ loading: true }, () => {
      this.searchGrille();
    });
  };

  searchGrille = () => {
    const { sCodeGrille, sLibelleGrille } = this.state;
    const GET_GRILLES = gql`
      {
        listGrille(sLibelle: "${sLibelleGrille}", sPKGrille: "${sCodeGrille}") {
          sPKGrille
          sLibelle
        }
      }
    `;
    fetchApollo(GET_GRILLES).then(response => {
      const searchList = response.data.listGrille;
      if (searchList) {
        this.setState({
          searchList,
          loading: false
        });
      }
    });
  };

  selectGrille = grille => {
    const { famille } = this.state;
    this.setState({
      famille: {
        ...famille,
        sPKGrille: grille.sPKGrille,
        sLibelleGrille: grille.sLibelle
      },
      disabled: false,
      activeIndex: 0
    });
  };

  removeGrille = () => {
    const { famille } = this.state;
    delete famille.sPKGrille;
    delete famille.sLibelleGrille;
    this.setState({
      famille,
      disabled: false
    });
  };

  render() {
    const {
      famille,
      errors,
      disabled,
      showConfirmDelete,
      activeIndex,
      searchList,
      loading,
      sLibelleGrille,
      sCodeGrille,
      message
    } = this.state;

    return (
      <Card fluid style={{ backgroundColor: famille.bInactif ? "lightgrey" : "" }}>
        <Card.Content>
          <Card.Header>
            <FormTitle>Modification d'une {famille.sPKSousFamille && "sous "} famille</FormTitle>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {famille.bInactif ? (
            <Message visible fluid warning>
              Cette famille est désactivée, elle ne sera pas visible par les utilisateurs.
            </Message>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Form.Input
                fluid
                id="sLibelle"
                label="Nom"
                value={famille.sLibelle}
                placeholder="Nom"
                onChange={this.handleChange}
                error={errors.sLibelle ? { content: errors.sLibelle, pointing: "below" } : false}
                disabled
              />
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                id="sInformation"
                label="Information"
                value={famille.sInformation || ""}
                placeholder="Information"
                onChange={this.handleChange}
              />
            </Form.Field>
            <label htmlFor="file">Photo (Dimension recommandée: 1160 x 250)</label>
            <input type="file" id="file" onChange={this.handleChange} />
            {famille.file && (
              <img
                style={{ height: 90, width: 350, objectFit: "contain", marginTop: 10 }}
                alt={famille.file.name}
                src={famille.file.content}
              />
            )}
            {famille.sImgPath && !famille.file && (
              <img
                style={{ height: 90, width: 350, objectFit: "contain", marginTop: 10 }}
                alt={famille.sImgPath}
                src={`${process.env.REACT_APP_GRAPHQL_URL}/media/${famille.sImgPath}`}
              />
            )}
            <Form.Field style={{ marginTop: 15 }}>
              <Form.Input
                fluid
                id="sAideGrille"
                label="Texte de la grille d'aide"
                value={famille.sAideGrille || ""}
                placeholder="Texte de la grille d'aide"
                onChange={this.handleChange}
                error={
                  errors.sAideGrille ? { content: errors.sAideGrille, pointing: "below" } : false
                }
              />
            </Form.Field>
            <Form.Field style={{ marginTop: 15 }}>
              {famille.sPKGrille && (
                <Form.Input
                  fluid
                  id="sLibelleGrille"
                  label="Grille sélectionnée"
                  value={famille.sLibelleGrille}
                  disabled
                />
              )}
            </Form.Field>
            <Accordion>
              <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                <BtnSubmit>
                  {activeIndex === 1
                    ? "Annuler"
                    : `${famille.sPKGrille ? "Remplacer la" : "Ajouter une"} grille`}
                </BtnSubmit>
                {famille.sPKGrille && (
                  <BtnDelete
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.selectGrille({ sPKGrille: null, sLibelleGrille: null });
                    }}
                  >
                    Supprimer la grille
                  </BtnDelete>
                )}
              </Accordion.Title>

              <Accordion.Content active={activeIndex === 1}>
                <Form onSubmit={this.handleSearchSubmit}>
                  <Form.Input
                    fluid
                    id="sLibelleGrille"
                    label="Libelle"
                    value={sLibelleGrille}
                    placeholder="Libelle"
                    onChange={this.handleSearchChange}
                  />
                  <Form.Input
                    fluid
                    id="sCodeGrille"
                    label="Code"
                    value={sCodeGrille}
                    placeholder="Code"
                    onChange={this.handleSearchChange}
                  />

                  <BtnSubmit onClick={this.handleSearchSubmit} type="submit">
                    Rechercher
                  </BtnSubmit>
                  <Loader active={loading} inline size="medium" />
                </Form>
                {searchList && (
                  <SearchList selection divided verticalAlign="middle">
                    {searchList.map(grille => (
                      <List.Item onClick={() => this.selectGrille(grille)} key={grille.sPKGrille}>
                        <List.Content>
                          <List.Header>{grille.sLibelle}</List.Header>
                        </List.Content>
                      </List.Item>
                    ))}
                  </SearchList>
                )}
              </Accordion.Content>
            </Accordion>
            <FamilleSubmit onClick={this.handleSubmit} disabled={disabled} type="submit">
              Enregistrer
            </FamilleSubmit>{" "}
            <BtnDelete
              onClick={e => {
                e.preventDefault();
                this.setState({ showConfirmDelete: true });
              }}
            >
              Supprimer
            </BtnDelete>
            <BtnEditRight id="bInactif" onClick={this.handleCheck}>
              {!famille.bInactif ? "Désactiver" : "Activer"}
            </BtnEditRight>{" "}
            {message && (
              <Message fluid positive>
                {message}
              </Message>
            )}
          </Form>
          <Confirm
            open={showConfirmDelete}
            content="Etes-vous sûr de vouloir supprimer cette famille ?"
            cancelButton="Annuler"
            confirmButton="Confirmer"
            onCancel={() => this.setState({ showConfirmDelete: false })}
            onConfirm={e =>
              this.setState({ showConfirmDelete: false }, () => this.deleteFamille(e))
            }
          />
        </Card.Content>
      </Card>
    );
  }
}

FormFamille.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKCatalogue: PropTypes.string.isRequired,
  reloadList: PropTypes.func.isRequired,
  famille: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  tClient: state.client.liste
});

export default connect(mapStateToProps)(FormFamille);
