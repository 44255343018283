import React from "react";
import styled from "styled-components";

import Navigation from "./Navigation";
import User from "./User";

const logo = require(`../../../../ui/assets/img/${process.env.REACT_APP_LOGO}`).default;

const Bar = styled.div`
  padding: 0 0 0 250px;
  height: 100%;
  background: ${props => props.theme.colors.navBar.background};
  ${process.env.REACT_APP_THEME !== "ARAIR" ? `background-image: url(${logo});` : ""}
    background-size: auto ${process.env.REACT_APP_THEME === "ARAIR" ? "50px" : "35px"};
  background-position: 50px 0px;
  background-repeat: no-repeat;
  border-bottom: 1px solid ${props => props.theme.border.dark};
`;

const TopBar = () => (
  <Bar>
    <User />
    <Navigation />
  </Bar>
);

export default TopBar;
