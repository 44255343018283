import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon } from "semantic-ui-react";

const Btn = styled.button`
  position: absolute;
  right: ${props => (props.dir === "left" ? "5px;" : "inherit")};
  left: ${props => (props.dir === "right" ? "5px;" : "inherit")};
  top: 5px;
  z-index: 10;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: ${props => props.theme.colors.backgroundLight};
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  & i {
    margin: 0 !important;
    color: ${props => props.theme.colors.textBlack};
  }

  &:hover {
    background: ${props => props.theme.colors.grey};

    & i {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

const Toggle = ({ opened, dir, onClick }) => {
  return (
    <Btn dir={dir} onClick={onClick}>
      {dir === "left" && (
        <Icon
          name={opened ? "angle double left" : "angle double right"}
          size="large"
        />
      )}
      {dir === "right" && (
        <Icon
          name={opened ? "angle double right" : "angle double left"}
          size="large"
        />
      )}
    </Btn>
  );
};

Toggle.propTypes = {
  dir: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Toggle;
