// import jwtDecode from "jwt-decode";

import {
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  CHANGE_USER_GROUP_SUCCESS,
  CHANGE_USER_GROUP_FAILURE,
  SEARCH_GROUP_SUCCESS,
  SEARCH_GROUP_FAILURE
} from "../constants/ActionTypes";

const initialAuthState = {
  liste: [],
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case GET_GROUP_SUCCESS: {
      return { ...state, liste: payload, error: null };
    }
    case GET_GROUP_FAILURE: {
      return { ...initialAuthState, error: payload };
    }
    case ADD_GROUP_SUCCESS: {
      return { ...state, error: null };
    }
    case ADD_GROUP_FAILURE: {
      return {
        ...state,
        error: `${Math.floor(Math.random() * 10 + 1)} ${payload}`
      };
    }
    case EDIT_GROUP_SUCCESS: {
      return { ...state, error: null };
    }
    case EDIT_GROUP_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case DELETE_GROUP_SUCCESS: {
      return { ...state, error: null };
    }
    case DELETE_GROUP_FAILURE: {
      return {
        ...state,
        error: !Number.isInteger(payload)
          ? `${Math.floor(Math.random() * 10 + 1)} Le groupe possède un ou plusieur utilisateur !`
          : null
      };
    }
    case CHANGE_USER_GROUP_SUCCESS: {
      return { ...state, error: null };
    }
    case CHANGE_USER_GROUP_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case SEARCH_GROUP_SUCCESS: {
      return { ...state, searchListe: payload, error: null };
    }
    case SEARCH_GROUP_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    default:
      return state;
  }
};
