import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";

import { Form, Confirm, Modal, Button, List, Loader, Message, Card } from "semantic-ui-react";

import { addGroup, editGroup, deleteGroup, searchGroup } from "../../../redux/actions/group";

import BtnSubmit from "../../Core/Btn/Submit";
import BtnDelete from "../../Core/Btn/Delete";

import "react-sortable-tree/style.css";

const Title = styled.span`
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

const SearchList = styled(List)`
  background: #ffffff;
`;

const BtnSearch = styled(BtnSubmit)`
  margin-top: 22px;
`;

class FormGroupe extends Component {
  constructor(props) {
    super(props);
    const { group, iFKClient } = props;
    this.state = {
      iFKClient,
      group,
      iPKGroupe: group.iPKGroupe || null,
      sLibelle: null,
      sSearchLibelle: "",
      showConfirmDelete: false,
      disabled: true,
      error: null,
      loading: false,
      searchList: null
    };
  }

  componentDidUpdate(prevProps) {
    const { group, iFKClient, searchPropsList, error } = this.props;
    if (prevProps.group !== group) {
      this.setState({
        iFKClient,
        group,
        iPKGroupe: group.iPKGroupe || null,
        sSearchLibelle: "",
        sLibelle: null,
        disabled: true,
        searchList: null,
        error: null
      });
    }

    if (prevProps.error !== error && error) {
      this.setState({ error });
    }

    if (prevProps.searchPropsList !== searchPropsList) {
      this.setState({ searchList: searchPropsList });
    }
  }

  handleChange = (evt, data) => {
    this.setState({ [data.id]: data.value });
  };

  handleSubmit = e => {
    const { dispatch, selectNode } = this.props;
    const { iPKGroupe, sLibelle } = this.state;
    e.stopPropagation();
    e.preventDefault();
    if (iPKGroupe) {
      dispatch(editGroup(this.state));
      selectNode(sLibelle);
    } else {
      dispatch(addGroup(this.state));
      selectNode(sLibelle);
    }
    this.setState({
      sLibelle: null,
      disabled: true,
      error: null,
      sSearchLibelle: "",
      searchList: null
    });
  };

  handleSearchSubmit = e => {
    const { dispatch } = this.props;
    const { sSearchLibelle } = this.state;
    e.stopPropagation();
    e.preventDefault();
    this.setState({ loading: true }, () => {
      dispatch(searchGroup({ sLibelle: sSearchLibelle }));
      setTimeout(() => {
        this.setState({ loading: false });
      }, 500);
    });
  };

  deleteGroup = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    const { dispatch } = this.props;
    const { iPKGroupe } = this.state;
    dispatch(deleteGroup(iPKGroupe));
    this.setState({
      sLibelle: null,
      iPKGroupe: null
    });
  };

  selectGroup = group => {
    this.setState({
      sLibelle: group.title || null,
      iFKAnnAnnuaire: group.iFKAnnAnnuaire || "",
      disabled: false,
      searchList: null,
      error: null
    });
  };

  render() {
    const {
      iPKGroupe,
      sLibelle,
      showConfirmDelete,
      disabled,
      loading,
      sSearchLibelle,
      searchList,
      group,
      error
    } = this.state;
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Title>{iPKGroupe ? "Modification d'un" : "Ajouter un nouveau"} groupe</Title>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {iPKGroupe && (
            <Card fluid>
              <Card.Content>
                <Card.Header style={{ fontSize: 16 }}>
                  {group.sFKTypeTiers} importé depuis Is@Dom
                </Card.Header>
              </Card.Content>
              <Card.Content>
                {group.sLibelle && (
                  <p>
                    <b>Nom:</b> {group.sLibelle}
                  </p>
                )}
              </Card.Content>
            </Card>
          )}
          <Form onSubmit={this.handleSearchSubmit}>
            <Form.Group>
              <Form.Input
                fluid
                id="sSearchLibelle"
                label={`${
                  iPKGroupe ? "Remplacer par un autre" : "Rechercher un"
                } tiers (importé depuis is@dom)`}
                value={sLibelle || sSearchLibelle}
                placeholder="Nom du tiers"
                onChange={this.handleChange}
                width={12}
                disabled={!!sLibelle}
              />
              <BtnSearch disabled={!!sLibelle} onClick={this.handleSearchSubmit} type="submit">
                Rechercher
              </BtnSearch>
            </Form.Group>
            <BtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
              Enregistrer
            </BtnSubmit>{" "}
            {iPKGroupe && (
              <BtnDelete
                onClick={evt => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  this.setState({ showConfirmDelete: true });
                }}
              >
                Supprimer
              </BtnDelete>
            )}
            {error && (
              <Message negative>
                <Message.Header>Groupe non enregistré</Message.Header>
                <p>{error.substring(2, error.length)}</p>
              </Message>
            )}
            <Loader active={loading} inline size="medium" />
          </Form>
          {searchList && (
            <SearchList selection divided verticalAlign="middle">
              {_.uniqBy(searchList, "title").map(g => (
                <List.Item onClick={() => this.selectGroup(g)} key={g.title}>
                  <List.Content>
                    <List.Header>{g.title}</List.Header>
                  </List.Content>
                </List.Item>
              ))}
            </SearchList>
          )}
          <Modal
            size="tiny"
            onClose={() => this.setState({ showConfirmDelete: false })}
            open={showConfirmDelete && group.children.length > 0}
          >
            <Modal.Content>
              Vous ne pouvez pas supprimer un groupe ayant des utilisateurs ou groupes liés.
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.setState({ showConfirmDelete: false })} primary>
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
          <Confirm
            open={showConfirmDelete && group.children.length === 0}
            content="Etes-vous sûr de vouloir supprimer ce groupe ?"
            cancelButton="Annuler"
            confirmButton="Confirmer"
            onCancel={() => this.setState({ showConfirmDelete: false })}
            onConfirm={e => this.setState({ showConfirmDelete: false }, () => this.deleteGroup(e))}
          />
        </Card.Content>
      </Card>
    );
  }
}

FormGroupe.defaultProps = {
  error: null
};

FormGroupe.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectNode: PropTypes.func.isRequired,
  iFKClient: PropTypes.number.isRequired,
  searchPropsList: PropTypes.arrayOf(PropTypes.any).isRequired,
  group: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  searchPropsList: state.group.searchListe,
  error: state.group.error
});

export default connect(mapStateToProps)(FormGroupe);
