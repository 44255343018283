import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import LayoutAdmin from "../../../../components/AdminClient/Layout";
import FormContact from "../../../../components/AdminClient/Contact/Form";

import BtnSubmit from "../../../../components/Core/Btn/Submit";
import FilteredTable from "../../../../components/Core/FilteredTable";

import { getContactsByUser } from "../../../../redux/actions/user";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanel: null,
      contacts: []
    };
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(getContactsByUser(user.iPKUtilisateur));
  }

  componentDidUpdate(prevProps) {
    const { contacts } = this.props;
    if (prevProps.contacts !== contacts) {
      this.setState({ contacts });
    }
  }

  hideForm = () => {
    this.setState({
      rightPanel: "close"
    });
  };

  showCreateFrom = () => {
    this.setState({
      rightPanel: <FormContact hideForm={this.hideForm} user={{}} />
    });
  };

  selectContact = user => {
    this.setState({
      rightPanel: <FormContact hideForm={this.hideForm} user={user} />
    });
  };

  render() {
    const { rightPanel, contacts } = this.state;
    return (
      <LayoutAdmin title="Gestion des utilisateurs" rightPanel={rightPanel}>
        <>
          <FilteredTable
            type="contact"
            fields={{
              sNom: "Nom",
              sPrenom: "Prénom",
              sEmail: "Email",
              sLibelleGroupe: "Groupe",
              sCodeAnnuaire: "Contact Is@Dom"
            }}
            items={contacts}
            selectItem={this.selectContact}
          />
          <BtnSubmit onClick={this.showCreateFrom} primary size="small">
            Ajouter un utilisateur
          </BtnSubmit>
        </>
      </LayoutAdmin>
    );
  }
}

Contact.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  contacts: state.user.contactListe,
  user: state.user.data
});

export default connect(mapStateToProps)(Contact);
