import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import _ from "lodash";

import { Form } from "semantic-ui-react";

import BtnSubmit from "../../../../components/Core/Btn/Submit";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";
import LayoutAdmin from "../../../../components/Admin/Layout";
import { getAllCount } from "../../../../redux/actions/count";

const ActionForm = styled(Form)``;

class Compte extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tCompte: [],
      tGroupe: [],
      formData: {}
    };
  }

  componentDidMount() {
    this.getGroupes();
    this.getCompte();
  }

  getGroupes = () => {
    const GET_GROUPE = gql`
      {
        listAllGroup {
          iPKGroupe
          iFKGroupeParent
          sLibelle
          title
          type
          iFKClient
          sLibelleClient
          level
          iFKAnnAnnuaire
        }
      }
    `;
    fetchApollo(GET_GROUPE).then(response => {
      const tGroupe = response.data.listAllGroup;
      if (tGroupe) {
        this.setState({
          tGroupe
        });
      }
    });
  };

  getCompte = () => {
    const GET_COMPTE = gql`
      {
        getContactsToCreate {
          iPKUtilisateur
          sCodeCivilite
          sNom
          sPrenom
          sEmail
          sLibelleGroupe
          iRole
          bTarif
          bInactif
        }
      }
    `;
    fetchApollo(GET_COMPTE).then(response => {
      const tCompte = response.data.getContactsToCreate;
      if (tCompte) {
        this.setState({
          tCompte
        });
      }
    });
  };

  handleChange = (evt, data) => {
    const { formData } = this.state;
    const parsedData = data.value.split("-");
    const iFKGroupe = parseInt(parsedData[0], 10);
    const iFKClient = parseInt(parsedData[1], 10);
    this.setState({
      formData: {
        ...formData,
        [data.ipkutilisateur]: {
          iFKGroupe,
          iFKClient
        }
      }
    });
  };

  saveGroupe = user => {
    const { formData, tCompte } = this.state;
    const { dispatch } = this.props;

    const VALIDE_USER = gql`
      mutation {
        valideUser(user: ${JSON.stringify(
          JSON.stringify({
            ...user,
            iFKGroupe: formData[user.iPKUtilisateur].iFKGroupe,
            iFKClient: formData[user.iPKUtilisateur].iFKClient
          })
        )}) {
          iPKUtilisateur
          iFKClient
          title
          type
          iRole
          bTarif
          sCodeCivilite
          sNom
          sPrenom
          sEmail
          iFKGroupe
        }
      }`;
    fetchApollo(VALIDE_USER).then(() => {
      dispatch(getAllCount());
      this.setState({
        tCompte: tCompte.filter(u => u.iPKUtilisateur !== user.iPKUtilisateur)
      });
    });
  };

  render() {
    const { rightPanel, tCompte, tGroupe, formData } = this.state;
    const tRenderedCompte = tCompte.map(c => {
      return {
        ...c,
        actions: (
          <ActionForm>
            <Form.Group widths="equal">
              <Form.Select
                fluid
                id="iFKGroupe"
                value={
                  formData[c.iPKUtilisateur] &&
                  `${formData[c.iPKUtilisateur].iFKGroupe}-${formData[c.iPKUtilisateur].iFKClient}`
                }
                ipkutilisateur={c.iPKUtilisateur}
                options={_.orderBy(tGroupe, ["sLibelleClient", "sLibelle"], ["asc"]).map(g => ({
                  key: `${g.iPKGroupe}-${g.iFKClient}`,
                  text: `${g.sLibelleClient} - ${g.sLibelle}`,
                  value: `${g.iPKGroupe}-${g.iFKClient}`
                }))}
                placeholder="Groupe"
                onChange={this.handleChange}
              />
              <BtnSubmit onClick={() => this.saveGroupe(c)} type="submit">
                Valider
              </BtnSubmit>
            </Form.Group>
          </ActionForm>
        )
      };
    });
    return (
      <LayoutAdmin title="Gestion des demandes de création de comptes" rightPanel={rightPanel}>
        <FilteredTable
          type="création de comptes"
          selectable={false}
          fields={{
            sNom: "Nom",
            sPrenom: "Prénom",
            sEmail: "Email",
            sLibelleGroupe: "Groupe",
            actions: ""
          }}
          items={tRenderedCompte}
          selectItem={() => {}}
        />
      </LayoutAdmin>
    );
  }
}

Compte.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  error: state.user.error
});

export default connect(mapStateToProps)(Compte);
