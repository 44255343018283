import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";

const SSFamille = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  color: ${props => props.theme.text.primary};
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.button.primary.background};
  transition: all 0.25s ease;

  &:hover {
    color: ${props => props.theme.button.primary.hover};
    background: ${props => props.theme.button.primary.hoverBackground};
    border: 1px solid ${props => props.theme.button.primary.hoverBackground};
    box-shadow: 0 0 20px ${props => props.theme.background.dark};
  }
`;

const ListeFamilles = ({ sLibelleFamille, tSousFamille }) => (
  <>
    <Grid.Row columns={4}>
      {tSousFamille
        .filter(sf => !sf.bInactif)
        .map(sousFamille => (
          <Grid.Column style={{ marginBottom: 15 }}>
            <SSFamille
              to={`/catalogue/${encodeURIComponent(sLibelleFamille)}/${encodeURIComponent(
                sousFamille.sLibelle
              )}`}
            >
              {sousFamille.sLibelle}
            </SSFamille>
          </Grid.Column>
        ))}
    </Grid.Row>
  </>
);

ListeFamilles.propTypes = {
  sLibelleFamille: PropTypes.string.isRequired,
  tSousFamille: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ListeFamilles;
