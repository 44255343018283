import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import BtnSignOut from "./BtnSignOut";

const Wrapper = styled.div`
  float: right;
  line-height: 40px;
  min-width: 150px;
`;

const UserName = styled.div`
  display: inline-block;
  float: left;
  color: ${props => props.theme.button.primary.background};
  &:hover {
    text-decoration: underline;
  }
`;

const CustomNavLink = styled(NavLink)`
  color: ${props => props.theme.button.primary.background};
  display: block;
  height: 45px;
  &:hover {
    text-decoration: underline !important;
    color: ${props => props.theme.button.primary.background};
  }
`;

const Authentification = ({ user }) => (
  <Wrapper>
    <UserName className="user">
      <CustomNavLink data-tooltip="Accéder à votre compte" data-position="bottom center" to="/moncompte">
        <Icon
          style={{ float: "left", marginRight: 10, height: 40, lineHeight: "40px" }}
          name="user"
        />
        <div style={{ float: "left" }}>
          <p style={{ margin: 0, fontWeight: "bold", textTransform: "uppercase" }}>{user.sNom}</p>
          <p style={{ margin: 0, textTransform: "capitalize" }}>{user.sPrenom}</p>
        </div>
      </CustomNavLink>
    </UserName>
    <BtnSignOut />
  </Wrapper>
);

Authentification.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Authentification);
