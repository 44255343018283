import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  border-bottom: 1px solid ${props => props.theme.border.dark};
`;

const Header = ({ children }) => <Wrapper>{children}</Wrapper>;

Header.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Header;
