import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

const StyledBtnToggle = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 20px;
  padding: 0;
  width: 20px;
  background: #000;
  border: 0;
  outline: none;
  cursor: pointer;

  & i {
    margin: 0 !important;
    width: auto !important;
    color: ${props => props.theme.colors.white};
  }
`;

const BtnToggle = ({ opened, onClick }) => (
  <StyledBtnToggle onClick={onClick}>
    <Icon name={opened ? "arrow right" : "arrow left"} />
  </StyledBtnToggle>
);

BtnToggle.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default BtnToggle;
