// import jwtDecode from "jwt-decode";

import {
  LISTE_CLIENT_SUCCESS,
  LISTE_CLIENT_FAILURE,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  FUSION_CLIENT_SUCCESS,
  FUSION_CLIENT_FAILURE
} from "../constants/ActionTypes";

const initialAuthState = {
  liste: [],
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case LISTE_CLIENT_SUCCESS: {
      return { ...state, liste: payload, error: null };
    }
    case LISTE_CLIENT_FAILURE: {
      return { ...initialAuthState, error: payload };
    }
    case ADD_CLIENT_SUCCESS: {
      return { ...state, error: null };
    }
    case ADD_CLIENT_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case EDIT_CLIENT_SUCCESS: {
      return { ...state, error: null };
    }
    case EDIT_CLIENT_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case DELETE_CLIENT_SUCCESS: {
      return { ...state, error: null };
    }
    case DELETE_CLIENT_FAILURE: {
      return {
        ...state,
        error: !Number.isInteger(payload)
          ? `${Math.floor(Math.random() * 10 + 1)} Le client possède un ou plusieur groupe !`
          : null
      };
    }
    case FUSION_CLIENT_SUCCESS: {
      return { ...state, error: null };
    }
    case FUSION_CLIENT_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    default:
      return state;
  }
};
