import { all, takeLatest } from "redux-saga/effects";
import {
  signUserIn,
  signUserOut,
  setUser,
  addUser,
  editUser,
  deleteUser,
  searchUser,
  clearListSearchUser,
  getContactsByUser,
  addContact,
  editContact,
  deleteContact
} from "./sagas/user";
import {
  getClients,
  getClientsWithoutCatalogue,
  addClient,
  editClient,
  deleteClient,
  fusionClient
} from "./sagas/client";
import {
  getGroups,
  getGroupsByUser,
  addGroup,
  editGroup,
  deleteGroup,
  searchGroup,
  changeUserGroup
} from "./sagas/group";
import {
  getCatalogue,
  addCatalogue,
  editCatalogue,
  deleteCatalogue,
  getCatalogues,
  getCataloguesWithoutClient,
  getFamillesCatalogue,
  importFamillesCatalogue,
  getIsadomFamillesCatalogue
} from "./sagas/catalogue";
import { editFamille, deleteFamille } from "./sagas/famille";
import { editProduit, deleteProduit } from "./sagas/produit";
import getCommandes from "./sagas/commande";
import { getCommandesType, addCommandeType, deleteCommandeType } from "./sagas/commandeType";
import { getAllCount, getAllCountByUser } from "./sagas/count";
import { getPanier, addPanier, editPanier, deletePanier, validePanier } from "./sagas/panier";
import { addFavoris, deleteFavoris, getFavoris } from "./sagas/favoris";

import {
  USER_SIGN_IN_REQUEST,
  USER_SIGN_OUT_REQUEST,
  SET_USER,
  ADD_USER_REQUEST,
  EDIT_USER_REQUEST,
  DELETE_USER_REQUEST,
  SEARCH_USER_REQUEST,
  CLEAR_LIST_SEARCH_USER_REQUEST,
  LISTE_CONTACT_REQUEST,
  ADD_CONTACT_REQUEST,
  EDIT_CONTACT_REQUEST,
  DELETE_CONTACT_REQUEST,
  LISTE_CLIENT_REQUEST,
  LISTE_CLIENT_WITHOUT_CATALOGUE_REQUEST,
  ADD_CLIENT_REQUEST,
  EDIT_CLIENT_REQUEST,
  DELETE_CLIENT_REQUEST,
  FUSION_CLIENT_REQUEST,
  GET_GROUP_REQUEST,
  GET_GROUP_BY_USER_REQUEST,
  ADD_GROUP_REQUEST,
  EDIT_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  CHANGE_USER_GROUP_REQUEST,
  SEARCH_GROUP_REQUEST,
  GET_CATALOGUE_REQUEST,
  ADD_CATALOGUE_REQUEST,
  EDIT_CATALOGUE_REQUEST,
  DELETE_CATALOGUE_REQUEST,
  LISTE_CATALOGUE_REQUEST,
  LISTE_CATALOGUE_WITHOUT_CLIENT_REQUEST,
  LISTE_FAMILLE_CATALOGUE_REQUEST,
  IMPORT_FAMILLE_CATALOGUE_REQUEST,
  LISTE_ISADOM_FAMILLE_CATALOGUE_REQUEST,
  EDIT_FAMILLE_REQUEST,
  DELETE_FAMILLE_REQUEST,
  EDIT_PRODUIT_REQUEST,
  DELETE_PRODUIT_REQUEST,
  LISTE_COMMANDE_REQUEST,
  LISTE_COMMANDE_TYPE_REQUEST,
  ADD_COMMANDE_TYPE_REQUEST,
  DELETE_COMMANDE_TYPE_REQUEST,
  GET_ALL_COUNT_REQUEST,
  GET_ALL_COUNT_BY_USER_REQUEST,
  GET_PANIER_REQUEST,
  ADD_PANIER_REQUEST,
  EDIT_PANIER_REQUEST,
  DELETE_PANIER_REQUEST,
  VALIDE_PANIER_REQUEST,
  GET_FAVORIS_REQUEST,
  ADD_FAVORIS_REQUEST,
  DELETE_FAVORIS_REQUEST
} from "./constants/ActionTypes";

export default function* rootSaga() {
  yield all([
    takeLatest(USER_SIGN_IN_REQUEST, signUserIn),
    takeLatest(SET_USER, setUser),
    takeLatest(ADD_USER_REQUEST, addUser),
    takeLatest(EDIT_USER_REQUEST, editUser),
    takeLatest(DELETE_USER_REQUEST, deleteUser),
    takeLatest(SEARCH_USER_REQUEST, searchUser),
    takeLatest(CLEAR_LIST_SEARCH_USER_REQUEST, clearListSearchUser),
    takeLatest(USER_SIGN_OUT_REQUEST, signUserOut),

    takeLatest(LISTE_CONTACT_REQUEST, getContactsByUser),
    takeLatest(ADD_CONTACT_REQUEST, addContact),
    takeLatest(EDIT_CONTACT_REQUEST, editContact),
    takeLatest(DELETE_CONTACT_REQUEST, deleteContact),

    takeLatest(LISTE_CLIENT_REQUEST, getClients),
    takeLatest(LISTE_CLIENT_WITHOUT_CATALOGUE_REQUEST, getClientsWithoutCatalogue),
    takeLatest(ADD_CLIENT_REQUEST, addClient),
    takeLatest(EDIT_CLIENT_REQUEST, editClient),
    takeLatest(DELETE_CLIENT_REQUEST, deleteClient),
    takeLatest(FUSION_CLIENT_REQUEST, fusionClient),

    takeLatest(GET_GROUP_REQUEST, getGroups),
    takeLatest(GET_GROUP_BY_USER_REQUEST, getGroupsByUser),
    takeLatest(ADD_GROUP_REQUEST, addGroup),
    takeLatest(EDIT_GROUP_REQUEST, editGroup),
    takeLatest(DELETE_GROUP_REQUEST, deleteGroup),
    takeLatest(SEARCH_GROUP_REQUEST, searchGroup),
    takeLatest(CHANGE_USER_GROUP_REQUEST, changeUserGroup),

    takeLatest(GET_CATALOGUE_REQUEST, getCatalogue),
    takeLatest(ADD_CATALOGUE_REQUEST, addCatalogue),
    takeLatest(EDIT_CATALOGUE_REQUEST, editCatalogue),
    takeLatest(DELETE_CATALOGUE_REQUEST, deleteCatalogue),

    takeLatest(LISTE_CATALOGUE_REQUEST, getCatalogues),
    takeLatest(LISTE_CATALOGUE_WITHOUT_CLIENT_REQUEST, getCataloguesWithoutClient),
    takeLatest(LISTE_FAMILLE_CATALOGUE_REQUEST, getFamillesCatalogue),
    takeLatest(IMPORT_FAMILLE_CATALOGUE_REQUEST, importFamillesCatalogue),
    takeLatest(LISTE_ISADOM_FAMILLE_CATALOGUE_REQUEST, getIsadomFamillesCatalogue),

    takeLatest(EDIT_FAMILLE_REQUEST, editFamille),
    takeLatest(DELETE_FAMILLE_REQUEST, deleteFamille),

    takeLatest(EDIT_PRODUIT_REQUEST, editProduit),
    takeLatest(DELETE_PRODUIT_REQUEST, deleteProduit),

    takeLatest(LISTE_COMMANDE_REQUEST, getCommandes),
    takeLatest(LISTE_COMMANDE_TYPE_REQUEST, getCommandesType),
    takeLatest(ADD_COMMANDE_TYPE_REQUEST, addCommandeType),
    takeLatest(DELETE_COMMANDE_TYPE_REQUEST, deleteCommandeType),

    takeLatest(GET_ALL_COUNT_REQUEST, getAllCount),
    takeLatest(GET_ALL_COUNT_BY_USER_REQUEST, getAllCountByUser),

    takeLatest(GET_PANIER_REQUEST, getPanier),
    takeLatest(ADD_PANIER_REQUEST, addPanier),
    takeLatest(EDIT_PANIER_REQUEST, editPanier),
    takeLatest(DELETE_PANIER_REQUEST, deletePanier),
    takeLatest(VALIDE_PANIER_REQUEST, validePanier),

    takeLatest(GET_FAVORIS_REQUEST, getFavoris),
    takeLatest(ADD_FAVORIS_REQUEST, addFavoris),
    takeLatest(DELETE_FAVORIS_REQUEST, deleteFavoris)
  ]);
}
