import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import { Card, Icon } from "semantic-ui-react";

import BtnSubmit from "../../Core/Btn/Submit";
import FormRetour from "../Retour/Form";
import FormIntervention from "../Intervention/Form";
import DemandeShow from "./DemandeShow";

const Title = styled.div`
  color: ${props => props.theme.colors.title.main};
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
`;

const BtnAction = styled(BtnSubmit)`
  padding: 0 10px !important;
  margin-bottom: 10px;
`;

const IconPrint = styled(Icon)`
  display: inline-block;
  cursor: pointer;
  color: black;
`;

class ShowMateriel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIntervention: null,
      formRetour: null
    };
  }

  componentDidUpdate(prevProps) {
    const { materiel } = this.props;
    if (materiel !== prevProps.materiel) {
      this.setState({
        formIntervention: null,
        formRetour: null
      });
    }
  }

  selectMateriel = materiel => {
    const { selectMateriel } = this.props;
    this.setState(
      {
        formIntervention: null,
        formRetour: null
      },
      () => {
        selectMateriel(materiel);
      }
    );
  };

  render() {
    const tEtat = {
      0: "En attente de validation",
      1: "En cours de traitement",
      2: "Validé",
      3: "Annulé"
    };
    const { materiel, getMateriels } = this.props;
    const { formRetour, formIntervention } = this.state;
    return (
      <div>
        <Title>
          Détail du produit <b>{materiel.sRefProduit}</b>{" "}
        </Title>
        <Card fluid>
          <Card.Content>
            <p>
              Désignation du produit : <b>{materiel.sLibelleProduit}</b>
            </p>
            <p>
              Contact : <b>{materiel.sContact}</b>
            </p>
            {materiel.sBeneficiair && (
              <p>
                Bénéficiaire : <b>{materiel.sBeneficiaire}</b>
              </p>
            )}
            <p>
              {materiel.sImgPath && (
                <img
                  style={{ width: 240 }}
                  alt={materiel.sImgPath}
                  src={`${process.env.REACT_APP_GRAPHQL_URL}/media/${materiel.sImgPath}`}
                />
              )}
            </p>
          </Card.Content>
        </Card>
        <>
          <Title>
            Demande de SAV{" "}
            {materiel.bSAV && (
              <ReactToPrint content={() => this.SAVRef}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <span data-tooltip="Imprimer">
                      <IconPrint name="print" onClick={handlePrint} />
                    </span>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            )}
          </Title>
          <Card fluid>
            <Card.Content>
              {!materiel.bSAV ? (
                <>
                  {!formIntervention && (
                    <BtnAction
                      onClick={() => {
                        this.setState({
                          formRetour: null,
                          formIntervention: (
                            <FormIntervention
                              materiel={materiel}
                              getMateriels={getMateriels}
                              selectMateriel={this.selectMateriel}
                              annule={() => {
                                this.setState({
                                  formRetour: null,
                                  formIntervention: null
                                });
                              }}
                            />
                          )
                        });
                      }}
                      disabled={!!materiel.bSAV}
                      primary
                      size="small"
                    >
                      Effectuer une demande de SAV
                    </BtnAction>
                  )}
                  {formIntervention}
                </>
              ) : (
                <DemandeShow
                  ref={el => (this.SAVRef = el)}
                  type="SAV"
                  materiel={materiel}
                  tEtat={tEtat}
                />
              )}
            </Card.Content>
          </Card>
          <Title>
            Demande de reprise de matériel{" "}
            {materiel.bRetour && (
              <ReactToPrint content={() => this.retourRef}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <span data-tooltip="Imprimer">
                      <IconPrint name="print" onClick={handlePrint} />
                    </span>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            )}
          </Title>
          <Card fluid>
            <Card.Content>
              {!materiel.bRetour ? (
                <>
                  {!formRetour && (
                    <BtnAction
                      onClick={() => {
                        this.setState({
                          formIntervention: null,
                          formRetour: (
                            <FormRetour
                              materiel={materiel}
                              getMateriels={getMateriels}
                              selectMateriel={this.selectMateriel}
                              annule={() => {
                                this.setState({
                                  formRetour: null,
                                  formIntervention: null
                                });
                              }}
                            />
                          )
                        });
                      }}
                      disabled={!!materiel.bRetour}
                      primary
                      size="small"
                    >
                      Effectuer une demande de retour
                    </BtnAction>
                  )}
                  {formRetour}
                </>
              ) : (
                <DemandeShow
                  ref={el => (this.retourRef = el)}
                  type="Retour"
                  materiel={materiel}
                  tEtat={tEtat}
                />
              )}
            </Card.Content>
          </Card>
        </>
      </div>
    );
  }
}

ShowMateriel.propTypes = {
  materiel: PropTypes.objectOf(PropTypes.any).isRequired,
  getMateriels: PropTypes.func.isRequired,
  selectMateriel: PropTypes.func.isRequired
};

export default ShowMateriel;
