import React, { Component } from "react";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";

import LayoutAdmin from "../../../../components/AdminClient/Layout";
import ShowMateriel from "../../../../components/AdminClient/Materiel/Show";

class Materiel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanel: null,
      tMateriel: []
    };
  }

  componentDidMount() {
    this.getMateriels();
  }

  getMateriels = () => {
    const listMaterielsByUser = gql`
      {
        listMaterielsByUser {
          iPKCommande
          iPKCommandeLigne
          iEtat
          iFKUtilisateur
          iFKProduit
          iFKClient
          dInsertCommande
          sLibelleFamille
          sLibelleSousFamille
          sRefProduit
          sNumeroSerie
          sImgPath
          sLibelleProduit
          sBeneficiaire
          sContact
          bSAV
          sObjetSAV
          sCommentaireSAV
          iEtatSAV
          bRetour
          sObjetRetour
          sCommentaireRetour
          dRetour
          iEtatRetour
          dLivraison
          sCodeAffectation
        }
      }
    `;
    fetchApollo(listMaterielsByUser).then(response => {
      const tMateriel = response.data.listMaterielsByUser;
      if (tMateriel) {
        this.setState({
          tMateriel: tMateriel.filter(m => m.iEtat !== 3)
        });
      }
    });
  };

  selectMateriel = materiel => {
    this.setState({
      rightPanel: {
        width: 650,
        renderHtml: (
          <ShowMateriel
            getMateriels={this.getMateriels}
            materiel={materiel}
            selectMateriel={this.selectMateriel}
          />
        )
      }
    });
  };

  render() {
    const { rightPanel, tMateriel } = this.state;
    return (
      <LayoutAdmin title="Gestion du parc matériel" rightPanel={rightPanel}>
        <FilteredTable
          type="matériel"
          fields={{
            sRefProduit: "N° du matériel",
            sCodeAffectation: "Affectation",
            dLivraison: "Date de livraison",
            sLibelleFamille: "Famille",
            sLibelleSousFamille: "Sous famille",
            iFKProduit: "Référence produit",
            sLibelleProduit: "Désignation produit",
            sBeneficiaire: "Bénéficiaire",
            bSAV: "Demande SAV",
            bRetour: "Demande Retour"
          }}
          items={tMateriel
            .sort((a, b) => {
              if (a.iEtatSAV < b.iEtatSAV) return -1;
              if (a.iEtatSAV > b.iEtatSAV) return 1;
              return 0;
            })
            .sort((a, b) => {
              if (a.iEtatRetour < b.iEtatRetour) return -1;
              if (a.iEtatRetour > b.iEtatRetour) return 1;
              return 0;
            })}
          selectItem={this.selectMateriel}
        />
      </LayoutAdmin>
    );
  }
}


export default Materiel;
