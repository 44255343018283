import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { Form, Confirm, Message, Card } from "semantic-ui-react";

import { editProduit, deleteProduit } from "../../../redux/actions/produit";
import { downloadFile } from "../../../utils";

import BtnSubmit from "../../Core/Btn/Submit";
import BtnDelete from "../../Core/Btn/Delete";
import BtnEdit from "../../Core/Btn/Edit";
import "react-sortable-tree/style.css";

const FormTitle = styled.div`
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

const ProduitSubmit = styled(BtnSubmit)`
  margin-top: 15px;
`;

const BtnEditRight = styled(BtnEdit)`
  float: right;
  margin-top: 15px;
`;

const Picture = styled.img`
  display: block;
  margin-left: 25%;
  margin-right: 25%;
  height: auto;
  width: 50%;
  max-width: 100%;
  border: ${props => (props.placeholder ? "none" : `1px solid ${props.theme.border.light}`)};
`;

const CheckboxBactif = styled(Form.Checkbox)`
  margin-top: 15px !important;
`;

class FormProduit extends Component {
  constructor(props) {
    super(props);
    const { produit } = props;
    this.state = {
      produit,
      errors: {},
      showConfirmDelete: false,
      disabled: true,
      message: null
    };
  }

  componentDidUpdate(prevProps) {
    const { produit } = this.props;
    if (prevProps.produit !== produit) {
      this.setState({
        produit
      });
    }
  }

  handleChange = (evt, data) => {
    const { errors, produit } = this.state;
    const {
      target: { files }
    } = evt;
    const current = this;
    if (files) {
      const success = content => {
        current.setState({
          produit: {
            ...produit,
            file: {
              name: files[0].name,
              size: files[0].size,
              content,
              type: files[0].type
            }
          },
          disabled: false
        });
      };
      const fileReader = new FileReader();
      fileReader.onload = e => {
        success(e.target.result);
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      this.setState({
        produit: { ...produit, [data.id]: data.value },
        errors: { ...errors, [data.id]: false },
        disabled: false
      });
    }
  };

  handleCheck = () => {
    const { produit } = this.state;
    this.setState({
      produit: { ...produit, bInactif: !produit.bInactif },
      disabled: false
    });
  };

  handleCheckDocument = (evt, data) => {
    const {
      produit,
      produit: { tDocument }
    } = this.state;
    this.setState({
      produit: {
        ...produit,
        tDocument: tDocument.map(d =>
          d.iPKCatCatalogueProduitDocument === data.id ? { ...d, bInactif: !d.bInactif } : d
        )
      },
      disabled: false
    });
  };

  handleSubmit = e => {
    const { dispatch, reloadList, iPKCatalogue } = this.props;
    const { produit } = this.state;
    e.stopPropagation();
    e.preventDefault();
    const errors = {};
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      dispatch(
        editProduit({
          ...produit,
          iPKCatalogue,
          sFields:
            typeof produit.sFields === "string" ? JSON.parse(produit.sFields) : produit.sFields
        })
      );
      this.setState({ message: "produit bien enregistré", disabled: true });
      setTimeout(() => {
        this.setState({ message: null });
      }, 3000);
      setTimeout(() => {
        reloadList();
      }, 500);
    }
  };

  deleteProduit = evt => {
    evt.preventDefault();
    const { dispatch, reloadList } = this.props;
    const {
      produit: { iPKProduit }
    } = this.props;
    dispatch(deleteProduit(iPKProduit));
    setTimeout(() => {
      reloadList(true);
    }, 500);
  };

  render() {
    const { produit, errors, showConfirmDelete, disabled, message } = this.state;
    return (
      <Card fluid style={{ backgroundColor: produit.bInactif ? "lightgrey" : "" }}>
        <Card.Content>
          <Card.Header>
            <FormTitle>Modification d&apos;un produit</FormTitle>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {produit.bInactif ? (
            <Message visible fluid warning>
              Ce produit est désactivé, il ne sera pas visible par les utilisateurs.
            </Message>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Form.Input
                fluid
                id="sLibelle"
                label="Nom"
                value={produit.sLibelle}
                placeholder="Nom"
                onChange={this.handleChange}
                error={errors.sLibelle ? { content: errors.sLibelle, pointing: "below" } : false}
                disabled
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                fluid
                id="sRefProduit"
                label="Référence"
                value={produit.sRefProduit}
                placeholder="Référence"
                onChange={this.handleChange}
                error={
                  errors.sRefProduit ? { content: errors.sRefProduit, pointing: "below" } : false
                }
                disabled
              />
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                id="sInformation"
                label="Description"
                value={produit.sInformation || ""}
                placeholder="Description"
                onChange={this.handleChange}
              />
            </Form.Field>
            <label htmlFor="file">Photo</label>
            <input type="file" id="file" onChange={this.handleChange} />
            {produit.file && <Picture alt={produit.file.name} src={produit.file.content} />}
            {!produit.file && produit.sImgPath && (
              <Picture
                alt={produit.sImgPath}
                src={`${process.env.REACT_APP_GRAPHQL_URL}/media/${produit.sImgPath}`}
              />
            )}
            <Form.Field style={{ marginTop: 10 }}>
              <Form.Select
                fluid
                multiple
                id="sFields"
                label="Champs à remplir pour le bénéficiaire"
                value={
                  typeof produit.sFields === "string"
                    ? JSON.parse(produit.sFields)
                    : produit.sFields
                }
                options={JSON.parse(process.env.REACT_APP_OPTIONS_BENEFICIAIRE)}
                onChange={this.handleChange}
                error={errors.sFields ? { content: errors.sFields, pointing: "below" } : false}
              />
            </Form.Field>
            {
            produit.tDocument.length > 0 && <FormTitle>Documents</FormTitle>}
            {produit.tDocument.map(d => (
              <div>
                <CheckboxBactif
                  disabled
                  style={{ float: "left", marginRight: 10 }}
                  id={`${d.iPKCatCatalogueProduitDocument}`}
                  label={d.sLibelle || d.sURL}
                  checked={!d.bInactif}
                  onChange={this.handleCheckDocument}
                />
                <a
                  onClick={(e) => downloadFile(e, d.sLibelle || d.sURL, `/${d.sURL}`)}
                  href=''
                  style={{ float: "left" }}
                >
                  {d.sLibelle || d.sURL}
                </a>
                <div style={{ clear: "both" }} />
              </div>
            ))
            }
            <ProduitSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
              Enregistrer
            </ProduitSubmit>{" "}
            <BtnDelete
              disabled={
                produit.iPKCommandeLigne || produit.iPKCommandeTypeLigne || produit.iPKMatPanier
              }
              onClick={e => {
                e.preventDefault();
                this.setState({ showConfirmDelete: true });
              }}
            >
              Supprimer
            </BtnDelete>
            <BtnEditRight id="bInactif" onClick={this.handleCheck}>
              {!produit.bInactif ? "Désactiver" : "Activer"}
            </BtnEditRight>
            {produit.iPKCommandeLigne || produit.iPKCommandeTypeLigne || produit.iPKMatPanier ? (
              <Message visible fluid warning>
                Ce produit ne peut être supprimé car il est présent dans une commande ou un panier.{" "}
                <br />
                Il ne peut être que désactivé.
              </Message>
            ) : (
              ""
            )}
            {produit.bAssocie && (
              <Message visible fluid warning>
                Ce produit est lié à un appareil.
              </Message>
            )}
            {message && (
              <Message fluid positive>
                {message}
              </Message>
            )}
          </Form>
          <Confirm
            open={showConfirmDelete}
            content="Etes-vous sûr de vouloir supprimer ce produit ?"
            cancelButton="Annuler"
            confirmButton="Confirmer"
            onCancel={() => this.setState({ showConfirmDelete: false })}
            onConfirm={e =>
              this.setState({ showConfirmDelete: false }, () => this.deleteProduit(e))
            }
          />
        </Card.Content>
      </Card>
    );
  }
}

FormProduit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reloadList: PropTypes.func.isRequired,
  iPKCatalogue: PropTypes.number.isRequired,
  produit: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  tClient: state.client.liste
});

export default connect(mapStateToProps)(FormProduit);
