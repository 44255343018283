import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerLocale } from "react-datepicker";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import fr from "date-fns/locale/fr";

import AnimatedRoutes from "./routes/Animated";
import themes from "./ui/themes";
import GlobalStyle from "./ui/assets/css/global-css";
import "semantic-ui-css/semantic.min.css";
import "react-reflex/styles.css";
import "react-datepicker/dist/react-datepicker.min.css";

registerLocale("fr", fr);

const theme = themes[process.env.REACT_APP_THEME]
  ? themes[process.env.REACT_APP_THEME]
  : themes.ADS;

class App extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { history } = this.props;
    
    let layout = "front";
    if (process.env.REACT_APP_THEME === "AGIR") {
      const [subdomain] = window.location.hostname.split("-");
      if (subdomain === "adminagir") {
        layout = "admin";
      } else if (subdomain === "admin") {
        layout = "client";
      }
    } else {
      const [subdomain] = window.location.hostname.split(".");
      if (subdomain === "admin") {
        layout = "admin";
      } else if (subdomain === "client") {
        layout = "client";
      }
    }


    return (
      <ThemeProvider theme={theme}>
        <>
          <Router>
            <AnimatedRoutes history={history} animatedTransitions layout={layout} />
          </Router>
          <GlobalStyle />
        </>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(App);
