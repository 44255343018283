import React, { Component } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { Loader, Segment, Icon, Select } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import BtnSubmit from "../../../Core/Btn/Submit";

const BtnImport = styled(BtnSubmit)`
  float: right;
  margin-right: 0 !important;
`;

const ContainerGroupe = styled(Segment)`
  width: 45%;
  float: left;
  min-height: 70px;
  margin: 1rem !important;
  background: ${(props) =>
    props.selected ? `${props.theme.button.primary.background} !important` : ""};
  color: ${(props) => (props.selected ? `white !important` : "")};
`;

const InfoGroupe = styled.div`
  line-height: 40px;
`;

class FormMessagerie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iPKParametre: null,
      oMailCommande: {},
      oMailDevis: {},
      oMailSAV: {},
      oMailRetour: {},
      tTypeObjetOption: [],
      loading: false,
      disabled: true,
    };
  }

  componentDidMount() {
    this.getEmailsParametre();
    this.getTypeObjetOption();
  }

  componentDidUpdate(prevProps) {
    const { fields } = this.props;
    if (prevProps.fields !== fields) {
      Object.keys(fields).forEach((key) => {
        this.setState({
          [key]: {
            iFKAnnAnnuaire: fields[key].iFKAnnAnnuaire,
            sEmail: fields[key].sEmail,
            sCodeCivilite: fields[key].sCodeCivilite,
            sNom: fields[key].sNom,
            sPrenom: fields[key].sPrenom,
          },
          disabled: false,
        });
      });
    }
  }

  getTypeObjetOption = () => {
    const GET_TYPE_OBJET = gql`
      {
        getTypeObjetIsadom {
          sLibelle
          sPKTypeObjet
        }
      }
    `;
    fetchApollo(GET_TYPE_OBJET).then((response) => {
      const tTypeObjetOption = response.data.getTypeObjetIsadom;
      if (tTypeObjetOption) {
        this.setState({ tTypeObjetOption });
      }
    });
  };

  getEmailsParametre = () => {
    const GET_EMAILS = gql`
      {
        getMessagerieParametres {
          iPKParametre
          oMailCommande {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
          oMailDevis {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
          oMailSAV {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
          oMailRetour {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
        }
      }
    `;
    fetchApollo(GET_EMAILS).then((response) => {
      const Emails = response.data.getMessagerieParametres;
      if (Emails) {
        this.setState(Emails);
      }
    });
  };

  editEmailsParametre = () => {
    this.setState(
      {
        loading: true,
        disabled: true,
      },
      () => {
        const GET_EMAILS = gql`
      mutation {
        editEmailsParametre (emails: ${JSON.stringify(JSON.stringify(this.state))}) {
          iPKParametre
          oMailCommande {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
          oMailDevis {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
          oMailSAV {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
          oMailRetour {
            iFKAnnAnnuaire
            sNom
            sEmail
            sCodeAnnuaire
            tTypeObjet
          }
        }
      }
    `;
        fetchApollo(GET_EMAILS).then((response) => {
          const Emails = response.data.editEmailsParametre;
          if (Emails) {
            this.setState({
              iPKParametre: Emails.iPKParametre,
              sMailCommande: Emails.sMailCommande,
              sMailDevis: Emails.sMailDevis,
              sMailSAV: Emails.sMailSAV,
              sMailRetour: Emails.sMailRetour,
            });
          }
          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 250);
        });
      }
    );
  };

  handleChange = (evt, data) => {
    const field = this.state[data.name];
    this.setState({
      [data.name]: { ...field, tTypeObjet: data.value },
      disabled: false,
    });
  };

  handleSubmit = () => {
    this.editEmailsParametre();
  };

  render() {
    const {
      oMailCommande,
      oMailDevis,
      oMailSAV,
      oMailRetour,
      disabled,
      loading,
      tTypeObjetOption,
    } = this.state;
    const { loadImportForm, selectedField } = this.props;

    tTypeObjetOption.sort((a, b) => {
      if (a.sLibelle.toLowerCase() < b.sLibelle.toLowerCase()) {
        return -1;
      }
      if (a.sLibelle.toLowerCase() > b.sLibelle.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    return (
      <>
        <ContainerGroupe selected={selectedField === "oMailCommande"}>
          <p>
            <b>Groupe de diffusion pour les commandes</b>
          </p>
          {selectedField !== "oMailCommande" && (
            <BtnImport
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                loadImportForm("oMailCommande");
              }}
            >
              Selectionnez un tiers depuis is@dom
            </BtnImport>
          )}
          <InfoGroupe>
            <Icon name="group" />
            <b> {oMailCommande ? oMailCommande.sNom : ""}</b>
          </InfoGroupe>
        </ContainerGroupe>
        <ContainerGroupe>
          <p>
            <b>Type d'objet pour les commandes</b>
          </p>
          <Select
            style={{ marginBottom: 10 }}
            onChange={this.handleChange}
            placeholder="Selectionnez un type d'objet"
            name="oMailCommande"
            value={oMailCommande.tTypeObjet}
            options={tTypeObjetOption.map((to) => ({
              key: to.sPKTypeObjet,
              value: to.sPKTypeObjet,
              text: to.sLibelle,
            }))}
          />
        </ContainerGroupe>
        <div style={{ clear: "both" }} />
        <ContainerGroupe selected={selectedField === "oMailDevis"}>
          <p>
            <b>Groupe de diffusion pour les devis</b>
          </p>
          {selectedField !== "oMailDevis" && (
            <BtnImport
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                loadImportForm("oMailDevis");
              }}
            >
              Selectionnez un tiers depuis is@dom
            </BtnImport>
          )}
          <InfoGroupe>
            <Icon name="group" />
            <b> {oMailDevis ? oMailDevis.sNom : ""}</b>
          </InfoGroupe>
        </ContainerGroupe>
        <ContainerGroupe>
          <p>
            <b>Type d'objet pour les devis</b>
          </p>
          <Select
            style={{ marginBottom: 10 }}
            onChange={this.handleChange}
            placeholder="Selectionnez un type d'objet"
            name="oMailDevis"
            value={oMailDevis.tTypeObjet}
            options={tTypeObjetOption.map((to) => ({
              key: to.sPKTypeObjet,
              value: to.sPKTypeObjet,
              text: to.sLibelle,
            }))}
          />
        </ContainerGroupe>
        <div style={{ clear: "both" }} />
        <ContainerGroupe selected={selectedField === "oMailSAV"}>
          <p>
            <b>Groupe de diffusion pour les SAV</b>
          </p>
          {selectedField !== "oMailSAV" && (
            <BtnImport
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                loadImportForm("oMailSAV");
              }}
            >
              Selectionnez un tiers depuis is@dom
            </BtnImport>
          )}
          <InfoGroupe>
            <Icon name="group" />
            <b> {oMailSAV ? oMailSAV.sNom : ""}</b>
          </InfoGroupe>
        </ContainerGroupe>
        <ContainerGroupe>
          <p>
            <b>Types d'objets possible pour les SAV</b>
          </p>
          <Select
            style={{ marginBottom: 10 }}
            onChange={this.handleChange}
            placeholder="Selectionnez un type d'objet"
            name="oMailSAV"
            multiple
            value={
              typeof oMailSAV.tTypeObjet === "string"
                ? oMailSAV.tTypeObjet.split(",")
                : oMailSAV.tTypeObjet
            }
            options={tTypeObjetOption.map((to) => ({
              key: to.sPKTypeObjet,
              value: to.sPKTypeObjet,
              text: to.sLibelle,
            }))}
          />
        </ContainerGroupe>
        <div style={{ clear: "both" }} />
        <ContainerGroupe selected={selectedField === "oMailRetour"}>
          <p>
            <b>Groupe de diffusion pour les retours</b>
          </p>
          {selectedField !== "oMailRetour" && (
            <BtnImport
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                loadImportForm("oMailRetour");
              }}
            >
              Selectionnez un tiers depuis is@dom
            </BtnImport>
          )}
          <InfoGroupe>
            <Icon name="group" />
            <b> {oMailRetour ? oMailRetour.sNom : ""}</b>
          </InfoGroupe>
        </ContainerGroupe>
        <ContainerGroupe>
          <p>
            <b>Types d'objets possible pour les retours</b>
          </p>
          <Select
            style={{ marginBottom: 10 }}
            onChange={this.handleChange}
            placeholder="Selectionnez un type d'objet"
            name="oMailRetour"
            multiple
            value={
              typeof oMailRetour.tTypeObjet === "string"
                ? oMailRetour.tTypeObjet.split(",")
                : oMailRetour.tTypeObjet
            }
            options={tTypeObjetOption.map((typeObjet) => {
              return {
                key: typeObjet.sPKTypeObjet,
                value: typeObjet.sPKTypeObjet,
                text: typeObjet.sLibelle,
              };
            })}
          />
        </ContainerGroupe>
        <div style={{ clear: "both" }} />
        <div>
          <BtnSubmit disabled={disabled} onClick={this.handleSubmit}>
            Enregistrer
          </BtnSubmit>
          <Loader active={loading} inline size="medium" />
        </div>
      </>
    );
  }
}

FormMessagerie.propTypes = {
  loadImportForm: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedField: PropTypes.string.isRequired,
};

export default FormMessagerie;
