import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  LISTE_COMMANDE_TYPE_SUCCESS,
  LISTE_COMMANDE_TYPE_FAILURE,
  ADD_COMMANDE_TYPE_SUCCESS,
  ADD_COMMANDE_TYPE_FAILURE,
  DELETE_COMMANDE_TYPE_SUCCESS,
  DELETE_COMMANDE_TYPE_FAILURE
} from "../constants/ActionTypes";

export function* getCommandesType() {
  try {
    const listCommandeType = gql`
      {
        listCommandeType {
          iPKCommandeType
          iFKUtilisateur
          iEtat
          dInsert
          bPriorite
          dLivraison
          sConsigne
          sLibelle
        }
      }
    `;
    const response = yield call(fetchApollo, listCommandeType);
    const commandesType = response.data.listCommandeType;

    if (commandesType !== null) {
      yield put({ type: LISTE_COMMANDE_TYPE_SUCCESS, payload: commandesType });
    } else {
      yield put({
        type: LISTE_COMMANDE_TYPE_FAILURE,
        payload: "Aucun client trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: LISTE_COMMANDE_TYPE_FAILURE, payload: message });
  }
}

export function* addCommandeType({
  payload: { iFKCommande, iFKUtilisateur, sLibelle }
}) {
  try {
    const ADD_COMMANDE_TYPE = gql`mutation
      {
        addCommandeType(iFKCommande: ${iFKCommande}, iFKUtilisateur: ${iFKUtilisateur}, sLibelle: "${sLibelle}") {
          iPKCommandeType
          iFKUtilisateur
          iEtat
          dInsert
          bPriorite
          dLivraison
          sConsigne
          sLibelle
        }
      }
    `;
    const response = yield call(fetchApollo, ADD_COMMANDE_TYPE);
    const commandeType = response.data.addCommandeType;

    if (commandeType !== null) {
      yield put({ type: ADD_COMMANDE_TYPE_SUCCESS, payload: commandeType });
    } else {
      yield put({
        type: ADD_COMMANDE_TYPE_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: ADD_COMMANDE_TYPE_FAILURE, payload: message });
  }
}

export function* deleteCommandeType({ payload: { iPKCommandeType } }) {
  try {
    const DELETE_COMMANDE_TYPE = gql`mutation
      {
        deleteCommandeType(iPKCommandeType: ${iPKCommandeType}) {
          iPKCommandeType
        }
      }
    `;
    const response = yield call(fetchApollo, DELETE_COMMANDE_TYPE);
    const commandeType = response.data.deleteCommandeType;

    if (commandeType !== null) {
      yield put({ type: DELETE_COMMANDE_TYPE_SUCCESS, payload: commandeType });
    } else {
      yield put({
        type: DELETE_COMMANDE_TYPE_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_COMMANDE_TYPE_FAILURE, payload: message });
  }
}
