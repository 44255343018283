import React from "react";
import { Grid } from "semantic-ui-react";

import Layout from "../../../components/Front/Layout";

const CGL = () => {
  window.scrollTo(0, 0);
  return (
    <Layout
      mainPane={
        <Grid stackable container doubling>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h1>CONDITIONS GÉNÉRALES DE LOCATION DE MATÉRIEL</h1>
            </Grid.Column>
          </Grid.Row>
          {process.env.REACT_APP_THEME === "ARAIR" ? (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  Les conditions générales de location de matériel de l'outil de commande MAD sont
                  disponibles sur demande à l'adresse{" "}
                  <a
                    href="mailto:mad@arair.fr?subject=Demande d'accès aux conditions générales de location de matériel"
                    title="Conditions générales de location de matériel"
                  >
                    mad@arair.fr
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  Les présentes conditions générales de location (ci-après les « CGL ») s’appliquent
                  à toutes les locations de matériel (ci-après le ou les « Matériel(s) ») par PHARMA
                  DOM sous son nom de marque ARAIR Assistance (ci-après « la Société ») à un
                  professionnel (ci-après le « Locataire »). Le Matériel mis à disposition par la
                  Société est un dispositif médical tel que défini par l’article L. 5211-1 du code
                  de la santé publique.
                </p>
                <p>
                  Les présentes CGL impliquent l’acceptation sans réserve par le Locataire et son
                  adhésion pleine et entière aux CGL qui prévalent sur tout autre document du
                  Locataire, sauf accord particulier préalable à la location convenu par écrit entre
                  les Parties. Par exception à ce qui précède, dans le cas où le Locataire a signé
                  avec la Société une convention de mise à disposition de matériel, les dispositions
                  de cette convention prévaudront sur les dispositions des CGL.
                </p>
                <p>
                  Tous les documents autres que les présentes CGL communiqués par la Société tels
                  que notamment catalogues, prospectus, publicités n’ont qu’une valeur informative
                  et indicative.
                </p>
                <p>
                  Les présentes CGL ne sont applicables que pour les locations effectuées par des
                  Locataires situés en France métropolitaine et livrées exclusivement sur ce
                  territoire.
                </p>
                <p>
                  Toute clause qui serait déclarée nulle ou invalide par l’application d’une loi,
                  d’un règlement ou à la suite d’une décision définitive d’une juridiction
                  compétente n'emporterait pas la nullité des autres dispositions des présentes CGL.
                </p>
                <p>
                  Le fait pour la Société de ne pas se prévaloir de l’une quelconque des
                  dispositions des CGL ne peut être interprété comme valant renonciation à s’en
                  prévaloir ultérieurement.
                </p>
                <p>
                  La Société se réserve le droit de modifier les CGL à tout moment. Les CGL
                  applicables sont celles en vigueur au moment de la passation de commande.
                </p>
                <p>
                  Dans le cas où il y est assujetti, le Locataire est seul responsable du respect
                  des règles de la commande publique préalablement à toute commande de Matériel.
                </p>
                <h3>COMMANDE</h3>
                <p>
                  Pour procéder à la location de Matériel, le Locataire doit adresser sa commande au
                  moyen de l’application de commande éditée par la Société.
                </p>
                <p>
                  La commande émanant du Locataire est réputée ferme et définitive pour le Locataire
                  dès réception par la Société. Toute demande de modification d’une commande passée
                  par le Locataire ne pourra être prise en compte que si la demande est faite au
                  moyen de l’application de commande éditée par la Société et sous réserve de
                  l’accord écrit de la Société. Toute annulation de commande devra être communiquée,
                  dans les plus brefs délais à la Société qui se réserve la possibilité de facturer
                  des frais au Locataire à ses conditions tarifaires en vigueur (frais de
                  déplacement et main d’œuvre).
                </p>
                <p>
                  La Société se réserve le droit, à tout moment, de retirer certaines références de
                  Matériel ou de remplacer le Matériel par un Matériel équivalent sans que le
                  Locataire ne puisse prétendre à quelque indemnité que ce soit. En cas
                  d’indisponibilité du Matériel commandé, la Société en informe le Locataire.
                </p>
                <h3>LIVRAISON</h3>
                <p>
                  Sauf stipulation expresse de la Société, les délais de livraison ne sont donnés
                  qu’à titre indicatif et ne constituent pas un engagement ferme de livrer à date
                  fixe. Aucune pénalité pour retard de livraison ne peut être réclamée à la Société.
                  Le Locataire s’engage à transmettre à la Société tous les éléments nécessaires et
                  toute autre information requise pour l’exécution de la Location. Le lieu de
                  livraison est celui mentionné par le Locataire dans sa commande. Le Locataire
                  garantit à la Société l’accès à son site et à ses locaux ainsi qu’au lieu de
                  livraison, dans la mesure où cela sera nécessaire pour réaliser la mise à
                  disposition du Matériel.
                </p>
                <p>
                  En cas d’absence du Locataire, la Société est susceptible de facturer
                  l’intervention au Locataire à ses conditions tarifaires en vigueur (frais de
                  déplacement et main d’œuvre).
                </p>
                <p>
                  Si la commande se compose de livraisons successives, le défaut d’une livraison ne
                  pourra donner lieu à l’annulation de la commande ni motiver la résiliation de la
                  commande.
                </p>
                <p>
                  Toute livraison sera accompagnée de la remise d’une fiche d’intervention signée
                  conjointement par la Société et le Locataire, ou le cas échéant par l’utilisateur
                  ou son entourage. Le Matériel est livré par la Société en bon état de
                  fonctionnement.
                </p>
                <p>
                  Dans le cas où la livraison est faite par la Société, à défaut de réserves au
                  moment de la livraison du Matériel, celui-ci est réputé avoir été réceptionné
                  exempt de tout défaut ou vice apparent et/ ou manquant.
                </p>
                <p>
                  Dans le cas où la livraison est effectuée par un transporteur, conformément aux
                  dispositions de l’article L.133-3 du code de commerce, il appartient au Locataire
                  d’effectuer toutes les réserves nécessaires auprès du transporteur public par
                  lettre recommandée dans les trois jours après la date de réception mentionnée. Une
                  copie du courrier devra être adressée simultanément à la Société. Passé ce délai,
                  et à défaut d’effectuer les réserves au moment de la livraison du Matériel, le
                  Matériel sera réputé avoir été réceptionné exempt de tout défaut ou vice apparent
                  et/ ou manquant.
                </p>
                <p>
                  Le Locataire déclare disposer de toutes les informations et aptitudes nécessaires
                  à l’utilisation adéquate du Matériel.
                </p>
                <h3>RECUPERATION DU MATERIEL</h3>
                <p>
                  A la fin de la location, le Locataire prévient la Société via l’outil de commande
                  MAD afin que la Société puisse venir récupérer le Matériel au lieu indiqué par le
                  Locataire à une date convenue ensemble par téléphone ou par mail. Le Locataire lui
                  garantit le libre accès au lieu de récupération. Une fiche d’intervention
                  attestant la reprise du Matériel est signée conjointement par la Société et le
                  Locataire, ou l’utilisateur et/ou son entourage.
                </p>
                <p>
                  En cas d’absence du Locataire, ou de l’utilisateur et/ou de son entourage, sur le
                  site de récupération à l’horaire convenu entre les deux parties, la Société est
                  susceptible de facturer des frais au Locataire à ses conditions tarifaires en
                  vigueur (frais de déplacement et main d’œuvre).
                </p>
                <h3>PRIX ET CONDITIONS DE PAIEMENT</h3>
                <p>
                  La location du Matériel est facturée au Locataire au tarif journalier en vigueur
                  au jour de la commande. La facturation commence au jour de la mise à disposition
                  du Matériel par la Société au Locataire et prend fin au jour où le Locataire
                  informe la Société selon les modalités figurant au paragraphe “Récupération du
                  Matériel” de la fin de location.
                </p>
                <p>
                  Les prix s’entendent nets, franco et hors taxes. Tout impôt, taxe ou autre
                  prestation à payer en application des règlements français sont à la charge du
                  Locataire. Les factures sont éditées mensuellement ou, si la location est
                  inférieure à un mois, à la fin du mois pendant lequel la location a eu lieu. Les
                  factures sont payables, sauf disposition particulière, à 30 jours de la date de
                  facture.
                </p>
                <p>
                  Sauf disposition particulière convenue par écrit, les factures sont réglées par
                  prélèvement ou virement bancaire. Aucun escompte ne sera accordé par la Société au
                  Locataire.
                </p>
                <p>
                  En cas de non-paiement d’une facture à l’échéance, sans préjudice des droits ou
                  recours dont la Société pourrait se prévaloir en vertu des présentes CGL, la
                  Société aura le droit de refuser toute nouvelle commande et d’exiger au préalable
                  le paiement des sommes dues. Elle pourra de plein droit demander la restitution du
                  Matériel loué au Locataire.
                </p>
                <p>
                  En outre, en cas de non-paiement aux échéances prévues, la Société se réserve la
                  possibilité d’appliquer des pénalités de retard au taux de trois (3) fois le taux
                  d'intérêt légal en vigueur à la date d'échéance, sur le montant des sommes restant
                  dues sans préjudice de l’exercice par la Société de ses droits à recours. Tout
                  retard de paiement donnera également lieu au versement par le Locataire d’une
                  indemnité forfaitaire pour frais de recouvrement d’un montant de 40 euros. La
                  Société se réserve la possibilité de demander une indemnisation complémentaire si
                  les frais de recouvrement effectivement engagés dépassent ce montant.
                </p>
                <p>
                  Les réclamations éventuelles relatives à la facturation devront être formulées par
                  écrit au plus tard dans les quarante-cinq (45) jours suivants la date d’émission
                  de la facture et devront être faites à l’adresse mail suivante : mad@arair.fr.
                  Après ce délai, aucune réclamation relative à la facturation ne sera admise.
                </p>
                <h3>LE MATÉRIEL MIS À DISPOSITION</h3>
                <h4>Utilisation du Matériel</h4>
                <p>
                  Le Locataire veille à ce que son personnel soit informé des modalités de
                  fonctionnement du Matériel ou soit formé à l’utilisation du Matériel. En cas de
                  besoin d’informations sur le Matériel, le Locataire fait connaître à la Société la
                  nécessité de former son personnel ou l’utilisateur sur le Matériel.
                </p>
                <p>
                  Le Locataire s’engage à s’assurer que le Matériel est utilisé conformément aux
                  consignes d’utilisation et de sécurité.
                </p>
                <p>
                  La Société réalise la mise en service du Matériel. Lorsque la mise en service
                  n’est pas possible en raison des contraintes d’organisation du Locataire, la
                  Société informe le Locataire et donne les consignes de mise en service. Si une
                  seconde intervention est nécessaire, des frais d’intervention supplémentaires
                  seront facturés aux conditions tarifaires en vigueur de la Société (frais de
                  déplacement et de main d’œuvre).
                </p>
                <p>
                  Tout Locataire doit prendre connaissance et se conformer strictement et
                  impérativement aux lois, règlements et prescriptions en vigueur, y compris les
                  prescriptions d’utilisation et d’entretien données par la Société applicables aux
                  Matériels mis à disposition. Le Locataire s’engage à ne pas porter atteinte à
                  l’intégrité physique du Matériel ce qui inclut toute modification des éléments de
                  packaging, le déconditionnement/reconditionnement, et la suppression, la
                  modification ou l’ajout d’étiquette ou de notice d’utilisation qui serait
                  susceptible de modifier les textes présents sur le Matériel ou l’accompagnant.
                </p>
                <p>
                  L’utilisation des Matériels se fait sous la responsabilité pleine et entière du
                  Locataire.
                </p>
                <p>
                  Le Locataire s’engage à utiliser le Matériel conformément à sa destination et à ne
                  pas enfreindre les règles de sécurité.
                </p>
                <p>
                  Pendant toute la durée de la mise à disposition du Matériel, celui-ci reste et
                  demeure la propriété de la Société ou de toute société qui, directement ou
                  indirectement au moyen de un ou plusieurs intermédiaires, contrôle, est contrôlée
                  par elle ou est sous le même contrôle qu’elle.. Le Locataire s’engage à ne pas
                  enlever ou altérer les plaques d’identification apposées sur le Matériel. Le
                  Locataire s’engage à ne pas céder, échanger, louer, prêter, nantir, affecter en
                  garantie ou laisser acquérir un droit quelconque sur le Matériel.
                </p>
                <p>
                  Si un tiers prétendait à des droits sur le Matériel notamment par voie de saisie
                  ou en cas d’ouverture d’une procédure de redressement ou de liquidation
                  judiciaire, le Locataire s’engage à s’y opposer et à en aviser sans délai la
                  Société afin de lui permettre de sauvegarder ses droits.
                </p>
                <p>
                  Il est convenu entre les Parties que le personnel de la Société doit avoir, à tout
                  moment, libre accès au Matériel.
                </p>
                <p>
                  Le Locataire se porte garant du respect de l’ensemble des dispositions de cet
                  article à l’utilisateur du Matériel et/ou à son entourage.
                </p>
                <h4>Entretien du Matériel</h4>
                <p>
                  Le Locataire utilise et entretient le Matériel selon les consignes fournies par la
                  Société.
                </p>
                <p>
                  Le Locataire s’engage à prendre soin du Matériel et est tenu responsable en tant
                  que gardien de tous dommages, notamment au Matériel lui-même, à d’autres biens ou,
                  à des personnes, occasionnés par une mauvaise utilisation ou un mauvais entretien
                  du Matériel. Les dommages ainsi occasionnés seront à sa charge.
                </p>
                <p>
                  A compter de la livraison et pendant toute la mise à disposition du Matériel, la
                  Société est dégagée de toute responsabilité concernant l’utilisation du Matériel.
                </p>
                <p>
                  Le Locataire s’engage à prévenir sans délai la Société de toute difficulté
                  rencontrée dans le cadre de l’utilisation du Matériel.
                </p>
                <p>
                  Le Locataire s’engage à prévenir sans délai la Société de tout dommage causé au
                  Matériel de son propre fait ou du fait d’un tiers, de toute perte, vol ou
                  altération du Matériel. Les réparations sur le Matériel dues à une utilisation non
                  conforme du Matériel ou en cas d’usure anormale sont à la charge du Locataire.
                </p>
                <h4>i) Maintenance préventive</h4>
                <p>
                  La Société réalise la maintenance préventive du Matériel selon les recommandations
                  du fabricant. Le Locataire prendra toutes dispositions pour que la Société puisse
                  effectuer les opérations de maintenance. Seuls les personnels mandatés par la
                  Société sont habilités à intervenir sur le Matériel mis à disposition et le
                  Locataire s’interdit d’effectuer ou de laisser effectuer toutes interventions sur
                  le Matériel.
                </p>
                <h4>ii) Maintenance curative</h4>
                <p>
                  Le Locataire signale sans délai, à la Société toute panne ou dysfonctionnement sur
                  le Matériel. Seules les personnes désignées par la Société sont habilitées à
                  intervenir sur le Matériel. Il est interdit au Locataire d’intervenir ou de faire
                  intervenir une entreprise tierce sur le Matériel. A défaut, la Société est
                  susceptible de facturer au Locataire des frais de remise en état du Matériel que
                  celui-ci s’engage à payer.
                </p>
                <p>
                  A l’exclusion des cas d’utilisation non conforme, d’usure anormale ou de
                  dégradations volontaires du Matériel, les interventions de la Société au titre de
                  la maintenance curative sont comprises dans le montant des loyers.
                </p>
                <h4>Astreintes</h4>
                <p>
                  La Société assure une permanence téléphonique 24 heures sur 24, 7 jours sur 7. Les
                  coordonnées de cette astreinte sont disponibles sur le site Internet
                  <a
                    title="Prestataires de santé"
                    href="https://www.prestataire-de-sante.fr/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://www.prestataire-de-sante.fr/
                  </a>
                  . La Société intervient dans les meilleurs délais afin de dépanner le Matériel si
                  cela est nécessaire.
                </p>
                <h4>Obligations réglementaires liées au statut de dispositif médical</h4>
                <p>
                  Le Locataire s’engage à respecter l’ensemble des dispositions légales et
                  réglementaires découlant du statut de dispositif médical notamment, sans que cela
                  soit exhaustif, les dispositions du code de la santé publique et du Règlement
                  européen 2017/745 du 5 avril 2017.
                </p>
                <p>
                  Le Locataire s’engage à respecter l’ensemble des dispositions légales et
                  réglementaires découlant du statut de dispositif médical notamment, sans que cela
                  soit exhaustif, les dispositions du code de la santé publique et du Règlement
                  européen 2017/745 du 5 avril 2017.
                </p>
                <p>
                  Concernant le Matériel ayant le statut de dispositif médical, le Locataire
                  s’engage à :<br />- se conformer aux obligations qui lui incombent en matière de
                  vigilance y compris les cas de mésusage du Matériel et en informer immédiatement
                  la Société ;
                  <br />- informer immédiatement la Société de toute non-conformité concernant le
                  Matériel qu’il pourrait constater ;<br />- informer immédiatement la Société ainsi
                  que l’autorité compétente de tout risque grave que pourrait présenter le Matériel
                  ;
                  <br />- respecter les conditions de stockage et de transport conformément aux
                  consignes données par la Société lorsque le Matériel est sous sa responsabilité ;
                  <br />- notifier sans délai à la Société toute réclamation ou signalement de
                  professionnels de santé, de patients ou d’utilisateurs relatifs à des incidents
                  supposés liés au Matériel ;<br />- assurer la traçabilité du Matériel auprès de
                  tout utilisateur du Matériel pendant une durée minimale de 10 ans ;<br />-
                  collaborer à tout rappel et/ou retrait de Matériel.
                </p>
                <h3>ASSURANCES</h3>
                <p>
                  Les parties attestent avoir souscrit, auprès d’une compagnie notoirement solvable,
                  une assurance garantissant leur responsabilité civile et professionnelle couvrant
                  leurs obligations contractuelles. Chaque partie s’engage à remettre à l’autre, à
                  première demande, les attestations d’assurance et les justificatifs de paiement
                  des primes correspondantes.
                </p>
                <p>
                  Le Locataire s’engage en outre à assurer le Matériel contre tout dommage,
                  notamment le vol, l’incendie, le bris, l’explosion ou l’inondation, pour son
                  compte et celui de la Société à concurrence de sa valeur à neuf.
                </p>
                <h3>RESPONSABILITÉ</h3>
                <p>
                  En cas de manquement de la Société à ses obligations contractuelles, sa
                  responsabilité à l’égard du Locataire est limitée aux dommages directs aux biens à
                  hauteur de vingt mille (20.000) euros toutes réclamations cumulées. Au-delà de ce
                  montant, le Locataire renonce à recours contre la Société, ses employés, agents,
                  représentants et assureurs et s’engage à obtenir de ses assureurs une renonciation
                  à recours équivalente. La Société ne pourra voir sa responsabilité engagée envers
                  le Locataire pour tout autre dommage, qu’il s’agisse, sans limitation, de dommages
                  immatériels, directs ou indirects, causés au Locataire dans le cadre de la
                  location objet des présentes CGL, de perte d’exploitation, d’utilisation ou de
                  revenus, de réclamations de tiers ou de tout autre dommage direct ou indirect quel
                  qu’il soit. Le Locataire renonce à cet égard à tout recours contre la Société, ses
                  employés, agents représentants et assureurs et s’engage à obtenir de ses assureurs
                  une renonciation à recours équivalente.
                </p>
                <h3>DONNÉES PERSONNELLES</h3>
                <p>
                  La Société traite les données personnelles conformément à la politique de
                  protection des données personnelles accessible au lien suivant :{" "}
                  <a
                    title="Politique de confidentialite"
                    href="https://www.arair.fr/politique-confidentialite-rgpd"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://www.arair.fr/politique-confidentialite-rgpd
                  </a>
                </p>
                <h3>FORCE MAJEURE</h3>
                <p>
                  Les évènements indépendants de la volonté de l’une des parties ou de ses
                  cocontractants, sous-traitants, fournisseurs ou agents pouvant affecter ladite
                  partie (ou ses cocontractants, sous-traitants, fournisseurs ou agents) dans
                  l’accomplissement de tout ou partie de ses obligations contractuelles (autres que
                  des obligations de payer des sommes d’argent), tels que, notamment : pandémie,
                  épidémie, incendie, inondation, catastrophe naturelle, émeute, guerre, sabotage,
                  grève, lock-out, fait de tiers, explosion, tremblement de terre, loi, ordonnance
                  et règlement gouvernementaux, défaut d’obtention ou de maintien d’une (de
                  plusieurs) autorisation(s) nécessaire(s), bris de machine ou avarie de matériel,
                  impossibilité d’obtenir de l’électricité ou de se procurer des matières premières,
                  des utilités, de la main d’œuvre, des équipements ou des moyens de transport,
                  suspendront l’exécution de la commande pendant la durée de l’(des) événement(s).
                  Cet(ces) événement(s) libérera(ont) les parties de leurs obligations pendant la
                  durée dudit(desdites) événement(s) et dans la mesure de ses(leurs) effets. Cet
                  article ne pourra être évoqué par le Locataire pour se dégager de son obligation
                  de payer toutes sommes d’argent dues à la Société. Si un tel (de tels)
                  évènement(s) se produisait(produisaient), les parties s’engagent à se prévenir
                  mutuellement le plus rapidement possible.
                </p>
                <h3>ÉTHIQUE - LUTTE CONTRE LA CORRUPTION</h3>
                <p>
                  La Société s'attache à ce que ses partenaires, leurs personnels et leurs
                  sous-traitants de premiers rangs adhèrent aux principes exposés dans le Code de
                  Conduite du groupe Air Liquide , disponible sur son site internet à l’adresse
                  suivante :{" "}
                  <a
                    title="Code de conduite anti corruption"
                    href="https://www.airliquide.com/fr/developpement-durable/ethique"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://www.airliquide.com/fr/developpement-durable/ethique
                  </a>
                  . Le Locataire s’engage à adhérer à ces principes lorsqu’il effectue toute
                  activité en lien avec les présentes CGL.
                </p>
                <p>
                  Il certifie qu’il a et qu’il continuera de mettre en place les règles et
                  procédures visant à promouvoir la conformité aux lois et réglementations qui lui
                  sont applicables et notamment en matière de lutte contre la corruption.
                </p>
                <h3>DROIT APPLICABLE - JURIDICTION COMPETENTE</h3>
                <p>
                  Les présentes CGL sont soumises au droit français. Tout litige sera de la
                  compétence du Tribunal de Commerce de Paris même en cas de pluralité de défendeurs
                  ou appels en garantie.
                </p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      }
    />
  );
};

export default CGL;
