import React from "react";
import styled from "styled-components";

const NavigationMenu = styled.ul`
  margin: 0;
  padding: 0;
`;

const Navigation = () => (
  <NavigationMenu />
);

export default Navigation;
