import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BtnEdit = styled.button`
  padding: 0 20px;
  height: 40px;
  margin-right: 10px;
  color: ${props => props.theme.button.primary.text};
  background: ${props => props.theme.colors.warning.main};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.warning.hover};
  }

  &:disabled {
    cursor: default;
  }
`;

const Edit = ({ className, children, disabled, onClick }) => {
  return (
    <BtnEdit className={className} disabled={disabled} onClick={onClick}>
      {children}
    </BtnEdit>
  );
};

Edit.propTypes = {
  className: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Edit.defaultProps = {
  className: "",
  disabled: false
};

export default Edit;
