import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const StyledAppName = styled.div`
  z-index: -1;
  padding: 0;
  width: 100%;
  text-align: center;
  font-family: "DaxRegular";
  font-weight: bold;
  font-size: 17px;
  left: 0;
  color: ${(props) => props.theme.text.secondary};
`;

const StyledAppMotto = styled(StyledAppName)`
  font-weight: normal;
  font-style: italic;
  font-size: 15px;
`;

const AppName = () => {
  return (
    <Link to="/">
      {process.env.REACT_APP_THEME !== "ARAIR" ? (
        <>
          <StyledAppName>{process.env.REACT_APP_NAME}</StyledAppName>
          <StyledAppMotto>{process.env.REACT_APP_MOTTO}</StyledAppMotto>
        </>
      ) : (
        ""
      )}
    </Link>
  );
};

export default AppName;
