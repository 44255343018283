import {
  LISTE_CLIENT_REQUEST,
  LISTE_CLIENT_WITHOUT_CATALOGUE_REQUEST,
  ADD_CLIENT_REQUEST,
  EDIT_CLIENT_REQUEST,
  DELETE_CLIENT_REQUEST,
  FUSION_CLIENT_REQUEST
} from "../constants/ActionTypes";

export const getClients = iPKUtilisateur => ({
  type: LISTE_CLIENT_REQUEST,
  payload: { iPKUtilisateur }
});

export const getClientsWithoutCatalogue = iPKUtilisateur => ({
  type: LISTE_CLIENT_WITHOUT_CATALOGUE_REQUEST,
  payload: { iPKUtilisateur }
});

export const addClient = client => ({
  type: ADD_CLIENT_REQUEST,
  payload: { client }
});

export const editClient = client => ({
  type: EDIT_CLIENT_REQUEST,
  payload: { client }
});

export const deleteClient = iPKClient => ({
  type: DELETE_CLIENT_REQUEST,
  payload: { iPKClient }
});

export const fusionClient = (sLibelle, client) => ({
  type: FUSION_CLIENT_REQUEST,
  payload: { sLibelle, client }
});
