import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Grid, Table, Input, Button, Icon, Message } from "semantic-ui-react";

import { getPanier, deleteProduit, editProduit } from "../../../../redux/actions/panier";

import Layout from "../../../../components/Front/Layout";

class Panier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      tPanier: [],
      message: null,
      error: null
    };
  }

  componentDidMount() {
    const { dispatch, tPanier } = this.props;
    dispatch(getPanier());
    if (tPanier) {
      tPanier.sort((a, b) => {
        if (a.produit.sLibelle < b.produit.sLibelle) {
          return -1;
        }
        if (a.produit.sLibelle > b.produit.sLibelle) {
          return 1;
        }
        return 0;
      });
      this.setState({ tPanier });
    }
  }

  componentDidUpdate(prevProps) {
    const { tPanier, timestamp } = this.props;
    if (timestamp !== prevProps.timestamp) {
      tPanier.sort((a, b) => {
        if (a.produit.sLibelle < b.produit.sLibelle) {
          return -1;
        }
        if (a.produit.sLibelle > b.produit.sLibelle) {
          return 1;
        }
        return 0;
      });
      this.setState({ tPanier });
    }
  }

  deletePanier = iPKMatPanier => {
    const { dispatch } = this.props;
    dispatch(deleteProduit(iPKMatPanier));
  };

  handleChange = (evt, data) => {
    const { dispatch } = this.props;
    if (
      Number.isInteger(Number(data.value)) &&
      Number(data.value) >= 0 &&
      Number(data.value) < Number(process.env.REACT_APP_LIMITE_QTE)
    ) {
      this.setState(
        {
          formData: { [data.id]: data.value }
        },
        () => {
          if (data.value) {
            dispatch(editProduit(data.id, data.value));
            this.setState(
              {
                message: "Votre panier a bien été modifié"
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    message: null
                  });
                }, 3000);
              }
            );
          }
        }
      );
    } else {
      this.setState(
        {
          error:
            Number(data.value) >= Number(process.env.REACT_APP_LIMITE_QTE)
              ? "La quantité saisie est trop importante"
              : "Vous ne pouvez pas mettre de quantité négative"
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null
            });
          }, 3000);
        }
      );
    }
  };

  render() {
    const { formData, tPanier, message, error } = this.state;
    let iQteTotal = 0;
    tPanier.forEach(p => {
      iQteTotal += p.iQteProduit;
    });
    return (
      <Layout
        mainPane={
          <Grid stackable container doubling>
            <Grid.Row columns={1}>
              <Grid.Column>
                <h1>Votre panier</h1>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Libellé</Table.HeaderCell>
                      <Table.HeaderCell>Référence</Table.HeaderCell>
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell>Quantité</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {tPanier.map(panier => (
                      <Table.Row key={panier.iPKMatPanier}>
                        <Table.Cell>{panier.produit.sLibelle}</Table.Cell>
                        <Table.Cell>{panier.produit.sRefProduit}</Table.Cell>
                        <Table.Cell>{panier.produit.sInformation}</Table.Cell>
                        <Table.Cell>
                          <Input
                            id={panier.iPKMatPanier}
                            onChange={this.handleChange}
                            type="number"
                            min="0"
                            value={
                              formData[panier.iPKMatPanier] !== undefined
                                ? formData[panier.iPKMatPanier]
                                : panier.iQteProduit
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <span data-tooltip="Supprimer">
                            <Icon
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.deletePanier(panier.iPKMatPanier);
                              }}
                              style={{ cursor: "pointer" }}
                              name="trash"
                            />
                          </span>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    {tPanier.length === 0 && (
                      <Table.Row>
                        <Table.Cell style={{ textAlign: "center" }} colSpan="5">
                          Votre panier est vide.
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                {message && (
                  <Message positive fluid>
                    {message}
                  </Message>
                )}
                {error && (
                  <Message negative fluid>
                    {error}
                  </Message>
                )}
                <NavLink to="/passerCommande">
                  <Button disabled={iQteTotal === 0} style={{ float: "right" }} color="green">
                    Passer commande
                  </Button>
                </NavLink>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
    );
  }
}

Panier.defaultProps = {
  timestamp: Date.now()
};

Panier.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tPanier: PropTypes.arrayOf(PropTypes.any).isRequired,
  timestamp: PropTypes.number
};

const mapStateToProps = state => ({
  tPanier: state.panier.liste,
  timestamp: state.panier.timestamp
});

export default connect(mapStateToProps)(Panier);
