import { combineReducers } from "redux";

import { USER_SIGN_OUT_SUCCESS } from "./constants/ActionTypes";

import user from "./reducers/user";
import client from "./reducers/client";
import group from "./reducers/group";
import catalogue from "./reducers/catalogue";
import commande from "./reducers/commande";
import commandeType from "./reducers/commandeType";
import count from "./reducers/count";
import panier from "./reducers/panier";
import favoris from "./reducers/favoris";

const appReducer = combineReducers({
  user,
  client,
  group,
  catalogue,
  commande,
  commandeType,
  count,
  panier,
  favoris
});

export default (state, action) => {
  let currentState = state;

  if (action.type === USER_SIGN_OUT_SUCCESS) {
    currentState = undefined;
  }

  return appReducer(currentState, action);
};
