import React, { Component } from "react";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import { Grid, Card, Icon, Button, List } from "semantic-ui-react";

import LayoutAdmin from "../../../components/Admin/Layout";

import { fetchApollo } from "../../../graphql/apolloClient";

import { getClients } from "../../../redux/actions/client";

const Btn = styled(Button)`
  height: 40px;
  line-height: 18px !important;
  margin-left: 15px !important;
  margin-top: 15px !important;
  color: white !important;
  border-radius: 0 !important;
  background: ${props => props.theme.colors.secondary.main} !important;
  &:hover {
    background: ${props => props.theme.colors.secondary.main} !important;
  }
`;
class TableauDeBord extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tIntervention: [],
      tRetour: [],
      tCommande: [],
      tDevis: [],
      tCreationCompte: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getClients());
    this.getInterventions();
    this.getRetours();
    this.getDevis();
    this.getCommandes();
    this.getCreationCompte();
  }

  getInterventions = () => {
    const GET_INTERVENTIONS = gql`
      {
        listLatestIntervention {
          iPKIntervention
          dInsert
          sRefProduit
          sObjet
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_INTERVENTIONS).then(response => {
      const tIntervention = response.data.listLatestIntervention;
      if (tIntervention) {
        this.setState({
          tIntervention
        });
      }
    });
  };

  getRetours = () => {
    const GET_RETOURS = gql`
      {
        listLatestRetour {
          iPKRetour
          dInsert
          sRefProduit
          sObjet
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_RETOURS).then(response => {
      const tRetour = response.data.listLatestRetour;
      if (tRetour) {
        this.setState({
          tRetour
        });
      }
    });
  };

  getDevis = () => {
    const GET_DEVIS = gql`
      {
        listLatestDevis {
          iPKDevis
          dInsert
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_DEVIS).then(response => {
      const tDevis = response.data.listLatestDevis;
      if (tDevis) {
        this.setState({
          tDevis
        });
      }
    });
  };

  getCommandes = () => {
    const GET_COMMANDES = gql`
      {
        listLatestCommande {
          iPKCommande
          iEtat
          dInsert
          bPriorite
          utilisateur {
            sNom
            sPrenom
            sEmail
            sLibelleGroupe
          }
        }
      }
    `;
    fetchApollo(GET_COMMANDES).then(response => {
      const tCommande = response.data.listLatestCommande;
      if (tCommande) {
        this.setState({
          tCommande
        });
      }
    });
  };

  getCreationCompte = () => {
    const GET_DEVIS = gql`
      {
        getLatestContactsToCreate {
          iPKUtilisateur
          sNom
          sPrenom
        }
      }
    `;
    fetchApollo(GET_DEVIS).then(response => {
      const tCreationCompte = response.data.getLatestContactsToCreate;
      if (tCreationCompte) {
        this.setState({
          tCreationCompte
        });
      }
    });
  };

  render() {
    const {
      clients,
      count: { nbCommande, nbDevis, nbSAV, nbRetour, nbCreation }
    } = this.props;
    const { tIntervention, tRetour, tCommande, tDevis, tCreationCompte } = this.state;
    return (
      <LayoutAdmin title="Tableau de bord">
        <Grid>
          <Grid.Row columns={5}>
            {clients.slice(0, 5).map(c => (
              <Grid.Column key={c.iPKClient}>
                <Link to={`/clients/${c.iPKClient}`}>
                  <Card>
                    <Card.Content header={c.sLibelle} />
                    <Card.Content extra>
                      <Icon name="user" />
                      {c.iNbGroupe} Groupe{c.iNbGroupe > 1 ? "s" : ""}
                    </Card.Content>
                  </Card>
                </Link>
              </Grid.Column>
            ))}
            <Link to="/clients">
              <Btn>Voir tous les clients</Btn>
            </Link>
          </Grid.Row>
          <hr style={{ width: "100%" }} />
          <Grid.Row columns={5}>
            <Grid.Column key="commandes">
              <Card fluid>
                <Card.Content as="a" href="/commandes" header="Commandes" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbCommande} commande{nbCommande > 1 ? "s" : ""} en attente
                  </p>
                  <List divided relaxed>
                    {tCommande.map(commande => (
                      <List.Item>
                        <List.Content>
                          <List.Header as="a" href={`/commandes/${commande.iPKCommande}`}>
                            N°{commande.iPKCommande} par {commande.utilisateur.sPrenom}{" "}
                            {commande.utilisateur.sNom}{" "}
                            {commande.bPriorite && (
                              <span data-tooltip="Commande urgente">
                                <Icon name="exclamation circle" color="red" />
                              </span>
                            )}
                          </List.Header>
                          <List.Description as="a" href={`/commandes/${commande.iPKCommande}`}>
                            Le{" "}
                            {DateTime.fromMillis(parseInt(commande.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column key="devis">
              <Card fluid>
                <Card.Content as="a" href="/devis" header="Devis" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbDevis} demande{nbDevis > 1 ? "s" : ""} de devis en attente
                  </p>
                  <List divided relaxed>
                    {tDevis.map(devis => (
                      <List.Item>
                        <List.Content>
                          <List.Header as="a" href={`/devis/${devis.iPKDevis}`}>
                            {devis.sCommentaire.slice(0, 50)}
                          </List.Header>
                          <List.Description as="a" href={`/devis/${devis.iPKDevis}`}>
                            Le{" "}
                            {DateTime.fromMillis(parseInt(devis.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column key="comptes">
              <Card fluid>
                <Card.Content as="a" href="/creationCompte" header="Création de comptes" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbCreation} création{nbCreation > 1 ? "s" : ""} de compte en attente
                  </p>
                  <List divided relaxed>
                    {tCreationCompte.map(compte => (
                      <List.Item>
                        <List.Content>
                          <List.Header as="a" href="/creationCompte">
                            {compte.sNom} {compte.sPrenom}
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column key="interventions">
              <Card fluid>
                <Card.Content as="a" href="/interventions" header="Demandes SAV" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbSAV} demande{nbSAV > 1 ? "s" : ""} de SAV en attente
                  </p>
                  <List divided relaxed>
                    {tIntervention.map(intervention => (
                      <List.Item>
                        <List.Content>
                          <List.Header
                            as="a"
                            href={`/interventions/${intervention.iPKIntervention}`}
                          >
                            {intervention.sObjet}
                          </List.Header>
                          <List.Description
                            as="a"
                            href={`/interventions/${intervention.iPKIntervention}`}
                          >
                            Le{" "}
                            {DateTime.fromMillis(parseInt(intervention.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column key="retours">
              <Card fluid>
                <Card.Content as="a" href="/retours" header="Demandes de retour" />
                <Card.Content extra>
                  <p>
                    <Icon name="user" />
                    {nbRetour} demande{nbRetour > 1 ? "s" : ""} de retour en attente
                  </p>
                  <List divided relaxed>
                    {tRetour.map(retour => (
                      <List.Item>
                        <List.Content>
                          <List.Header as="a" href={`/retours/${retour.iPKRetour}`}>
                            {retour.sObjet}
                          </List.Header>
                          <List.Description as="a" href={`/retours/${retour.iPKRetour}`}>
                            Le{" "}
                            {DateTime.fromMillis(parseInt(retour.dInsert, 10)).toFormat(
                              "dd/LL/yyyy"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </LayoutAdmin>
    );
  }
}

TableauDeBord.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.any).isRequired,
  count: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  clients: state.client.liste,
  count: state.count,
  error: state.client.error
});

export default connect(mapStateToProps)(TableauDeBord);
