import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { Form, Icon, Accordion, List, Confirm, Loader, Card, Message } from "semantic-ui-react";

import BtnSubmit from "../../Core/Btn/Submit";
import BtnEdit from "../../Core/Btn/Edit";

import { addContact, editContact, deleteContact, searchUser } from "../../../redux/actions/user";

import { getGroupsByUser } from "../../../redux/actions/group";

import "react-sortable-tree/style.css";

const SearchList = styled(List)`
  background: #ffffff;
`;

const Title = styled.span`
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

const BtnEditRight = styled(BtnEdit)`
  float: right;
`;

const options = [
  { key: "m", text: "Monsieur", value: "M" },
  { key: "f", text: "Madame", value: "Mme" },
  { key: "o", text: "Autre", value: "Autre" }
];

class FormContact extends Component {
  constructor(props) {
    super(props);
    const { user, adminUser, groupes } = props;
    const groupe = groupes.find(g => g.iPKGroupe === String(adminUser.iFKGroupe));
    this.state = {
      iFKClient: adminUser.iFKClient,
      sLibelleGroupe: groupe?.sLibelle,
      iPKUtilisateur: user.iPKUtilisateur || "",
      sNom: user.sNom || "",
      sPrenom: user.sPrenom || "",
      sCodeCivilite: user.sCodeCivilite || "",
      iFKAnnAnnuaire: user.iFKAnnAnnuaire || "",
      iFKAnnEtablissement: groupe?.iFKAnnAnnuaire || null,
      iFKGroupe: adminUser.iFKGroupe || null,
      sEmail: user.sEmail || "",
      bInactif: user.bInactif || false,
      isAdmin: false,
      bTarif: user.bTarif || false,
      changePassword: false,
      activeIndex: 0,
      sCodeAnnuaire: "",
      errors: {},
      showConfirmDelete: false,
      loading: false,
      disabled: true,
      sSearchNom: "",
      sSearchPrenom: "",
      sSearchCodeAnnuaire: "",
      errorRedux: null
    };
  }

  componentDidMount() {
    const { dispatch, adminUser } = this.props;
    dispatch(getGroupsByUser(adminUser.iPKUtilisateur));
  }

  componentDidUpdate(prevProps) {
    const { user, adminUser, groupes, errorRedux } = this.props;
    if (prevProps.user !== user || prevProps.groupes !== groupes) {
      const groupe = groupes.find(g => g.iPKGroupe === String(adminUser.iFKGroupe));
      this.setState({
        iFKClient: adminUser.iFKClient,
        iPKUtilisateur: user.iPKUtilisateur || "",
        sLibelleGroupe: groupe?.sLibelle,
        sNom: user.sNom || "",
        sPrenom: user.sPrenom || "",
        sCodeCivilite: user.sCodeCivilite || "",
        iFKAnnAnnuaire: user.iFKAnnAnnuaire || "",
        iFKAnnEtablissement: groupe?.iFKAnnAnnuaire || null,
        iFKGroupe: adminUser.iFKGroupe || null,
        sEmail: user.sEmail || "",
        isAdmin: false,
        bInactif: user.bInactif || false,
        bTarif: user.bTarif || false,
        changePassword: false,
        errors: {},
        loading: false,
        disabled: true,
        errorRedux: null
      });
    }
    if (prevProps.errorRedux !== errorRedux && errorRedux) {
      this.setState({ errorRedux });
    }
  }

  handleCheckInactif = () => {
    const { bInactif } = this.state;
    const { dispatch, adminUser } = this.props;
    this.setState({
      bInactif: !bInactif,
      disabled: false
    });
    dispatch(editContact({ ...this.state, bInactif: !bInactif, iFKClient: adminUser.iFKClient }));
  };

  handleChange = (evt, data) => {
    const { errors } = this.state;
    const error = { ...errors, [data.id]: false };
    this.setState({ [data.id]: data.value, error, disabled: false });
  };

  handleSubmit = e => {
    const { dispatch, adminUser } = this.props;
    const { iPKUtilisateur, sNom, sPrenom, sCodeCivilite, sEmail, iFKAnnAnnuaire } = this.state;
    const errors = {};
    e.stopPropagation();
    e.preventDefault();
    if (!iFKAnnAnnuaire) {
      if (!sNom) {
        errors.sNom = "Champ obligatoire";
      }
      if (!sPrenom) {
        errors.sPrenom = "Champ obligatoire";
      }
      if (!sCodeCivilite) {
        errors.sCodeCivilite = "Champ obligatoire";
      }
      if (!sEmail) {
        errors.sEmail = "Champ obligatoire";
      }
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      if (iPKUtilisateur) {
        dispatch(editContact({ ...this.state, iFKClient: adminUser.iFKClient }));
      } else {
        dispatch(
          addContact({
            ...this.state,
            iFKClient: adminUser.iFKClient
          })
        );
      }
    }
  };

  handleSearchSubmit = e => {
    const { dispatch } = this.props;
    e.stopPropagation();
    e.preventDefault();
    this.setState({ loading: true }, () => {
      dispatch(searchUser(this.state));
      setTimeout(() => {
        this.setState({ loading: false });
      }, 500);
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  deleteUser = () => {
    const { dispatch } = this.props;
    const { iPKUtilisateur } = this.state;
    dispatch(deleteContact(iPKUtilisateur));
    this.setState({
      iPKUtilisateur: "",
      sNom: "",
      sPrenom: "",
      sCodeCivilite: "",
      sPassword: "",
      iFKAnnAnnuaire: "",
      isAdmin: false,
      sEmail: "",
      iFKGroupe: ""
    });
  };

  handleCheckTarif = () => {
    const { bTarif } = this.state;
    this.setState({
      bTarif: !bTarif,
      disabled: false
    });
  };

  selectUser = user => {
    this.setState({
      sNom: user.sNom || "",
      sPrenom: user.sPrenom || "",
      sCodeCivilite: user.sCodeCivilite || "",
      sPassword: user.sMotDePasse || "",
      iFKAnnAnnuaire: user.iFKAnnAnnuaire || "",
      sEmail: user.sEmail || "",
      activeIndex: 0,
      disabled: false
    });
  };

  render() {
    const { searchList, groupes } = this.props;
    const {
      iPKUtilisateur,
      bInactif,
      iFKGroupe,
      sNom,
      sPrenom,
      sCodeCivilite,
      sEmail,
      activeIndex,
      errors,
      showConfirmDelete,
      disabled,
      loading,
      iFKAnnAnnuaire,
      sSearchPrenom,
      sSearchNom,
      errorRedux
    } = this.state;
    return (
      <Card fluid style={{ backgroundColor: bInactif ? "lightgrey" : "" }}>
        <Card.Content>
          <Accordion>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
              <Icon name="dropdown" />
              <Title>{iPKUtilisateur ? "Modification" : "Création"} d&apos;un utilisateur</Title>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Form onSubmit={this.handleSubmit}>
                {!iFKAnnAnnuaire ? (
                  <>
                    <Form.Select
                      fluid
                      id="sCodeCivilite"
                      label="Civilité"
                      error={
                        errors.sCodeCivilite
                          ? { content: errors.sCodeCivilite, pointing: "below" }
                          : false
                      }
                      value={sCodeCivilite}
                      options={options}
                      placeholder="Civilité"
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      fluid
                      id="sNom"
                      label="Nom"
                      error={errors.sNom ? { content: errors.sNom, pointing: "below" } : false}
                      value={sNom}
                      placeholder="Nom"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      fluid
                      id="sPrenom"
                      label="Prénom"
                      error={
                        errors.sPrenom ? { content: errors.sPrenom, pointing: "below" } : false
                      }
                      value={sPrenom}
                      placeholder="Prénom"
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      id="sEmail"
                      label="Email"
                      error={errors.sEmail ? { content: errors.sEmail, pointing: "below" } : false}
                      value={sEmail}
                      autoComplete="off"
                      placeholder="Email"
                      onChange={this.handleChange}
                    />
                  </>
                ) : (
                  <Card fluid>
                    <Card.Content>
                      <Card.Header style={{ fontSize: 16 }}>
                        Utilisateur importé depuis Is@Dom
                      </Card.Header>
                    </Card.Content>
                    <Card.Content>
                      {sNom && (
                        <p>
                          <b>Nom:</b> {sNom}
                        </p>
                      )}
                      {sPrenom && (
                        <p>
                          <b>Prenom:</b> {sPrenom}
                        </p>
                      )}
                      {sEmail && (
                        <p>
                          <b>Email:</b> {sEmail}
                        </p>
                      )}
                    </Card.Content>
                  </Card>
                )}
                <Form.Select
                  fluid
                  id="iFKGroupe"
                  label="Groupe"
                  error={
                    errors.iFKGroupe ? { content: errors.iFKGroupe, pointing: "below" } : false
                  }
                  value={iFKGroupe}
                  options={groupes.map(g => ({
                    key: parseInt(g.iPKGroupe, 10),
                    text: g.sLibelle,
                    value: parseInt(g.iPKGroupe, 10)
                  }))}
                  placeholder="Groupe"
                  onChange={this.handleChange}
                />
                <BtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
                  Enregistrer
                </BtnSubmit>{" "}
                {iPKUtilisateur && (
                  <BtnEditRight
                    style={{ float: "right" }}
                    onClick={e => {
                      e.preventDefault();
                      return !bInactif
                        ? this.setState({ showConfirmDelete: true })
                        : this.handleCheckInactif();
                    }}
                  >
                    {!bInactif ? "Désactiver" : "Activer"}
                  </BtnEditRight>
                )}
                {errorRedux && (
                  <Message negative>
                    <Message.Header>Utilisateur non enregistré</Message.Header>
                    <p>{errorRedux}</p>
                  </Message>
                )}
              </Form>
              <Confirm
                open={showConfirmDelete}
                content="L'utilisateur ne pourra plus se connecter à l'application. Etes-vous sûr de vouloir désactiver cet utilisateur ?"
                cancelButton="Annuler"
                confirmButton="Confirmer"
                onCancel={() => this.setState({ showConfirmDelete: false })}
                onConfirm={() =>
                  this.setState({ showConfirmDelete: false }, () => this.handleCheckInactif())
                }
              />
            </Accordion.Content>
            {!iPKUtilisateur && (
              <>
                <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                  <Icon name="dropdown" />
                  <Title>Importer un utilisateur depuis Isadom</Title>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <Form onSubmit={this.handleSearchSubmit}>
                    <Form.Input
                      fluid
                      id="sSearchNom"
                      label="Nom"
                      value={sSearchNom}
                      placeholder="Nom"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      fluid
                      id="sSearchPrenom"
                      label="Prénom"
                      value={sSearchPrenom}
                      placeholder="Prénom"
                      onChange={this.handleChange}
                    />
                    <BtnSubmit onClick={this.handleSearchSubmit} type="submit">
                      Rechercher
                    </BtnSubmit>
                    <Loader active={loading} inline size="medium" />
                  </Form>
                  {searchList && (
                    <SearchList selection divided verticalAlign="middle">
                      {searchList.map(user => (
                        <List.Item
                          onClick={() => this.selectUser(user)}
                          key={`${user.sPrenom} ${user.sNom}`}
                        >
                          <List.Content>
                            <List.Header>
                              {user.sPrenom} {user.sNom}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                      ))}
                    </SearchList>
                  )}
                </Accordion.Content>
              </>
            )}
          </Accordion>
        </Card.Content>
      </Card>
    );
  }
}

FormContact.defaultProps = {
  searchList: [],
  errorRedux: ""
};

FormContact.propTypes = {
  dispatch: PropTypes.func.isRequired,
  searchList: PropTypes.arrayOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  groupes: PropTypes.arrayOf(PropTypes.any).isRequired,
  errorRedux: PropTypes.string
};

const mapStateToProps = state => ({
  searchList: state.user.searchListe,
  errorRedux: state.user.error,
  groupes: state.group.liste,
  adminUser: state.user.data
});

export default connect(mapStateToProps)(FormContact);
