import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const StyledBtnSignIn = styled(Icon)`
  position: relative;
  top: 2px;
  height: 40px !important;
  width: 40px !important;
  font-size: 20px !important;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: ${props => props.theme.button.primary.background} !important;

  transition: all 0.25s ease;

  &:hover {
    color: #ffffff !important;
    background: ${props => props.theme.button.primary.background};
  }
`;

const BtnSignOut = () => (
  <NavLink
    style={{ position: "absolute", right: 5 }}
    to="/deconnexion"
    data-tooltip="Fermer la session"
    data-position="bottom center"
  >
    <StyledBtnSignIn className="noPrint" name="power off" />
  </NavLink>
);

export default BtnSignOut;
