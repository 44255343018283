import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import BtnToggle from "./BtnToggle";

const Wrapper = styled.div`
  background-color: #eff2f7;
  position: absolute;
  top: 0;
  right: ${props => (props.opened ? "0px" : "-310px")};
  z-index: 51;
  padding: 40px;
  padding-top: 15px;
  width: 350px;
  height: 100%;
  border-left: 1px solid ${props => props.theme.border.light};
  transition: all 0.25s ease;
  overflow: auto;
`;

class SideBarRight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: true
    };
  }

  handleBtnToggleClicked = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ opened: !prevState.opened }));
  };

  render() {
    const { show, children } = this.props;
    const { opened } = this.state;

    return show ? (
      <Wrapper opened={opened}>
        <BtnToggle opened={opened} onClick={this.handleBtnToggleClicked} />
        {children}
      </Wrapper>
    ) : null;
  }
}

SideBarRight.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node
};

SideBarRight.defaultProps = {
  children: null
};

export default SideBarRight;
