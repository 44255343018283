import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Accordion, Icon, Checkbox } from "semantic-ui-react";

const ListCheckbox = styled(Checkbox)`
  float: left;
  margin-right: 10px;
`;

const FamilyAccordion = styled(Accordion)`
  margin-top: 20px;
  & .title.selected {
    color: white !important;
    background: ${props => props.theme.colors.secondary.main} !important;
  }
`;

const Title = styled.span`
  line-height: 35px;
  height: 35px;
  display: inline-block;
  width: 84%;
  margin-top: -10px;
  margin-bottom: -10px;
`;

class FamilyFormList extends Component {
  constructor(props) {
    super(props);

    this.once = false;

    this.state = {
      itemsCheck: {}
    };
  }

  handleCheck = (evt, data) => {
    evt.stopPropagation();
    evt.preventDefault();
    const { famillesCatalogue, sendItemsCheck } = this.props;
    const { itemsCheck } = this.state;
    if (data.famille) {
      const famille = famillesCatalogue.filter(f => f.sPKFamille === data.famille.sPKFamille)[0];
      if (!data.checked) {
        // create famille
        if (!itemsCheck[famille.sPKFamille]) {
          itemsCheck[famille.sPKFamille] = {
            famille: {
              sLibelle: famille.sLibelle,
              sPKFamille: famille.sPKFamille,
              sInformation: famille.sInformation
            },
            tPKSousFamille: {},
            tRefProduit: {}
          };
        }
        // Add all subfamily and product
        if (data.famille && !data.sousfamille && !data.produit) {
          famille.tProduit.forEach(p => {
            itemsCheck[famille.sPKFamille].tRefProduit[p.sRefProduit] = p;
          });

          if (famille.tSousFamilleCatalogue.length > 0) {
            famille.tSousFamilleCatalogue.forEach(sf => {
              itemsCheck[famille.sPKFamille].tPKSousFamille[sf.sPKSousFamille] = {
                sousFamille: {
                  sLibelle: sf.sLibelle,
                  sPKFamille: sf.sPKFamille,
                  sInformation: sf.sInformation
                },
                tRefProduit: {}
              };

              sf.tProduit.forEach(p => {
                itemsCheck[famille.sPKFamille].tPKSousFamille[sf.sPKSousFamille].tRefProduit[
                  p.sRefProduit
                ] = p;
              });
            });
          }
        } else if (data.famille && data.sousfamille && !data.produit) {
          // Add all product of subfamily
          const sousFamille = famille.tSousFamilleCatalogue.filter(
            f => f.sPKSousFamille === data.sousfamille.sPKSousFamille
          )[0];
          itemsCheck[famille.sPKFamille].tPKSousFamille[data.sousfamille.sPKSousFamille] = {
            ...itemsCheck[famille.sPKFamille].tPKSousFamille,
            sousFamille: {
              sLibelle: data.sousfamille.sLibelle,
              sPKFamille: data.sousfamille.sPKFamille,
              sInformation: data.sousfamille.sInformation
            },
            tRefProduit: {}
          };

          if (sousFamille.tProduit && sousFamille.tProduit.length > 0) {
            sousFamille.tProduit.forEach(p => {
              itemsCheck[famille.sPKFamille].tPKSousFamille[sousFamille.sPKSousFamille].tRefProduit[
                p.sRefProduit
              ] = p;
            });
          }
        }

        const tPKSousFamille =
          itemsCheck[data.famille.sPKFamille] && itemsCheck[data.famille.sPKFamille].tPKSousFamille;

        const tRefProduitSousFamille =
          data.sousfamille &&
          itemsCheck[data.famille.sPKFamille] &&
          itemsCheck[data.famille.sPKFamille].tPKSousFamille &&
          itemsCheck[data.famille.sPKFamille].tPKSousFamille[data.sousfamille.sPKSousFamille] &&
          itemsCheck[data.famille.sPKFamille].tPKSousFamille[data.sousfamille.sPKSousFamille]
            .tRefProduit;

        const tRefProduit =
          itemsCheck[data.famille.sPKFamille] && itemsCheck[data.famille.sPKFamille].tRefProduit;

        itemsCheck[data.famille.sPKFamille] = {
          famille: {
            sLibelle: data.famille.sLibelle,
            sPKFamille: data.famille.sPKFamille,
            sInformation: data.famille.sInformation
          },
          tPKSousFamille: data.sousfamille
            ? {
                ...tPKSousFamille,
                [data.sousfamille.sPKSousFamille]: {
                  sousFamille: {
                    sLibelle: data.sousfamille.sLibelle,
                    sPKFamille: data.sousfamille.sPKSousFamille,
                    sInformation: data.sousfamille.sInformation
                  },
                  tRefProduit: data.produit
                    ? {
                        ...tRefProduitSousFamille,
                        [data.produit.sRefProduit]: data.produit
                      }
                    : tRefProduitSousFamille
                }
              }
            : tPKSousFamille,
          tRefProduit:
            !data.sousfamille && data.produit
              ? {
                  ...tRefProduit,
                  [data.produit.sRefProduit]: data.produit
                }
              : tRefProduit
        };
      } else {
        if (data.produit && !data.sousfamille) {
          delete itemsCheck[data.famille.sPKFamille].tRefProduit[data.produit.sRefProduit];
        } else if (data.produit) {
          delete itemsCheck[data.famille.sPKFamille].tPKSousFamille[data.sousfamille.sPKSousFamille]
            .tRefProduit[data.produit.sRefProduit];
        } else if (data.sousfamille) {
          delete itemsCheck[data.famille.sPKFamille].tPKSousFamille[
            data.sousfamille.sPKSousFamille
          ];
        } else if (data.famille) {
          delete itemsCheck[data.famille.sPKFamille];
        }
      }
      this.setState({ itemsCheck }, () => {
        sendItemsCheck(itemsCheck);
      });
    }
  };

  render() {
    const { famillesCatalogue, selectItem, currentItem } = this.props;
    const { itemsCheck } = this.state;
    const rootPanels = [];
    famillesCatalogue.forEach(famille => {
      const level1Panels = [];
      if (famille.tSousFamilleCatalogue) {
        famille.tSousFamilleCatalogue.forEach(sousFamille =>
          level1Panels.push({
            key: sousFamille.sPKSousFamille,
            title: (
              <Accordion.Title
                className={
                  currentItem && currentItem.sPKSousFamille === sousFamille.sPKSousFamille
                    ? "selected"
                    : ""
                }
                style={{ background: sousFamille.bInactif ? "lightgrey" : "" }}
                key={`level1panel${sousFamille.sPKSousFamille}`}
              >
                <Icon name="dropdown" /> <Icon name="folder open" />{" "}
                {sousFamille.bAssocie && (
                  <span
                    data-position="top left"
                    data-tooltip="Cette famille contient des consommables liés à un appareil"
                  >
                    <Icon name="linkify" />
                  </span>
                )}
                <Title
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectItem(sousFamille);
                  }}
                >
                  {sousFamille.sLibelle || sousFamille.sPKSousFamille}
                </Title>
                <ListCheckbox
                  onClick={this.handleCheck}
                  famille={famille}
                  sousfamille={sousFamille}
                  produit={null}
                  checked={
                    itemsCheck[famille.sPKFamille] &&
                    itemsCheck[famille.sPKFamille].tPKSousFamille &&
                    itemsCheck[famille.sPKFamille].tPKSousFamille[sousFamille.sPKSousFamille]
                      ? false
                      : true
                  }
                />
              </Accordion.Title>
            ),
            content:
              sousFamille.tProduit &&
              sousFamille.tProduit.map(produit => (
                <Accordion.Title
                  style={{
                    marginLeft: 35,
                    width: "95%",
                    background: produit.bInactif ? "lightgrey" : ""
                  }}
                  className={
                    currentItem && currentItem.sRefProduit === produit.sRefProduit ? "selected" : ""
                  }
                  onClick={() => {
                    selectItem(produit);
                  }}
                  key={`level1panel${produit.sRefProduit}`}
                >
                  <Icon name={`${produit.sCodeNatureProduit === "M" ? "laptop" : "file"}`} />{" "}
                  {produit.sLibelle}{" "}
                  {produit.bAssocie && (
                    <span data-position="top left" data-tooltip="Ce produit est lié à un appareil">
                      <Icon name="linkify" />
                    </span>
                  )}
                  <ListCheckbox
                    onClick={this.handleCheck}
                    famille={famille}
                    sousfamille={sousFamille}
                    produit={produit}
                    checked={
                      itemsCheck[famille.sPKFamille] &&
                      itemsCheck[famille.sPKFamille].tPKSousFamille &&
                      itemsCheck[famille.sPKFamille].tPKSousFamille[sousFamille.sPKSousFamille] &&
                      itemsCheck[famille.sPKFamille].tPKSousFamille[sousFamille.sPKSousFamille]
                        .tRefProduit &&
                      itemsCheck[famille.sPKFamille].tPKSousFamille[sousFamille.sPKSousFamille]
                        .tRefProduit[produit.sRefProduit]
                        ? false
                        : true
                    }
                  />
                </Accordion.Title>
              ))
          })
        );
      }
      const Level1Content = (
        <div>
          {level1Panels.length > 0 && (
            <Accordion.Accordion
              exclusive={false}
              style={{
                marginLeft: 35,
                marginTop: 0,
                marginBottom: 10,
                width: "96%"
              }}
              key={`level1content${famille.sPKFamille}`}
              panels={level1Panels}
            />
          )}
          {famille.tProduit && famille.tProduit.length > 0 && (
            <Accordion
              style={{ marginLeft: 35, marginTop: 0, width: "96%" }}
              key={`level2${famille.sPKFamille}`}
              styled
            >
              {famille.tProduit.map(produit => (
                <Accordion.Title
                  className={
                    currentItem && currentItem.sRefProduit === produit.sRefProduit ? "selected" : ""
                  }
                  onClick={() => {
                    selectItem(produit);
                  }}
                  key={`level1content${produit.sRefProduit}`}
                  style={{ background: produit.bInactif ? "lightgrey" : "" }}
                >
                  <Icon name={`${produit.sCodeNatureProduit === "M" ? "laptop" : "file"}`} />{" "}
                  {produit.bAssocie && (
                    <span data-position="top left" data-tooltip="Ce produit est lié à un appareil">
                      <Icon name="linkify" />
                    </span>
                  )}
                  {produit.sLibelle || produit.sRefProduit}
                  <ListCheckbox
                    onClick={this.handleCheck}
                    famille={famille}
                    sousfamille={null}
                    produit={produit}
                    checked={
                      itemsCheck[famille.sPKFamille] &&
                      itemsCheck[famille.sPKFamille].tRefProduit[produit.sRefProduit]
                        ? false
                        : true
                    }
                  />
                </Accordion.Title>
              ))}
            </Accordion>
          )}
        </div>
      );
      rootPanels.push({
        key: famille.sPKFamille,
        title: (
          <Accordion.Title
            className={
              currentItem && currentItem.sPKFamille === famille.sPKFamille ? "selected" : ""
            }
            style={{ background: famille.bInactif ? "lightgrey" : "" }}
            key={`level0${famille.sPKFamille}`}
          >
            <Icon name="dropdown" /> <Icon name="folder open" />{" "}
            {famille.bAssocie && (
              <span
                data-position="top left"
                data-tooltip="Cette famille contient des consommables liés à un appareil"
              >
                <Icon name="linkify" />
              </span>
            )}
            <Title
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                selectItem(famille);
              }}
            >
              {famille.sLibelle || famille.sPKFamille}
            </Title>
            <ListCheckbox
              onClick={this.handleCheck}
              famille={famille}
              sousfamille={null}
              produit={null}
              checked={itemsCheck[famille.sPKFamille] ? false : true}
            />
          </Accordion.Title>
        ),
        content: { content: Level1Content }
      });
    });
    return <FamilyAccordion panels={rootPanels} styled fluid />;
  }
}

FamilyFormList.defaultProps = {
  currentItem: null
};

FamilyFormList.propTypes = {
  selectItem: PropTypes.func.isRequired,
  sendItemsCheck: PropTypes.func.isRequired,
  famillesCatalogue: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentItem: PropTypes.objectOf(PropTypes.any)
};

const mapStateToProps = state => ({
  error: state.group.error
});

export default connect(mapStateToProps)(FamilyFormList);
