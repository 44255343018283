import React from "react";

import { Grid } from "semantic-ui-react";

import Layout from "../../components/Front/Layout";
import H1 from "../../components/Core/Title/Front/H1";

const Erreur404 = () => (
  <Layout
    mainPane={
      <Grid stackable container doubling>
        <Grid.Row columns={1}>
          <Grid.Column>
            <H1>La page demandée est introuvable.</H1>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    }
  />
);
export default Erreur404;
