import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_ALL_COUNT_SUCCESS,
  GET_ALL_COUNT_FAILURE,
  GET_ALL_COUNT_BY_USER_SUCCESS,
  GET_ALL_COUNT_BY_USER_FAILURE
} from "../constants/ActionTypes";

export function* getAllCount() {
  try {
    const getCount = gql`
      {
        getAllCount {
          nbCommande
          nbDevis
          nbSAV
          nbRetour
          nbCreation
          nbMotDePasse
        }
      }
    `;
    const response = yield call(fetchApollo, getCount);
    const allCount = response.data.getAllCount;

    if (allCount !== null) {
      yield put({ type: GET_ALL_COUNT_SUCCESS, payload: allCount });
    } else {
      yield put({
        type: GET_ALL_COUNT_FAILURE,
        payload: "Aucun client trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: GET_ALL_COUNT_FAILURE, payload: message });
  }
}

export function* getAllCountByUser({ payload: { iPKUtilisateur } }) {
  try {
    const getCount = gql`
      {
        getAllCountByUser (iPKUtilisateur: ${iPKUtilisateur}) {
          nbCommande
          nbDevis
          nbSAV
          nbRetour
        }
      }
    `;
    const response = yield call(fetchApollo, getCount);
    const allCount = response.data.getAllCountByUser;

    if (allCount !== null) {
      yield put({ type: GET_ALL_COUNT_BY_USER_SUCCESS, payload: allCount });
    } else {
      yield put({
        type: GET_ALL_COUNT_BY_USER_FAILURE,
        payload: "Aucun client trouvé"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }

    yield put({ type: GET_ALL_COUNT_BY_USER_FAILURE, payload: message });
  }
}
