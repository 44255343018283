// import jwtDecode from "jwt-decode";

import {
  GET_FAVORIS_SUCCESS,
  GET_FAVORIS_FAILURE,
  ADD_FAVORIS_SUCCESS,
  ADD_FAVORIS_FAILURE,
  DELETE_FAVORIS_SUCCESS,
  DELETE_FAVORIS_FAILURE
} from "../constants/ActionTypes";

const initialAuthState = {
  liste: [],
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case GET_FAVORIS_SUCCESS: {
      return { ...state, liste: payload, error: null };
    }
    case GET_FAVORIS_FAILURE: {
      return { ...initialAuthState, error: payload };
    }
    case ADD_FAVORIS_SUCCESS: {
      return { ...state, liste: [...state.liste, payload], error: null };
    }
    case ADD_FAVORIS_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case DELETE_FAVORIS_SUCCESS: {
      return {
        ...state,
        liste: state.liste.filter(
          item => item.iPKMatPanier !== payload.iPKMatPanier
        ),
        error: null
      };
    }
    case DELETE_FAVORIS_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    default:
      return state;
  }
};
