import React from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { connect } from "react-redux";

import { Card, Icon, Grid, Button, Confirm, Modal, Loader, Input, Message, Tab } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import { addProduit } from "../../../../redux/actions/panier";
import { addCommandeType, deleteCommandeType } from "../../../../redux/actions/commandeType";

import FilteredTable from "../../../../components/Core/FilteredTable";
import ShowDevis from "../../../../components/Admin/Devis/show";
import FormDevis from "../../../../components/AdminClient/Devis/Form";

import Layout from "../../../../components/Front/Layout";

const CreateButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.title.main} !important;
  color: white !important;
`;

const LabelAction = styled.span`
  font-size: 18px;
  margin: 2px;
`;

const CustomLoader = styled(Loader)`
  position: relative !important;
  top: 15px !important;
`;

class Commande extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tCommande: [],
      tCommandeType: [],
      rightPanel: null,
      tDevis: [],
      modalOpen: false,
      showConfirmAnnule: false,
      showConfirmDelete: false,
      iPKCommandeType: null,
      sCommandeTypeName: null,
      activeIndex: 0,
      isOpen: false,
      visible: true,
      loading: true,
    };
  }

  componentDidMount() {
    this.getCommandes();
    this.getCommandesType();
    this.getDevis();
  }

  getCommandesType = () => {
    const GET_COMMANDE_TYPE = gql`
    {
      listCommandeType {
        iPKCommandeType
        iFKUtilisateur
        iEtat
        dInsert
        bPriorite
        dLivraison
        sConsigne
        sLibelle
      }
    }
  `;
    fetchApollo(GET_COMMANDE_TYPE).then((response) => {
      const tCommandeType = response.data.listCommandeType;

      if (tCommandeType) {
        this.setState({
          tCommandeType,
          loading: false,
        });
      }
    });
  };

  getCommandes = () => {
    const GET_COMMANDE = gql`
      {
        listCommandeByUser {
          iPKCommande
          iEtat
          dInsert
          dLivraison
          bPriorite
          files {
            name
            path
          }
          utilisateur {
            sNom
            sPrenom
            sEmail
            sLibelleGroupe
          }
        }
      }
    `;
    fetchApollo(GET_COMMANDE).then((response) => {
      const tCommande = response.data.listCommandeByUser;

      if (tCommande) {
        this.setState({
          tCommande,
          loading: false,
        });
      }
    });
  };

  getDevis = () => {
    const GET_DEVIS = gql`
      {
        listDevisByUser {
          iPKDevis
          dInsert
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_DEVIS).then((response) => {
      const tDevis = response.data.listDevisByUser;
      if (tDevis) {
        this.setState({
          tDevis,
        });
      }
    });
  };

  annuleCommande = () => {
    const { tCommande, iPKCommandeAnnule } = this.state;
    const commandeAnnule = { iPKCommande: iPKCommandeAnnule, iEtat: 4 };
    const GET_COMMANDE = gql`mutation
      {
        editCommande(commande: ${JSON.stringify(JSON.stringify(commandeAnnule))}) {
          iPKCommande
          iEtat
          dInsert
          bPriorite
        }
      }
    `;
    fetchApollo(GET_COMMANDE).then((response) => {
      const commande = response.data.editCommande;

      if (commande) {
        this.setState({
          tCommande: tCommande.map((c) => (c.iPKCommande === commande.iPKCommande ? commande : c)),
        });
      }
    });
  };

  getProductsByCommande = async (iPKCommande) => {
    const GET_PRODUCTS = gql`
      {
        listProductsByCommande(iPKCommande: ${iPKCommande}) {
          iPKCommandeLigne
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          iQuantite
          sBeneficiaire
          bPriorite
          sFields
        }
      }
    `;
    return fetchApollo(GET_PRODUCTS).then((response) => {
      const tCommandeLigne = response.data.listProductsByCommande;
      if (tCommandeLigne) {
        return tCommandeLigne;
      }
    });
  };

  getProductsByCommandeType = async (iPKCommande) => {
    const GET_PRODUCTS = gql`
      {
        listProductsByCommandeType(iPKCommandeType: ${iPKCommande}) {
          iPKCommandeLigne
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          iQuantite
          sBeneficiaire
          bPriorite
          sFields
        }
      }
    `;
    return fetchApollo(GET_PRODUCTS).then((response) => {
      const tCommandeLigne = response.data.listProductsByCommandeType;
      if (tCommandeLigne) {
        return tCommandeLigne;
      }
    });
  };

  renouvelleCommande = async (iPKCommande) => {
    const { dispatch, user } = this.props;
    const tCommandeLigne = await this.getProductsByCommande(iPKCommande);
    tCommandeLigne.forEach((cl) => {
      dispatch(
        addProduit(cl.iPKProduit, user.iPKUtilisateur, cl.iQuantite, cl.sFields, "", cl.bPriorite)
      );
    });
    document.location = "/panier";
  };

  renouvelleCommandeType = async (iPKCommande) => {
    const { dispatch, user } = this.props;
    const tCommandeLigne = await this.getProductsByCommandeType(iPKCommande);
    tCommandeLigne.forEach((cl) => {
      dispatch(
        addProduit(cl.iPKProduit, user.iPKUtilisateur, cl.iQuantite, cl.sFields, "", cl.bPriorite)
      );
    });
    document.location = "/panier";
  };

  addCommandeType = () => {
    const { user, dispatch } = this.props;
    const { iPKCommandeType, sCommandeTypeName } = this.state;
    dispatch(addCommandeType(iPKCommandeType, user.iPKUtilisateur, sCommandeTypeName));
    this.setState({
      activeIndex: 1,
      modalOpen: false,
      sCommandeTypeName: null,
    });
  };

  deleteCommandeType = () => {
    const { dispatch } = this.props;
    const { iPKCommandeType } = this.state;
    dispatch(deleteCommandeType(iPKCommandeType));
  };

  handleChange = (e, data) => {
    this.setState({
      [data.id]: data.value,
    });
  };

  selectDevis = (devis) => {
    this.setState({
      rightPanel: <ShowDevis devis={devis} />,
    });
  };

  selectCommande = (cmd) => {
    window.location = `/commandes/${cmd.iPKCommande}`;
  };

  selectCommandeType = (cmdType) => {
    window.location = `/commandes-type/${cmdType.iPKCommandeType}`;
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  handleDismiss = () => {
    this.setState({ visible: false });
  };

  handleToggleOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  addDevis = (devis) => {
    const { tDevis } = this.state;
    tDevis.push(devis);
    this.setState({
      tDevis,
    });
  };

  render() {
    const {
      tCommande,
      tCommandeType,
      tDevis,
      showConfirmAnnule,
      showConfirmDelete,
      modalOpen,
      sCommandeTypeName,
      iPKCommandeType,
      activeIndex,
      rightPanel,
      isOpen,
      visible,
      loading,
    } = this.state;
    const { location } = this.props;
    const tRenderedCommande = tCommande.map((c) => {
      return {
        ...c,
        actions: (
          <>
            <LabelAction data-tooltip="Ajouter cette commande au panier">
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.renouvelleCommande(c.iPKCommande);
                }}
                style={{ cursor: "pointer" }}
                name="add to cart"
              />
            </LabelAction>
            <LabelAction data-tooltip="Définir en commande type">
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    modalOpen: true,
                    iPKCommandeType: c.iPKCommande,
                  });
                }}
                style={{ cursor: "pointer" }}
                name="save"
              />
            </LabelAction>
            {c.iEtat < 1 && (
              <LabelAction data-tooltip="Annuler">
                <Icon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                      iPKCommandeAnnule: c.iPKCommande,
                      showConfirmAnnule: true,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                  name="cancel"
                />
              </LabelAction>
            )}
          </>
        ),
      };
    });
    const tRenderedCommandeType = tCommandeType.map((c) => {
      return {
        ...c,
        iEtat: 0,
        actions: (
          <>
            <LabelAction data-tooltip="Ajouter cette commande au panier">
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.renouvelleCommandeType(c.iPKCommandeType);
                }}
                style={{ cursor: "pointer" }}
                name="add to cart"
              />
            </LabelAction>
            <LabelAction data-tooltip="Supprimer">
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    showConfirmDelete: true,
                    iPKCommandeType: c.iPKCommandeType,
                  });
                }}
                style={{ cursor: "pointer" }}
                name="trash"
              />
            </LabelAction>
          </>
        ),
      };
    });
    const panes = [
      {
        menuItem: `Vos commandes (${tRenderedCommande.length})`,
        render: () => (
          <Tab.Pane>
            {loading ? (
              <CustomLoader active />
            ) : (
              <FilteredTable
                type="commande"
                fields={{
                  iPKCommande: "N° de commande",
                  dInsert: "Date",
                  dLivraison: "Date de livraison souhaitée",
                  bPriorite: "Urgent",
                  iEtat: "Etat",
                  actions: "",
                }}
                selectable
                items={tRenderedCommande}
                selectItem={this.selectCommande}
              />
            )}
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Vos commandes types (${tRenderedCommandeType.length})`,
        render: () => (
          <Tab.Pane>
            <FilteredTable
              type="commande type"
              fields={{
                sLibelle: "Libelle de la commande type",
                dInsert: "Date",
                bPriorite: "Urgent",
                actions: "",
              }}
              selectable
              items={tRenderedCommandeType}
              selectItem={this.selectCommandeType}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Vos devis (${tDevis.length})`,
        render: () => (
          <Tab.Pane>
            <CreateButton size="tiny" onClick={this.handleToggleOpen}>
              Créer un devis
            </CreateButton>
            {isOpen && (
              <Card fluid style={{ width: "50%" }}>
                <Card.Content>
                  Création d'un devis{" "}
                  <Icon
                    style={{ cursor: "pointer", float: "right" }}
                    name="close"
                    onClick={this.handleToggleOpen}
                  />
                </Card.Content>
                <Card.Content>
                  <FormDevis addDevisToList={this.addDevis} />
                </Card.Content>
              </Card>
            )}
            <FilteredTable
              type="devis"
              fields={{
                iPKDevis: "N° de devis",
                dInsert: "Date",
                sPatient: "Contact",
                sGroupe: "Groupe",
                iEtat: "Etat",
              }}
              items={tDevis}
              selectItem={this.selectDevis}
            />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Layout
        sideBarRight={rightPanel}
        mainPane={
          <>
            <Grid stackable container doubling>
              <Grid.Row columns={1}>
                <Grid.Column>
                  {visible && location.state && location.state.message && (
                    <Message positive fluid onDismiss={this.handleDismiss}>
                      <Message.Header>{location.state.message}</Message.Header>
                    </Message>
                  )}
                  <Tab panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Modal
              open={modalOpen}
              onClose={(e) => {
                e.preventDefault();
                this.setState({ modalOpen: false });
              }}
              size="small"
            >
              <Modal.Header content={`Définir la commande N°${iPKCommandeType} en commande type`} />
              <Modal.Content>
                <Input
                  fluid
                  placeholder="Nom de votre modèle de commande"
                  id="sCommandeTypeName"
                  onChange={this.handleChange}
                  value={sCommandeTypeName}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color="red"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ modalOpen: false });
                  }}
                >
                  Annuler
                </Button>
                <Button disabled={!sCommandeTypeName} color="green" onClick={this.addCommandeType}>
                  Valider
                </Button>
              </Modal.Actions>
            </Modal>
            <Confirm
              open={showConfirmAnnule}
              content="Etes-vous sûr de vouloir annuler cette commande ?"
              cancelButton="Annuler"
              confirmButton="Confirmer"
              onCancel={() => this.setState({ showConfirmAnnule: false })}
              onConfirm={() =>
                this.setState({ showConfirmAnnule: false }, () => this.annuleCommande())
              }
            />
            <Confirm
              open={showConfirmDelete}
              content="Etes-vous sûr de vouloir supprimer cette commande type ?"
              cancelButton="Annuler"
              confirmButton="Confirmer"
              onCancel={() => this.setState({ showConfirmDelete: false })}
              onConfirm={() =>
                this.setState({ showConfirmDelete: false }, () => this.deleteCommandeType())
              }
            />
          </>
        }
      />
    );
  }
}

Commande.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  tPanier: state.panier.liste,
  user: state.user.data,
});

export default connect(mapStateToProps)(Commande);
