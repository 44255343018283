import React, { Component } from "react";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { Grid } from "semantic-ui-react";

import { fetchApollo } from "../../../graphql/apolloClient";

import Layout from "../../../components/Front/Layout";
import H1 from "../../../components/Core/Title/Front/H1";

import img from "../../../ui/assets/img/accueil.png";

const Picture = styled.img`
  display: block;
  height: auto;
  width: 100%;
  max-width: 100%;
`;

const BannerLink = styled.a`
  text-align: center;
  display: block;
`;

const BannerImg = styled.img`
  max-height: 250px;
`;

class Accueil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parametres: null
    };
  }

  componentDidMount() {
    this.getPubliciteParametre();
  }

  getPubliciteParametre = () => {
    const GET_PARAMETRE = gql`
      {
        getParametres {
          iPKParametre
          sPubTexte
          sPubImgPath
          bPubInactif
          sPubType
          sPubLien
        }
      }
    `;
    fetchApollo(GET_PARAMETRE).then(response => {
      const parametres = response.data.getParametres;
      if (parametres) {
        this.setState({ parametres });
      }
    });
  };

  render = () => {
    const { parametres } = this.state;

    return (
      <Layout
        mainPane={
          <Grid stackable container doubling>
            {parametres && Number(parametres.bPubInactif) !== 1 && (
              <Grid.Row columns={1}>
                <Grid.Column>
                  {parametres.sPubType === "txt" ? (
                    <a target="_blank" rel="noopener noreferrer" href={parametres.sPubLien}>
                      {parametres.sPubTexte}
                    </a>
                  ) : (
                    <BannerLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={parametres.sPubLien}
                    >
                      <BannerImg
                        style
                        src={`${process.env.REACT_APP_GRAPHQL_URL}/media/${parametres.sPubImgPath}`}
                        alt="banniere pub"
                      />
                    </BannerLink>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row columns={1}>
              <Grid.Column>
                <H1>Commande en ligne de matériel médical</H1>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <Picture src={img} alt="" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
    );
  };
}

export default Accueil;
