import React, { Component } from "react";
import { gql } from "apollo-boost";
import { Form, Radio, Loader } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import BtnSubmit from "../../../Core/Btn/Submit";

class FormPublicite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iPKParametre: null,
      file: null,
      sPubTexte: "",
      sPubImgPath: "",
      sPubLien: "",
      sPubType: "txt",
      bPubInactif: false,
      disabled: true,
    };
  }

  componentDidMount() {
    this.getPubliciteParametre();
  }

  getPubliciteParametre = () => {
    const GET_PARAMETRE = gql`
      {
        getParametres {
          iPKParametre
          sPubTexte
          sPubImgPath
          bPubInactif
          sPubType
          sPubLien
        }
      }
    `;
    fetchApollo(GET_PARAMETRE).then((response) => {
      const parametres = response.data.getParametres;
      if (parametres) {
        this.setState({
          iPKParametre: parametres.iPKParametre,
          sPubTexte: parametres.sPubTexte,
          sPubImgPath: parametres.sPubImgPath,
          bPubInactif: parseInt(parametres.bPubInactif, 10) === 1,
          sPubType: parametres.sPubType,
          sPubLien: parametres.sPubLien,
        });
      }
    });
  };

  editPubliciteParametre = () => {
    this.setState(
      {
        loading: true,
        disabled: true,
      },
      () => {
        const EDIT_PARAMETRE = gql`
      mutation {
        editPubliciteParametre (publicite: ${JSON.stringify(JSON.stringify(this.state))}) {
          iPKParametre
          sPubTexte
          sPubImgPath
          sPubLien
          bPubInactif
        }
      }
    `;
        fetchApollo(EDIT_PARAMETRE).then((response) => {
          const publicite = response.data.editPubliciteParametre;
          if (publicite) {
            this.setState({
              iPKParametre: publicite.iPKParametre,
              sPubTexte: publicite.sPubTexte,
              sPubImgPath: publicite.sPubImgPath,
              sPubLien: publicite.sPubLien,
              bPubInactif: parseInt(publicite.bPubInactif, 10) === 1,
            });
          }
          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 250);
        });
      }
    );
  };

  handleChange = (evt, data) => {
    const files = evt.target.files;
    const current = this;
    if (files) {
      const success = (content) => {
        current.setState({
          file: {
            name: files[0].name,
            size: files[0].size,
            content,
            type: files[0].type,
          },
          disabled: false,
        });
      };
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        success(e.target.result);
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      this.setState({
        disabled: false,
        [data.name]: data.value,
      });
    }
  };

  handleCheck = () => {
    const { bPubInactif } = this.state;
    this.setState({
      disabled: false,
      bPubInactif: !bPubInactif,
    });
  };

  handleSubmit = () => {
    this.editPubliciteParametre();
  };

  render() {
    const {
      sPubTexte,
      file,
      sPubImgPath,
      sPubLien,
      bPubInactif,
      disabled,
      sPubType,
      loading,
    } = this.state;
    return (
      <>
        <Form style={{ width: "50%" }} onSubmit={this.handleSubmit}>
          <Form.Field>
            <Radio
              label="Texte"
              style={{ marginRight: 10 }}
              name="sPubType"
              value="txt"
              checked={sPubType === "txt"}
              onChange={this.handleChange}
            />{" "}
            <Radio
              label="Image"
              name="sPubType"
              value="img"
              checked={sPubType === "img"}
              onChange={this.handleChange}
            />
          </Form.Field>
          {sPubType === "txt" && (
            <Form.Field>
              <Form.TextArea
                name="sPubTexte"
                value={sPubTexte}
                label="Texte de la publicité"
                placeholder="Texte de la publicité"
                onChange={this.handleChange}
                rows={1}
              />
            </Form.Field>
          )}
          {sPubType === "img" && (
            <>
              <Form.Field>
                <Form.Input
                  name="file"
                  type="file"
                  label="Image de la publicité"
                  onChange={this.handleChange}
                />
              </Form.Field>
              {file && !sPubImgPath && <img alt={file.name} src={file.content} />}
              {sPubImgPath && (
                <img
                  alt={sPubImgPath}
                  src={`${process.env.REACT_APP_GRAPHQL_URL}/media/${sPubImgPath}`}
                />
              )}
            </>
          )}
          <Form.Field>
            <Form.Input
              name="sPubLien"
              value={sPubLien}
              label="Lien de la publicité"
              placeholder="Lien de la publicité"
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Checkbox
            name="bInactif"
            label="Affichage"
            checked={!bPubInactif}
            onChange={this.handleCheck}
          />
          <BtnSubmit disabled={disabled} onClick={this.handleSubmit}>
            Enregistrer
          </BtnSubmit>
          <Loader active={loading} inline size="medium" />
        </Form>
      </>
    );
  }
}

export default FormPublicite;
