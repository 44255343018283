import React, { Component } from "react";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";

import FilteredTable from "../../../../components/Core/FilteredTable";
import LayoutClient from "../../../../components/AdminClient/Layout";
import CommandeShow from "../../../../components/Admin/Commande/show";

import { fetchApollo } from "../../../../graphql/apolloClient";

class Commande extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commandes: [],
      selectedCommande: null
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this.getCommandesByGroup(user.iFKGroupe);
  }

  getCommandesByGroup = iFKGroupe => {
    const GET_COMMANDES = gql`
      {
        listCommandeByGroup(iFKGroupe: ${iFKGroupe}) {
          iPKCommande
          iEtat
          dInsert
          dLivraison
          sConsigne
          bPriorite
          files {
            name
            path
          }
          utilisateur {
            sNom
            sPrenom
            sEmail
            sLibelleGroupe
          }
        }
      }
    `;
    fetchApollo(GET_COMMANDES).then(response => {
      const commandes = response.data.listCommandeByGroup;
      if (commandes) {
        this.setState({
          commandes
        });
      }
    });
  };

  selectCommande = data => {
    this.setState({
      selectedCommande: data,
      rightPanel: {
        width: 800,
        renderHtml: <CommandeShow commande={data} />
      }
    });
  };

  render() {
    const { rightPanel, commandes, selectedCommande } = this.state;
    return (
      <LayoutClient title="Gestion des commandes" rightPanel={rightPanel}>
        <FilteredTable
          type="commande"
          fields={{
            iPKCommande: "N° de commande",
            dInsert: "Date de création",
            dLivraison: "Date de livraison souhaitée",
            bPriorite: "Urgent",
            "utilisateur.sNom": "Contact",
            "utilisateur.sLibelleGroupe": "Groupe",
            iEtat: "Etat"
          }}
          items={commandes}
          selectedItem={selectedCommande}
          selectItem={this.selectCommande}
        />
      </LayoutClient>
    );
  }
}

Commande.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  commandes: state.commande.liste,
  user: state.user.data,
  error: state.client.error
});

export default connect(mapStateToProps)(Commande);
