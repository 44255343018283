import React from "react";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Icon, Loader, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { setVignetteImg } from "../../../../utils";
import { fetchApollo } from "../../../../graphql/apolloClient";

import Layout from "../../../../components/Front/Layout";

import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbRightLink,
} from "../../../../components/Core/Breadcrumb";
import ListeFamilles from "../../../../components/Front/Catalogue/Famille/ListeFamilles";
import Grille from "../../../../components/Front/Grille";
import placeholder from "../../../../ui/assets/img/placeholder.svg";
import favoriteSrc from "../../../../ui/assets/img/favorite.svg";

const Product = styled(Link)`
  position: relative;
  display: block;
  padding: 15px;
  background-color: ${(props) => props.theme.background.paper};
  border: 1px solid ${(props) => props.theme.border.light};
  border-radius: 4px;

  &:hover {
    box-shadow: 0 0 20px ${(props) => props.theme.background.dark};
  }

  &:hover .action {
    background: ${(props) => props.theme.button.primary.hoverBackground};
  }
`;

const Title = styled.span`
  display: block;
  padding: 0 0 15px;
  font-size: 16px;
  font-weight: bold;
  height: 55px;
  color: ${(props) => props.theme.text.primary};
`;

const Vignette = styled.img`
  display: block;
  max-width: 100%;
  height: 150px;
  object-fit: contain;
  border: none;
  width: ${(props) => (props.placeholder ? "50%" : "100%")};
  margin-left: ${(props) => (props.placeholder ? "25%" : "0")};
  margin-right: ${(props) => (props.placeholder ? "25%" : "0")};
`;

const Action = styled.span`
  display: block;
  margin: 15px -15px -15px;
  padding: 15px 0;
  text-align: center;
  color: ${(props) => props.theme.button.primary.text};
  background: ${(props) => props.theme.button.primary.background};
  transition: all 0.25s ease;
`;

const FavoriteIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  background-image: url("${favoriteSrc}");
  background-size: 40px 40px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

const Bandeau = styled.img`
  margin-top: 30px;
  width: 100%;
  height: 250px;
  object-fit: contain;
  background: ${(props) => props.theme.background.paper};
`;

class Famille extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      famille: {},
      tGrille: [],
      tSousFamille: [],
      tSousFamilleOrigin: [],
      tProduit: [],
      tProduitOrigin: [],
      sSearch: "",
      loading: true,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    window.scrollTo(0, 0);
    this.getFamillesCatalogue(decodeURIComponent(params.famille));
    this.getSousFamillesCatalogue(decodeURIComponent(params.famille));
    this.listProduitFamille(decodeURIComponent(params.famille));
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;
    if (prevProps.match.params !== params) {
      this.getFamillesCatalogue(decodeURIComponent(params.famille));
      this.getSousFamillesCatalogue(decodeURIComponent(params.famille));
      this.listProduitFamille(decodeURIComponent(params.famille));
    }
  }

  listProduitFamille = (sLibelle) => {
    const { user } = this.props;
    const GET_FAMILLE_CATALOGUE = gql`
      {
        listProduitFamille(sLibelle: "${sLibelle}", iFKCatalogue: ${user.iFKCatalogue}) {
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          sCodeNatureProduit
          sImgPath
          bInactif
          iFKCatFamille
          iQuantite
          tDocument {
            iPKCatCatalogueProduitDocument
            sLibelle
            sURL
            iFKDocument
            iFKCatCatalogueProduit
            bInactif
          }
        }
      }
    `;
    fetchApollo(GET_FAMILLE_CATALOGUE).then((response) => {
      const tProduit = response.data.listProduitFamille;

      if (tProduit) {
        const tProduitOrigin = tProduit.sort((a, b) => {
          if (a.sLibelle.toLowerCase() < b.sLibelle.toLowerCase()) {
            return -1;
          }
          if (a.sLibelle.toLowerCase() > b.sLibelle.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        this.setState({
          tProduitOrigin,
          tProduit: tProduitOrigin,
          loading: false,
        });
      }
    });
  };

  getGrille = (sPKGrille) => {
    const GET_GRILLE = gql`
    {
      getGrille(sPKGrille: "${sPKGrille}") {
        iPKGrilleDetail
        sFKGrille
        sTheme
        sItem
        sReponse
        iScore
      }
    }
  `;
    fetchApollo(GET_GRILLE).then((response) => {
      const tGrille = response.data.getGrille;
      if (tGrille) {
        this.setState({
          tGrille,
        });
      }
    });
  };

  getFamillesCatalogue = (sLibelle) => {
    const { user } = this.props;
    const GET_FAMILLE_CATALOGUE = gql`
      {
        getFamilleCatalogue(sLibelle: "${sLibelle}", iFKCatalogue: ${user.iFKCatalogue}) {
          iPKCatFamille
          sPKFamille
          sLibelle
          sInformation
          sImgPath
          bInactif
          sPKGrille
          sAideGrille
          sLibelleGrille
        }
      }
    `;
    fetchApollo(GET_FAMILLE_CATALOGUE).then((response) => {
      const famille = response.data.getFamilleCatalogue;

      if (famille) {
        this.setState({ famille });
        this.getGrille(famille.sPKGrille);
      }
    });
  };

  getSousFamillesCatalogue = (sLibelle) => {
    const { user } = this.props;
    const GET_FAMILLE_CATALOGUE = gql`
      {
        getSousFamilleCatalogue(sLibelle: "${sLibelle}", iFKCatalogue: ${user.iFKCatalogue}) {
          iPKCatFamille
          sPKFamille
          sLibelle
          sInformation
          sImgPath
          bInactif
          sPKGrille
          sAideGrille
          sLibelleGrille
        }
      }
    `;
    fetchApollo(GET_FAMILLE_CATALOGUE).then((response) => {
      const tFamillesCatalogue = response.data.getSousFamilleCatalogue;

      if (tFamillesCatalogue) {
        const tSousFamilleOrigin = tFamillesCatalogue
          .filter((f) => !f.bInactif)
          .sort((a, b) => {
            if (a.sLibelle.toLowerCase() < b.sLibelle.toLowerCase()) {
              return -1;
            }
            if (a.sLibelle.toLowerCase() > b.sLibelle.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        this.setState({
          tSousFamilleOrigin,
          tSousFamille: tSousFamilleOrigin,
        });
      }
    });
  };

  searchChange = (evt, data) => {
    const { tProduitOrigin, tSousFamilleOrigin } = this.state;
    this.setState({
      sSearch: data.value,
      tProduit: data.value
        ? tProduitOrigin.filter((p) => p.sLibelle.toLowerCase().indexOf(data.value) !== -1)
        : tProduitOrigin,
      tSousFamille: data.value
        ? tSousFamilleOrigin.filter((sf) => sf.sLibelle.toLowerCase().indexOf(data.value) !== -1)
        : tSousFamilleOrigin,
    });
  };

  render() {
    const {
      match: { params },
      tFavoris,
    } = this.props;
    const { loading, famille, sSearch, tSousFamille, tProduit, tGrille } = this.state;

    console.log(tProduit);
    return (
      <Layout
        // sideBarLeft={<SideBarFilters />}
        // sideBarRight={<SideBarPreferences />}
        mainPane={
          <Grid stackable container doubling>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Breadcrumb>
                  <BreadcrumbLink to="/">
                    <Icon name="home" />
                  </BreadcrumbLink>
                  <BreadcrumbLink to="/catalogue">Catalogue</BreadcrumbLink>
                  {decodeURIComponent(params.famille)}
                  <BreadcrumbRightLink to="/favoris">
                    <Icon name="star" /> Mes favoris
                  </BreadcrumbRightLink>
                </Breadcrumb>
                {famille.sImgPath && (
                  <Bandeau src={`${process.env.REACT_APP_GRAPHQL_URL}/media/${famille.sImgPath}`} />
                )}
                <div style={{ marginTop: 20 }}>
                  <h1 style={{ float: "left", marginRight: 20 }}>
                    {decodeURIComponent(params.famille)}
                  </h1>
                  <Input
                    style={{ float: "left" }}
                    onChange={this.searchChange}
                    value={sSearch}
                    icon={{ name: "search", circular: true, link: true }}
                    placeholder="Recherche..."
                  />
                  <div style={{ clear: "both" }} />
                </div>
              </Grid.Column>
            </Grid.Row>

            {Object.keys(tGrille).length > 0 && (
              <Grid.Row>
                <Grille sMessage={famille.sAideGrille} tGrille={tGrille} />
              </Grid.Row>
            )}

            <ListeFamilles sLibelleFamille={params.famille} tSousFamille={tSousFamille} />
            <Grid.Row columns={4}>
              {loading && <Loader active />}
              {tProduit.map((produit) => {
                console.log(produit);
                return (
                  <Grid.Column style={{ marginBottom: 30 }}>
                    <Product
                      to={`/catalogue/${params.famille}/produit/${encodeURIComponent(
                        produit.sLibelle
                      )}`}
                    >
                      {tFavoris.filter(
                        (f) => f.iFKProduit === parseInt(produit.iPKProduit, 10)
                      )[0] && <FavoriteIcon />}
                      <Title>{produit.sLibelle}</Title>
                      <figure>
                        <Loader
                          active={produit.tDocument?.length !== 0 && !produit.sImgPath}
                          id={`loader-${produit.iPKProduit}`}
                        />
                        <Vignette
                          placeholder={!produit.sImgPath && produit.tDocument?.length === 0}
                          id={`vignette-${produit.iPKProduit}`}
                          src={setVignetteImg(produit)}
                          alt=""
                        />
                        {(produit.sImgPath || produit.tDocument?.length > 0) && (
                          <figcaption
                            style={{
                              color: "black",
                              textAlign: "center",
                              fontStyle: "italic",
                              fontSize: 12,
                            }}
                          >
                            Photo non contractuelle
                          </figcaption>
                        )}
                      </figure>
                      <Action className="action">Voir la fiche produit</Action>
                    </Product>
                  </Grid.Column>
                );
              })}
            </Grid.Row>
          </Grid>
        }
      />
    );
  }
}

Famille.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  tFavoris: PropTypes.arrayOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  tFavoris: state.favoris.liste,
  user: state.user.data,
});

export default connect(mapStateToProps)(Famille);
