import React, { Component } from "react";
import { gql } from "apollo-boost";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Form } from "semantic-ui-react";

import { fetchApollo } from "../../../graphql/apolloClient";

import BtnSubmit from "../../Core/Btn/Submit";

const FormContainer = styled.div`
  margin-top: 12px;
`;

class FormDevis extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      sCommentaire: "",
      iFKUtilisateur: user.iPKUtilisateur,
      disabled: true
    };
  }

  handleChange = (evt, data) => {
    this.setState({ [data.id]: data.value, disabled: false });
  };

  handleSubmit = e => {
    const { addDevisToList } = this.props;
    e.stopPropagation();
    e.preventDefault();
    const addDevis = gql`mutation
      {
        addDevis (devis: ${JSON.stringify(JSON.stringify(this.state))}) {
          iPKDevis
          sCommentaire
          sPatient
          sGroupe
          dInsert
          iEtat
        }
      }
    `;
    fetchApollo(addDevis).then(response => {
      const devis = response.data.addDevis;
      if (devis) {
        this.setState(
          {
            sCommentaire: "",
            disabled: true
          },
          () => {
            addDevisToList(devis);
          }
        );
      }
    });
  };

  render() {
    const { sCommentaire, disabled } = this.state;
    return (
      <FormContainer>
        <Form>
          <Form.Field>
            <Form.TextArea
              id="sCommentaire"
              label="Description du besoin"
              value={sCommentaire}
              placeholder="Description du besoin"
              onChange={this.handleChange}
            />
          </Form.Field>
          <BtnSubmit onClick={this.handleSubmit} disabled={disabled} primary size="small">
            Envoyer
          </BtnSubmit>
        </Form>
      </FormContainer>
    );
  }
}

FormDevis.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  addDevisToList: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(FormDevis);
