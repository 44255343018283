import {
  GET_FAVORIS_REQUEST,
  ADD_FAVORIS_REQUEST,
  DELETE_FAVORIS_REQUEST
} from "../constants/ActionTypes";

export const getFavoris = () => ({
  type: GET_FAVORIS_REQUEST,
  payload: {}
});

export const addFavoris = (iFKProduit, iFKUtilisateur) => ({
  type: ADD_FAVORIS_REQUEST,
  payload: { iFKProduit, iFKUtilisateur }
});

export const deleteFavoris = (iFKProduit, iFKUtilisateur) => ({
  type: DELETE_FAVORIS_REQUEST,
  payload: { iFKProduit, iFKUtilisateur }
});
