import React, { Component } from "react";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Form, Segment, Loader, Confirm, Message, Icon, Modal } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import LayoutAdmin from "../../../../components/Admin/Layout";
import FormFamille from "../../../../components/Admin/Famille/Form";
import FormProduit from "../../../../components/Admin/Produit/Form";
import FamilyFormList from "../../../../components/Admin/Catalogue/FamilyFormList";

import BtnSubmit from "../../../../components/Core/Btn/Submit";

import { editCatalogue } from "../../../../redux/actions/catalogue";

import "react-sortable-tree/style.css";
import CatalogueImport from "./CatalogueImport";

const ArianeLink = styled(Link)`
  color: ${props => props.theme.colors.title.link} !important;
  &:hover {
    color: ${props => props.theme.colors.title.link} !important;
    text-decoration: underline !important;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primary.main};
  font-weight: bold;
  text-transform: uppercase;
  margin: 26px 0;
`;

const CatalogueSubmit = styled(BtnSubmit)`
  margin-top: 22px;
`;

const ContentFilter = styled.div`
  z-index: 10;
  position: sticky;
  top: 0;
  vertical-align: middle;
  line-height: 60px;
  margin: 0 -20px;
  padding: 0 20px;
  padding-top: 1px;
  background: ${props => props.theme.background.light};
`;

const FilterGroup = styled(Form.Group)`
  margin-bottom: 0 !important;
`;

class CatalogueShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rightPanel: null,
      currentItem: null,
      bConso: true,
      bMat: true,
      formData: {
        sLibelle: "",
        iFKClient: null
      },
      itemsCheck: {},
      showConfirmDelete: false,
      catalogue: {},
      errors: {},
      tClient: [],
      sKeywordSearch: "",
      basetFamillesCatalogue: [],
      tFamillesCatalogue: null,
      disabled: true,
      message: null,
      openImport: false,
      catalogueError: null
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getCatalogue(parseInt(params.iPKCatalogue, 10));
    this.getClientsWithoutCatalogue();
    this.loadCatalogue();
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      importSuccess
    } = this.props;
    if (params !== prevProps.match.params) {
      this.getCatalogue(parseInt(params.iPKCatalogue, 10));
      this.getClientsWithoutCatalogue();
      this.loadCatalogue();
    }
    if (importSuccess !== prevProps.importSuccess) {
      this.getCatalogue(parseInt(params.iPKCatalogue, 10));
      this.getClientsWithoutCatalogue();
      this.loadCatalogue();
      this.setState({ openImport: false });
    }
  }

  loadCatalogue = (closePanel = false) => {
    const {
      match: { params }
    } = this.props;
    this.getFamillesCatalogue(parseInt(params.iPKCatalogue, 10));
    if (closePanel) {
      this.setState({
        rightPanel: "close"
      });
    }
  };

  getCatalogue = iPKCatalogue => {
    const GET_CATALOGUE = gql`{
      getCatalogue(iPKCatalogue: ${iPKCatalogue}) {
        iPKCatalogue
        sLibelle
        iFKClient
        sLibelleClient
      }
    }`;

    fetchApollo(GET_CATALOGUE).then(response => {
      const catalogue = response.data.getCatalogue;
      if (catalogue) {
        this.setState({
          formData: {
            sLibelle: catalogue.sLibelle || "",
            iPKClient: catalogue.iFKClient || null
          },
          catalogue
        });
      }
    });
  };

  getFamillesCatalogue = iPKCatalogue => {
    const GET_FAMILLE_CATALOGUE = gql`
      {
      listFamilleCatalogue(iPKCatalogue: ${iPKCatalogue}) {
        iPKCatFamille
        sPKFamille
        sLibelle
        sInformation
        sImgPath
        bInactif
        sPKGrille
        sAideGrille
        sLibelleGrille
        bAssocie
        tSousFamilleCatalogue {
          iPKCatFamille
          sPKSousFamille
          sLibelle
          sInformation
          sImgPath
          bInactif
          sPKGrille
          sAideGrille
          sLibelleGrille
          bAssocie
          tProduit {
            iPKProduit
            sLibelle
            sRefProduit
            sInformation
            sCodeNatureProduit
            sImgPath
            bInactif
            bAssocie
            iFKCatFamille
            sFields
            iPKCommandeLigne
            iPKCommandeTypeLigne
            iPKMatPanier
            tDocument {
              iPKCatCatalogueProduitDocument
              sLibelle
              sURL
              iFKDocument
              iFKCatCatalogueProduit
              bInactif
            }
          }
        }
        tProduit {
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          sCodeNatureProduit
          sImgPath
          bInactif
          bAssocie
          iFKCatFamille
          sFields
          iPKCommandeLigne
          iPKCommandeTypeLigne
          iPKMatPanier
          tDocument {
            iPKCatCatalogueProduitDocument
            sLibelle
            sURL
            iFKDocument
            iFKCatCatalogueProduit
            bInactif
          }
        }
      }
    }`;
    fetchApollo(GET_FAMILLE_CATALOGUE).then(response => {
      const tFamillesCatalogue = response.data.listFamilleCatalogue;
      const newFamillesCatalogue = [];

      if (tFamillesCatalogue) {
        const { bConso, bMat, sKeywordSearch } = this.state;
        tFamillesCatalogue.forEach(f => {
          let newSFProduits = [];
          const newSFFamillesCatalogue = [];
          if (f.tSousFamilleCatalogue.length > 0) {
            f.tSousFamilleCatalogue.forEach(sf => {
              newSFProduits = sf.tProduit.filter(
                sp =>
                  (bMat && sp.sCodeNatureProduit === "M") ||
                  (bConso && sp.sCodeNatureProduit === "C")
              );
              if (newSFProduits.length > 0) {
                newSFFamillesCatalogue.push({ ...sf, tProduit: newSFProduits });
              }
            });
          }
          const newProduits = f.tProduit.filter(
            p => (bMat && p.sCodeNatureProduit === "M") || (bConso && p.sCodeNatureProduit === "C")
          );
          if (newProduits.length > 0 || newSFProduits.length > 0) {
            newFamillesCatalogue.push({
              ...f,
              tProduit: newProduits,
              tSousFamilleCatalogue: newSFFamillesCatalogue
            });
          }
        });
        if (sKeywordSearch) {
          this.setState(
            {
              basetFamillesCatalogue: tFamillesCatalogue
            },
            () => {
              this.handleSearchFilterChange({}, { id: "sKeywordSearch", value: sKeywordSearch });
            }
          );
        } else {
          this.setState({
            tFamillesCatalogue: newFamillesCatalogue.sort((a, b) => {
              const sLibelleA = a.sLibelle.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              const sLibelleB = b.sLibelle.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              if (sLibelleA < sLibelleB) return -1;
              if (sLibelleA > sLibelleB) return 1;
              return 0;
            }),
            basetFamillesCatalogue: tFamillesCatalogue.sort((a, b) => {
              const sLibelleA = a.sLibelle.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              const sLibelleB = b.sLibelle.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              if (sLibelleA < sLibelleB) return -1;
              if (sLibelleA > sLibelleB) return 1;
              return 0;
            })
          });
        }
      }
    });
  };

  getClientsWithoutCatalogue = () => {
    const GET_CATALOGUES = gql`
      {
        listClientWithoutCatalogue {
          iPKClient
          sLibelle
          iFKCatalogue
          sLibelleCatalogue
          iNbGroupe
        }
      }
    `;
    fetchApollo(GET_CATALOGUES).then(response => {
      const tClient = response.data.listClientWithoutCatalogue;
      if (tClient) {
        this.setState({
          tClient
        });
      }
    });
  };

  handleChange = (evt, data) => {
    const { formData } = this.state;
    this.setState({
      formData: { ...formData, [data.id]: data.value },
      disabled: false
    });
  };

  handleFilterChange = (evt, data) => {
    const { basetFamillesCatalogue } = this.state;
    this.setState(
      {
        [data.id]: data.checked
      },
      () => {
        const { bConso, bMat } = this.state;
        const newFamillesCatalogue = [];
        basetFamillesCatalogue.forEach(f => {
          let newSFProduits = [];
          const newSFFamillesCatalogue = [];
          if (f.tSousFamilleCatalogue.length > 0) {
            f.tSousFamilleCatalogue.forEach(sf => {
              newSFProduits = sf.tProduit.filter(
                sp =>
                  (bMat && sp.sCodeNatureProduit === "M") ||
                  (bConso && sp.sCodeNatureProduit === "C")
              );
              if (newSFProduits.length > 0) {
                newSFFamillesCatalogue.push({ ...sf, tProduit: newSFProduits });
              }
            });
          }
          const newProduits = f.tProduit.filter(
            p => (bMat && p.sCodeNatureProduit === "M") || (bConso && p.sCodeNatureProduit === "C")
          );
          if (newProduits.length > 0 || newSFProduits.length > 0) {
            newFamillesCatalogue.push({
              ...f,
              tProduit: newProduits,
              tSousFamilleCatalogue: newSFFamillesCatalogue
            });
          }
        });
        this.setState({ tFamillesCatalogue: newFamillesCatalogue });
      }
    );
  };

  handleSearchFilterChange = (evt, data) => {
    const { basetFamillesCatalogue } = this.state;
    this.setState(
      {
        [data.id]: data.value
      },
      () => {
        const { sKeywordSearch } = this.state;
        const newFamillesCatalogue = [];
        basetFamillesCatalogue.forEach(f => {
          let newSFProduits = [];
          const newSFFamillesCatalogue = [];
          if (f.tSousFamilleCatalogue.length > 0) {
            f.tSousFamilleCatalogue.forEach(sf => {
              newSFProduits =
                sf.tProduit &&
                (sf.tProduit.filter(sp => sp.sLibelle.includes(sKeywordSearch)) ||
                  sf.tProduit.filter(sp => sp.sLibelle.toLowerCase().includes(sKeywordSearch)) ||
                  sf.tProduit.filter(sp =>
                    sp.sLibelle
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .includes(sKeywordSearch)
                  ) ||
                  sf.tProduit.filter(sp =>
                    sp.sLibelle
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .includes(sKeywordSearch)
                  ));
              if (newSFProduits && newSFProduits.length > 0) {
                newSFFamillesCatalogue.push({ ...sf, tProduit: newSFProduits });
              } else if (
                sf.sLibelle.includes(sKeywordSearch) ||
                sf.sLibelle
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(sKeywordSearch) ||
                sf.sLibelle.toLowerCase().includes(sKeywordSearch) ||
                sf.sLibelle
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(sKeywordSearch)
              ) {
                newSFFamillesCatalogue.push(sf);
              }
            });
          }
          const newProduits = f.tProduit.filter(
            p =>
              p.sLibelle.includes(sKeywordSearch) ||
              p.sLibelle
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(sKeywordSearch) ||
              p.sLibelle.toLowerCase().includes(sKeywordSearch) ||
              p.sLibelle
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(sKeywordSearch)
          );
          if (newProduits.length > 0) {
            newFamillesCatalogue.push({
              ...f,
              tProduit: newProduits,
              tSousFamilleCatalogue: newSFFamillesCatalogue
            });
          } else if (
            f.sLibelle.includes(sKeywordSearch) ||
            f.sLibelle
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(sKeywordSearch) ||
            f.sLibelle.toLowerCase().includes(sKeywordSearch) ||
            f.sLibelle
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(sKeywordSearch)
          ) {
            newFamillesCatalogue.push(f);
          } else if (newSFFamillesCatalogue.length > 0) {
            newFamillesCatalogue.push({
              ...f,
              tSousFamilleCatalogue: newSFFamillesCatalogue
            });
          }
        });

        this.setState({ tFamillesCatalogue: newFamillesCatalogue });
      }
    );
  };

  handleSubmitFormCatalogue = evt => {
    evt.stopPropagation();
    const { dispatch } = this.props;
    const { formData, catalogue } = this.state;
    const errors = {};
    if (!formData.sLibelle) {
      errors.sLibelle = "Champ obligatoire";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      dispatch(editCatalogue({ ...formData, iPKCatalogue: catalogue.iPKCatalogue }));
      this.setState({ disabled: true, message: "Catalogue enregistré" });
      setTimeout(() => {
        this.setState({ message: null });
      }, 3000);
    }
  };

  selectItem = item => {
    const { catalogue } = this.state;
    if (item.sRefProduit) {
      this.setState(
        {
          rightPanel: {
            width: 450,
            renderHtml: (
              <FormProduit
                produit={item}
                iPKCatalogue={parseInt(catalogue.iPKCatalogue, 10)}
                reloadList={this.loadCatalogue}
              />
            )
          },
          currentItem: item
        },
        () => this.loadCatalogue()
      );
    } else {
      this.setState(
        {
          rightPanel: {
            width: 450,
            renderHtml: (
              <FormFamille
                famille={item}
                iPKCatalogue={catalogue.iPKCatalogue}
                reloadList={this.loadCatalogue}
              />
            )
          },
          currentItem: item
        },
        () => this.loadCatalogue()
      );
    }
  };

  receiveItemsCheck = itemsCheck => {
    this.setState({ itemsCheck });
  };

  handleDeleteSelection = () => {
    const { itemsCheck } = this.state;
    const REMOVE_SELECTION = gql`mutation {
      deleteSelection(items: ${JSON.stringify(JSON.stringify(itemsCheck))}) {
        iPKCatalogue
        sMessage
      }
    }`;

    fetchApollo(REMOVE_SELECTION).then(
      ({
        data: {
          deleteSelection: { sMessage }
        }
      }) => {
        if (sMessage) {
          this.setState({ catalogueError: sMessage }, () => {
            setTimeout(() => {
              this.setState({ catalogueError: null });
            }, 3000);
          });
        }
        this.loadCatalogue();
      }
    );
  };

  render() {
    const {
      catalogue,
      tFamillesCatalogue,
      sKeywordSearch,
      tClient,
      rightPanel,
      currentItem,
      formData,
      errors,
      bConso,
      bMat,
      itemsCheck,
      showConfirmDelete,
      disabled,
      message,
      openImport,
      catalogueError
    } = this.state;
    const link = <ArianeLink to="/catalogues">Gestion des catalogues</ArianeLink>;
    const options = [];
    options.push({
      key: parseInt(catalogue.iFKClient, 10),
      text: catalogue.sLibelleClient,
      value: parseInt(catalogue.iFKClient, 10)
    });
    tClient.forEach(c => {
      options.push({
        key: parseInt(c.iPKClient, 10),
        text: c.sLibelle,
        value: parseInt(c.iPKClient, 10)
      });
    });

    return (
      <LayoutAdmin linkTitle={link} title={catalogue.sLibelle} rightPanel={rightPanel}>
        <>
          <Form onSubmit={this.handleSubmitFormCatalogue}>
            <Form.Group>
              <Form.Input
                id="sLibelle"
                label="Nom"
                value={formData.sLibelle}
                placeholder="Nom"
                onChange={this.handleChange}
                style={{ minWidth: 130 }}
                error={errors.sLibelle ? true : false}
                width={3}
              />
              <Form.Select
                id="iPKClient"
                label="Client"
                clearable
                value={formData.iPKClient}
                options={options}
                placeholder="Client"
                style={{ zIndex: 999, minWidth: 110 }}
                onChange={this.handleChange}
                width={3}
              />
              <CatalogueSubmit
                onClick={this.handleSubmitFormCatalogue}
                disabled={disabled}
                type="submit"
              >
                Enregistrer
              </CatalogueSubmit>
            </Form.Group>
            {message && (
              <Message style={{ width: "50%" }} fluid positive>
                {message}
              </Message>
            )}
          </Form>
          <Title>Gestion des familles et produits</Title>
          <BtnSubmit onClick={() => this.setState({ openImport: true })} primary size="small">
            Lister les familles disponible pour l'import
          </BtnSubmit>
          <ContentFilter>
            <Segment
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginTop: 12,
                display: "flex",
                minWidth: 650
              }}
              compact
            >
              <Form>
                <FilterGroup inline>
                  <label>Type de produit :</label>
                  <Form.Checkbox
                    label={
                      <label>
                        Consommable <Icon name="file" />
                      </label>
                    }
                    id="bConso"
                    icon="file"
                    iconPosition="right"
                    checked={bConso}
                    onChange={this.handleFilterChange}
                  />
                  <Form.Checkbox
                    label={
                      <label>
                        Matériel <Icon name="laptop" />
                      </label>
                    }
                    id="bMat"
                    checked={bMat}
                    onChange={this.handleFilterChange}
                  />
                  <Form.Input
                    id="sKeywordSearch"
                    type="text"
                    icon="search"
                    iconPosition="left"
                    value={sKeywordSearch}
                    placeholder="Recherche"
                    onChange={this.handleSearchFilterChange}
                  />
                </FilterGroup>
                {Object.keys(itemsCheck).length > 0 && (
                  <BtnSubmit
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({ showConfirmDelete: true });
                    }}
                    primary
                    size="small"
                  >
                    Enregistrer les modifications
                  </BtnSubmit>
                )}
              </Form>
            </Segment>
          </ContentFilter>
          {tFamillesCatalogue ? (
            <FamilyFormList
              famillesCatalogue={tFamillesCatalogue}
              selectItem={this.selectItem}
              currentItem={currentItem}
              sendItemsCheck={this.receiveItemsCheck}
            />
          ) : (
            <Loader style={{ position: "relative", top: 50 }} active />
          )}
          {catalogueError && (
            <Modal closeIcon open>
              <Modal.Content>{catalogueError}</Modal.Content>
            </Modal>
          )}
          {catalogue.iPKCatalogue && (
            <CatalogueImport open={openImport}  onClose={() => this.setState({ openImport: false })} iPKCatalogue={catalogue.iPKCatalogue} />
          )}
          <Confirm
            open={showConfirmDelete}
            content="Etes-vous sûr de vouloir supprimer ces produits ?"
            cancelButton="Annuler"
            confirmButton="Confirmer"
            onCancel={() => this.setState({ showConfirmDelete: false })}
            onConfirm={() =>
              this.setState({ showConfirmDelete: false }, () => this.handleDeleteSelection())
            }
          />
        </>
      </LayoutAdmin>
    );
  }
}

CatalogueShow.defaultProps = {
  importSuccess: false
};

CatalogueShow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  importSuccess: PropTypes.bool
};

const mapStateToProps = state => ({
  importSuccess: state.catalogue.success
});

export default connect(mapStateToProps)(CatalogueShow);
