import React, { Component } from "react";
import { gql } from "apollo-boost";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Form, Select } from "semantic-ui-react";

import { fetchApollo } from "../../../graphql/apolloClient";

import BtnSubmit from "../../Core/Btn/Submit";

const FormContainer = styled.div`
  margin-top: 12px;
`;

class FormIntervention extends Component {
  constructor(props) {
    super(props);
    const { materiel } = props;

    this.state = {
      sObjet: "",
      sTypeObjet: "",
      tFKTypeObjet: "",
      sCommentaire: "",
      iFKUtilisateur: props.user.iPKUtilisateur,
      iPKCommandeLigne: materiel.iPKCommandeLigne,
      sBeneficiaire: materiel.sBeneficiaire,
      sNumeroSerie: materiel.sNumeroSerie,
      sContact: materiel.sContact,
      iFKProduit: materiel.iFKProduit,
      iFKClient: materiel.iFKClient,
      sRefProduit: materiel.sRefProduit,
      disabled: true
    };
  }

  componentDidMount() {
    this.getEmailsParametre();
  }

  getEmailsParametre = () => {
    const GET_EMAILS = gql`
      {
        getParametres {
          iPKParametre
          tFKTypeObjetSAV
        }
      }
    `;
    fetchApollo(GET_EMAILS).then(response => {
      const parametres = response.data.getParametres;
      if (parametres) {
        const GET_TYPE_OBJET = gql`
          {
            getTypeObjetIsadom {
              sLibelle
              sPKTypeObjet
            }
          }
        `;
        fetchApollo(GET_TYPE_OBJET).then(resp => {
          const tTypeObjetOption = resp.data.getTypeObjetIsadom;
          if (tTypeObjetOption) {
            this.setState({
              tFKTypeObjet: parametres.tFKTypeObjetSAV.split(",").map(sFKTypeObjetSAV => {
                const [option] = tTypeObjetOption.filter(
                  sTypeOption => sTypeOption.sPKTypeObjet === sFKTypeObjetSAV
                );
                return {
                  key: option.sPKTypeObjet,
                  text: option.sLibelle,
                  value: option.sPKTypeObjet
                };
              })
            });
          }
        });
      }
    });
  };

  handleChange = (evt, data) => {
    this.setState({ [data.id]: data.value, disabled: false });
  };

  handleSubmit = e => {
    const { getMateriels, selectMateriel, materiel } = this.props;
    e.stopPropagation();
    e.preventDefault();
    const addIntervention = gql`mutation
      {
        addIntervention (intervention: ${JSON.stringify(JSON.stringify(this.state))}) {
          iPKIntervention
        }
      }
    `;
    fetchApollo(addIntervention).then(response => {
      const { sObjet, sCommentaire } = this.state;
      const materiels = response.data.addIntervention;
      if (materiels) {
        this.setState(
          {
            sObjet: "",
            sTypeObjet: "",
            sCommentaire: "",
            disabled: true
          },
          () => {
            getMateriels();
            selectMateriel({
              ...materiel,
              bSAV: true,
              sObjetSAV: sObjet,
              sCommentaireSAV: sCommentaire
            });
          }
        );
      }
    });
  };

  render() {
    const { tFKTypeObjet, sTypeObjet, sObjet, sCommentaire, disabled } = this.state;
    const { annule } = this.props;
    return (
      <FormContainer>
        <Form>
          <Form.Field>
            <Select
              style={{ marginBottom: 10 }}
              onChange={this.handleChange}
              placeholder="Sélectionnez la raison de votre demande"
              id="sTypeObjet"
              value={sTypeObjet}
              options={tFKTypeObjet}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              id="sObjet"
              label="Objet"
              value={sObjet}
              placeholder="Objet"
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.TextArea
              id="sCommentaire"
              label="Commentaire"
              value={sCommentaire}
              placeholder="Commentaire"
              onChange={this.handleChange}
            />
          </Form.Field>
          <BtnSubmit onClick={this.handleSubmit} disabled={disabled} primary size="small">
            Envoyer
          </BtnSubmit>
          <BtnSubmit
            onClick={() => {
              annule();
            }}
            primary
            size="small"
          >
            Annuler
          </BtnSubmit>
        </Form>
      </FormContainer>
    );
  }
}

FormIntervention.propTypes = {
  getMateriels: PropTypes.func.isRequired,
  selectMateriel: PropTypes.func.isRequired,
  annule: PropTypes.func.isRequired,
  materiel: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(FormIntervention);
