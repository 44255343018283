import React from "react";
import { Grid } from "semantic-ui-react";

import Layout from "../../../components/Front/Layout";

const CGV = () => {
  window.scrollTo(0, 0);
  return (
    <Layout
      mainPane={
        <Grid stackable container doubling>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h1>Conditions générales de vente de matériel</h1>
            </Grid.Column>
          </Grid.Row>
          {process.env.REACT_APP_THEME === "ARAIR" ? (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  Les conditions générales de vente de matériel de l'outil de commande MAD sont
                  disponibles sur demande à l'adresse{" "}
                  <a
                    href="mailto:mad@arair.fr?subject=Demande d'accès aux conditions générales de vente de matériel"
                    title="Conditions générales de vente de matériel"
                  >
                    mad@arair.fr
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p>
                  Les présentes conditions générales de vente (ci-après les « CGV ») s’appliquent à
                  toutes les ventes de matériel (ci-après le ou les « Matériel(s) ») par la société
                  PHARMA DOM sous son nom de marque ARAIR Assistance. (ci-après « la Société ») à un
                  professionnel (ci-après le « Client »). Toute commande de Matériel implique
                  l’acceptation sans réserve par le Client et son adhésion pleine et entière aux CGV
                  qui prévalent sur tout autre document du Client et notamment sur toutes conditions
                  générales d’achat, sauf accord particulier préalable à la commande convenu par
                  écrit entre les Parties.
                </p>
                <p>
                  Tous autres documents que les présentes CGV communiqués par la Société tels que
                  notamment catalogues, prospectus, publicités n’ont qu’une valeur informative et
                  indicative.
                </p>
                <p>
                  Dans le cas où le Client a signé avec la Société une convention de fourniture de
                  matériel, les dispositions de cette convention prévaudront sur les dispositions
                  des CGV.
                </p>
                <p>
                  Les présentes CGV ne sont applicables que pour les achats effectués par des
                  Clients situés en France métropolitaine et livrés exclusivement sur ce territoire.
                </p>
                <p>
                  Toute clause qui serait déclarée nulle ou invalide par l’application d’une loi,
                  d’un règlement ou à la suite d’une décision définitive d’une juridiction
                  compétente n'emporterait pas la nullité des autres dispositions des présentes CGV.
                </p>
                <p>
                  Le fait pour la Société de ne pas se prévaloir de l’une quelconque des
                  dispositions des CGV ne peut être interprété comme valant renonciation à s’en
                  prévaloir ultérieurement.
                </p>
                <p>
                  La Société se réserve le droit de modifier les CGV à tout moment. Les CGV
                  applicables sont celles en vigueur au moment de la passation de commande.
                  <p>
                    Dans le cas où il y est assujetti, le Client est seul responsable du respect des
                    règles de la commande publique préalablement à toute commande de Matériel.
                  </p>
                  <h3>COMMANDE</h3>
                </p>
                <p>
                  Pour procéder à l’achat de matériel, le Client passe commande au moyen de
                  l’application de commande éditée par la Société.
                </p>
                <p>La Société n’est liée que par son acceptation de la commande du Client.</p>
                <p>
                  La commande émanant du Client est réputée ferme et définitive pour le Client dès
                  réception par la Société. Toute demande d’annulation ou de modification d’une
                  commande passée par le Client ne pourra être prise en compte que si la demande est
                  faite par écrit et sous réserve de l’accord écrit de la Société.
                </p>
                <p>
                  Le Client déclare bien connaître la destination propre du Matériel qu’il achète et
                  qu’il en fera bon usage. L’utilisation des Matériels se fait sous sa
                  responsabilité pleine et entière.
                  <p>
                    La Société se réserve le droit, à tout moment, de retirer certaines références
                    de Matériel ou de remplacer le Matériel par un Matériel équivalent sans que le
                    Client ne puisse prétendre à quelque indemnité que ce soit. En cas
                    d’indisponibilité du Matériel commandé, la Société en informe le Client.
                  </p>
                  <h3>LIVRAISON</h3>
                </p>
                <p>
                  Le transfert de risque est réputé effectué dans les locaux de la Société une fois
                  l’accord sur la chose et le prix entendu selon les termes de l’article 1583 du
                  Code Civil.
                </p>
                <p>
                  Si l’expédition est retardée pour une cause non imputable à la Société, et si
                  cette dernière y consent, le Matériel sera entreposé et manutentionné, s’il y a
                  lieu, aux frais et risques du Client, la Société déclinant toute responsabilité à
                  cet égard. Ces dispositions ne modifient en rien l’obligation de paiement du prix
                  par le Client.
                </p>
                <p>
                  Sauf stipulation expresse de la Société, les délais de livraison ne sont donnés
                  qu’à titre indicatif et ne constituent pas un engagement ferme de livrer à date
                  fixe. Aucune pénalité pour retard de livraison ne peut être réclamée à la Société.
                </p>
                <p>
                  Si la commande se compose de livraisons successives, le défaut d’une livraison ne
                  pourra donner lieu à l’annulation de la commande ni motiver la résiliation de la
                  commande.
                </p>
                <p>
                  Le Client s’engage à transmettre à la Société tous les éléments nécessaires et
                  toute autre information requise pour l’exécution de la vente.
                </p>
                <p>
                  Toute livraison sera accompagnée de la remise au Client d’une fiche
                  d’intervention. A réception, le Client émargera la fiche d’intervention ou en cas
                  d’avarie des Matériels livrés ou de manquants, effectuera les réserves.
                </p>
                <p>
                  Dans le cas où la livraison est faite par la Société, à défaut de réserves au
                  moment de la livraison du Matériel, celui-ci est réputé avoir été réceptionné
                  exempt de tout défaut ou vice apparent et/ ou manquant.
                </p>
                <p>
                  Dans le cas où la livraison est effectuée par un transporteur et conformément aux
                  dispositions de l’article L.133-3 du code de commerce, il appartient au Client
                  d’effectuer toutes les réserves nécessaires auprès du transporteur par lettre
                  recommandée dans les trois jours après la date de réception mentionnée. Une copie
                  du courrier devra être adressée simultanément à la Société. Passé ce délai, et à
                  défaut d’effectuer les formalités précitées, aucune réclamation ne pourra être
                  effectuée à l’encontre de la Société. Le Matériel sera réputé avoir été
                  réceptionné exempt de tout défaut ou vice apparent et/ ou manquant.
                  <p>
                    Sans préjudice des dispositions à prendre par le Client vis-à-vis du
                    transporteur, toute réclamation sur le Matériel livré devra être effectuée par
                    lettre recommandée avec accusé de réception dans un délai de 7 jours suivant la
                    livraison. Il appartient au Client de fournir toutes les justifications quant à
                    la réalité des vices/manquants constatés. Aucun retour ne pourra être effectué
                    par le Client sans l’accord préalable et écrit de la Société. Les frais de
                    retour ne seront à la charge de la Société que dans le cas où un vice apparent
                    ou des manquants sont effectivement constatés par la Société ou par toute
                    personne mandatée par elle. Lorsqu’après contrôle, un vice apparent ou un
                    manquant est effectivement constaté par la Société, le Client n’aura droit qu’au
                    remplacement des Matériels non conformes et/ou au complément sans que le Client
                    ne puisse prétendre à quelque indemnité que ce soit. La réception sans réserve
                    du Matériel commandé par le Client couvre tout vice apparent et ou manquant. La
                    réclamation effectuée par le Client dans les conditions du présent article ne
                    suspend pas le paiement par le Client des Matériels concernés.
                  </p>
                  <h3>RETOUR DE MATÉRIEL</h3>
                  <p>
                    Toute livraison de Matériel acceptée sans réserve par le Client ne peut donner
                    lieu à reprise sauf accord express de la Société et au plus tard dans un délai
                    d’un mois à compter de la date de livraison du Matériel. En tout état de cause,
                    les colis ne doivent pas avoir été ouverts. En cas de retour accepté par la
                    Société, les frais et risques sont à la charge du Client.
                  </p>
                  <h3>PRIX ET CONDITIONS DE PAIEMENT</h3>
                </p>
                <p>
                  Le Matériel est facturé au Client au tarif en vigueur au jour de la commande. Les
                  prix s’entendent nets, départ et hors taxes. Tout impôt, taxe ou autre prestation
                  à payer en application des règlements français sont à la charge du Client.
                </p>
                <p>
                  Les factures sont payables, sauf disposition particulière, à 30 jours de la date
                  de facture.
                </p>
                <p>
                  Sauf disposition particulière convenue par écrit, les factures sont réglées par
                  prélèvement ou virement bancaire. Aucun escompte ne sera accordé par la Société au
                  Client.
                </p>
                <p>
                  En cas de non-paiement d’une facture à l’échéance, sans préjudice des droits ou
                  recours dont la Société pourrait se prévaloir en vertu des présentes CGV, la
                  Société aura le droit de refuser toute nouvelle commande et d’exiger au préalable
                  le paiement des sommes dues.
                </p>
                <p>
                  En outre, en cas de non-paiement aux échéances prévues, la Société se réserve la
                  possibilité d’appliquer des pénalités de retard au taux de trois (3) fois le taux
                  d'intérêt légal en vigueur à la date d'échéance, sur le montant des sommes restant
                  dues sans préjudice de l’exercice par la Société de ses droits à recours. Tout
                  retard de paiement donnera également lieu au versement par le Client d’une
                  indemnité forfaitaire pour frais de recouvrement d’un montant de 40 euros. La
                  Société se réserve la possibilité de demander une indemnisation complémentaire si
                  les frais de recouvrement effectivement engagés dépassent ce montant.
                </p>
                <p>
                  Toute commande dont le montant est inférieur à 100 € HT donne lieu à la
                  facturation d’une somme forfaitaire de 15 € HT destinée à couvrir les frais
                  administratifs.
                </p>
                <p>
                  Sauf accord écrit de la Société, les frais de port sont toujours facturés au
                  Client selon le tarif en vigueur.
                </p>
                <p>
                  Les réclamations éventuelles relatives à la facturation devront être formulées par
                  écrit au plus tard dans les quarante-cinq (45) jours suivants la date d’émission
                  de la facture. Après ce délai, aucune réclamation relative à la facturation ne
                  sera admise.
                  <p>
                    Toute détérioration de la solvabilité du Client pourra justifier l’exigence de
                    garanties avant la livraison des commandes passées et pourra subordonner
                    l’acceptation de la commande ou la poursuite de son exécution à un paiement
                    comptant.
                  </p>
                  <h3>RESERVE DE PROPRIETE</h3>
                </p>
                <p>
                  Le Matériel est vendu sous réserve de propriété : le transfert de propriété est
                  subordonné au complet paiement du prix, à l’échéance convenue, par le Client.
                </p>
                <p>
                  Le Matériel ne pourra faire l’objet d’aucune constitution de gage ou de
                  nantissement avant le paiement intégral du prix.
                  <p>
                    En cas d’ouverture d’une procédure collective, les commandes en cours seront
                    automatiquement annulées et la Société se réserve le droit de revendiquer le
                    Matériel.
                  </p>
                  <h3>GARANTIES</h3>
                </p>
                <p>
                  Le Matériel doit être vérifié par le Client lors de la livraison et toute
                  réclamation, réserve ou contestation relative aux vices apparents doit être
                  effectuée selon les modalités de l'article “Livraison”.
                </p>
                <p>
                  La garantie est strictement limitée aux termes et conditions figurant dans le
                  manuel d’utilisation joint au Matériel. Cette garantie ne pourra être actionnée
                  par le Client que dans les conditions, notamment de durée, consenties à la Société
                  par le fabricant du Matériel concerné. Aucune autre garantie n’est accordée par la
                  Société au Client ou à quelque utilisateur que ce soit.
                </p>
                <p>
                  La garantie cessera de plein droit dès lors que le Client n’aura pas averti la
                  Société du vice allégué dans un délai de 30 jours à compter de sa découverte. Il
                  appartient au Client de rapporter la preuve du jour de cette découverte.
                </p>
                <p>
                  La responsabilité de la Société est strictement limitée à l’obligation de faire
                  réparer ou de remplacer le Matériel au choix de la Société. La Société fait
                  réparer ou remplace gratuitement, le Matériel ou les pièces qu’elle a examinées et
                  reconnues défectueuses. En vue de satisfaire à cette garantie, la Société se
                  réserve le droit de modifier le Matériel ou de remplacer certaines pièces. Les
                  pièces reprises en application de la garantie seront la propriété de la Société.
                </p>
                <p>
                  La garantie ne s’applique pas aux remplacements ni aux réparations qui
                  résulteraient de l’usure normale du Matériel, de détériorations ou accidents
                  provenant de négligences, défaut de surveillance ou d’entretien, d’utilisation
                  défectueuse ou non conforme du Matériel et/ou conditions de stockage, de transport
                  ou de conservation anormales. En outre, cette garantie cessera au cas où le Client
                  effectuerait lui-même des réparations ou modifications sur le Matériel. La
                  réparation du Matériel ne comporte aucune nouvelle garantie et ne prolonge pas la
                  durée de la garantie initiale.
                </p>
                <p>
                  La Société ne garantit que l’aptitude du Matériel à l’usage prévu par le manuel
                  d’utilisation, sauf stipulation expresse figurant dans la confirmation de commande
                  par la Société et ne saurait être tenue responsable de tout dommage résultant
                  d’une utilisation anormale, impropre ou non conforme du Matériel, de négligence ou
                  violation des présentes CGV et de toute autre faute du Client ou du destinataire
                  final.
                </p>
                <p>
                  La Société se réserve le droit de suspendre toute intervention au titre de la
                  garantie, si le Matériel ou les pièces sur lesquels le personnel doit intervenir,
                  ne sont pas en bon état de propreté et de désinfection, et ce jusqu’à ce que ledit
                  Matériel ne présente plus de risque pour le personnel intervenant. La Société se
                  réserve le droit de renvoyer aux frais du Client le Matériel ou les pièces qui ne
                  satisferont pas à cette condition de propreté et de désinfection.
                </p>
                <p>
                  Sauf disposition particulière convenue par écrit, le coût du transport du Matériel
                  ou des pièces défectueuses, ainsi que celui du retour du Matériel ou des pièces
                  réparées ou remplacées, sont à la charge du Client.
                  <h3>RESPONSABILITÉ</h3>
                  <p>
                    La responsabilité de la Société résultant de la vente est expressément limitée à
                    la garantie ci-dessus définie, à l’exclusion de tous dommages et intérêts de
                    quelque nature que ce soit. En cas de manquement de la Société à ses obligations
                    contractuelles, sa responsabilité à l’égard du Client est limitée aux dommages
                    directs aux biens à hauteur de vingt mille (20.000) euros toutes réclamations
                    cumulées. Au-delà de ce montant, le Client renonce à recours contre la Société,
                    ses employés, agents, représentants et assureurs et s’engage à obtenir de ses
                    assureurs une renonciation à recours équivalente. La Société ne pourra voir sa
                    responsabilité engagée envers le Client pour tout autre dommage, qu’il s’agisse,
                    sans limitation, de dommages immatériels, directs ou indirects, causés au Client
                    dans le cadre de la vente objet des présentes CGV, de perte d’exploitation,
                    d’utilisation ou de revenus, de réclamations de tiers ou de tout autre dommage
                    direct ou indirect quel qu’il soit. Le Client renonce à cet égard à tout recours
                    contre la Société, ses employés, agents représentants et assureurs et s’engage à
                    obtenir de ses assureurs une renonciation à recours équivalente.
                  </p>
                  <h3>FORCE MAJEURE</h3>
                  <p>
                    Les évènements indépendants de la volonté de l’une des parties ou de ses
                    cocontractants, sous-traitants, fournisseurs ou agents pouvant affecter ladite
                    partie (ou ses cocontractants, sous-traitants, fournisseurs ou agents) dans
                    l’accomplissement de tout ou partie de ses obligations contractuelles (autres
                    que des obligations de payer des sommes d’argent), tels que, notamment :
                    épidémie, pandémie, incendie, inondation, catastrophe naturelle, émeute, guerre,
                    sabotage, grève, lock-out, fait de tiers, explosion, tremblement de terre, loi,
                    ordonnance et règlement gouvernementaux, défaut d’obtention ou de maintien d’une
                    (de plusieurs) autorisation(s) nécessaire(s), bris de machine ou avarie de
                    matériel, impossibilité d’obtenir de l’électricité ou de se procurer des
                    matières premières, des utilités, de la main d’œuvre, des équipements ou des
                    moyens de transport, suspendront l’exécution de la commande pendant la durée de
                    l’(des) événement(s). Cet(ces) événement(s) libérera(ont) les parties de leurs
                    obligations pendant la durée dudit(desdites) évènement(s) et dans la mesure de
                    ses(leurs) effets. Cet article ne pourra être évoqué par le Client pour se
                    dégager de son obligation de payer toutes sommes d’argent dues à la Société. Si
                    un tel (de tels) évènement(s) se produisait(produisaient), les parties
                    s’engagent à se prévenir mutuellement le plus rapidement possible.
                  </p>
                  <h3>OBLIGATIONS REGLEMENTAIRES LIEES AU STATUT DE DISPOSITIF MÉDICAL</h3>
                  <p>
                    Dans le cas où le Matériel vendu est un dispositif médical, le Client s’engage à
                    respecter l’ensemble des dispositions légales et réglementaires découlant de ce
                    statut notamment, sans que cela soit exhaustif, les dispositions du code de la
                    santé publique et des Règlements européens 2017/745 et 2017/746 du 5 avril 2017.
                    Concernant le Matériel ayant le statut de dispositif médical, le Client s’engage
                    à :
                    <br />- vérifier que les conditions préalables à la mise à disposition sur le
                    marché sont remplies, le cas échéant en appliquant une méthode d’échantillonnage
                    ;
                    <br />- se conformer aux obligations qui lui incombent en matière de vigilance y
                    compris les cas de mésusage du Matériel et en informer immédiatement la Société
                    ;
                    <br />- informer immédiatement la Société de toute non-conformité concernant le
                    Matériel qu’il pourrait constater ;
                    <br />- informer immédiatement la Société ainsi que l’autorité compétente de
                    tout risque grave que pourrait présenter le Matériel ;
                    <br />- respecter les conditions de stockage et de transport conformément au
                    manuel d’utilisation lorsque le Matériel est sous sa responsabilité ;
                    <br />- notifier sans délai à la Société toute réclamation ou signalement de
                    professionnels de santé, de patients ou d’utilisateurs relatifs à des incidents
                    supposés liés au Matériel ;
                    <br />- assurer la traçabilité du Matériel auprès de tout utilisateur du
                    Matériel pendant une durée minimale de 10 ans ;
                    <br />- tenir un registre des réclamations, du Matériel non conforme ainsi que
                    des rappels et des retraits de Matériel ;
                    <br />- collaborer à tout rappel et/ou retrait de Matériel.
                  </p>
                  <h3>UTILISATION DU MATÉRIEL</h3>
                </p>
                <p>
                  Le Client s’engage à former ou à faire former son personnel à l’utilisation du
                  Matériel préalablement à toute mise à disposition de l’utilisateur final du
                  Matériel.
                </p>
                <p>
                  Tout Client et/ou utilisateur doit prendre connaissance et se conformer
                  strictement et impérativement aux lois, règlements et prescriptions en vigueur, y
                  compris celles du manuel d’utilisation, applicables aux Matériels vendus.
                </p>
                <p>
                  L’installation et la mise en service du Matériel sont effectuées par le Client
                  sous sa responsabilité. En cas de besoin, le Client demande à la Société de
                  procéder à l’installation et à la mise en service du Matériel.
                </p>
                <p>
                  Le Client s’engage à réaliser la maintenance, le nettoyage, la désinfection et le
                  contrôle du Matériel selon les prescriptions du fabricant figurant dans le manuel
                  d’utilisation.
                  <p>
                    Le Client s’engage à ne pas porter atteinte à l’intégrité physique du Matériel
                    ce qui inclut la suppression, la modification ou l’ajout d’étiquette ou de
                    notice d’utilisation qui serait susceptible de modifier les textes présents sur
                    le Matériel ou l’accompagnant.
                  </p>
                  <h3>PROTECTION DES DONNEES A CARACTERE PERSONNEL </h3>
                  <p>
                    La Société traite les données personnelles conformément à la politique de
                    protection des données personnelles accessible au lien suivant :{" "}
                    <a
                      title="Politique de confidentialite"
                      href="https://www.arair.fr/politique-confidentialite-rgpd"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      https://www.arair.fr/politique-confidentialite-rgpd
                    </a>
                  </p>
                  <h3>ÉTHIQUE - LUTTE CONTRE LA CORRUPTION</h3>
                </p>
                La Société s'attache à ce que ses partenaires, leurs personnels et leurs
                sous-traitants de premiers rangs adhèrent aux principes exposés dans le Code de
                Conduite du groupe Air Liquide, disponible sur son site internet à l’adresse
                suivante :{" "}
                <a
                  title="Code de conduite anti corruption"
                  href="https://www.airliquide.com/fr/developpement-durable/ethique"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.airliquide.com/fr/developpement-durable/ethique
                </a>
                . Le Client s’engage à adhérer à ces principes lorsqu’il effectue toute activité en
                lien avec les présentes CGL.
                <p>
                  Il certifie qu’il a et qu’il continuera de mettre en place les règles et
                  procédures visant à promouvoir la conformité aux lois et réglementations qui lui
                  sont applicables en matière de lutte contre la corruption.
                </p>
                <h3>DROIT APPLICABLE - JURIDICTION COMPETENTE</h3>
                <p>
                  Les présentes CGV sont soumises au droit français. Tout litige sera de la
                  compétence du Tribunal de Commerce de Paris même en cas de pluralité de défendeurs
                  ou appels en garantie.
                </p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      }
    />
  );
};
export default CGV;
