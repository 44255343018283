import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import styled from "styled-components";

import { Grid, Icon, Loader, Message, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { fetchApollo } from "../../../../graphql/apolloClient";

import Layout from "../../../../components/Front/Layout";

import { Breadcrumb, BreadcrumbLink, BreadcrumbRightLink } from "../../../../components/Core/Breadcrumb";

const Famille = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  color: ${props => props.theme.text.primary};
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.button.primary.background};
  transition: all 0.25s ease;

  &:hover {
    color: ${props => props.theme.button.primary.hover};
    background: ${props => props.theme.button.primary.hoverBackground};
    border: 1px solid ${props => props.theme.button.primary.hoverBackground};
    box-shadow: 0 0 20px ${props => props.theme.background.dark};
  }
`;

class Catalogue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tFamille: [],
      tFamilleOrigin: [],
      message: null,
      loading: true,
      sSearch: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getFamillesCatalogue();
  }

  getFamillesCatalogue = () => {
    const { user } = this.props;
    if (user.iFKCatalogue) {
      const GET_FAMILLE_CATALOGUE = gql`
      {
        listFamilleCatalogue(iPKCatalogue: ${user.iFKCatalogue}) {
          iPKCatFamille
          sPKFamille
          sLibelle
          sInformation
          sImgPath
          bInactif
          sPKGrille
          sAideGrille
          sLibelleGrille
        }
      }
    `;
      fetchApollo(GET_FAMILLE_CATALOGUE).then(response => {
        const tFamillesCatalogue = response.data.listFamilleCatalogue;

        if (tFamillesCatalogue) {
          const tFamilleOrigin = tFamillesCatalogue
            .filter(f => !f.bInactif)
            .sort((a, b) => {
              if (a.sLibelle.toLowerCase() < b.sLibelle.toLowerCase()) {
                return -1;
              }
              if (a.sLibelle.toLowerCase() > b.sLibelle.toLowerCase()) {
                return 1;
              }
              return 0;
            });
          this.setState({
            tFamilleOrigin,
            tFamille: tFamilleOrigin,
            loading: false
          });
        } else {
          this.setState({
            loading: false
          });
        }
      });
    } else {
      this.setState({
        loading: false,
        message: "Vous n'avez pas de catalogue."
      });
    }
  };

  searchChange = (evt, data) => {
    const { tFamilleOrigin } = this.state;
    this.setState({
      sSearch: data.value,
      tFamille: data.value
        ? tFamilleOrigin.filter(p => p.sLibelle.toLowerCase().indexOf(data.value) !== -1)
        : tFamilleOrigin
    });
  };

  render() {
    const { loading, tFamille, message, sSearch } = this.state;
    return (
      <Layout
        mainPane={
          <Grid stackable container doubling>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Breadcrumb>
                  <BreadcrumbLink to="/">
                    <Icon name="home" />
                  </BreadcrumbLink>
                  Catalogue
                  <BreadcrumbRightLink to="/favoris">
                    <Icon name="star" /> Mes favoris
                  </BreadcrumbRightLink>
                </Breadcrumb>

                <div style={{ marginTop: 20 }}>
                  <h1 style={{ float: "left", marginRight: 20 }}>Catalogue</h1>
                  <Input
                    style={{ float: "left" }}
                    onChange={this.searchChange}
                    value={sSearch}
                    icon={{ name: "search", circular: true, link: true }}
                    placeholder="Recherche..."
                  />
                  <div style={{ clear: "both" }} />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              {loading && <Loader active />}
              {tFamille.map(famille => (
                <Grid.Column style={{ marginBottom: 30 }}>
                  <Famille to={`/catalogue/${encodeURIComponent(famille.sLibelle)}`}>
                    {famille.sLibelle}
                  </Famille>
                </Grid.Column>
              ))}
            </Grid.Row>
            <Grid.Row>
              {message && (
                <Message fluid warning>
                  {message}
                </Message>
              )}
            </Grid.Row>
          </Grid>
        }
      />
    );
  }
}

Catalogue.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Catalogue);
