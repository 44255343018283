import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { DateTime } from "luxon";

import { Grid, Card } from "semantic-ui-react";

import ChangePasswordForm from "../../../../components/Admin/Utilisateur/ChangePasswordForm";

import Layout from "../../../../components/Front/Layout";

const CardProfile = styled(Card.Content)`
  min-height: 350px !important;
  font-size: 16px;
  & p {
    line-height: 35px;
    font-size: 16px;
  }
`;

const Profile = ({ user }) => (
  <Layout
    mainPane={
      <Grid stackable container doubling>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Mon compte</Card.Header>
              </Card.Content>
              <CardProfile>
                <p>
                  Nom: <b>{user.sNom}</b>
                </p>
                <p>
                  Prénom: <b>{user.sPrenom}</b>
                </p>
                <p>
                  Email: <b>{user.sEmail}</b>
                </p>
                <p>
                  Structure: <b>{user.sLibelleGroupe}</b>
                </p>
                <p>
                  Catalogue lié: <b>{user.sLibelleCatalogue}</b>
                </p>
                <p>
                  Date de derniere mise à jour du mot de passe:{" "}
                  <b>le {DateTime.fromISO(user.dUpdateMdp).toFormat("dd/LL/yyyy")}</b>
                </p>
              </CardProfile>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Modifier mon mot de passe</Card.Header>
              </Card.Content>
              <Card.Content>
                <ChangePasswordForm />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    }
  />
);

Profile.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Profile);
