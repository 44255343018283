import axios from "axios";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setDate from "date-fns/setDate";

import { Link } from "react-router-dom";

import { Card, Label, Message, Grid, Table, Input, Button, Checkbox, Form, Icon, List, CardContent, Progress } from "semantic-ui-react";

import { getPanier, validePanier } from "../../../../redux/actions/panier";

import { getCookie } from "../../../../utils";

import Layout from "../../../../components/Front/Layout";
// import commandeReducer from "../../../../redux/reducers/commande";

const token = getCookie("jwtToken");
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GRAPHQL_URL,
  headers: {
    'Authorization': `Bearer ${token}`
  }
})

class PasserCommande extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commande: { dLivraison: null, sConsigne: process.env.REACT_APP_PRE_REMPLISSAGE_CONSIGNES_PARTICULIERES, tProduit: [], files: [] },
      uploadsPercent: [],
      errors: {},
      bCGUAccepte: false,
    };
  }

  componentDidMount() {
    const { dispatch, user, tPanier } = this.props;
    dispatch(getPanier(user.iPKUtilisateur));
    if (tPanier) {
      this.transformCommande();
    }
  }

  componentDidUpdate(prevpProps) {
    const { successValidePanier, panierTimestamp, history } = this.props;
    if (panierTimestamp !== prevpProps.panierTimestamp) {
      this.transformCommande();
    }
    if (successValidePanier !== prevpProps.successValidePanier && successValidePanier) {
      history.push({
        pathname: "/commandes",
        state: { message: "Commande passée avec succès" },
      });
      // document.location = "commandes";
    }
  }

  setupReader = (file) => {
    const current = this;
    const { commande } = this.state;
    const { name, type, size } = file;
    const reader = new FileReader();
    const files = commande.files;
    reader.onload = function (e) {
      // get file content
      const content = e.target.result;
      files.push({ name, content, type, size });
      current.setState({
        commande: {
          ...commande,
          files
        },
      });
    }
    reader.readAsText(file, "UTF-8");
  }

  deleteFile = (name) => {
    const { commande } = this.state;
    this.setState({
      commande: {
        ...commande,
        files: commande.files.filter((p) => p.name !== name),
      },
    });
  };

  transformCommande = () => {
    const { commande } = this.state;
    const { tPanier } = this.props;
    commande.tProduit = [];
    tPanier.forEach((produit) => {
      for (let i = 0; i < Number(produit.iQteProduit); i++) {
        commande.tProduit.push({
          ...produit,
          formFields: produit.sFields,
          sFields: "{}",
          iQteProduit: 1,
          iPKMatPanier: `${produit.iPKMatPanier}-${i}`,
        });
      }
    });
    this.setState({ commande });
  };

  deletePanier = (iPKMatPanier) => {
    const { commande } = this.state;
    this.setState({
      commande: {
        ...commande,
        tProduit: commande.tProduit.filter((p) => p.iPKMatPanier !== iPKMatPanier),
      },
    });
  };

  handleChangeLivraison = (date) => {
    const { commande } = this.state;
    this.setState({
      commande: { ...commande, dLivraison: date },
    });
  };

  handleChangeConsigne = (evt, data) => {
    const { commande } = this.state;
    this.setState({
      commande: { ...commande, sConsigne: data.value },
    });
  };

  handleChange = (evt, data) => {
    const { commande } = this.state;
    this.setState({
      commande: {
        ...commande,
        tProduit: commande.tProduit.map((p) =>
          p.iPKMatPanier === data.mykey
            ? { ...p, produit: { ...p.produit, bPriorite: data.checked } }
            : p
        ),
      },
    });
  };

  handleChangeBeneficaire = (evt, data) => {
    const { commande } = this.state;
    const regex = /^[a-zA-Z0-9À-ÿ\u00f1\u00d1 ]*$/gm;
    const result = regex.test(data.value);
    if (result === false) {
      return null;
    }
    this.setState({
      commande: {
        ...commande,
        tProduit: commande.tProduit.map((p) =>
          p.iPKMatPanier === data.mykey
            ? { ...p, produit: { ...p.produit, sBeneficiaire: data.value.toUpperCase() || "" } }
            : p
        ),
      },
    });
  };

  validePanier = () => {
    const { dispatch, user } = this.props;
    const { commande, bCGUAccepte } = this.state;
    const commandeUrgente = commande.tProduit.filter((p) => p.produit.bPriorite === true)[0];
    const errors = {};
    if (!bCGUAccepte) {
      errors.bCGUAccepte = {
        content: "Merci de prendre connaissance et de valider les conditions générales.",
        pointing: "left",
      };
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      dispatch(
        validePanier({
          tPanier: commande.tProduit,
          files: commande.files,
          dLivraison: commande.dLivraison
            ? DateTime.fromJSDate(commande.dLivraison).toFormat("yyyy-MM-dd HH:mm")
            : null,
          iFKUtilisateur: user.iPKUtilisateur,
          bPriorite: !!commandeUrgente,
          sConsigne: commande.sConsigne,
        })
      );
    }
  };

  handleChangeField = (evt, data) => {
    const { commande } = this.state;
    this.setState({
      commande: {
        ...commande,
        tProduit: commande.tProduit.map((p) =>
          p.iPKMatPanier === data.mykey
            ? {
              ...p,
              sFields: JSON.stringify({ ...JSON.parse(p.sFields), [data.id]: data.value }),
              formFields: p.formFields,
            }
            : p
        ),
      },
    });
  };

  handleChangeFile = (evt) => {
    const { errors, uploadsPercent } = this.state;
    const files = evt.target.files;

    Object.keys(files).forEach(key => {
      const formData = new FormData()
      formData.append("fileToUpload", files[key]);
      axiosInstance.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: data => {
          // Set the progress value to show the progress bar
          const percentKey = files[key].name;
          uploadsPercent[percentKey] = Math.round((100 * data.loaded) / data.total);
          this.setState({
            uploadsPercent
          })
        },
      });
      this.setupReader(files[key]);
    });
  };

  handleSearchChange = (evt, data) => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, [data.id]: data.value },
    });
  };

  onChangeCgu = (evt) => {
    const {
      target: { id, checked },
    } = evt;
    const { errors } = this.state;
    const nextErrors = { ...errors };
    if (nextErrors[id]) {
      delete nextErrors[id];
    }

    this.setState({
      [id]: checked,
      errors: nextErrors,
    });
  };

  render() {
    const { commande, bCGUAccepte, errors, uploadsPercent } = this.state;
    const currentDate = new Date();
    commande.tProduit.sort((a, b) => {
      if (a.produit.sLibelle < b.produit.sLibelle) {
        return -1;
      }
      if (a.produit.sLibelle > b.produit.sLibelle) {
        return 1;
      }
      return 0;
    });
    return (
      <Layout
        mainPane={
          <Grid stackable container doubling>
            <Grid.Row columns={1}>
              <Form style={{ width: "100%" }}>
                <Grid.Column>
                  <h1>Votre commande</h1>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Libellé</Table.HeaderCell>
                        <Table.HeaderCell>Référence</Table.HeaderCell>
                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                        <Table.HeaderCell>Service - Chambre - Bénéficiaire</Table.HeaderCell>
                        <Table.HeaderCell>Informations complémentaires</Table.HeaderCell>
                        <Table.HeaderCell>Urgent*</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {commande.tProduit.map((panier) => (
                        <Table.Row>
                          <Table.Cell>{panier.produit.sLibelle}</Table.Cell>
                          <Table.Cell textAlign="center">{panier.produit.sRefProduit}</Table.Cell>
                          <Table.Cell textAlign="center">{panier.iQteProduit}</Table.Cell>
                          <Table.Cell style={{ minWidth: 200 }}>
                            <span
                              {...(panier.produit.sBeneficiaire && {
                                "data-tooltip": panier.produit.sBeneficiaire,
                              })}
                            >
                              <Input
                                type="text"
                                style={{ height: 25 }}
                                id={`sBeneficiaire${panier.iPKMatPanier}`}
                                mykey={panier.iPKMatPanier}
                                name="sBeneficiaire"
                                onChange={this.handleChangeBeneficaire}
                                value={panier.produit.sBeneficiaire || ""}
                              />
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <Form>
                              {panier.formFields &&
                                panier.formFields !== "null" &&
                                panier.formFields !== '"null"' &&
                                JSON.parse(panier.formFields) &&
                                JSON.parse(panier.formFields).length > 0 && (
                                  <>
                                    {JSON.parse(panier.formFields).map((field) => (
                                      <Form.Field>
                                        <label style={{ fontSize: 12 }} htmlFor={field}>
                                          {field}
                                        </label>
                                        <Input
                                          id={field}
                                          mykey={panier.iPKMatPanier}
                                          onChange={this.handleChangeField}
                                          value={panier[field]}
                                          placeholder={field}
                                          style={{ width: 100, height: 25 }}
                                        />
                                      </Form.Field>
                                    ))}
                                  </>
                                )}
                            </Form>
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <Checkbox
                              id={panier.iPKMatPanier}
                              mykey={panier.iPKMatPanier}
                              onChange={this.handleChange}
                              value={panier.produit.bPriorite}
                              checked={panier.produit.bPriorite}
                            />
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <span data-tooltip="Supprimer">
                              <Icon
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  this.deletePanier(panier.iPKMatPanier);
                                }}
                                style={{ cursor: "pointer" }}
                                name="trash"
                              />
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  {process.env.REACT_APP_UPLOAD_DOCUMENT === "1" && (
                    <Form.Field>
                      <label htmlFor="file">Pièce(s) jointe(s)</label>
                      <Card fluid>
                        <CardContent>
                          {commande.files.length > 0 &&
                            <List id="filesList" divided relaxed>
                              {commande.files.map(file =>
                                <List.Item>
                                  <List.Content floated='right'>
                                    <List.Icon
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.deleteFile(file.name);
                                      }}
                                      style={{ cursor: "pointer" }}
                                      name='trash'
                                      verticalAlign='middle'
                                    />
                                  </List.Content>
                                  <List.Icon name='file' size='large' verticalAlign='middle' />
                                  <List.Content>
                                    <Grid>
                                      <Grid.Row columns={2}>
                                        <Grid.Column computer={4}>
                                          <List.Header>{file.name} <i>(~{Math.round(Number(file.size) / 1000, 10)}Ko)</i></List.Header>
                                          <List.Description style={{ textTransform: "capitalize" }}>{file.type}</List.Description>
                                        </Grid.Column>
                                        <Grid.Column computer={12}>
                                          <Progress color='blue' percent={uploadsPercent[file.name]} progress active={uploadsPercent[file.name] < 100} />
                                        </Grid.Column>
                                      </Grid.Row>
                                    </Grid>
                                  </List.Content>
                                </List.Item>
                              )}
                            </List>}
                          <Label
                            as="label"
                            basic
                            htmlFor="file"
                            style={{ border: 'none' }}
                          >
                            <Button
                              icon="upload"
                              label={{
                                basic: true,
                                content: 'Sélectionner des fichiers'
                              }}
                              labelPosition="right"
                            />
                            <input
                              hidden
                              id="file"
                              multiple
                              onChange={this.handleChangeFile}
                              type="file"
                            />
                          </Label>
                        </CardContent>
                      </Card>
                    </Form.Field>
                  )}
                  <Form.Field>
                    <Form.TextArea
                      id="sConsigne"
                      onChange={this.handleChangeConsigne}
                      label="Consignes particulières de livraison"
                      placeholder="Consignes particulières de livraison"
                      value={commande.sConsigne}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label htmlFor="dLivraison">Date de livraison souhaitée</label>
                    <DatePicker
                      id="dLivraison"
                      selected={commande.dLivraison}
                      onChange={(date) => this.handleChangeLivraison(date)}
                      locale="fr"
                      timeFormat="HH:mm"
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      timeCaption="Heure"
                      minDate={
                        currentDate.getHours() >= 12
                          ? setDate(currentDate, currentDate.getDate() + 1)
                          : currentDate
                      }
                      minTime={
                        new Date(commande.dLivraison).getDate() === currentDate.getDate() ||
                          !commande.dLivraison
                          ? currentDate.getHours() >= 12
                            ? setHours(setMinutes(new Date(), 0), 8)
                            : new Date()
                          : setHours(setMinutes(new Date(), 0), 8)
                      }
                      maxTime={setHours(setMinutes(currentDate, 0), 20)}
                      dropdownMode="select"
                      dateFormat="dd/MM/yyyy - HH:mm"
                    />
                  </Form.Field>
                  <Message negative fluid>
                    <b>Urgent* : </b>
                    <br />
                    <b>
                      La notion d’urgence fait référence à une situation pathologique dans laquelle,
                      un traitement et des soins doivent être réalisés très rapidement.
                    </b>{" "}
                    {process.env.REACT_APP_THEME} Assistance se réserve le droit de contacter le
                    client, pour chaque demande urgente qui sera notifiée pour en apprécier le degré
                    et définir les conditions d’intervention.
                  </Message>
                  <Form.Checkbox
                    label={
                      <label htmlFor="bCGUAccepte">
                        J'accepte les{" "}
                        <Link target="_blank" to="/conditions-generales-de-location">
                          Conditions Générales de location
                        </Link>{" "}
                        ainsi que les{" "}
                        <Link target="_blank" to="/conditions-generales-de-vente">
                          Conditions Générales de vente
                        </Link>
                      </label>
                    }
                    id="bCGUAccepte"
                    value={bCGUAccepte}
                    onChange={this.onChangeCgu}
                    error={errors.bCGUAccepte}
                  />
                  <Button
                    style={{ float: "right" }}
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      this.validePanier();
                    }}
                    color="green"
                  >
                    Valider la commande
                  </Button>
                </Grid.Column>
              </Form>
            </Grid.Row>
          </Grid>
        }
      />
    );
  }
}
PasserCommande.defaultProps = {
  panierTimestamp: 0,
};

PasserCommande.propTypes = {
  dispatch: PropTypes.func.isRequired,
  successValidePanier: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tPanier: PropTypes.arrayOf(PropTypes.any).isRequired,
  panierTimestamp: PropTypes.number,
};

const mapStateToProps = (state) => ({
  tPanier: state.panier.liste,
  panierTimestamp: state.panier.timestamp,
  successValidePanier: state.panier.success,
  errorValidePanier: state.panier.error,
  user: state.user.data,
});

export default connect(mapStateToProps)(PasserCommande);
