import React, { Component } from "react";
import { DateTime } from "luxon";
import { gql } from "apollo-boost";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Form, Select } from "semantic-ui-react";
import setDate from "date-fns/setDate";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import { fetchApollo } from "../../../graphql/apolloClient";

import BtnSubmit from "../../Core/Btn/Submit";

const FormContainer = styled.div`
  margin-top: 12px;
`;

class FormRetour extends Component {
  constructor(props) {
    super(props);
    const { materiel } = props;
    this.state = {
      sObjet: "",
      sTypeObjet: "",
      sCommentaire: "",
      dRetour: materiel.dRetour || null,
      iPKCommandeLigne: materiel.iPKCommandeLigne,
      iFKUtilisateur: props.user.iPKUtilisateur,
      sNumeroSerie: materiel.sNumeroSerie,
      sRefProduit: materiel.sRefProduit,
      sBeneficiaire: materiel.sBeneficiaire,
      sContact: materiel.sContact,
      iFKProduit: materiel.iFKProduit,
      iFKClient: materiel.iFKClient,
      disabled: true
    };
  }

  componentDidMount() {
    this.getEmailsParametre();
  }

  getEmailsParametre = () => {
    const GET_EMAILS = gql`
      {
        getParametres {
          iPKParametre
          tFKTypeObjetRetour
        }
      }
    `;
    fetchApollo(GET_EMAILS).then(response => {
      const parametres = response.data.getParametres;
      if (parametres) {
        const GET_TYPE_OBJET = gql`
          {
            getTypeObjetIsadom {
              sLibelle
              sPKTypeObjet
            }
          }
        `;
        fetchApollo(GET_TYPE_OBJET).then(responseTypeObjetOption => {
          const tTypeObjetOption = responseTypeObjetOption.data.getTypeObjetIsadom;
          if (tTypeObjetOption) {
            this.setState({
              tFKTypeObjet: parametres.tFKTypeObjetRetour.split(",").map(sFKTypeObjetRetour => {
                const [option] = tTypeObjetOption.filter(
                  sTypeOption => sTypeOption.sPKTypeObjet === sFKTypeObjetRetour
                );
                return {
                  key: option.sPKTypeObjet,
                  text: option.sLibelle,
                  value: option.sPKTypeObjet
                };
              })
            });
          }
        });
      }
    });
  };

  handleChange = (evt, data) => {
    this.setState({ [data.id]: data.value, disabled: false });
  };

  handleChangeRetour = date => {
    this.setState({
      dRetour: setHours(setMinutes(date, 0), 8)
    });
  };

  handleSubmit = e => {
    const { getMateriels, selectMateriel, materiel } = this.props;
    e.stopPropagation();
    e.preventDefault();
    const addRetour = gql`mutation
        {
          addRetour (retour: ${JSON.stringify(JSON.stringify(this.state))}) {
            iPKRetour
          }
        }
      `;
    fetchApollo(addRetour).then(response => {
      const { sObjet, sTypeObjet, sCommentaire, dRetour } = this.state;
      const materiels = response.data.addRetour;
      if (materiels) {
        this.setState(
          {
            sObjet: "",
            sTypeObjet: "",
            sCommentaire: "",
            disabled: true
          },
          () => {
            getMateriels();
            selectMateriel({
              ...materiel,
              bRetour: true,
              sFKTypeObjet: sTypeObjet,
              dRetour: DateTime.fromJSDate(dRetour).toMillis(),
              sObjetRetour: sObjet,
              sCommentaireRetour: sCommentaire
            });
          }
        );
      }
    });
  };

  render() {
    const { tFKTypeObjet, sTypeObjet, sObjet, sCommentaire, dRetour, disabled } = this.state;
    const { annule } = this.props;
    const currentDate = new Date();
    return (
      <FormContainer>
        <Form>
          <Form.Field>
            <Select
              style={{ marginBottom: 10 }}
              onChange={this.handleChange}
              placeholder="Sélectionnez la raison de votre demande"
              id="sTypeObjet"
              value={sTypeObjet}
              options={tFKTypeObjet}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              id="sObjet"
              label="Objet"
              value={sObjet}
              placeholder="Objet"
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.TextArea
              id="sCommentaire"
              label="Commentaire"
              value={sCommentaire}
              placeholder="Commentaire"
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="dRetour">Date de retour souhaitée</label>
            <DatePicker
              id="dRetour"
              selected={dRetour}
              onChange={date => this.handleChangeRetour(date)}
              locale="fr"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd/MM/yyyy"
            />
          </Form.Field>
          <BtnSubmit onClick={this.handleSubmit} disabled={disabled} primary size="small">
            Envoyer
          </BtnSubmit>
          <BtnSubmit
            onClick={() => {
              annule();
            }}
            primary
            size="small"
          >
            Annuler
          </BtnSubmit>
        </Form>
        <br />
      </FormContainer>
    );
  }
}

FormRetour.propTypes = {
  getMateriels: PropTypes.func.isRequired,
  selectMateriel: PropTypes.func.isRequired,
  annule: PropTypes.func.isRequired,
  materiel: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(FormRetour);
