import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BtnDelete = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.button.primary.text};
  background: ${props => props.theme.colors.error.main};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.error.hover};
  }

  &:disabled {
    background: ${props => props.theme.colors.error.main};
    cursor: default;
    opacity: 0.6;
  }
`;

const Delete = ({ className, children, disabled, onClick }) => {
  return (
    <BtnDelete className={className} disabled={disabled} onClick={onClick}>
      {children}
    </BtnDelete>
  );
};

Delete.propTypes = {
  className: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Delete.defaultProps = {
  className: "",
  disabled: false
};

export default Delete;
