import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Icon, Menu } from "semantic-ui-react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { getAllCount } from "../../../../redux/actions/count";

const MenuItem = styled(Menu)`
  background: none !important;
  border: none !important;
  height: 100%;
  background: ${props => props.theme.background.dark} !important;
`;

const NavItemLink = styled(NavLink)`
  display: block;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  font-family: "Futura";
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.theme.colors.primary.main};
  transition: background ease-in-out 0.25s;

  &:hover {
    color: white;
    background: ${props => props.theme.colors.primary.hover};
  }

  &.active {
    color: white;
    background: ${props => props.theme.colors.primary.active};
  }
`;

const Badge = styled.span`
  border-radius: 25px;
  color: white;
  background-color: ${props => props.theme.colors.title.main};
  font-size: 12px;
  width: 25px;
  height: 25px;
  padding: 4px;
  display: inline-block;
  float: right;
  text-align: center;
  line-height: 18px;
  margin-top: 8px;
  margin-right: -5px;
`;

class LeftPanel extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllCount());
  }

  render() {
    const {
      count: { nbCommande, nbDevis, nbSAV, nbRetour, nbCreation }
    } = this.props;
    return (
      <MenuItem vertical fluid>
        <NavItemLink to="/tableauDeBord">
          <Icon name="dashboard" /> Tableau de bord
        </NavItemLink>
        <NavItemLink to="/clients">
          <Icon name="address book outline" /> Clients
        </NavItemLink>
        <NavItemLink to="/catalogues">
          <Icon name="book" /> Catalogues
        </NavItemLink>
        <NavItemLink to="/commandes">
          <Icon name="cart" /> Commandes <Badge>{nbCommande}</Badge>
        </NavItemLink>
        <NavItemLink to="/devis">
          <Icon name="file" /> Demandes de devis <Badge>{nbDevis}</Badge>
        </NavItemLink>
        <NavItemLink to="/interventions">
          <Icon name="wrench" /> Demandes de SAV <Badge>{nbSAV}</Badge>
        </NavItemLink>
        <NavItemLink to="/retours">
          <Icon name="dolly" /> Demandes de retour <Badge>{nbRetour}</Badge>
        </NavItemLink>
        <hr />
        <NavItemLink to="/creationCompte">
          <Icon name="users" /> Création de comptes <Badge>{nbCreation}</Badge>
        </NavItemLink>
        <hr />
        <NavItemLink to="/parametres">
          <Icon name="cog" /> Paramètres
        </NavItemLink>
      </MenuItem>
    );
  }
}

LeftPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  count: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  count: state.count,
  error: state.count.error
});

export default connect(mapStateToProps)(LeftPanel);
