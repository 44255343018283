import {
  LISTE_COMMANDE_TYPE_REQUEST,
  ADD_COMMANDE_TYPE_REQUEST,
  DELETE_COMMANDE_TYPE_REQUEST
} from "../constants/ActionTypes";

export const getCommandesType = iFKUtilisateur => ({
  type: LISTE_COMMANDE_TYPE_REQUEST,
  payload: { iFKUtilisateur }
});

export const addCommandeType = (iFKCommande, iFKUtilisateur, sLibelle) => ({
  type: ADD_COMMANDE_TYPE_REQUEST,
  payload: { iFKCommande, iFKUtilisateur, sLibelle }
});

export const deleteCommandeType = iPKCommandeType => ({
  type: DELETE_COMMANDE_TYPE_REQUEST,
  payload: { iPKCommandeType }
});
