import {
  GET_ALL_COUNT_SUCCESS,
  GET_ALL_COUNT_FAILURE,
  GET_ALL_COUNT_BY_USER_SUCCESS,
  GET_ALL_COUNT_BY_USER_FAILURE
} from "../constants/ActionTypes";

const initialState = {
  error: null,
  nbCommande: 0,
  nbDevis: 0,
  nbSAV: 0,
  nbRetour: 0
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_COUNT_SUCCESS: {
      return { ...state, ...payload, error: null };
    }
    case GET_ALL_COUNT_FAILURE: {
      return { ...state, error: payload };
    }
    case GET_ALL_COUNT_BY_USER_SUCCESS: {
      return { ...state, ...payload, error: null };
    }
    case GET_ALL_COUNT_BY_USER_FAILURE: {
      return { ...state, error: payload };
    }
    default:
      return state;
  }
};
