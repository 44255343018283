import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import styled from "styled-components";

import { NavLink } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import ReactMegaMenu from "react-mega-menu";

import { toggleElement } from "../../../../../utils";

import { fetchApollo } from "../../../../../graphql/apolloClient";

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  height: 40px;
  float: right;
`;

const MenuListItem = styled.li`
  position: relative;
  float: left;
  margin: 0 20px 0 0;
  list-style: none;
  height: 100%;

  &:last-child::after {
    display: none;
  }

  &::after {
    position: absolute;
    right: -10px;
    top: 0;
    content: " ";
    height: 40px;
    border-right: 1px solid ${props => props.theme.border.light};
  }
`;

const MenuListItemLink = styled(NavLink)`
  display: inline-block;
  padding: 0 15px;
  height: 100%;
  line-height: 40px;
  font-size: 16px;
  color: ${props => props.theme.button.primary.background};
  text-transform: uppercase;
  transition: all 0.25s ease;

  &:hover {
    color: #ffffff !important;
    background: ${props => props.theme.button.primary.background};
  }

  &.active {
    color: #ffffff !important;
    background: ${props => props.theme.button.secondary.background};
  }

  &.opened {
    color: #ffffff !important;
    background: ${props => props.theme.button.primary.background};
  }
`;

const CatalogueMenu = styled.ul`
  margin: 0;
  padding: 0;
`;

const CatalogueMenuItem = styled.li`
  list-style: none;
  display: block;
  margin: 0 20px 20px 0;
  text-align: center;
  font-size: 14px;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.button.primary.background};
  transition: all 0.25s ease;

  &:hover {
    box-shadow: 0 0 20px ${props => props.theme.background.dark};
    background: ${props => props.theme.button.primary.background};
    border: 1px solid ${props => props.theme.button.primary.background};
    box-shadow: 0 0 20px ${props => props.theme.background.dark};
  }
  &:hover a {
  }
`;

const CatalogueMenuItemLink = styled(NavLink)`
  display: block;
  padding: 10px !important;
  color: ${props => props.theme.text.primary} !important;

  &:hover {
    color: ${props => props.theme.button.primary.hover} !important;
  }
`;

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catalogueMenuOpen: false,
      tFamille: []
    };

    this.catalogueRef = React.createRef();
  }

  componentDidMount() {
    this.getFamillesCatalogue();
  }

  getFamillesCatalogue = () => {
    const { user } = this.props;
    if (user.iFKCatalogue) {
      const GET_FAMILLE_CATALOGUE = gql`
      {
        listFamilleCatalogue(iPKCatalogue: ${user.iFKCatalogue}) {
          iPKCatFamille
          sPKFamille
          sLibelle
          sInformation
          sImgPath
          bInactif
          sPKGrille
          sAideGrille
          sLibelleGrille
          tSousFamilleCatalogue {
            iPKCatFamille
            sPKSousFamille
            sLibelle
            sInformation
            sImgPath
            bInactif
            sPKGrille
            sAideGrille
            sLibelleGrille
          }
        }
      }
    `;
      fetchApollo(GET_FAMILLE_CATALOGUE).then(response => {
        const tFamillesCatalogue = response.data.listFamilleCatalogue;

        if (tFamillesCatalogue) {
          this.setState({
            tFamille: tFamillesCatalogue
              .filter(f => !f.bInactif)
              .sort((a, b) => {
                if (a.sLibelle < b.sLibelle) {
                  return -1;
                }
                if (a.sLibelle > b.sLibelle) {
                  return 1;
                }
                return 0;
              })
          });
        }
      });
    }
  };

  openCatalogueMenu = evt => {
    evt.preventDefault();

    this.setState(
      prevState => ({
        catalogueMenuOpen: !prevState.catalogueMenuOpen
      }),
      () => toggleElement(this.catalogueRef.current, "opened")
    );
  };

  closeCatalogueMenu = () => {
    this.setState({ catalogueMenuOpen: false }, () =>
      toggleElement(this.catalogueRef.current, "opened")
    );
  };

  render() {
    const { catalogueMenuOpen, tFamille } = this.state;
    return (
      <>
        <MenuList className="noPrint">
          <MenuListItem>
            <MenuListItemLink
              ref={this.catalogueRef}
              to="/catalogue"
              onMouseOver={this.openCatalogueMenu}
              onFocus={this.openCatalogueMenu}
            >
              Catalogue
            </MenuListItemLink>
          </MenuListItem>
          <MenuListItem>
            <MenuListItemLink to="/commandes">Commandes</MenuListItemLink>
          </MenuListItem>
          <MenuListItem>
            <MenuListItemLink to="/materiels">Parc matériel</MenuListItemLink>
          </MenuListItem>
        </MenuList>

        {catalogueMenuOpen && (
          <ReactMegaMenu
            tolerance={50}
            direction="RIGHT"
            styleConfig={{
              menuProps: {
                style: {
                  width: "300px",
                  minWidth: "300px",
                  padding: "0",
                  margin: "0",
                  maxHeight: 400,
                  overflow: "auto"
                }
              },
              contentProps: {
                style: {
                  padding: "0 20px",
                  width: "calc(100% - 300px)",
                  background: "#ffffff"
                }
              },
              menuItemProps: {
                style: {
                  display: "block",
                  padding: "0 15px",
                  fontSize: "16px",
                  cursor: "pointer !important",
                  transition: "all 0.125s ease-in-out"
                }
              },
              menuItemSelectedProps: {
                style: {
                  display: "block",
                  padding: "0 15px",
                  width: "100%",
                  fontSize: "16px",
                  backgroundColor: "rgba(88, 164, 202, 1)",
                  color: "#ffffff"
                }
              },
              containerProps: {
                style: {
                  position: "absolute",
                  margin: "54px 0 0",
                  padding: "0",
                  background: "#ffffff",
                  width: "1127px",
                  border: "1px solid #beb5b6",
                  borderTop: "0",
                  boxShadow: "box-shadow: 0 0 20px rgba(178, 179, 179, 0.2)"
                }
              }
            }}
            onExit={this.closeCatalogueMenu}
            data={tFamille.map(famille => ({
              label: (
                <CatalogueMenuItemLink to={`/catalogue/${encodeURIComponent(famille.sLibelle)}`}>
                  {famille.sLibelle}
                </CatalogueMenuItemLink>
              ),
              key: famille.sLibelle,
              items: (
                <Grid columns={1}>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <CatalogueMenu>
                        {famille.tSousFamilleCatalogue.map(sousFamille => (
                          <CatalogueMenuItem key={sousFamille}>
                            <CatalogueMenuItemLink
                              to={`/catalogue/${encodeURIComponent(
                                famille.sLibelle
                              )}/${encodeURIComponent(sousFamille.sLibelle)}`}
                            >
                              {sousFamille.sLibelle}
                            </CatalogueMenuItemLink>
                          </CatalogueMenuItem>
                        ))}
                      </CatalogueMenu>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )
            }))}
          />
        )}
      </>
    );
  }
}

Menu.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Menu);
