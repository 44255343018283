import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  EDIT_FAMILLE_SUCCESS,
  EDIT_FAMILLE_FAILURE,
  DELETE_FAMILLE_SUCCESS,
  DELETE_FAMILLE_FAILURE
} from "../constants/ActionTypes";

export function* editFamille({ payload: { famille } }) {
  try {
    const FAMILLE_REQUEST = gql`mutation {
      editFamille(famille: ${JSON.stringify(JSON.stringify(famille))}) {
          sPKFamille
          iPKCatFamille
          sInformation
          sImgPath
          sLibelle
        }
      }`;
    const response = yield call(fetchApollo, FAMILLE_REQUEST);
    const savedFamille = response.data.editFamille;

    if (savedFamille !== null) {
      yield put({ type: EDIT_FAMILLE_SUCCESS, payload: savedFamille });
    } else {
      yield put({
        type: EDIT_FAMILLE_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_FAMILLE_FAILURE, payload: message });
  }
}

export function* deleteFamille({ payload: { iPKCatFamille } }) {
  try {
    const FAMILLE_REQUEST = gql`mutation {
        deleteFamille(iPKCatFamille: ${iPKCatFamille}) {
          sPKFamille
          iPKCatFamille
          sInformation
          sImgPath
          sLibelle
        }
      }`;
    const response = yield call(fetchApollo, FAMILLE_REQUEST);
    const deletedFamille = response.data.deleteFamille;

    if (deletedFamille !== null) {
      yield put({ type: DELETE_FAMILLE_SUCCESS, payload: deletedFamille });
    } else {
      yield put({
        type: DELETE_FAMILLE_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_FAMILLE_FAILURE, payload: message });
  }
}
