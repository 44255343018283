import React, { Component } from "react";

import PropTypes from "prop-types";
import { gql } from "apollo-boost";

import { Table, Card, Button } from "semantic-ui-react";
import { downloadFile } from '../../../utils';

import { fetchApollo } from "../../../graphql/apolloClient";

class CommandeShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tProduct: []
    };
  }

  componentDidMount() {
    const { commande } = this.props;
    this.getProductsByCommande(parseInt(commande.iPKCommande, 10));
  }

  componentDidUpdate(prevProps) {
    const { commande } = this.props;
    if (prevProps.commande !== commande) {
      this.getProductsByCommande(parseInt(commande.iPKCommande, 10));
    }
  }

  getProductsByCommande = iPKCommande => {
    const GET_PRODUCTS = gql`
      {
        listProductsByCommande(iPKCommande: ${iPKCommande}) {
          iPKProduit
          bPriorite
          sLibelle
          sRefProduit
          sInformation
          sBeneficiaire
          iQuantite
          sFields
        }
      }
    `;
    fetchApollo(GET_PRODUCTS).then(response => {
      const tProduct = response.data.listProductsByCommande;
      this.setState({
        tProduct
      });
    });
  };



  render() {
    const { tProduct } = this.state;
    const { commande } = this.props;
    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Card.Header style={{ fontSize: 14 }}>Consignes particulières de livraison</Card.Header>
          </Card.Content>
          <Card.Content>
            <p>{commande.sConsigne || ""}</p>
          </Card.Content>
        </Card>
        {commande.files && commande.files.length > 0 &&
          <Card fluid>
            <Card.Content>
              <Card.Header style={{ fontSize: 14 }}>Pièce(s) jointe(s)</Card.Header>
            </Card.Content>
            <Card.Content>
              {commande.files.map(
                file => (
                  <Button
                    icon="download"
                    as="a"
                    onClick={(e) => downloadFile(e, file.name, file.path)}
                    label={{
                      basic: true,
                      content: file.name
                    }}
                    labelPosition="right"
                    href=''
                  />
                )
              )}
            </Card.Content>
          </Card>
        }
        <Table compact celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Référence</Table.HeaderCell>
              <Table.HeaderCell>Désignation</Table.HeaderCell>
              <Table.HeaderCell>Quantité</Table.HeaderCell>
              <Table.HeaderCell>Bénéficiaire</Table.HeaderCell>
              <Table.HeaderCell>Informations complémentaires</Table.HeaderCell>
              <Table.HeaderCell>Urgent</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {tProduct.map((p, i) => {
              return (
                <Table.Row key={`${p.sRefProduit}-${i}`}>
                  <Table.Cell>{p.sRefProduit}</Table.Cell>
                  <Table.Cell>{p.sLibelle}</Table.Cell>
                  <Table.Cell>{p.iQuantite}</Table.Cell>
                  <Table.Cell> {p.sBeneficiaire}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    {p.sFields
                      ? Object.keys(JSON.parse(p.sFields)).map(field => (
                        <>
                          {field}: {JSON.parse(p.sFields)[field]}{" "}
                        </>
                      ))
                      : ""}
                  </Table.Cell>
                  <Table.Cell>{p.bPriorite ? "Oui" : ""}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

CommandeShow.propTypes = {
  commande: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CommandeShow;
