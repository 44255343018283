import styled from "styled-components";
import { Link } from "react-router-dom";

export const BreadcrumbLink = styled(Link)`
  color: ${props => props.theme.text.primary};
  font-size: 16px;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.secondary.main};
    text-decoration: underline;
  }

  &::after {
    display: inline-block;
    padding: 0 10px;
    content: ">";
    font-weight: bold;
    color: ${props => props.theme.colors.secondary.main};
  }
`;

export const BreadcrumbRightLink = styled(BreadcrumbLink)`
  float: right;
  &::after {
    display: none;
  }
`;

export const Breadcrumb = styled.div`
  padding: 15px;
  font-size: 16px;
  cursor: default;

  background: #ffffff;
  border: 1px solid ${props => props.theme.border.light};
  border-radius: 4px;
`;
