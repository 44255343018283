import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {deleteCookie} from '../../utils';

import { signUserOut } from "../../redux/actions/user";

import Screen from "../../components/Core/Screen";
import Wrapper from "../../components/Screens/Deconnexion/Wrapper";
import H1 from "../../components/Core/Title/H1";
import Notice from "../../components/Screens/Deconnexion/Notice";

class Deconnexion extends Component {
  componentDidMount() {
    const { dispatchSignUserOut } = this.props;
    dispatchSignUserOut();
    deleteCookie();
  }

  render() {
    return (
      <Screen>
        <Wrapper>
          <H1>Déconnexion</H1>

          <Notice>Votre session est terminée.</Notice>

          <Link to="/connexion">Cliquez ici pour vous connecter de nouveau</Link>
        </Wrapper>
      </Screen>
    );
  }
}

Deconnexion.propTypes = {
  dispatchSignUserOut: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  dispatchSignUserOut: () => {
    dispatch(signUserOut());
  }
});

export default connect(null, mapDispatchToProps)(Deconnexion);
