import React, { Component } from "react";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Confirm } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import { deleteCatalogue } from "../../../../redux/actions/catalogue";

import LayoutAdmin from "../../../../components/Admin/Layout";

import FormCatalogue from "../../../../components/Admin/Catalogue/Form";

import BtnSubmit from "../../../../components/Core/Btn/Submit";
import BtnEdit from "../../../../components/Core/Btn/Edit";
import BtnDelete from "../../../../components/Core/Btn/Delete";

class Catalogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanel: null,
      tCatalogue: [],
      showConfirmDelete: false
    };
  }

  componentDidMount() {
    this.getCatalogues();
  }

  getCatalogues = () => {
    const listCatalogue = gql`
      {
        listCatalogue {
          iPKCatalogue
          iFKClient
          sLibelle
          sLibelleClient
        }
      }
    `;
    fetchApollo(listCatalogue).then(response => {
      const catalogues = response.data.listCatalogue;
      if (catalogues) {
        this.setState({
          tCatalogue: catalogues
        });
      }
    });
  };

  handleClick = () => {
    this.setState({
      rightPanel: <FormCatalogue reloadList={this.getCatalogues} />
    });
  };

  deleteCatalogue = () => {
    const { dispatch } = this.props;
    const { showConfirmDelete } = this.state;
    dispatch(deleteCatalogue(showConfirmDelete));
    this.setState({ showConfirmDelete: false }, () => {
      setTimeout(() => this.getCatalogues(), 500);
    });
  };

  render() {
    const { tCatalogue, rightPanel, showConfirmDelete } = this.state;
    return (
      <LayoutAdmin title="Gestion des Catalogues" rightPanel={rightPanel}>
        <>
          <Table compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Client</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tCatalogue.map(c => {
                return (
                  <Table.Row key={c.sLibelle}>
                    <Table.Cell width={6}>{c.sLibelle}</Table.Cell>
                    <Table.Cell width={6}>
                      <Link to={`/clients/${c.iFKClient}`}>{c.sLibelleClient}</Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/catalogues/${c.iPKCatalogue}`}>
                        <BtnEdit onClick={() => {}} floated="left" primary size="small">
                          Modifier
                        </BtnEdit>
                      </Link>{" "}
                      <BtnDelete
                        onClick={() => this.setState({ showConfirmDelete: c.iPKCatalogue })}
                        floated="left"
                        alert
                        size="small"
                      >
                        Supprimer
                      </BtnDelete>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>

            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <BtnSubmit onClick={this.handleClick} primary size="small">
                    Ajouter un catalogue
                  </BtnSubmit>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Confirm
            open={!!showConfirmDelete}
            content="Etes-vous sûr de vouloir supprimer ce catalogue ?"
            cancelButton="Annuler"
            confirmButton="Confirmer"
            onCancel={() => this.setState({ showConfirmDelete: false })}
            onConfirm={e => this.deleteCatalogue(e)}
          />
        </>
      </LayoutAdmin>
    );
  }
}

Catalogue.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  tCatalogue: state.catalogue.liste
});

export default connect(mapStateToProps)(Catalogue);
