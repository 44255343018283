import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { DateTime } from "luxon";
import setDate from "date-fns/setDate";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import {
  Grid,
  Table,
  Input,
  Checkbox,
  Confirm,
  Modal,
  Button,
  Form,
  Card,
  Icon,
  Popup,
  Message
} from "semantic-ui-react";

import DatePicker from "react-datepicker";

import { fetchApollo } from "../../../../graphql/apolloClient";

import Layout from "../../../../components/Front/Layout";

const IconPrint = styled(Icon)`
  display: inline-block;
  cursor: pointer;
  font-size: 0.8em !important;
`;

class CommandeTypeShow extends React.Component {

  autoSaveCmd;

  autoSaveCmdLine;

  constructor(props) {
    super(props);
    this.state = {
      tCommandeTypeLigne: [],
      showConfirmDelete: false,
      commandeType: {},
      message: null,
      error: null,
      isOpen: false
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { iPKCommandeType }
      }
    } = this.props;
    this.getCommandeType(iPKCommandeType);
    this.getProductsByCommandeType(iPKCommandeType);
  }

  getCommandeType = iPKCommandeType => {
    const GET_COMMANDE = gql`
      {
        getCommandeType(iPKCommandeType: ${iPKCommandeType}) {
          iPKCommandeType
          iEtat
          dInsert
          bPriorite
          dLivraison
          sConsigne
        }
      }
    `;
    fetchApollo(GET_COMMANDE).then(response => {
      const commandeType = response.data.getCommandeType;
      if (commandeType) {
        const currentDate = new Date();
        const tz = Math.abs(currentDate.getTimezoneOffset() / 60);
        this.setState({
          commandeType: {
            ...commandeType,
            dLivraison:
              Number(commandeType.dLivraison) > 0
                ? DateTime.fromMillis(Number(commandeType.dLivraison))
                    .toUTC()
                    .setZone(`UTC+${tz}`, { keepLocalTime: true })
                    .toMillis()
                : ""
          }
        });
        document.title += ` - Commande N°${commandeType.iPKCommandeType}`;
      }
    });
  };

  deleteCommandeTypeLigne = () => {
    const { tCommandeTypeLigne, iPKCommandeTypeLigneDelete } = this.state;
    const DELETE_COMMANDE_LIGNE = gql`mutation
    {
      deleteCommandeTypeLigne(iPKCommandeTypeLigne: ${iPKCommandeTypeLigneDelete}) {
        iPKCommandeTypeLigne
      }
    }
  `;
    fetchApollo(DELETE_COMMANDE_LIGNE).then(response => {
      const commandeTypeLigne = response.data.deleteCommandeTypeLigne;
      if (commandeTypeLigne) {
        if (
          tCommandeTypeLigne.filter(
            cl => cl.iPKCommandeTypeLigne !== commandeTypeLigne.iPKCommandeTypeLigne
          ).length === 0
        ) {
          window.history.go(-1);
        } else {
          this.setState({
            tCommandeTypeLigne: tCommandeTypeLigne.filter(
              cl => cl.iPKCommandeTypeLigne !== commandeTypeLigne.iPKCommandeTypeLigne
            )
          });
        }
      }
    });
  };

  getProductsByCommandeType = iPKCommandeType => {
    const GET_PRODUCTS = gql`
      {
        listProductsByCommandeType(iPKCommandeType: ${iPKCommandeType}) {
          iPKCommandeTypeLigne
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          iQuantite
          sBeneficiaire
          bPriorite
          sFields
        }
      }
    `;
    fetchApollo(GET_PRODUCTS).then(response => {
      const tCommandeTypeLigne = response.data.listProductsByCommandeType;
      if (tCommandeTypeLigne) {
        this.setState({
          tCommandeTypeLigne
        });
      }
    });
  };

  editCommandeTypeLigne = iPKCommandeTypeLigne => {
    const { tCommandeTypeLigne } = this.state;
    const GET_PRODUCTS = gql`mutation
      {
        editCommandeTypeLigne(commandeTypeLigne: ${JSON.stringify(
          JSON.stringify(
            tCommandeTypeLigne.filter(l => l.iPKCommandeTypeLigne === iPKCommandeTypeLigne)[0]
          )
        )}) {
          iPKCommandeTypeLigne
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          iQuantite
          sBeneficiaire
          bPriorite
        }
      }
    `;
    fetchApollo(GET_PRODUCTS).then(response => {
      const commandeTypeLigne = response.data.editCommandeTypeLigne;
      if (tCommandeTypeLigne) {
        this.setState(
          {
            tCommandeTypeLigne: tCommandeTypeLigne.map(cl =>
              cl.iPKCommandeTypeLigne === commandeTypeLigne.iPKCommandeTypeLigne
                ? {
                    ...cl,
                    sBeneficiaire: commandeTypeLigne.sBeneficiaire,
                    iQuantite: commandeTypeLigne.iQuantite,
                    bPriorite: commandeTypeLigne.bPriorite
                  }
                : cl
            )
          },
          () => {
            this.setState({ message: "Votre commandeType a bien été modifiée" }, () => {
              setTimeout(() => {
                this.setState({
                  message: null
                });
              }, 3000);
            });
          }
        );
      }
    });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, 2500);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  handleChange = (evt, data) => {
    const { tCommandeTypeLigne } = this.state;
    if (data.name === "sBeneficiaire") {
      const regex = /^[a-zA-Z0-9À-ÿ\u00f1\u00d1 ]*$/gm;
      const result = regex.test(data.value);
      if (result === false) {
        return null;
      }
    }
    this.setState(
      {
        tCommandeTypeLigne: tCommandeTypeLigne.map(l =>
          l.iPKCommandeTypeLigne === data.ipkcommandeligne
            ? {
                ...l,
                [data.name]:
                  data.name === "sBeneficiaire"
                    ? data.value
                      ? data.value.toUpperCase()
                      : ""
                    : data.value
              }
            : l
        )
      },
      () => {
        clearTimeout(this.autoSaveCmdLine);
        this.autoSaveCmdLine = setTimeout(() => {
          this.editCommandeTypeLigne(data.ipkcommandeligne);
        }, 1000);
      }
    );
  };

  handleChangeQte = (evt, data) => {
    const { tCommandeTypeLigne } = this.state;
    if (
      Number.isInteger(Number(data.value)) &&
      Number(data.value) >= 0 &&
      Number(data.value) < Number(process.env.REACT_APP_LIMITE_QTE)
    ) {
      this.setState(
        {
          tCommandeTypeLigne: tCommandeTypeLigne.map(l =>
            l.iPKCommandeTypeLigne === data.ipkcommandeligne
              ? {
                  ...l,
                  [data.name]: Number(data.value)
                }
              : l
          )
        },
        () => {
          clearTimeout(this.autoSaveCmdLine);
          this.autoSaveCmdLine = setTimeout(() => {
            this.editCommandeTypeLigne(data.ipkcommandeligne);
          }, 1000);
        }
      );
    } else {
      this.setState(
        {
          error:
            Number(data.value) >= Number(process.env.REACT_APP_LIMITE_QTE)
              ? "La quantité saisie est trop importante"
              : "Vous ne pouvez pas mettre de quantité négative"
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null
            });
          }, 3000);
        }
      );
    }
  };

  handleChangeLivraison = date => {
    const { commandeType } = this.state;
    this.setState(
      { commandeType: { ...commandeType, dLivraison: DateTime.fromJSDate(date).toMillis() } },
      () => {
        clearTimeout(this.autoSaveCmd);
        this.autoSaveCmd = setTimeout(() => {
          this.handleSubmit();
        }, 1000);
      }
    );
  };

  handleChangeConsigne = (evt, data) => {
    const { commandeType } = this.state;
    this.setState({ commandeType: { ...commandeType, sConsigne: data.value } }, () => {
      clearTimeout(this.autoSaveCmd);
      this.autoSaveCmd = setTimeout(() => {
        this.handleSubmit();
      }, 1000);
    });
  };

  handleSubmit = () => {
    const { commandeType } = this.state;
    const commandeEdit = {
      ...commandeType,
      dLivraison:
        typeof commandeType.dLivraison === "number"
          ? DateTime.fromMillis(commandeType.dLivraison).toFormat("yyyy-MM-dd HH:mm")
          : null
    };
    const GET_COMMANDE = gql`mutation
      {
        editCommandeType(commandeType: ${JSON.stringify(JSON.stringify(commandeEdit))}) {
          iPKCommandeType
          iEtat
          dInsert
          bPriorite
          dLivraison
        }
      }
    `;
    fetchApollo(GET_COMMANDE).then(response => {
      const commandeTypeResult = response.data.editCommandeType;

      if (commandeTypeResult) {
        const currentDate = new Date();
        const tz = Math.abs(currentDate.getTimezoneOffset() / 60);
        this.setState(
          {
            commandeType: {
              ...commandeTypeResult,
              dLivraison:
                Number(commandeTypeResult.dLivraison) > 0
                  ? DateTime.fromMillis(Number(commandeTypeResult.dLivraison))
                      .toUTC()
                      .setZone(`UTC+${tz}`, { keepLocalTime: true })
                      .toMillis()
                  : ""
            },
            message: "Votre commandeType a bien été modifiée"
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: null
              });
            }, 3000);
          }
        );
      }
    });
  };

  render() {
    const {
      tCommandeTypeLigne,
      showConfirmDelete,
      commandeType,
      isOpen,
      message,
      error
    } = this.state;

    const currentDate = new Date();
    const tz = Math.abs(currentDate.getTimezoneOffset() / 60);
    tCommandeTypeLigne.sort((a, b) => {
      if (a.sLibelle < b.sLibelle) {
        return -1;
      }
      if (a.sLibelle > b.sLibelle) {
        return 1;
      }
      return 0;
    });
    return (
      <Layout
        mainPane={
          <>
            <Grid stackable container doubling>
              <Grid.Row columns={1}>
                <Form style={{ width: "100%" }}>
                  <Grid.Column>
                    <h1>
                      Commande type N°{commandeType.iPKCommandeType}{" "}
                      <span
                        style={{
                          fontSize: 17,
                          color: "grey",
                          fontStyle: "italic"
                        }}
                      >
                        Le{" "}
                        {DateTime.fromMillis(parseInt(commandeType.dInsert, 10))
                          .toUTC()
                          .setZone(`UTC+${tz}`, { keepLocalTime: true })
                          .toFormat("dd/MM/yyyy à HH:mm")}
                      </span>
                      <Popup
                        content="Imprimer"
                        position="top center"
                        open={isOpen}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                        trigger={
                          <span style={{ float: "right" }}>
                            <IconPrint
                              className="noPrint"
                              name="print"
                              onClick={() => {
                                this.setState({ isOpen: false }, () => {
                                  window.print();
                                });
                              }}
                            />
                          </span>
                        }
                      />
                    </h1>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Libellé</Table.HeaderCell>
                          <Table.HeaderCell>Référence</Table.HeaderCell>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                          <Table.HeaderCell>Quantité</Table.HeaderCell>
                          <Table.HeaderCell>Service - Chambre - Bénéficiaire</Table.HeaderCell>
                          <Table.HeaderCell>Informations complémentaires</Table.HeaderCell>
                          <Table.HeaderCell>Urgent</Table.HeaderCell>
                          {commandeType.iEtat < 1 && <Table.HeaderCell className="noPrint" />}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {tCommandeTypeLigne.map(p => (
                          <Table.Row>
                            <Table.Cell>
                              <p className="labelPrint inputPrint">{p.sLibelle}</p>
                            </Table.Cell>
                            <Table.Cell>{p.sRefProduit}</Table.Cell>
                            <Table.Cell>{p.sInformation}</Table.Cell>
                            <Table.Cell>
                              {commandeType.iEtat === 0 ? (
                                <>
                                  <Input
                                    id={`iQuantite${p.iPKProduit}`}
                                    type="number"
                                    min="0"
                                    ipkcommandeligne={p.iPKCommandeTypeLigne}
                                    name="iQuantite"
                                    onChange={this.handleChangeQte}
                                    value={p.iQuantite}
                                    className="noPrint"
                                  />
                                  <p className="goPrint">{p.iQuantite}</p>
                                </>
                              ) : (
                                p.iQuantite
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {commandeType.iEtat === 0 ? (
                                <span {...(p.sBeneficiaire && { "data-tooltip": p.sBeneficiaire })}>
                                  <Input
                                    type="text"
                                    id={`sBeneficiaire${p.iPKProduit}`}
                                    ipkcommandeligne={p.iPKCommandeTypeLigne}
                                    name="sBeneficiaire"
                                    onChange={this.handleChange}
                                    value={p.sBeneficiaire}
                                    className="noPrint"
                                  />
                                  <p className="goPrint">{p.sBeneficiaire}</p>
                                </span>
                              ) : (
                                p.sBeneficiaire
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {" "}
                              {p.sFields && p.sFields !== "null"
                                ? Object.keys(JSON.parse(p.sFields)).map(field => (
                                    <>
                                      {field}: {JSON.parse(p.sFields)[field]}{" "}
                                    </>
                                  ))
                                : ""}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <Checkbox
                                id={`bPriorite${p.iPKProduit}`}
                                disabled={commandeType.iEtat !== 0}
                                ipkcommandeligne={p.iPKCommandeTypeLigne}
                                name="bPriorite"
                                onChange={this.handleChange}
                                value={!p.bPriorite}
                                checked={p.bPriorite}
                              />
                            </Table.Cell>
                            {commandeType.iEtat === 0 && (
                              <Table.Cell className="noPrint">
                                <span data-tooltip="Supprimer">
                                  <Icon
                                    onClick={() => {
                                      this.setState({
                                        iPKCommandeTypeLigneDelete: p.iPKCommandeTypeLigne,
                                        showConfirmDelete: true
                                      });
                                    }}
                                    style={{ cursor: "pointer" }}
                                    name="trash"
                                  />
                                </span>
                              </Table.Cell>
                            )}
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                    <Card fluid>
                      <Card.Content>
                        <Form.Field>
                          <Form.TextArea
                            id="sConsigne"
                            className="noPrint"
                            disabled={commandeType.iEtat !== 0 || tCommandeTypeLigne.length === 0}
                            onChange={this.handleChangeConsigne}
                            label="Consignes particulières de livraison"
                            placeholder="Consignes particulières de livraison"
                            value={commandeType.sConsigne}
                          />
                          <div className="goPrint">
                            <b>Consignes particulières de livraison</b>
                            <p>{commandeType.sConsigne}</p>
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <label className="noPrint" htmlFor="dLivraison">
                            Date de livraison souhaitée
                          </label>
                          <DatePicker
                            id="dLivraison"
                            className="noPrint"
                            disabled={commandeType.iEtat !== 0 || tCommandeTypeLigne.length === 0}
                            selected={parseInt(commandeType.dLivraison, 10)}
                            onChange={date => this.handleChangeLivraison(date)}
                            locale="fr"
                            timeFormat="HH:mm"
                            showTimeSelect
                            showMonthDropdown
                            showYearDropdown
                            timeCaption="Heure"
                            minDate={
                              currentDate.getHours() >= 12
                                ? setDate(currentDate, currentDate.getDate() + 1)
                                : currentDate
                            }
                            minTime={
                              new Date(commandeType.dLivraison).getDate() ===
                                currentDate.getDate() || !commandeType.dLivraison
                                ? currentDate.getHours() >= 12
                                  ? setHours(setMinutes(new Date(), 0), 8)
                                  : new Date()
                                : setHours(setMinutes(new Date(), 0), 8)
                            }
                            maxTime={setHours(setMinutes(currentDate, 0), 20)}
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy - HH:mm"
                          />
                          <div className="goPrint">
                            <b>Date de livraison souhaitée</b>
                            <p>
                              {commandeType.dLivraison &&
                                DateTime.fromMillis(parseInt(commandeType.dLivraison, 10)).toFormat(
                                  "dd/MM/yyyy - HH:mm"
                                )}
                            </p>
                          </div>
                        </Form.Field>
                        <span style={{ clear: "both" }} />
                      </Card.Content>
                    </Card>
                    {message && (
                      <Message fluid positive>
                        {message}
                      </Message>
                    )}
                    {error && (
                      <Message fluid negative>
                        {error}
                      </Message>
                    )}
                  </Grid.Column>
                </Form>
              </Grid.Row>
            </Grid>
            <Modal
              size="tiny"
              onClose={() => this.setState({ showConfirmDelete: false })}
              open={showConfirmDelete && tCommandeTypeLigne.length === 1}
            >
              <Modal.Content>
                Vous ne pouvez pas supprimer le dernier produit, vous pouvez supprimer votre
                commande type.
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({ showConfirmDelete: false })} primary>
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
            <Confirm
              open={showConfirmDelete && tCommandeTypeLigne.length > 1}
              content={
                <div className="content">Etes-vous sûr de vouloir supprimer cette ligne ?</div>
              }
              cancelButton="Annuler"
              confirmButton="Confirmer"
              onCancel={() => this.setState({ showConfirmDelete: false })}
              onConfirm={() =>
                this.setState({ showConfirmDelete: false }, () => this.deleteCommandeTypeLigne())
              }
            />
          </>
        }
      />
    );
  }
}

CommandeTypeShow.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CommandeTypeShow;
