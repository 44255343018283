import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import FilteredTable from "../../../../components/Core/FilteredTable";
import LayoutAdmin from "../../../../components/Admin/Layout";
import CommandeShow from "../../../../components/Admin/Commande/show";

import { getCommandes } from "../../../../redux/actions/commande";

class Commande extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commandes: [],
      selectedCommande: null
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getCommandes());
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { iPKCommande }
      },
      commandes
    } = this.props;
    if (prevProps.commandes !== commandes) {
      this.setState({ commandes }, () => {
        if (iPKCommande) {
          const commande = commandes.filter(i => i.iPKCommande === iPKCommande)[0];
          this.selectCommande(commande);
        }
      });
    }
  }

  selectCommande = data => {
    this.setState({
      selectedCommande: data,
      rightPanel: {
        width: 720,
        renderHtml: <CommandeShow commande={data} />
      }
    });
  };

  render() {
    const { rightPanel, commandes, selectedCommande } = this.state;
    return (
      <LayoutAdmin title="Gestion des commandes" rightPanel={rightPanel}>
        <FilteredTable
          type="commande"
          fields={{
            iPKCommande: "N° de commande",
            dInsert: "Date de création",
            dLivraison: "Date de livraison souhaitée",
            bPriorite: "Urgent",
            "utilisateur.sNom": "Contact",
            "utilisateur.sLibelleGroupe": "Groupe",
            iEtat: "Etat"
          }}
          items={commandes}
          selectedItem={selectedCommande}
          selectItem={this.selectCommande}
        />
      </LayoutAdmin>
    );
  }
}

Commande.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  commandes: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  commandes: state.commande.liste,
  user: state.user.data,
  error: state.client.error
});

export default connect(mapStateToProps)(Commande);
