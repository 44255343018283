import React, { Component } from "react";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Form, List, Loader } from "semantic-ui-react";

import { fetchApollo } from "../../../graphql/apolloClient";

import BtnSubmit from "../../Core/Btn/Submit";

import "react-sortable-tree/style.css";

const SearchList = styled(List)`
  background: #ffffff;
`;

const Title = styled.span`
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

class FormUtilisateur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disabled: true,
      sSearchNom: "",
      sSearchCodeAnnuaire: ""
    };
  }

  handleChange = (evt, data) => {
    this.setState({ [data.id]: data.value, disabled: false });
  };

  handleCheckInactif = () => {
    const { bInactif } = this.state;
    this.setState({
      bInactif: !bInactif,
      disabled: false
    });
  };

  handleSearchSubmit = () => {
    this.setState({ loading: true }, () => {
      const GET_EMAILS = gql`
      {
        searchGroupeDiffusion(user: ${JSON.stringify(JSON.stringify(this.state))}) {
            iPKUtilisateur
            iFKClient
            title
            type
            iRole
            sCodeCivilite
            sNom
            sPrenom
            sMotDePasse
            iFKAnnAnnuaire
            sEmail
            iFKGroupe
          }
        }
      `;
      fetchApollo(GET_EMAILS).then(response => {
        const Emails = response.data.searchGroupeDiffusion;
        if (Emails) {
          this.setState({
            searchList: Emails
          });
        }
      });

      setTimeout(() => {
        this.setState({ loading: false });
      }, 500);
    });
  };

  selectUser = user => {
    const { selectUser, type } = this.props;
    selectUser(
      {
        sNom: user.sNom || "",
        sPrenom: user.sPrenom || "",
        sCodeCivilite: user.sCodeCivilite || "",
        iFKAnnAnnuaire: user.iFKAnnAnnuaire || "",
        sEmail: user.sEmail || ""
      },
      type
    );
    this.setState({
      searchList: null
    });
  };

  render() {
    const { sSearchNom, sSearchCodeAnnuaire, searchList, loading } = this.state;

    return (
      <div>
        <Title>Importer le groupe de diffusion depuis Isadom</Title>
        <Form onSubmit={this.handleSearchSubmit}>
          <Form.Input
            fluid
            id="sSearchNom"
            label="Nom"
            value={sSearchNom}
            placeholder="Nom"
            onChange={this.handleChange}
          />
          <Form.Input
            fluid
            id="sSearchCodeAnnuaire"
            label="Code annuaire"
            value={sSearchCodeAnnuaire}
            placeholder="Code annuaire"
            onChange={this.handleChange}
          />
          <BtnSubmit onClick={this.handleSearchSubmit} type="submit">
            Rechercher
          </BtnSubmit>
          <Loader active={loading} inline size="medium" />
        </Form>
        {searchList && (
          <SearchList selection divided verticalAlign="middle">
            {searchList.map(user => (
              <List.Item onClick={() => this.selectUser(user)} key={user.iFKAnnAnnuaire}>
                <List.Content>
                  <List.Header>
                    {user.sNom} {user.sPrenom}
                  </List.Header>
                </List.Content>
              </List.Item>
            ))}
          </SearchList>
        )}
      </div>
    );
  }
}

FormUtilisateur.propTypes = {
  selectUser: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormUtilisateur;
