import React, { Component } from "react";
import styled from "styled-components";
import { gql } from "apollo-boost";

import { Grid, Card, Modal, Loader } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";

import Layout from "../../../../components/Front/Layout";
import ShowMateriel from "../../../../components/AdminClient/Materiel/Show";

const CustomLoader = styled(Loader)`
  position: relative !important;
`;
class Materiel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanel: null,
      modal: null,
      loading: true,
      tMateriel: [],
    };
  }

  componentDidMount() {
    this.getMateriels();
  }

  getMateriels = () => {
    const listMaterielsByUser = gql`
      {
        listMaterielsByUser {
          iPKCommande
          iPKCommandeLigne
          iEtat
          iFKUtilisateur
          iFKProduit
          iFKClient
          dInsertCommande
          sLibelleFamille
          sLibelleSousFamille
          sRefProduit
          sNumeroSerie
          sImgPath
          sLibelleProduit
          sBeneficiaire
          sContact
          bSAV
          sObjetSAV
          sCommentaireSAV
          iEtatSAV
          bRetour
          sObjetRetour
          sCommentaireRetour
          dRetour
          iEtatRetour
          dLivraison
          sCodeAffectation
        }
      }
    `;
    fetchApollo(listMaterielsByUser).then((response) => {
      const tMateriel = response.data.listMaterielsByUser;
      if (tMateriel) {
        this.setState({
          tMateriel: tMateriel.filter((m) => m.iEtat !== 4),
        });
      }
      this.setState({
        loading: false,
      });
    });
  };

  selectMateriel = (materiel) => {
    this.setState({
      modal: (
        <ShowMateriel
          getMateriels={this.getMateriels}
          materiel={materiel}
          selectMateriel={this.selectMateriel}
        />
      ),
    });
  };

  render() {
    const { rightPanel, tMateriel, loading, modal } = this.state;
    console.log(this.materiel);

    return (
      <Layout
        sideBarRight={rightPanel}
        mainPane={
          <>
            <Grid stackable container doubling>
              <Card fluid>
                <Card.Content>
                  {loading ? (
                    <CustomLoader active />
                  ) : (
                    <Grid.Row columns={1}>
                      <FilteredTable
                        type="matériel"
                        fields={{
                          sRefProduit: "N° du matériel",
                          sCodeAffectation: "Affectation",
                          dLivraison: "Date de livraison",
                          sLibelleFamille: "Famille",
                          sLibelleSousFamille: "Sous famille",
                          iFKProduit: "Référence produit",
                          sLibelleProduit: "Désignation produit",
                          sBeneficiaire: "Bénéficiaire",
                          bSAV: "Demande SAV",
                          bRetour: "Demande Retour",
                        }}
                        items={tMateriel
                          .sort((a, b) => {
                            if (a.iEtatSAV < b.iEtatSAV) return -1;
                            if (a.iEtatSAV > b.iEtatSAV) return 1;
                            return 0;
                          })
                          .sort((a, b) => {
                            if (a.iEtatRetour < b.iEtatRetour) return -1;
                            if (a.iEtatRetour > b.iEtatRetour) return 1;
                            return 0;
                          })}
                        selectItem={this.selectMateriel}
                      />
                    </Grid.Row>
                  )}
                </Card.Content>
              </Card>
            </Grid>
            {modal && (
              <Modal
                open
                onClose={() => this.setState({ modal: null })}
                onOpen={() => this.setState({ modal: null })}
                closeIcon
              >
                <Modal.Content>{modal}</Modal.Content>
              </Modal>
            )}
          </>
        }
      />
    );
  }
}

export default Materiel;
