import {
  GET_CATALOGUE_REQUEST,
  ADD_CATALOGUE_REQUEST,
  EDIT_CATALOGUE_REQUEST,
  DELETE_CATALOGUE_REQUEST,
  LISTE_CATALOGUE_REQUEST,
  LISTE_CATALOGUE_WITHOUT_CLIENT_REQUEST,
  LISTE_FAMILLE_CATALOGUE_REQUEST,
  IMPORT_FAMILLE_CATALOGUE_REQUEST,
  LISTE_ISADOM_FAMILLE_CATALOGUE_REQUEST
} from "../constants/ActionTypes";

export const getCatalogue = iPKCatalogue => ({
  type: GET_CATALOGUE_REQUEST,
  payload: { iPKCatalogue }
});

export const getCatalogues = () => ({
  type: LISTE_CATALOGUE_REQUEST,
  payload: null
});

export const getCataloguesWithoutClient = () => ({
  type: LISTE_CATALOGUE_WITHOUT_CLIENT_REQUEST,
  payload: null
});

export const addCatalogue = catalogue => ({
  type: ADD_CATALOGUE_REQUEST,
  payload: { catalogue }
});

export const editCatalogue = catalogue => ({
  type: EDIT_CATALOGUE_REQUEST,
  payload: { catalogue }
});

export const deleteCatalogue = iPKCatalogue => ({
  type: DELETE_CATALOGUE_REQUEST,
  payload: { iPKCatalogue }
});

export const getFamillesCatalogue = iPKCatalogue => {
  return {
    type: LISTE_FAMILLE_CATALOGUE_REQUEST,
    payload: { iPKCatalogue }
  };
};

export const importFamillesCatalogue = (treeItem, iPKCatalogue) => {
  return {
    type: IMPORT_FAMILLE_CATALOGUE_REQUEST,
    payload: { treeItem, iPKCatalogue }
  };
};

export const getIsadomFamillesCatalogue = () => ({
  type: LISTE_ISADOM_FAMILLE_CATALOGUE_REQUEST,
  payload: null
});
