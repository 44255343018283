import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Accordion, Icon, Checkbox } from "semantic-ui-react";

const ListCheckbox = styled(Checkbox)`
  float: left;
  margin-right: 10px;
`;

const FamilyAccordion = styled(Accordion)`
  margin-top: 20px;
  & .title.selected {
    color: white !important;
    background: rgba(244, 166, 35, 1) !important;
  }
`;

const Title = styled.div`
  padding: 0.5em 0;
  margin-left: 18px;
  margin-bottom: 10px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

const AccordionTitle = styled.span`
line-height: 35px;
height: 35px;
display: inline-block;
width: 90%;
margin-top: -10px;
margin-bottom: -10px;
}
`;

class FamilyFormList extends Component {
  constructor(props) {
    super(props);

    this.once = false;

    this.state = {
      itemsCheck: {},
      rootPanels: [],
      familleActive: ""
    };
  }

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps) {
    const { famillesCatalogue } = this.props;
    if (prevProps.famillesCatalogue !== famillesCatalogue) {
      this.loadList();
    }
  }

  loadList = (data = {}) => {
    const { famillesCatalogue } = this.props;
    const { itemsCheck } = this.state;
    const rootPanels = [];
    famillesCatalogue.forEach(familleCatalogue => {
      rootPanels.push({
        key: familleCatalogue.sPKFamille,
        title: (
          <Accordion.Title id={familleCatalogue.sPKFamille} key={`level0${familleCatalogue.sPKFamille}`}>
            {familleCatalogue.tSousFamilleCatalogue.length > 0 && (
              <Icon
                name="dropdown"
                onClick={() => {
                  this.loadSubFamily(familleCatalogue);
                }}
              />
            )}{" "}
            <Icon name="folder open" />{" "}
            <AccordionTitle
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.selectItem(familleCatalogue);
              }}
            >
              {familleCatalogue.sLibelle || familleCatalogue.sPKFamille}{" "}
            </AccordionTitle>
            <ListCheckbox
              onClick={this.handleCheck}
              famille={familleCatalogue}
              sousfamille={null}
              produit={null}
              disabled={!!familleCatalogue.active}
              checked={!!itemsCheck[familleCatalogue.sPKFamille] || !!familleCatalogue.active}
            />
          </Accordion.Title>
        )
      });
    });
    const famille = famillesCatalogue.filter(
      f => data.famille && f.sPKFamille === data.famille.sPKFamille
    )[0];
    this.setState(
      {
        rootPanels
      },
      () => {
        if (famille) {
          this.selectItem(famille);
          if (data.sousfamille) {
            this.selectItem({
              ...data.sousfamille,
              sPKFamille: famille.sPKFamille
            });
          }
        }
      }
    );
  };

  handleCheck = (evt, data) => {
    evt.stopPropagation();
    evt.preventDefault();
    const { itemsCheck } = this.state;
    const { famillesCatalogue, sendItemsCheck } = this.props;
    if (data.famille) {
      const famille = famillesCatalogue.filter(f => f.sPKFamille === data.famille.sPKFamille)[0];
      if (!data.disabled) {
        if (data.checked) {
          if (!itemsCheck[famille.sPKFamille]) {
            itemsCheck[famille.sPKFamille] = {
              famille: {
                sLibelle: famille.sLibelle,
                sPKFamille: famille.sPKFamille,
                sInformation: famille.sInformation
              },
              tPKSousFamille: {},
              tRefProduit: {}
            };
          }
          if (data.famille && !data.sousfamille && !data.produit) {
            famille.tProduit.forEach(p => {
              itemsCheck[famille.sPKFamille].tRefProduit[p.sRefProduit] = p;
            });

            if (famille.tSousFamilleCatalogue.length > 0) {
              famille.tSousFamilleCatalogue.forEach(sf => {
                itemsCheck[famille.sPKFamille].tPKSousFamille[sf.sPKSousFamille] = {
                  sousFamille: {
                    sLibelle: sf.sLibelle,
                    sPKFamille: sf.sPKSousFamille,
                    sInformation: sf.sInformation
                  },
                  tRefProduit: {}
                };

                if (sf.tProduit) {
                  sf.tProduit.forEach(p => {
                    itemsCheck[famille.sPKFamille].tPKSousFamille[sf.sPKSousFamille].tRefProduit[
                      p.sRefProduit
                    ] = p;
                  });
                }
              });
            }
          } else if (data.famille && data.sousfamille && !data.produit) {
            const sousFamille = famille.tSousFamilleCatalogue.filter(
              f => f.sPKSousFamille === data.sousfamille.sPKSousFamille
            )[0];
            itemsCheck[famille.sPKFamille].tPKSousFamille[data.sousfamille.sPKSousFamille] = {
              ...itemsCheck[famille.sPKFamille].tPKSousFamille,
              sousFamille: {
                sLibelle: data.sousfamille.sLibelle,
                sPKFamille: data.sousfamille.sPKFamille,
                sInformation: data.sousfamille.sInformation
              },
              tRefProduit: {}
            };

            if (sousFamille.tProduit && sousFamille.tProduit.length > 0) {
              sousFamille.tProduit.forEach(p => {
                itemsCheck[famille.sPKFamille].tPKSousFamille[
                  sousFamille.sPKSousFamille
                ].tRefProduit[p.sRefProduit] = p;
              });
            }
          }

          const tPKSousFamille =
            itemsCheck[data.famille.sPKFamille] &&
            itemsCheck[data.famille.sPKFamille].tPKSousFamille;

          const tRefProduitSousFamille =
            data.sousfamille &&
            itemsCheck[data.famille.sPKFamille] &&
            itemsCheck[data.famille.sPKFamille].tPKSousFamille &&
            itemsCheck[data.famille.sPKFamille].tPKSousFamille[data.sousfamille.sPKSousFamille] &&
            itemsCheck[data.famille.sPKFamille].tPKSousFamille[data.sousfamille.sPKSousFamille]
              .tRefProduit;

          const tRefProduit =
            itemsCheck[data.famille.sPKFamille] && itemsCheck[data.famille.sPKFamille].tRefProduit;

          itemsCheck[data.famille.sPKFamille] = {
            famille: {
              sLibelle: data.famille.sLibelle,
              sPKFamille: data.famille.sPKFamille,
              sInformation: data.famille.sInformation
            },
            tPKSousFamille: data.sousfamille
              ? {
                  ...tPKSousFamille,
                  [data.sousfamille.sPKSousFamille]: {
                    sousFamille: {
                      sLibelle: data.sousfamille.sLibelle,
                      sPKFamille: data.sousfamille.sPKSousFamille,
                      sInformation: data.sousfamille.sInformation
                    },
                    tRefProduit: data.produit
                      ? {
                          ...tRefProduitSousFamille,
                          [data.produit.sRefProduit]: data.produit
                        }
                      : tRefProduitSousFamille
                  }
                }
              : tPKSousFamille,
            tRefProduit:
              !data.sousfamille && data.produit
                ? {
                    ...tRefProduit,
                    [data.produit.sRefProduit]: data.produit
                  }
                : tRefProduit
          };
        } else {
          if (data.produit && !data.sousfamille) {
            delete itemsCheck[data.famille.sPKFamille].tRefProduit[data.produit.sRefProduit];
          } else if (data.produit) {
            delete itemsCheck[data.famille.sPKFamille].tPKSousFamille[
              data.sousfamille.sPKSousFamille
            ].tRefProduit[data.produit.sRefProduit];
          } else if (data.sousfamille) {
            delete itemsCheck[data.famille.sPKFamille].tPKSousFamille[
              data.sousfamille.sPKSousFamille
            ];
          } else if (data.famille) {
            delete itemsCheck[data.famille.sPKFamille];
          }
        }
        this.setState({ itemsCheck }, () => {
          sendItemsCheck(itemsCheck);
          if (data.sousfamille) {
            this.loadSubFamily(famille, {
              ...data.sousfamille,
              sPKFamille: famille.sPKFamille
            });
          } else {
            this.loadSubFamily(famille, famille);
          }
        });
      }
      if (famille) {
        this.loadList(data);
      }
    }
  };

  loadSubFamily = (famille, selected = {}) => {
    const { itemsCheck, rootPanels } = this.state;
    const level1Panels = [];
    let Level1Content;
    if (!famille.sPKSousFamille && famille.tSousFamilleCatalogue) {
      famille.tSousFamilleCatalogue.forEach(sousFamille => {
        level1Panels.push({
          key: sousFamille.sPKSousFamille,
          title: (
            <Accordion.Title
              id={sousFamille.sPKSousFamille}
              key={`level1panel${sousFamille.sPKSousFamille}`}
            >
              <Icon
                name="folder open"
                onClick={() => {
                  this.loadSubFamily(famille);
                }}
              />{" "}
              <AccordionTitle
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.selectItem({
                    ...sousFamille,
                    sPKFamille: famille.sPKFamille
                  });
                }}
              >
                {sousFamille.sLibelle || sousFamille.sPKSousFamille}{" "}
              </AccordionTitle>
              <ListCheckbox
                onClick={this.handleCheck}
                famille={famille}
                sousfamille={{ ...sousFamille, sPKFamille: famille.sPKFamille }}
                produit={null}
                disabled={!!sousFamille.active}
                checked={
                  (itemsCheck[famille.sPKFamille] &&
                    itemsCheck[famille.sPKFamille].tPKSousFamille &&
                    !!itemsCheck[famille.sPKFamille].tPKSousFamille[sousFamille.sPKSousFamille]) ||
                  !!sousFamille.active
                }
              />
            </Accordion.Title>
          )
        });
      });
      Level1Content = (
        <div>
          {level1Panels.length > 0 && (
            <Accordion.Accordion
              style={{ marginLeft: 50, marginBottom: 25, width: "91%" }}
              key={`level1content${famille.sPKFamille}`}
              panels={level1Panels}
            />
          )}
        </div>
      );

      this.setState(
        {
          rootPanels: rootPanels.map(f =>
            (f.key === famille.sPKFamille || f.key === famille.sPKSousFamille) && !f.content
              ? { ...f, content: Level1Content }
              : { ...f, content: null }
          ),
          familleActive: famille.sPKFamille
        },
        () => {
          if (selected) {
            this.selectItem(selected);
          }
        }
      );
    }
  };

  selectItem = famille => {
    const { itemsCheck } = this.state;
    const { setProduits, famillesCatalogue } = this.props;
    const currentItem = document.getElementById(famille.sPKSousFamille || famille.sPKFamille);
    if (currentItem) {
      const elements = document.getElementsByClassName("selected");
      while (elements.length > 0) {
        elements[0].classList.remove("selected");
      }
      currentItem.classList.add("selected");
    }

    const familleSelected = famillesCatalogue.filter(
      f => f.sPKFamille === famille.sPKFamille || f.sPKFamille === famille.sPKSousFamille
    )[0];

    if (famille.tProduit && famille.tProduit.length > 0) {
      setProduits(
        famille,
        famille.tProduit && famille.tProduit.length > 0 && (
          <>
            <Title>Liste des produits</Title>
            <Accordion
              style={{ marginLeft: 18, width: "93%" }}
              key={`level2${famille.sPKFamille}`}
              styled
            >
              {famille.tProduit.map(produit => {
                return (
                  <Accordion.Title onClick={() => {}} key={`level1content${produit.sRefProduit}`}>
                    <Icon name={`${produit.sCodeNatureProduit === "M" ? "laptop" : "file"}`} />{" "}
                    {produit.sLibelle || produit.sRefProduit}{" "}
                    <ListCheckbox
                      onClick={this.handleCheck}
                      famille={familleSelected}
                      sousfamille={famille.sPKSousFamille ? famille : null}
                      produit={produit}
                      disabled={!!produit.active}
                      checked={
                        famille.sPKSousFamille
                          ? (!!familleSelected &&
                              itemsCheck[familleSelected.sPKFamille] &&
                              itemsCheck[familleSelected.sPKFamille].tPKSousFamille &&
                              itemsCheck[familleSelected.sPKFamille].tPKSousFamille[
                                famille.sPKSousFamille
                              ] &&
                              itemsCheck[familleSelected.sPKFamille].tPKSousFamille[
                                famille.sPKSousFamille
                              ].tRefProduit &&
                              itemsCheck[familleSelected.sPKFamille].tPKSousFamille[
                                famille.sPKSousFamille
                              ].tRefProduit[produit.sRefProduit]) ||
                            !!produit.active
                          : (!!itemsCheck[famille.sPKFamille] &&
                              itemsCheck[famille.sPKFamille].tRefProduit &&
                              itemsCheck[famille.sPKFamille].tRefProduit[produit.sRefProduit]) ||
                            !!produit.active
                      }
                    />
                  </Accordion.Title>
                );
              })}
            </Accordion>
          </>
        )
      );
    } else {
      setProduits(null, []);
    }
    if (famille.sPKSousFamille) {
      this.setState({
        familleActive: famille.sPKFamille
      });
    }
  };

  render() {
    const { rootPanels, familleActive } = this.state;
    const { famillesCatalogue } = this.props;
    if (!this.once && familleActive) {
      this.selectItem(famillesCatalogue.filter(f => f.sPKFamille === familleActive)[0]);
      this.once = true;
    }

    return <FamilyAccordion panels={rootPanels} styled fluid />;
  }
}

FamilyFormList.propTypes = {
  setProduits: PropTypes.func.isRequired,
  sendItemsCheck: PropTypes.func.isRequired,
  famillesCatalogue: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  error: state.group.error
});

export default connect(mapStateToProps)(FamilyFormList);
