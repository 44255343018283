import {
  LISTE_COMMANDE_SUCCESS,
  LISTE_COMMANDE_FAILURE
} from "../constants/ActionTypes";

const initialState = {
  liste: [],
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LISTE_COMMANDE_SUCCESS: {
      return { ...state, liste: payload, error: null };
    }
    case LISTE_COMMANDE_FAILURE: {
      return { ...state, liste: [], error: payload };
    }
    default:
      return state;
  }
};
