import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Icon, Label } from "semantic-ui-react";

import AppName from "./AppName";
import Authentification from "./Authentification";
import Navigation from "../Navigation/Menu";

const logo = require(`../../../../ui/assets/img/${process.env.REACT_APP_LOGO}`).default;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 70px;
  width: 100%;
  background: ${props => props.theme.background.paper};
  border-bottom: 1px solid ${props => props.theme.border.light};
`;

const Body = styled.div`
  position: fixed;
  top: 0;
  left: calc((100% - 1155px) / 2);

  padding: 15px;
  height: 70px;
  width: 1155px;
  background: ${props => props.theme.background.paper};
  border-bottom: 1px solid ${props => props.theme.border.light};
`;

const Logo = styled.img`
  float: left;
  position: relative;
  top: -3px;
  height: 50px;
  width: auto;
`;

const LogoPrint = styled.img`
  float: left;
  position: relative;
  top: -3px;
  height: 50px;
  width: auto;
  left: 210px;
  display: none;
`;

const MenuList = styled.ul`
  float: right;
  margin: 0;
  padding: 0;
  height: 40px;
`;

const MenuListItem = styled.li`
  position: relative;
  float: left;
  margin: 0 20px 0 0;
  list-style: none;
  height: 100%;

  &:last-child::after {
    display: none;
  }

  &::after {
    position: absolute;
    right: -10px;
    top: 0;
    content: " ";
    height: 40px;
    border-right: 1px solid ${props => props.theme.border.light};
  }
`;

const MenuListItemNoBorder = styled.li`
  position: relative;
  float: left;
  margin: 0 20px 0 0;
  list-style: none;
  height: 100%;
`;

const MenuListItemBefore = styled(MenuListItem)`
  &::after {
    display: none;
  }
  &::before {
    position: absolute;
    right: -10px;
    top: 0;
    content: " ";
    height: 40px;
    border-right: 1px solid ${props => props.theme.border.light};
  }
`;

const MenuListItemExternalLink = styled.a`
  display: inline-block;
  padding: 0 15px;
  height: 100%;
  line-height: 40px;
  font-size: ${props => (props.iRole > 0 ? "14px" : "16px")};
  color: ${props => props.theme.button.primary.background};
  text-transform: uppercase;
  transition: all 0.25s ease;

  &:hover {
    color: #ffffff !important;
    background: ${props => props.theme.button.primary.background};
  }

  &.active {
    color: #ffffff !important;
    background: ${props => props.theme.button.secondary.background};
  }

  &.opened {
    color: #ffffff !important;
    background: ${props => props.theme.button.primary.background};
  }
`;

const MenuListItemLink = styled(NavLink)`
  display: inline-block;
  padding: 0 15px;
  height: 100%;
  line-height: 40px;
  font-size: ${props => (props.iRole > 0 ? "14px" : "16px")};
  color: ${props => props.theme.button.primary.background};
  text-transform: uppercase;
  transition: all 0.25s ease;

  &:hover {
    color: #ffffff !important;
    background: ${props => props.theme.button.primary.background};
  }

  &.active {
    color: #ffffff !important;
    background: ${props => props.theme.button.secondary.background};
  }

  &.opened {
    color: #ffffff !important;
    background: ${props => props.theme.button.primary.background};
  }
`;

const TopBar = ({ isAuthenticated, iRole, tPanier }) => (
  <>
    <Wrapper>
      <Body>
        {isAuthenticated && <Authentification className="noPrint" />}
        {isAuthenticated && (
          <MenuList className="noPrint" iRole={iRole}>
            <MenuListItemNoBorder>
              <MenuListItemLink iRole={iRole} to="/panier">
                <Icon name="shopping cart" /> Panier
                {tPanier.length > 0 && (
                  <Label circular color="orange" floating>
                    {tPanier.length}
                  </Label>
                )}
              </MenuListItemLink>
            </MenuListItemNoBorder>
            {iRole > 0 && (
              <MenuListItemBefore>
                <MenuListItemExternalLink
                  iRole={iRole}
                  target="_blank"
                  href={`${window.location.protocol === "https:" ? "https:" : "http:" }//${iRole === 2 ? process.env.REACT_APP_THEME === "AGIR" ? "admin-" : "client." : process.env.REACT_APP_THEME === "AGIR" ? "adminagir-" : "admin."}${
                    process.env.REACT_APP_DOMAIN
                  }${window.location.port ? `:${window.location.port}` : ''}`}
                >
                  Admin
                </MenuListItemExternalLink>
              </MenuListItemBefore>
            )}
          </MenuList>
        )}
        <LogoPrint className="goPrint" src={logo} alt="" />
        <Link className="noPrint" to="/">
          <Logo src={logo} alt="" />
        </Link>
        {isAuthenticated && <Navigation className="noPrint" />}
        <AppName isAdmin={iRole > 0} />
      </Body>
    </Wrapper>
  </>
);

TopBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  iRole: PropTypes.bool.isRequired,
  tPanier: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  iRole: state.user.data.iRole,
  tPanier: state.panier.liste
});

export default connect(mapStateToProps)(TopBar);
