import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter, matchPath } from "react-router-dom";
import { TransitionGroup, Transition } from "react-transition-group";

import { handleScreenEnterAnimation, handleScreenExitAnimation } from "../ui/animations";

import { deleteCookie } from "../utils";
import NotFound from "./screens/Erreur404";

import routes from "./screens";

const filterRoutes = location =>
  routes.filter(
    ({ path, privateRoute, exact, transitionAnimation }) =>
      !!matchPath(location.pathname, {
        path,
        privateRoute,
        exact,
        transitionAnimation
      })
  );

class Animated extends React.Component {
  selectTransitionAnimation = () => ({
    enter: handleScreenEnterAnimation,
    exit: handleScreenExitAnimation,
    delay: 1000
  });

  render() {
    const { isAuthenticated, animatedTransitions, layout, user, location } = this.props;
    let anims = this.selectTransitionAnimation();
    let path;
    if (layout === "client") {
      if (isAuthenticated && user.iRole !== 1 && user.iRole !== 2) {
        deleteCookie();
        document.location = "/";
        return null;
      }
      path = "/client";
    } else if (layout === "admin") {
      if (isAuthenticated && user.iRole !== 1) {
        deleteCookie();
        document.location = "/";
        return null;
      }
      path = "/admin";
    } else {
      path = "/front";
    }
    const selected = filterRoutes(location).filter(
      s => (s.subPath && s.subPath === path) || !s.subPath
    );

    return (
      <Switch>
        <Route
          path={selected.length > 0 ? selected[0].path : ""}
          exact
          render={props => {
            if (selected.length === 0) {
              return (
                <TransitionGroup appear exit>
                  <Transition
                    key={404}
                    timeout={() => {}}
                    onEnter={() => {}}
                    onEntering={() => {}}
                    onEntered={() => {}}
                    onExit={() => {}}
                    onExiting={() => {}}
                    onExited={() => {}}
                  >
                    <Suspense fallback={<div>Chargement...</div>}>
                      <NotFound />
                    </Suspense>
                  </Transition>
                </TransitionGroup>
              );
            }

            if (selected[0].privateRoute && !isAuthenticated) {
              return <Redirect to="/connexion" />;
            }

            if (!animatedTransitions || !selected[0].transitionAnimation) {
              anims = { enter: () => {}, exit: () => {}, delay: 0 };
            }

            if (animatedTransitions) {
              return (
                <TransitionGroup appear exit>
                  {selected.map(({ key, ...attribs }) => (
                    <Transition
                      key={`child-${key}`}
                      timeout={anims.delay}
                      onEnter={anims.enter}
                      onEntering={() => {}}
                      onEntered={() => {}}
                      onExit={anims.exit}
                      onExiting={() => {}}
                      onExited={() => {}}
                    >
                      <Suspense fallback={<div>Chargement...</div>}>
                        {React.createElement(
                          selected[0].component,
                          {
                            ...props,
                            ...attribs,
                            location,
                            layout
                          },
                          null
                        )}
                      </Suspense>
                    </Transition>
                  ))}
                </TransitionGroup>
              );
            }

            return (
              <Suspense fallback={<div>Chargement...</div>}>
                {React.createElement(
                  selected[0].component,
                  {
                    location
                  },
                  null
                )}
              </Suspense>
            );
          }}
        />
      </Switch>
    );
  }
}

Animated.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  layout: PropTypes.string.isRequired,
  animatedTransitions: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired
};

Animated.defaultProps = {
  animatedTransitions: true
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.data
});

export default withRouter(connect(mapStateToProps)(Animated));
