import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";

import LayoutAdmin from "../../../../components/Admin/Layout";
import InterventionShow from "../../../../components/Admin/Intervention/show";

class Intervention extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tIntervention: [],
      selectedIntervention: null
    };
  }

  componentDidMount() {
    this.getInterventions();
  }

  componentDidUpdate(prevProps) {
    const {
      commandes,
      match: {
        params: { iPKIntervention }
      }
    } = this.props;
    if (prevProps.commandes !== commandes) {
      this.setState({ tIntervention: commandes }, () => {
        if (iPKIntervention) {
          const intervention = commandes.filter(i => i.iPKIntervention === iPKIntervention)[0];
          this.selectIntervention(intervention);
        }
      });
    }
  }

  editIntervention = intervention => {
    const { tIntervention } = this.state;
    this.setState({
      tIntervention: tIntervention.map(d =>
        d.iPKIntervention === intervention.iPKIntervention ? { ...d, ...intervention } : d
      )
    });
  };

  selectIntervention = data => {
    this.setState({
      selectedIntervention: data,
      rightPanel: {
        width: 400,
        renderHtml: (
          <InterventionShow intervention={data} editInterventionList={this.editIntervention} />
        )
      }
    });
  };

  getInterventions = () => {
    const {
      match: {
        params: { iPKIntervention }
      }
    } = this.props;
    const GET_INTERVENTIONS = gql`
      {
        listIntervention {
          iPKIntervention
          dInsert
          sRefProduit
          sLibelleProduit
          sObjet
          sCommentaire
          sPatient
          sGroupe
          iEtat
          sNumeroSerie
          sRefProduitIsadom
        }
      }
    `;
    fetchApollo(GET_INTERVENTIONS).then(response => {
      const tIntervention = response.data.listIntervention;
      if (tIntervention) {
        this.setState(
          {
            tIntervention
          },
          () => {
            if (iPKIntervention) {
              const intervention = tIntervention.filter(
                i => i.iPKIntervention === iPKIntervention
              )[0];
              this.selectIntervention(intervention);
            }
          }
        );
      }
    });
  };

  render() {
    const { rightPanel, tIntervention, selectedIntervention } = this.state;

    return (
      <LayoutAdmin title="Gestion des demandes d'intervention SAV" rightPanel={rightPanel}>
        <FilteredTable
          type="intervention"
          fields={{
            iPKIntervention: "N° d'intervention",
            dInsert: "Date",
            sRefProduitIsadom: "N° de produit",
            sRefProduit: "Produit",
            sObjet: "Objet",
            sPatient: "Contact",
            sGroupe: "Groupe",
            iEtat: "Etat"
          }}
          items={tIntervention}
          selectItem={this.selectIntervention}
          selectedItem={selectedIntervention}
        />
      </LayoutAdmin>
    );
  }
}

Intervention.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  commandes: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  commandes: state.commande.liste
});

export default connect(mapStateToProps)(Intervention);
