import axios from "axios";
import placeholder from "./ui/assets/img/placeholder.svg";

export const hasClassName = (element, className) => {
  if (!element) return false;

  const elementClassName = element.className;

  if (elementClassName === undefined) return false;

  if (elementClassName.length === 0) return false;
  if (elementClassName === className) return true;

  return new RegExp(`(^|\\s+)${className}(\\s+|$)`).test(elementClassName);
};

export const addClassName = (element, className) => {
  if (!element) return false;

  const newElement = element;

  if (!hasClassName(element, className)) {
    if (newElement.className === undefined) {
      newElement.className = className;
    } else {
      newElement.className += (element.className ? " " : "") + className;
    }
  }

  return newElement;
};

export const removeClassName = (element, className) => {
  if (!element) return false;

  const newElement = element;

  newElement.className = element.className
    .replace(new RegExp(`(^|\\s+)${className}(\\s+|$)`), " ")
    .replace(/^\s+/, "")
    .replace(/\s+$/, "");

  return newElement;
};

export const toggleElement = (element, className = "active") => {
  if (!element) return false;

  if (hasClassName(element, className)) {
    return removeClassName(element, className);
  }

  return addClassName(element, className);
};

export const htmlEntitiesDecode = (text) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(`<!doctype html><body>${text}`, "text/html");
  return dom.body.textContent
    .replace(/&apos;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&#13;/g, "");
};

export const truncate = (text, limit, after = "...") => {
  const sourceText = htmlEntitiesDecode(text);

  if (sourceText.length <= limit) return sourceText;

  const words = sourceText.split(" ");
  let truncated = "";

  words.some((word) => {
    if (truncated.length + word.length <= limit) {
      truncated += ` ${word}`;

      return false;
    }

    return true;
  });

  return truncated + after;
};

export const getCookie = (name) => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

export const setCookie = (sJeton) => {
  document.cookie = `jwtToken=${sJeton}; max-age=21600; domain=${window.location.hostname};`;
};

export const deleteCookie = () => {
  document.cookie = `jwtToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${window.location.hostname};`;
};

export const downloadFile = (e, name, path) => {
  e.preventDefault();
  e.stopPropagation();

  const token = getCookie("jwtToken");
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_GRAPHQL_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  axiosInstance
    .get(`/document${path}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

export const importImg = (id, path) => {
  let imageElement = document.getElementById(`vignette-${id}`);
  imageElement &&
    imageElement.setAttribute(
      "src",
      "data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7"
    );

  const token = getCookie("jwtToken");
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_GRAPHQL_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  axiosInstance
    .get(path, {
      responseType: "blob",
    })
    .then((response) => {
      console.log(response);
      const loaderElement = document.getElementById(`loader-${id}`);
      imageElement = document.getElementById(`vignette-${id}`);
      loaderElement.classList.remove("active");
      const reader = new window.FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = function () {
        loaderElement.classList.remove("active");
        const imageDataUrl = reader.result;
        console.log(imageDataUrl);
        imageElement.setAttribute("src", imageDataUrl);
      };
    })
    .catch(() => {
      imageElement = document.getElementById(`vignette-${id}`);
      const loaderElement = document.getElementById(`loader-${id}`);
      loaderElement.classList.remove("active");
      imageElement.setAttribute("src", placeholder);
    });
};

export const setVignetteImg = (produit) => {
  if (produit.sImgPath) {
    return `${process.env.REACT_APP_GRAPHQL_URL}/media/${produit.sImgPath}`;
  }

  if (produit.tDocument && produit.tDocument.length !== 0) {
    return importImg(
      produit.iPKProduit,
      `/document/${produit.tDocument[produit.tDocument.length - 1].sURL}`
    );
  }
  return placeholder;
};
export const setAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const generatePassword = () => {
  const length = 8;
  const string = "abcdefghijklmnopqrstuvwxyz";
  const numeric = "0123456789";
  const punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  let character = "";

  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    const entity2 = Math.ceil(string.length * Math.random() * Math.random());
    const entity3 = Math.ceil(numeric.length * Math.random() * Math.random());
    const entity4 = Math.ceil(punctuation.length * Math.random() * Math.random());
    const lower = string.charAt(entity1);
    const upper = string.charAt(entity2).toUpperCase();
    character += lower;
    character += upper;
    character += numeric.charAt(entity3);
    character += punctuation.charAt(entity4);
    password = character;
  }
  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");
  return password.substr(0, length);
};

export const checkIsValideEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const checkPassword = (sPassword) => {
  let re;
  if (sPassword.length < 8) {
    return { error: "Le mot de passe doit avoir au moins 8 caractères" };
  }
  re = /[0-9]/;
  if (!re.test(sPassword)) {
    return { error: "Le mot de passe doit contenir au moins un chiffre" };
  }
  re = /[a-z]/;
  if (!re.test(sPassword)) {
    return {
      error: "Le mot de passe doit contenir au moins une lettre minuscule",
    };
  }
  re = /[A-Z]/;
  if (!re.test(sPassword)) {
    return {
      error: "Le mot de passe doit contenir au moins une lettre majuscule",
    };
  }
  re = /[ !@#$%^&*`()_+\-=\[\]{};':"\\|,.<>\/?]/;
  if (!re.test(sPassword)) {
    return {
      error: "Le mot de passe doit contenir au moins un charactère spécial",
    };
  }
  return true;
};
