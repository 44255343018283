import React from "react";
import PropTypes from "prop-types";

import { Icon, Button, Form } from "semantic-ui-react";

import styled from "styled-components";

const Control = styled.div`
  display: inline-flex;
  width: 100%;
`;

const FormInput = styled(Form.Input)`
  flex-grow: 1;
`;

const Container = styled.div`
  margin-bottom: 10px;
`;

const PasswordInput = ({
  password,
  onChange,
  error,
  className,
  id,
  label,
  type,
  generatePassword
}) => {
  return (
    <Container className={className}>
      <Control>
        <FormInput
          type={type}
          id={id}
          label={label}
          icon={
            <Icon
              id={`icon${id}`}
              name={type === "text" ? "eye slash" : "eye"}
              onClick={() => {
                const pwd = document.getElementById(id);
                const iconPassword = document.getElementById(`icon${id}`);
                if (pwd.getAttribute("type") === "text") {
                  iconPassword.classList.remove("slash");
                  pwd.setAttribute("type", "password");
                } else {
                  iconPassword.classList.add("slash");
                  pwd.setAttribute("type", "text");
                }
              }}
              link
            />
          }
          placeholder="Mot de passe"
          autoComplete="password"
          value={password}
          onChange={onChange}
          error={error}
        />
      </Control>
      {generatePassword && (
        <Button style={{ marginTop: 5 }} size="mini" onClick={e => generatePassword(e)}>
          Générer un mot de passe
        </Button>
      )}
    </Container>
  );
};

PasswordInput.defaultProps = {
  password: "",
  error: "",
  type: "password",
  generatePassword: null
};

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  generatePassword: PropTypes.func,
  password: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string
};

export default PasswordInput;
