import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { Button, Card, Icon } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";
import Layout from "../../../../components/AdminClient/Layout";
import FormDevis from "../../../../components/AdminClient/Devis/Form";
import DevisShow from "../../../../components/Admin/Devis/show";

const CreateButton = styled(Button)`
  background-color: ${props => props.theme.colors.title.main} !important;
  color: white !important;
`;

class Devis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tDevis: [],
      selectedDevis: null,
      isOpen: false
    };
  }

  componentDidMount() {
    this.getDevis();
  }

  getFormDevis = () => {};

  getDevis = () => {
    const {
      match: {
        params: { iPKDevis }
      },
      user
    } = this.props;
    const GET_DEVIS = gql`
      {
        listDevisByGroup(iFKUtilisateur: ${user.iPKUtilisateur}) {
          iPKDevis
          dInsert
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_DEVIS).then(response => {
      const tDevis = response.data.listDevisByGroup;
      if (tDevis) {
        this.setState(
          {
            tDevis
          },
          () => {
            if (iPKDevis) {
              const devis = tDevis.filter(i => i.iPKDevis === iPKDevis)[0];
              this.selectDevis(devis);
            }
          }
        );
      }
    });
  };

  addDevis = devis => {
    const { tDevis } = this.state;
    tDevis.push(devis);
    this.setState({
      tDevis
    });
  };

  editDevis = devis => {
    const { tDevis } = this.state;
    this.setState({
      tDevis: tDevis.map(d => (d.iPKDevis === devis.iPKDevis ? { ...d, ...devis } : d))
    });
  };

  selectDevis = data => {
    this.setState({
      selectedDevis: data,
      rightPanel: {
        width: 710,
        renderHtml: <DevisShow devis={data} editDevisList={this.editDevis} />
      }
    });
  };

  handleToggleOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { rightPanel, tDevis, selectedDevis, isOpen } = this.state;
    return (
      <>
        <Layout
          title={
            <>
              Gestion des demandes de devis{" "}
              <CreateButton size="tiny" onClick={this.handleToggleOpen}>
                Créer un devis
              </CreateButton>
            </>
          }
          rightPanel={rightPanel}
        >
          {isOpen && (
            <Card fluid style={{ width: "50%" }}>
              <Card.Content>
                Création d'un devis{" "}
                <Icon
                  style={{ cursor: "pointer", float: "right" }}
                  name="close"
                  onClick={this.handleToggleOpen}
                />
              </Card.Content>
              <Card.Content>
                <FormDevis addDevisToList={this.addDevis} />
              </Card.Content>
            </Card>
          )}
          <FilteredTable
            type="devis"
            fields={{
              iPKDevis: "N° de devis",
              dInsert: "Date",
              sPatient: "Contact",
              sGroupe: "Groupe",
              iEtat: "Etat"
            }}
            items={tDevis}
            selectItem={this.selectDevis}
            selectedItem={selectedDevis}
          />
        </Layout>
      </>
    );
  }
}

Devis.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Devis);
