import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../graphql/apolloClient";

import {
  EDIT_PRODUIT_SUCCESS,
  EDIT_PRODUIT_FAILURE,
  DELETE_PRODUIT_SUCCESS,
  DELETE_PRODUIT_FAILURE
} from "../constants/ActionTypes";

export function* editProduit({ payload: { produit } }) {
  try {
    const PRODUIT_REQUEST = gql`mutation {
      editProduit(produit: ${JSON.stringify(JSON.stringify(produit))}) {
          iPKProduit
          sInformation
          sImgPath
          sLibelle
          sFields
        }
      }`;
    const response = yield call(fetchApollo, PRODUIT_REQUEST);
    const savedProduit = response.data.editProduit;

    if (savedProduit !== null) {
      yield put({ type: EDIT_PRODUIT_SUCCESS, payload: savedProduit });
    } else {
      yield put({
        type: EDIT_PRODUIT_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: EDIT_PRODUIT_FAILURE, payload: message });
  }
}

export function* deleteProduit({ payload: { iPKProduit } }) {
  try {
    const PRODUIT_REQUEST = gql`mutation {
        deleteProduit(iPKProduit: ${iPKProduit}) {
          iPKProduit
          sInformation
          sImgPath
          sLibelle
        }
      }`;
    const response = yield call(fetchApollo, PRODUIT_REQUEST);
    const deletedProduit = response.data.deleteProduit;

    if (deletedProduit !== null) {
      yield put({ type: DELETE_PRODUIT_SUCCESS, payload: deletedProduit });
    } else {
      yield put({
        type: DELETE_PRODUIT_FAILURE,
        payload: "Une erreur est survenue"
      });
    }
  } catch (error) {
    let { message } = error;

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Identifiants incorrects";
        break;
      default:
        break;
    }
    yield put({ type: DELETE_PRODUIT_FAILURE, payload: message });
  }
}
