import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";

import { composeWithDevTools } from "redux-devtools-extension";

import jwt from "jsonwebtoken";

import { setUser, signUserOut } from "./redux/actions/user";
import { getFavoris } from "./redux/actions/favoris";
import { getPanier } from "./redux/actions/panier";
import { getCommandesType } from "./redux/actions/commandeType";

import { setAuthorizationToken, getCookie, deleteCookie } from "./utils";

import rootSaga from "./redux/rootSaga";
import rootReducer from "./redux/rootReducer";

import App from "./App";

const sagaMiddleware = createSagaMiddleware();
const middleware =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(sagaMiddleware)
    : composeWithDevTools(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, middleware);
sagaMiddleware.run(rootSaga);

const history = createBrowserHistory();

if (localStorage.modalOpened) {
  localStorage.removeItem("modalOpened");
}

if (getCookie("jwtToken") && getCookie("jwtToken") !== "") {
  jwt.verify(getCookie("jwtToken"), process.env.REACT_APP_JWT_PUBLIC, (err, decoded) => {
    if (err) {
      deleteCookie();
      store.dispatch(signUserOut());
    } else {
      setAuthorizationToken(getCookie("jwtToken"));
      store.dispatch(setUser(decoded));
      store.dispatch(getFavoris());
      store.dispatch(getPanier());
      store.dispatch(getCommandesType());
    }
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);
