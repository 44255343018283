import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  height: auto;
`;

const Pane = ({ children }) => <Wrapper>{children}</Wrapper>;

Pane.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Pane;
