import React, { Component } from "react";
import { gql } from "apollo-boost";
import { Form, Loader } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import BtnSubmit from "../../../Core/Btn/Submit";

class FormTelephone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iPKParametre: null,
      sNumero: "",
      disabled: true
    };
  }

  componentDidMount() {
    this.getTelephoneParametre();
  }

  getTelephoneParametre = () => {
    const GET_PARAMETRE = gql`
      {
        getParametres {
          iPKParametre
          sNumero
        }
      }
    `;
    fetchApollo(GET_PARAMETRE).then(response => {
      const parametres = response.data.getParametres;
      if (parametres) {
        this.setState({
          iPKParametre: parametres.iPKParametre,
          sNumero: parametres.sNumero
        });
      }
    });
  };

  editTelephoneParametre = () => {
    this.setState(
      {
        loading: true,
        disabled: true
      },
      () => {
        const EDIT_PARAMETRE = gql`
      mutation {
        editTelephoneParametre (telephone: ${JSON.stringify(
          JSON.stringify(this.state)
        )}) {
          iPKParametre
          sNumero
        }
      }
    `;
        fetchApollo(EDIT_PARAMETRE).then(response => {
          const telephone = response.data.editTelephoneParametre;
          if (telephone) {
            this.setState({
              iPKParametre: telephone.iPKParametre,
              sNumero: telephone.sNumero
            });
          }
          setTimeout(() => {
            this.setState({
              loading: false
            });
          }, 250);
        });
      }
    );
  };

  handleChange = (evt, data) => {
    this.setState({
      disabled: false,
      [data.name]: data.value
    });
  };

  handleSubmit = () => {
    this.editTelephoneParametre();
  };

  render() {
    const { sNumero, disabled, loading } = this.state;
    return (
      <>
        <Form style={{ width: "50%" }} onSubmit={this.handleSubmit}>
          <Form.Input
            name="sNumero"
            value={sNumero}
            type="text"
            label="Numéro de téléphone"
            onChange={this.handleChange}
          />
          <BtnSubmit disabled={disabled} onClick={this.handleSubmit}>
            Enregistrer
          </BtnSubmit>
          <Loader active={loading} inline size="medium" />
        </Form>
      </>
    );
  }
}

export default FormTelephone;
