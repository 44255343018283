import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import { Table, Form, Card, Pagination, Grid, Icon } from "semantic-ui-react";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";

const SelectableRow = styled(Table.Row)`
  cursor: ${props => (props.selectable ? "pointer" : "")};
  background: ${props => (props.etat > 1 ? "lightgrey" : "white")};
  ${props =>
    props.selectable
      ? `
  &:hover {
    color: white !important;
    background-color: ${props.theme.colors.secondary.main} !important;
    background: ${props.theme.colors.secondary.main} !important;
  };`
      : ""}
  &.selected {
    color: white !important;
    background: ${props => props.theme.colors.secondary.main} !important;
  }
`;

class FilteredTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items || [],
      selectedItemState: null,
      dSearchLivraison: null,
      dSearchCreation: null,
      column: null,
      direction: null,
      sKeywordSearch: null,
      offsetPagination: 10,
      itemPerPage: 10
    };
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (prevProps.items !== items) {
      this.setState({ items });
    }
  }

  handleSort = clickedColumn => () => {
    const { fields } = this.props;
    const { column, items, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        items:
          clickedColumn === Object.keys(fields)[0]
            ? _.sortBy(items, [
                c => {
                  return parseInt(c[Object.keys(fields)[0]], 10);
                }
              ])
            : _.sortBy(items, [clickedColumn]),
        direction: "ascending"
      });

      return;
    }

    this.setState({
      items: items.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  };

  handleChangeLivraison = date => {
    const { items } = this.props;
    if (date) {
      const itemsFiltered = items.filter(
        c =>
          DateTime.fromMillis(parseInt(c.dLivraison, 10)).toFormat("dd/LL/yyyy") ===
          DateTime.fromJSDate(date).toFormat("dd/LL/yyyy")
      );
      this.setState({
        dSearchLivraison: date,
        items: itemsFiltered
      });
      return;
    }
    this.setState({
      dSearchLivraison: date,
      items
    });
  };

  handleChangeCreation = date => {
    const { items } = this.props;
    if (date) {
      const itemsFiltered = items.filter(
        c =>
          DateTime.fromMillis(parseInt(c.dInsert, 10)).toFormat("dd/LL/yyyy") ===
          DateTime.fromJSDate(date).toFormat("dd/LL/yyyy")
      );
      this.setState({
        dSearchCreation: date,
        items: itemsFiltered
      });
      return;
    }
    this.setState({
      dSearchCreation: date,
      items
    });
  };

  handleChange = (evt, data) => {
    const { items } = this.props;
    const itemsFiltered = items.filter(c => c[data.id] === data.value);
    if (data.value !== "") {
      this.setState({
        [data.id]: data.value,
        items: itemsFiltered
      });
      return;
    }

    this.setState({
      items
    });
  };

  handleChangeItemPerPage = (evt, data) => {
    this.setState({
      [data.id]: data.value,
      offsetPagination: data.value
    });
  };

  handleChangePagination = (evt, data) => {
    const { itemPerPage } = this.state;
    this.setState({
      offsetPagination: Math.ceil(itemPerPage * data.activePage)
    });
  };

  handleChangeSearch = (evt, data) => {
    const { items, fields } = this.props;
    const itemsFiltered = Object.keys(fields).map(field => {
      const composeKey = field.split(".");
      return items.filter(c => {
        const object = composeKey.length > 1 ? c[composeKey[0]][composeKey[1]] : c[field];
        return field !== "dInsert"
          ? (object && typeof object.includes !== "undefined" && object.includes(data.value)) ||
              (object &&
                typeof object.toLowerCase !== "undefined" &&
                object.toLowerCase().includes(data.value))
          : object && object === data.value;
      });
    });
    this.setState({
      sKeywordSearch: data.value,
      items: _.spread(_.union)(itemsFiltered)
    });
  };

  selectItem = c => {
    const { selectItem } = this.props;
    this.setState(
      {
        selectedItemState: c
      },
      () => {
        selectItem(c);
      }
    );
  };

  render() {
    const {
      sKeywordSearch,
      selectedItemState,
      items,
      column,
      direction,
      dSearchLivraison,
      dSearchCreation,
      offsetPagination,
      itemPerPage
    } = this.state;
    const { type, fields, selectable, selectedItem } = this.props;

    let tEtat = {};
    if (type === "commande") {
      tEtat = {
        0: {
          label: "En attente de validation",
          tooltip: `Votre commande a été transmise aux équipes d'${process.env.REACT_APP_THEME} Assistance pour traitement.`
        },
        1: {
          label: "En cours de traitement",
          tooltip: `Votre commande est prise en compte par les équipes d'${process.env.REACT_APP_THEME} Assistance, elle va être analysée et traitée dans les meilleurs délais.`
        },
        2: {
          label: "En cours de livraison/livrée",
          tooltip: `Votre commande a été validée par les équipes d'${process.env.REACT_APP_THEME} Assistance, elle est en chemin et/ou a déjà été livrée dans votre établissement.`
        },
        3: {
          label: "Partiellement livrée",
          tooltip: `Une partie de votre commande a été livrée, les équipes d'${process.env.REACT_APP_THEME} Assistance mettent tout en oeuvre pour livrer le reliquat dans les meilleurs délais.`
        },
        4: {
          label: "Annulée",
          tooltip: "La commande a été annulée et n'est pas livrée."
        }
      };
    } else {
      tEtat = {
        0: "En attente de validation",
        1: "En cours",
        2: type === "devis" || type === "retour" || type === "intervention" ? "Validée" : "Livrée",
        3: "Annulée"
      };
    }
    return (
      <>
        <Card fluid>
          <Card.Content style={{ padding: "2em 1em", marginTop: 1 }}>
            <Form>
              <Form.Group style={{ margin: 0 }}>
                <Grid>
                  <Form.Input
                    id="sKeywordSearch"
                    type="text"
                    icon="search"
                    iconPosition="left"
                    style={{ width: 365, marginBottom: 5 }}
                    value={sKeywordSearch}
                    placeholder={`Recherche (N° ${
                      type === "intervention" ? "d'intervention" : `de ${type}`
                    }, Contact, Groupe)`}
                    onChange={this.handleChangeSearch}
                  />
                  {fields.dInsert && (
                    <DatePicker
                      id="dCreation"
                      minWidth={80}
                      selected={dSearchCreation}
                      onChange={date => this.handleChangeCreation(date)}
                      locale="fr"
                      placeholderText="Date de création"
                      showMonthDropdown
                      showYearDropdown
                      isClearable
                      dropdownMode="select"
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                  {fields.dLivraison && (
                    <DatePicker
                      id="dLivraison"
                      selected={dSearchLivraison}
                      onChange={date => this.handleChangeLivraison(date)}
                      locale="fr"
                      placeholderText="Date de livraison"
                      showMonthDropdown
                      showYearDropdown
                      isClearable
                      dropdownMode="select"
                      dateFormat="dd/MM/yyyy"
                      style={{ width: "110px !important", minWidth: 110 }}
                    />
                  )}
                  {fields.bPriorite && (
                    <Form.Select
                      style={{ minWidth: 85 }}
                      placeholder="Urgent"
                      id="bPriorite"
                      onChange={this.handleChange}
                      options={[
                        { key: "", value: "", text: "" },
                        { key: true, value: true, text: "Oui" },
                        { key: false, value: false, text: "Non" }
                      ]}
                    />
                  )}
                  {fields.bSAV && (
                    <Form.Select
                      style={{ minWidth: 85 }}
                      placeholder="SAV"
                      id="bSAV"
                      onChange={this.handleChange}
                      options={[
                        { key: "", value: "", text: "" },
                        { key: true, value: true, text: "Oui" },
                        { key: false, value: false, text: "Non" }
                      ]}
                    />
                  )}
                  {fields.bRetour && (
                    <Form.Select
                      style={{ minWidth: 85 }}
                      placeholder="Retour"
                      id="bRetour"
                      onChange={this.handleChange}
                      options={[
                        { key: "", value: "", text: "" },
                        { key: true, value: true, text: "Oui" },
                        { key: false, value: false, text: "Non" }
                      ]}
                    />
                  )}
                  {fields.iEtat && (
                    <Form.Select
                      placeholder="Etat"
                      id="iEtat"
                      onChange={this.handleChange}
                      options={
                        type === "commande" || type === "matériel"
                          ? [
                              { key: "", value: "", text: "" },
                              { key: 0, value: 0, text: "En attente de validation" },
                              { key: 1, value: 1, text: "En cours de traitement" },
                              { key: 2, value: 2, text: "En cours de livraison/Livrée" },
                              { key: 3, value: 3, text: "Partiellement livrée" },
                              { key: 4, value: 4, text: "Annulée" }
                            ]
                          : [
                              { key: "", value: "", text: "" },
                              { key: 0, value: 0, text: "En attente de validation" },
                              { key: 1, value: 1, text: "En cours de traitement" },
                              { key: 2, value: 2, text: "Validée" },
                              { key: 3, value: 3, text: "Annulée" }
                            ]
                      }
                    />
                  )}
                  <Form.Select
                    id="itemPerPage"
                    value={itemPerPage}
                    style={{ minWidth: 50, float: 'right' }}
                    onChange={this.handleChangeItemPerPage}
                    options={[
                      { key: 10, value: 10, text: "10 / page" },
                      { key: 15, value: 15, text: "15 / page" },
                      { key: 20, value: 20, text: "20 / page" },
                      { key: 25, value: 25, text: "25 / page" },
                      { key: 50, value: 50, text: "50 / page" },
                      { key: 100, value: 100, text: "100 / page" }
                    ]}
                  />
                </Grid>
              </Form.Group>
            </Form>
          </Card.Content>
        </Card>
        <Table sortable compact celled size="small">
          <Table.Header>
            <Table.Row>
              {Object.keys(fields).map(field => (
                <Table.HeaderCell
                  sorted={column === field ? direction : null}
                  onClick={this.handleSort(field)}
                >
                  {fields[field]}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.length === 0 && (
              <Table.Row>
                <Table.Cell style={{ textAlign: "center" }} colSpan="5">
                  Vous n&apos;avez pas de {type} en attente
                </Table.Cell>
              </Table.Row>
            )}
            {items.slice(offsetPagination - itemPerPage, offsetPagination).map(c => {
              let backgroundColor = "#FFFFFF";
              if (type !== "matériel") {
                if (c.iEtat === 1) {
                  backgroundColor = "#f3ebb2c4";
                } else if (c.iEtat === 2) {
                  backgroundColor = "#bcdabfc4";
                } else if (c.iEtat === 3) {
                  backgroundColor = type === "commande" ? "#ffc686c4" : "#ff8686c4";
                } else if (c.iEtat === 4) {
                  backgroundColor = "#ff8686c4";
                }
              }
              if (c.bInactif) {
                backgroundColor = "lightgrey";
              }

              return (
                <SelectableRow
                  onClick={() => this.selectItem(c)}
                  key={c[Object.keys(fields)[0]]}
                  etat={c.iEtat}
                  selectable={selectable}
                  style={{ backgroundColor }}
                  className={
                    (selectable &&
                      selectedItemState &&
                      c[Object.keys(fields)[0]] === selectedItemState[Object.keys(fields)[0]]) ||
                    (selectedItem &&
                      c[Object.keys(fields)[0]] === selectedItem[Object.keys(fields)[0]])
                      ? "selected"
                      : ""
                  }
                >
                  {Object.keys(fields).map(field => {
                    switch (field) {
                      case "dInsert":
                        return (
                          <Table.Cell>
                            {DateTime.fromMillis(parseInt(c.dInsert, 10))
                              .toUTC()
                              .setZone("UTC+2", { keepLocalTime: true })
                              .toFormat("dd/MM/yyyy à HH:mm")}
                          </Table.Cell>
                        );
                      case "dLivraison":
                        return (
                          <Table.Cell>
                            {c.dLivraison
                              ? DateTime.fromMillis(parseInt(c.dLivraison, 10))
                                  .toUTC()
                                  .setZone("UTC+2", { keepLocalTime: true })
                                  .toFormat("dd/MM/yyyy à HH:mm")
                              : ""}
                          </Table.Cell>
                        );
                      case "dRetour":
                        return (
                          <Table.Cell>
                            {c.dRetour
                              ? DateTime.fromMillis(parseInt(c.dRetour, 10)).toFormat("dd/MM/yyyy")
                              : ""}
                          </Table.Cell>
                        );
                      case "bPriorite":
                        return <Table.Cell>{c.bPriorite ? "Oui" : ""}</Table.Cell>;
                      case "bSAV":
                        return <Table.Cell>{c.bSAV ? "Oui" : ""}</Table.Cell>;
                      case "bRetour":
                        return <Table.Cell>{c.bRetour ? "Oui" : ""}</Table.Cell>;
                      case "sCodeAffectation":
                        return (
                          <Table.Cell>
                            {c.sCodeAffectation === "L" ? "Location" : "Vente"}
                          </Table.Cell>
                        );
                      case "sCodeAnnuaire":
                        return <Table.Cell>{c.sCodeAnnuaire ? "Oui" : ""}</Table.Cell>;
                      case "utilisateur.sNom":
                        return (
                          <Table.Cell>
                            {c.utilisateur.sCodeCivilite} {c.utilisateur.sNom}{" "}
                            {c.utilisateur.sPrenom}
                          </Table.Cell>
                        );
                      case "utilisateur.sLibelleGroupe":
                        return <Table.Cell>{c.utilisateur.sLibelleGroupe}</Table.Cell>;
                      case "iEtat":
                        return (
                          <Table.Cell>
                            {type === "commande" ? (
                              <>
                                {tEtat[c.iEtat].label}{" "}
                                <span
                                  style={{ fontStyle: "italic" }}
                                  data-tooltip={tEtat[c.iEtat].tooltip}
                                  data-position="top right"
                                >
                                  <Icon name="info" />
                                </span>
                              </>
                            ) : (
                              tEtat[c.iEtat]
                            )}
                          </Table.Cell>
                        );
                      case "actions":
                        return (
                          <Table.Cell
                            style={{
                              width:
                                type !== "création de comptes" &&
                                type !== "renouvellement de mot de passe"
                                  ? "10%"
                                  : "30%",
                              textAlign: "center"
                            }}
                          >
                            {c.actions}
                          </Table.Cell>
                        );
                      default:
                        return (
                          <Table.Cell>
                            {c[field] ? c[field] : field !== "sLibelleSousFamille" ? "" : "Aucune"}
                          </Table.Cell>
                        );
                    }
                  })}
                </SelectableRow>
              );
            })}
          </Table.Body>
          {items.length / itemPerPage >= 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={Object.keys(fields).length}>
                  <span style={{ marginTop: 10, float: "left" }}>
                    Affichage de {offsetPagination - (itemPerPage - 1)} à {offsetPagination} sur{" "}
                    {items.length}{" "}
                    {type === "devis" ? type : `${type}${items.length > 1 ? "s" : ""}`}
                  </span>
                  <Pagination
                    floated="right"
                    onPageChange={this.handleChangePagination}
                    defaultActivePage={1}
                    totalPages={
                      items.length / itemPerPage >= 1 ? Math.ceil(items.length / itemPerPage) : 1
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </>
    );
  }
}

FilteredTable.defaultProps = {
  selectedItem: null,
  selectable: true
};

FilteredTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  selectable: PropTypes.bool,
  selectedItem: PropTypes.objectOf(PropTypes.any),
  selectItem: PropTypes.func.isRequired
};

export default FilteredTable;
