import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";

import LayoutAdmin from "../../../../components/Admin/Layout";
import RetourShow from "../../../../components/Admin/Retour/show";

class Retour extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tRetour: [],
      selectedRetour: null
    };
  }

  componentDidMount() {
    this.getRetours();
  }

  editRetour = retour => {
    const { tRetour } = this.state;
    this.setState({
      tRetour: tRetour.map(d => (d.iPKRetour === retour.iPKRetour ? { ...d, ...retour } : d))
    });
  };

  selectRetour = data => {
    this.setState({
      selectedRetour: data,
      rightPanel: {
        width: 400,
        renderHtml: (
          <RetourShow
            selectRetour={this.selectRetour}
            retour={data}
            editRetourList={this.editRetour}
          />
        )
      }
    });
  };

  getRetours = () => {
    const {
      match: {
        params: { iPKRetour }
      }
    } = this.props;
    const GET_RETOURS = gql`
      {
        listRetour {
          iPKRetour
          dInsert
          sRefProduit
          sLibelleProduit
          sObjet
          sCommentaire
          sPatient
          sGroupe
          iEtat
          dRetour
          sNumeroSerie
          sRefProduitIsadom
        }
      }
    `;
    fetchApollo(GET_RETOURS).then(response => {
      const tRetour = response.data.listRetour;
      if (tRetour) {
        this.setState(
          {
            tRetour
          },
          () => {
            if (iPKRetour) {
              const retour = tRetour.filter(i => i.iPKRetour === iPKRetour)[0];
              this.selectRetour(retour);
            }
          }
        );
      }
    });
  };

  render() {
    const { rightPanel, tRetour, selectedRetour } = this.state;
    return (
      <LayoutAdmin title="Gestion des demandes de retours" rightPanel={rightPanel}>
        <FilteredTable
          type="retour"
          fields={{
            iPKRetour: "N° de retour",
            dRetour: "Date de retour souhaitée",
            dInsert: "Date",
            sRefProduitIsadom: "N° de produit",
            sRefProduit: "Produit",
            sObjet: "Objet",
            sPatient: "Contact",
            sGroupe: "Groupe",
            iEtat: "Etat"
          }}
          items={tRetour}
          selectItem={this.selectRetour}
          selectedItem={selectedRetour}
        />
      </LayoutAdmin>
    );
  }
}

Retour.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  error: state.client.error
});

export default connect(mapStateToProps)(Retour);
