import React, { Component } from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { Grid, Form, Card, Message, Divider } from "semantic-ui-react";

import { Redirect, Link } from "react-router-dom";

import { fetchApollo } from "../../../graphql/apolloClient";

import { addUser, signUserIn } from "../../../redux/actions/user";

import ErrorNotice from "../../../components/Core/Form/ErrorNotice";
import Introduction from "../../../components/Screens/Connexion/Introduction";
import Submit from "../../../components/Core/Btn/Submit";
import PasswordInput from "../../../components/Core/Form/Input/PasswordInput";

import Layout from "../../../components/Front/Layout";

const ConnexionFormSubmit = styled(Submit)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 0 auto;
  }
`;

const TinyPasswordInput = styled(PasswordInput)`
  width: 50% !important;
`;

const FormErrorNotice = styled(ErrorNotice)`
  display: inline-block;
  position: relative;
  padding: 7px 10px;
  line-height: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #9f3a38 !important;
  background: #ffffff;
  border: 1px solid #e0b4b4 !important;
  border-radius: 4px;

  &:before {
    position: absolute;
    content: "";
    transform: rotate(45deg);
    background-image: none;
    z-index: 2;
    width: 0.6666em;
    height: 0.6666em;
    border-width: 1px 0 0 1px;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 41px;
    background-color: inherit;
    background-image: inherit;
    border-width: none;
    border-style: solid;
    border-color: inherit;
  }
`;

const CardLeft = styled(Card)`
  width: 90% !important;
`;

const CardRight = styled(Card)`
  margin-left: 10% !important;
  width: 90% !important;
`;

const options = [
  { key: "m", text: "Monsieur", value: "M" },
  { key: "f", text: "Madame", value: "Mme" },
  { key: "o", text: "Autre", value: "Autre" },
];

class Connexion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      dCGUAccepte: null,
      createForm: {
        sNom: "",
        sPrenom: "",
        sEmail: "",
        sStructure: "",
        sCodeCivilite: "",
        createErrors: {},
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.getTelephoneParametre();
  }

  onChange = (evt) => {
    const {
      target: { id, value },
    } = evt;

    const { errors } = this.state;
    const nextErrors = { ...errors };
    if (nextErrors[id]) {
      delete nextErrors[id];
    }

    this.setState({
      [id]: value,
      errors: nextErrors,
    });
  };

  onChangeCgu = (evt) => {
    const {
      target: { id, value, checked },
    } = evt;
    const { errors } = this.state;
    const nextErrors = { ...errors };
    if (nextErrors[id]) {
      delete nextErrors[id];
    }

    this.setState({
      [id]: checked ? value : null,
      errors: nextErrors,
    });
  };

  onChangeCreate = (evt) => {
    const {
      target: { id, value },
    } = evt;

    const { createForm } = this.state;
    const nextErrors = { ...createForm.errors };
    if (nextErrors && nextErrors[id]) {
      delete nextErrors[id];
    }

    this.setState({
      createForm: { ...createForm, [id]: value, createErrors: nextErrors },
    });
  };

  onChangeCreateCivilite = (evt, data) => {
    const { createForm } = this.state;
    const nextErrors = { ...createForm.errors };
    if (nextErrors && nextErrors[data.id]) {
      delete nextErrors[data.id];
    }

    this.setState({
      createForm: { ...createForm, [data.id]: data.value, createErrors: nextErrors },
    });
  };

  getTelephoneParametre = () => {
    const GET_PARAMETRE = gql`
      {
        getParametres {
          iPKParametre
          sNumero
        }
      }
    `;
    fetchApollo(GET_PARAMETRE).then((response) => {
      const parametres = response.data.getParametres;
      if (parametres) {
        this.setState({
          sNumero: parametres.sNumero,
        });
      }
    });
  };

  checkForm = (evt) => {
    evt.preventDefault();

    const { dispatchSignUserIn } = this.props;
    const { login, password, dCGUAccepte } = this.state;
    const errors = {};
    const errorMessages = {
      login: "Veuillez saisir votre identifiant.",
      password: "Veuillez saisir votre mot de passe.",
      dCGUAccepte: "Merci de valider les CGU.",
    };

    if (login.length === 0) {
      errors.login = errorMessages.login;
    }

    if (password.length === 0) {
      errors.password = errorMessages.password;
    }

    if (!dCGUAccepte) {
      errors.dCGUAccepte = errorMessages.dCGUAccepte;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      dispatchSignUserIn(login.trim(), password.trim(), dCGUAccepte);
    }
  };

  createContact = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { dispatchCreateUser } = this.props;
    const { createForm } = this.state;
    const errors = {};

    if (createForm.sNom.length === 0) {
      errors.sNom = "Veuillez saisir votre nom.";
    }

    if (createForm.sPrenom.length === 0) {
      errors.sPrenom = "Veuillez saisir votre prénom.";
    }

    if (createForm.sEmail.length === 0) {
      errors.sEmail = "Veuillez saisir votre e-mail.";
    }

    if (createForm.sStructure.length === 0) {
      errors.sStructure = "Veuillez saisir votre structure.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ createForm: { ...createForm, createErrors: errors } });
    } else {
      dispatchCreateUser(createForm);
      this.setState({
        createForm: {
          sNom: "",
          sPrenom: "",
          sEmail: "",
          sStructure: "",
          sCodeCivilite: "",
          createErrors: {},
        },
      });
    }
  };

  render() {
    const { isAuthenticated, user, signInError, signUpError, signUpSuccess } = this.props;
    const {
      login,
      password,
      errors,
      createForm: { sCodeCivilite, sNom, sPrenom, sEmail, sStructure, createErrors },
      sNumero,
    } = this.state;
    if (isAuthenticated) {
      if (user.iRole === 1) {
        window.location.href = `${window.location.protocol === "https:" ? "https:" : "http:"}//${
          process.env.REACT_APP_THEME === "AGIR" ? "adminagir-" : "admin."
        }${process.env.REACT_APP_DOMAIN}${window.location.port ? `:${window.location.port}` : ""}`;
      }
      return <Redirect to="/" />;
    }

    return (
      <Layout
        mainPane={
          <>
            <Grid stackable container doubling>
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign="middle">
                  <CardLeft>
                    <Card.Content>
                      <h1>Identification</h1>
                      <Introduction>
                        Bienvenue dans <strong>{process.env.REACT_APP_NAME}</strong> !
                        <br />
                        Pour commencer, veuillez vous connecter avec vos identifiants.
                      </Introduction>
                      <Form onSubmit={this.checkForm}>
                        <Form.Input
                          label="Identifiant :"
                          placeholder="Identifiant"
                          id="login"
                          value={login}
                          onChange={this.onChange}
                          error={errors.login}
                          width={8}
                        />

                        <TinyPasswordInput
                          label="Mot de passe :"
                          type="password"
                          id="password"
                          placeholder="Mot de passe"
                          password={password}
                          onChange={this.onChange}
                          error={
                            errors.password
                              ? { content: errors.password, pointing: "below" }
                              : false
                          }
                        />

                        <Form.Checkbox
                          label={
                            <label htmlFor="dCGUAccepte">
                              J'accepte les{" "}
                              <Link
                                to={
                                  process.env.REACT_APP_DEMO === "1"
                                    ? "#"
                                    : "/conditions-generales-utilisation"
                                }
                              >
                                Conditions Générales d’Utilisation
                              </Link>
                            </label>
                          }
                          id="dCGUAccepte"
                          value={DateTime.local()}
                          onChange={this.onChangeCgu}
                          error={errors.dCGUAccepte}
                        />
                        <Form.Field style={{ marginTop: 10 }}>
                          <ConnexionFormSubmit primary onClick={this.checkForm}>
                            Valider
                          </ConnexionFormSubmit>
                        </Form.Field>
                        {Object.keys(errors).length === 0 && signInError && (
                          <FormErrorNotice>{signInError}</FormErrorNotice>
                        )}
                        <Link to="/mot-de-passe-oublie">Mot de passe oublié</Link>
                      </Form>
                      {Object.keys(errors).length === 0 && signInError && (
                        <Message fluid negative>
                          Pour tout problème vous pouvez appeler le{" "}
                          <a href={`tel:${sNumero}`}>{sNumero}</a>
                        </Message>
                      )}
                    </Card.Content>
                  </CardLeft>
                </Grid.Column>
                <Grid.Column verticalAlign="middle">
                  <CardRight>
                    <Card.Content>
                      <h1>Demande de création de compte</h1>
                      <Introduction>Merci de renseigner les champs suivants.</Introduction>
                      <Form onSubmit={this.createContact}>
                        <Form.Select
                          id="sCodeCivilite"
                          label="Civilité : "
                          width={8}
                          error={
                            errors.sCodeCivilite
                              ? { content: errors.sCodeCivilite, pointing: "below" }
                              : false
                          }
                          value={sCodeCivilite}
                          options={options}
                          placeholder="Civilité"
                          onChange={this.onChangeCreateCivilite}
                        />
                        <Form.Input
                          fluid
                          label="Nom :"
                          placeholder="Nom"
                          id="sNom"
                          value={sNom}
                          onChange={this.onChangeCreate}
                          error={createErrors.sNom}
                          width={8}
                        />
                        <Form.Input
                          label="Prénom :"
                          placeholder="Prénom"
                          id="sPrenom"
                          value={sPrenom}
                          onChange={this.onChangeCreate}
                          error={createErrors.sPrenom}
                          width={8}
                        />
                        <Form.Input
                          label="Email :"
                          placeholder="Email"
                          id="sEmail"
                          autoComplete="off"
                          value={sEmail}
                          onChange={this.onChangeCreate}
                          error={createErrors.sEmail}
                          width={8}
                        />
                        <Form.Input
                          label="Structure :"
                          placeholder="Structure"
                          id="sStructure"
                          value={sStructure}
                          onChange={this.onChangeCreate}
                          error={createErrors.sStructure}
                          width={8}
                        />
                        <Form.Field>
                          <ConnexionFormSubmit primary onClick={this.createContact}>
                            Demander la création
                          </ConnexionFormSubmit>
                        </Form.Field>
                      </Form>
                      {Object.keys(createErrors).length === 0 && signUpError && (
                        <Message fluid negative>
                          {signUpError.substr(2, signUpError.length)}
                        </Message>
                      )}
                      {signUpSuccess && (
                        <Message fluid positive>
                          {signUpSuccess}
                        </Message>
                      )}
                    </Card.Content>
                  </CardRight>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider vertical>Ou</Divider>
          </>
        }
      />
    );
  }
}

Connexion.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  signInError: PropTypes.string,
  signUpError: PropTypes.string,
  signUpSuccess: PropTypes.string,
  dispatchSignUserIn: PropTypes.func.isRequired,
  dispatchCreateUser: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

Connexion.defaultProps = {
  signInError: null,
  signUpSuccess: null,
  signUpError: null,
  user: null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.data,
  signInError: state.user.signInError,
  signUpSuccess: state.user.success,
  signUpError: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSignUserIn: (sLogin, sPassword, dCGUAccepte) => {
    dispatch(signUserIn(sLogin, sPassword, dCGUAccepte));
  },
  dispatchCreateUser: (user) => {
    dispatch(addUser(user));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Connexion);
