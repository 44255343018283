import Connexion from "./Connexion";
import MotDePasseOublie from "./MotDePasseOublie";
import Deconnexion from "./Deconnexion";

import Accueil from "./Front/Accueil";
import Panier from "./Front/Panier/Panier";
import Commande from "./Front/Commande/List";
import CommandeShow from "./Front/Commande/Commande";
import CommandeTypeShow from "./Front/Commande/CommandeType";
import MentionsLegales from "./Front/MentionsLegales";
import Catalogue from "./Front/Catalogue";
import CatalogueFamille from "./Front/Catalogue/Famille";
import CatalogueSousFamille from "./Front/Catalogue/SousFamille";
import CatalogueProduit from "./Front/Catalogue/Produit";
import ConnexionFront from "./Front/Connexion";
import MaterielFrontList from "./Front/Materiel/List";
import Profile from "./Front/Profile";
import Favoris from "./Front/Profile/ProduitFavoris";

import TableauDeBord from "./Admin/TableauDeBord";
import ClientList from "./Admin/Client/List";
import ClientShow from "./Admin/Client/Client";

import CatalogueList from "./Admin/Catalogue/List";
import CatalogueShow from "./Admin/Catalogue/Catalogue";
import CatalogueImport from "./Admin/Catalogue/CatalogueImport";

import CommandeList from "./Admin/Commande/List";
import Compte from "./Admin/Compte/List";
// import MotDePasse from "./Admin/MotDePasse/List";
import DevisList from "./Admin/Devis/List";
import InterventionList from "./Admin/Intervention/List";
import RetourList from "./Admin/Retour/List";

import Parametre from "./Admin/Parametre/Parametre";
import ChangePassword from "./Admin/ChangePassword";

import TableauDeBordClient from "./AdminClient/TableauDeBordClient";
import ClientCommandeList from "./AdminClient/Commande/List";
import ContactList from "./AdminClient/Contact/List";
import MaterielList from "./AdminClient/Materiel/List";
import ClientDevisList from "./AdminClient/Devis/List";
import ClientInterventionList from "./AdminClient/Intervention/List";
import ClientRetourList from "./AdminClient/Retour/List";
import PasserCommande from "./Front/Panier/PasserCommande";
import CGU from "./Front/CGU";
import CGL from "./Front/CGL";
import CGV from "./Front/CGV";
import RGPD from "./Front/RGPD";

const screens = [
  {
    component: Accueil,
    key: "home",
    path: "/",
    subPath: "/front",
    id: "home",
    title: "Accueil",
    description: "ECommandes, site de commandes en ligne",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Profile,
    key: "profile",
    path: "/moncompte",
    subPath: "/front",
    id: "profile",
    title: "Mon compte",
    description: "ECommandes, site de commandes en ligne",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },

  {
    component: Favoris,
    key: "favoris",
    path: "/favoris",
    subPath: "/front",
    id: "favoris",
    title: "Mes favoris",
    description: "ECommandes, site de commandes en ligne",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Catalogue,
    key: "catalogue",
    path: "/catalogue",
    subPath: "/front",
    id: "catalogue",
    title: "Catalogue",
    description: "Catalogue",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CatalogueFamille,
    key: "catalogue-famille",
    path: "/catalogue/:famille",
    subPath: "/front",
    id: "catalogue-famille",
    title: "Familles de produits",
    description: "Catalogue, famille de produits",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CatalogueSousFamille,
    key: "catalogue-sous-famille",
    path: "/catalogue/:famille/:sousfamille",
    subPath: "/front",
    id: "catalogue-sous-famille",
    title: "Sous familles de produits",
    description: "Catalogue, sous famille de produits",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CatalogueProduit,
    key: "catalogue-produit",
    path: "/catalogue/:famille/:sousfamille/:produit",
    subPath: "/front",
    id: "catalogue-produit",
    title: "Produit",
    description: "Catalogue, produit",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CatalogueProduit,
    key: "catalogue-produit",
    path: "/catalogue/:famille/produit/:produit",
    subPath: "/front",
    id: "catalogue-produit",
    title: "Produit",
    description: "Catalogue, produit",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Panier,
    key: "panier",
    path: "/panier",
    subPath: "/front",
    id: "panier",
    title: "Panier",
    description: "Panier",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Commande,
    key: "commandes",
    path: "/commandes",
    subPath: "/front",
    id: "commandes",
    title: "Commandes",
    description: "Commandes",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CommandeShow,
    key: "commande",
    path: "/commandes/:iPKCommande",
    subPath: "/front",
    id: "commande",
    title: "Commande",
    description: "Commande",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CommandeTypeShow,
    key: "commande-type",
    path: "/commandes-type/:iPKCommandeType",
    subPath: "/front",
    id: "commande+-type",
    title: "Commande type",
    description: "Commande type",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: PasserCommande,
    key: "passerCommande",
    path: "/passerCommande",
    subPath: "/front",
    id: "passerCommande",
    title: "Valider la commande",
    description: "Valider la commande",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: MentionsLegales,
    key: "mentions-legales",
    path: "/mentions-legales",
    subPath: "/front",
    id: "mentions-legales",
    title: "Mentions Légales",
    description: "Mentions légales",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: CGU,
    key: "cgu",
    path: "/conditions-generales-utilisation",
    subPath: "/front",
    id: "conditions-generales-utilisation",
    title: "Conditions générales d'utilisation",
    description: "Conditions générales d'utilisation",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: CGL,
    key: "cgu",
    path: "/conditions-generales-de-location",
    subPath: "/front",
    id: "conditions-generales-de-location",
    title: "Conditions générales de location",
    description: "Conditions générales de location",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: CGV,
    key: "cgu",
    path: "/conditions-generales-de-vente",
    subPath: "/front",
    id: "conditions-generales-de-vente",
    title: "Conditions générales de vente",
    description: "Conditions générales de vente",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: RGPD,
    key: "cgu",
    path: "/politique-de-protection-des-donnees-personnelles",
    subPath: "/front",
    id: "politique-de-protection-des-donnees-personnelles",
    title: "Politique de protection des données personnelles",
    description: "Politique de protection des données personnelles",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: MaterielFrontList,
    key: "materiels",
    path: "/materiels",
    subPath: "/front",
    id: "materiels",
    title: "Gestion des materiels",
    description: "Gestion des materiels",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Connexion,
    key: "home",
    path: "/",
    subPath: "/admin",
    id: "home",
    title: "Connexion",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: Connexion,
    key: "home",
    path: "/connexion",
    subPath: "/admin",
    id: "home",
    title: "Connexion",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: ChangePassword,
    key: "changePassword",
    path: "/changePassword",
    subPath: "/admin",
    id: "changePassword",
    title: "Changement du mot de passe",
    description: "Changement du mot de passe",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Connexion,
    key: "home",
    path: "/",
    subPath: "/client",
    id: "home",
    title: "Connexion",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: MotDePasseOublie,
    key: "motdepasseoublie",
    path: "/mot-de-passe-oublie",
    subPath: "/front",
    id: "motdepasseoublie",
    title: "Mot de passe oublié",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: MotDePasseOublie,
    key: "motdepasseoublie",
    path: "/mot-de-passe-oublie",
    subPath: "/client",
    id: "motdepasseoublie",
    title: "Mot de passe oublié",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: MotDePasseOublie,
    key: "motdepasseoublie",
    path: "/mot-de-passe-oublie",
    subPath: "/admin",
    id: "motdepasseoublie",
    title: "Mot de passe oublié",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: Connexion,
    key: "home",
    path: "/connexion",
    subPath: "/client",
    id: "home",
    title: "Connexion",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: ConnexionFront,
    key: "connexion",
    path: "/connexion",
    subPath: "/front",
    id: "home",
    title: "Connexion",
    description: "Pour accéder à votre compte eCommande, merci de saisir vos identifiants",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: Parametre,
    key: "parametre",
    path: "/parametres",
    id: "config",
    title: "parametres",
    description: "Paramètrage de l'application",
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  },
  {
    component: TableauDeBord,
    key: "tableauDeBord",
    path: "/tableauDeBord",
    subPath: "/admin",
    id: "tableauDeBord",
    title: "Tableau de bord",
    description: "Tableau de bord de l'application",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: TableauDeBordClient,
    key: "tableauDeBordClient",
    path: "/tableauDeBord",
    subPath: "/client",
    id: "tableauDeBordClient",
    title: "Tableau de bord client",
    description: "Futur tableau de bord client de l'application",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: ContactList,
    key: "contacts",
    path: "/utilisateurs",
    subPath: "/client",
    id: "contacts",
    title: "Gestion des utilisateurs",
    description: "Gestion des utilisateurs",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: MaterielList,
    key: "materiels",
    path: "/materiels",
    subPath: "/client",
    id: "materiels",
    title: "Gestion des materiels",
    description: "Gestion des materiels",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: ClientDevisList,
    key: "devis",
    path: "/devis/:iPKDevis?",
    subPath: "/client",
    id: "devis",
    title: "Gestion des devis",
    description: "Gestion des devis",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: ClientInterventionList,
    key: "interventions",
    path: "/interventions/:iPKIntervention?",
    subPath: "/client",
    id: "interventions",
    title: "Gestion des interventions",
    description: "Gestion des interventions",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: ClientRetourList,
    key: "retour",
    path: "/retours/:iPKRetour?",
    subPath: "/client",
    id: "retour",
    title: "Gestion des retours",
    description: "Gestion des retours",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: ClientCommandeList,
    key: "commandes",
    path: "/commandes/:iPKCommande?",
    subPath: "/client",
    id: "commandes",
    title: "Gestion des commandes",
    description: "Gestion des commandes",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: ClientList,
    key: "clients",
    path: "/clients",
    subPath: "/admin",
    id: "clients",
    title: "Gestion des clients",
    description: "Gestion des clients",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: ClientShow,
    key: "client",
    path: "/clients/:iPKClient",
    subPath: "/admin",
    id: "client",
    title: "Gestion client",
    description: "Gestion client",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CatalogueList,
    key: "catalogues",
    path: "/catalogues",
    subPath: "/admin",
    id: "catalogues",
    title: "Gestion des catalogues",
    description: "Gestion des catalogues",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CatalogueShow,
    key: "catalogue",
    path: "/catalogues/:iPKCatalogue",
    subPath: "/admin",
    id: "catalogue",
    title: "Gestion catalogue",
    description: "Gestion catalogue",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CatalogueImport,
    key: "catalogueImport",
    path: "/catalogues/:iPKCatalogue/import",
    subPath: "/admin",
    id: "catalogueImport",
    title: "Import catalogue",
    description: "Import catalogue",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: CommandeList,
    key: "commandes",
    path: "/commandes/:iPKCommande?",
    subPath: "/admin",
    id: "commandes",
    title: "Gestion des commandes",
    description: "Gestion des commandes",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Compte,
    key: "compte",
    path: "/creationCompte",
    subPath: "/admin",
    id: "compte",
    title: "Gestion des demandes de création de compte",
    description: "Gestion des demandes de création de compte",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: DevisList,
    key: "devis",
    path: "/devis/:iPKDevis?",
    subPath: "/admin",
    id: "devis",
    title: "Gestion des devis",
    description: "Gestion des devis",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: InterventionList,
    key: "interventions",
    path: "/interventions/:iPKIntervention?",
    subPath: "/admin",
    id: "interventions",
    title: "Gestion des interventions",
    description: "Gestion des interventions",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: RetourList,
    key: "retour",
    path: "/retours/:iPKRetour?",
    subPath: "/admin",
    id: "retour",
    title: "Gestion des retours",
    description: "Gestion des retours",
    exact: true,
    privateRoute: true,
    transitionAnimation: false
  },
  {
    component: Deconnexion,
    key: "deconnexion",
    path: "/deconnexion",
    id: "home",
    title: "Déconnexion",
    description: `Vous avez fermé votre session sur l'application AIDE`,
    exact: true,
    privateRoute: false,
    transitionAnimation: false
  }
];

export default screens;
