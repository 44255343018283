import React, { Component } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";

import { fetchApollo } from "../../../../graphql/apolloClient";

import FilteredTable from "../../../../components/Core/FilteredTable";
import LayoutAdmin from "../../../../components/Admin/Layout";
import DevisShow from "../../../../components/Admin/Devis/show";

class Devis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tDevis: [],
      selectedDevis: null
    };
  }

  componentDidMount() {
    this.getDevis();
  }

  getDevis = () => {
    const {
      match: {
        params: { iPKDevis }
      }
    } = this.props;
    const GET_DEVIS = gql`
      {
        listDevis {
          iPKDevis
          dInsert
          sCommentaire
          sPatient
          sGroupe
          iEtat
        }
      }
    `;
    fetchApollo(GET_DEVIS).then(response => {
      const tDevis = response.data.listDevis;
      if (tDevis) {
        this.setState(
          {
            tDevis
          },
          () => {
            if (iPKDevis) {
              const devis = tDevis.filter(i => i.iPKDevis === iPKDevis)[0];
              this.selectDevis(devis);
            }
          }
        );
      }
    });
  };

  editDevis = devis => {
    const { tDevis } = this.state;
    this.setState({
      tDevis: tDevis.map(d => (d.iPKDevis === devis.iPKDevis ? { ...d, ...devis } : d))
    });
  };

  selectDevis = data => {
    this.setState({
      selectedDevis: data,
      rightPanel: {
        width: 710,
        renderHtml: <DevisShow devis={data} editDevisList={this.editDevis} />
      }
    });
  };

  render() {
    const { rightPanel, tDevis, selectedDevis } = this.state;
    return (
      <LayoutAdmin title="Gestion des demandes de devis" rightPanel={rightPanel}>
        <FilteredTable
          type="devis"
          fields={{
            iPKDevis: "N° de devis",
            dInsert: "Date",
            sPatient: "Contact",
            sGroupe: "Groupe",
            iEtat: "Etat"
          }}
          items={tDevis}
          selectItem={this.selectDevis}
          selectedItem={selectedDevis}
        />
      </LayoutAdmin>
    );
  }
}

Devis.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Devis;
