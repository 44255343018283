import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import styled from "styled-components";

import { Grid, Icon, Loader, Message, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { fetchApollo } from "../../../../graphql/apolloClient";

import Layout from "../../../../components/Front/Layout";
import placeholder from "../../../../ui/assets/img/placeholder.svg";
import { importImg, setVignetteImg } from "../../../../utils";

import { Breadcrumb, BreadcrumbLink } from "../../../../components/Core/Breadcrumb";

const Product = styled(Link)`
  position: relative;
  display: block;
  padding: 15px;
  background-color: ${(props) => props.theme.background.paper};
  border: 1px solid ${(props) => props.theme.border.light};
  border-radius: 4px;

  &:hover {
    box-shadow: 0 0 20px ${(props) => props.theme.background.dark};
  }

  &:hover .action {
    background: ${(props) => props.theme.button.primary.hoverBackground};
  }
`;

const Title = styled.span`
  display: block;
  padding: 0 0 15px;
  font-size: 16px;
  font-weight: bold;
  height: 55px;
  color: ${(props) => props.theme.text.primary};
`;

const Vignette = styled.img`
  display: block;
  max-width: 100%;
  height: 150px;
  object-fit: contain;
  width: ${(props) => (props.placeholder ? "50%" : "100%")};
  margin-left: ${(props) => (props.placeholder ? "25%" : "0")};
  margin-right: ${(props) => (props.placeholder ? "25%" : "0")};
`;

const Action = styled.span`
  display: block;
  margin: 15px -15px -15px;
  padding: 15px 0;
  text-align: center;
  color: ${(props) => props.theme.button.primary.text};
  background: ${(props) => props.theme.button.primary.background};
  transition: all 0.25s ease;
`;

class ProduitFavoris extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tProduit: [],
      tProduitOrigin: [],
      message: null,
      loading: true,
      sSearch: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.listProduitFavoris();
  }

  listProduitFavoris = () => {
    const { user } = this.props;
    const GET_FAMILLE_CATALOGUE = gql`
      {
        listProduitFavoris(iFKUtilisateur: ${user.iPKUtilisateur}) {
          iPKProduit
          sLibelle
          sLibelleFamille
          sLibelleSousFamille
          sRefProduit
          sInformation
          sCodeNatureProduit
          sImgPath
          bInactif
          iFKCatFamille
          iQuantite
          tDocument {
            iPKCatCatalogueProduitDocument
            sLibelle
            sURL
            iFKDocument
            iFKCatCatalogueProduit
            bInactif
          }
        }
      }
    `;
    fetchApollo(GET_FAMILLE_CATALOGUE).then((response) => {
      const tProduit = response.data.listProduitFavoris;

      if (tProduit) {
        const tProduitOrigin = tProduit.sort((a, b) => {
          if (a.sLibelle.toLowerCase() < b.sLibelle.toLowerCase()) {
            return -1;
          }
          if (a.sLibelle.toLowerCase() > b.sLibelle.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        this.setState({
          tProduitOrigin,
          tProduit: tProduitOrigin,
          loading: false,
        });
      }
    });
  };

  searchChange = (evt, data) => {
    const { tProduitOrigin } = this.state;
    this.setState({
      sSearch: data.value,
      tProduit: data.value
        ? tProduitOrigin.filter((p) => p.sLibelle.toLowerCase().indexOf(data.value) !== -1)
        : tProduitOrigin,
    });
  };

  setVignetteImg = (produit) => {
    if (produit.sImgPath) {
      return `${process.env.REACT_APP_GRAPHQL_URL}/media/${produit.sImgPath}`;
    }

    if (produit.tDocument.length !== 0) {
      return importImg(produit.iPKProduit, `/document/${produit.tDocument[0].sURL}`);
    }
    return placeholder;
  };

  render() {
    const { loading, tProduit, message, sSearch } = this.state;
    return (
      <Layout
        mainPane={
          <Grid stackable container doubling>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Breadcrumb>
                  <BreadcrumbLink to="/">
                    <Icon name="home" />
                  </BreadcrumbLink>
                  <BreadcrumbLink to="/catalogue">Catalogue</BreadcrumbLink>
                  Mes favoris
                </Breadcrumb>

                <div style={{ marginTop: 20 }}>
                  <h1 style={{ float: "left", marginRight: 20 }}>Mes favoris</h1>
                  <Input
                    style={{ float: "left" }}
                    onChange={this.searchChange}
                    value={sSearch}
                    icon={{ name: "search", circular: true, link: true }}
                    placeholder="Recherche..."
                  />
                  <div style={{ clear: "both" }} />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              {loading && <Loader active />}
              {tProduit.map((produit) => (
                <Grid.Column key={produit.iPKProduit} style={{ marginBottom: 30 }}>
                  <Product
                    to={`/catalogue/${encodeURIComponent(produit.sLibelleFamille)}${
                      produit.sLibelleSousFamille
                        ? `/${encodeURIComponent(produit.sLibelleSousFamille)}`
                        : ""
                    }/produit/${encodeURIComponent(produit.sLibelle)}`}
                  >
                    <Title>{produit.sLibelle}</Title>
                    <figure>
                      <Loader
                        active={produit.tDocument?.length > 0}
                        id={`loader-${produit.iPKProduit}`}
                      />
                      <Vignette
                        placeholder={!produit.sImgPath}
                        id={`vignette-${produit.iPKProduit}`}
                        src={setVignetteImg(produit)}
                        alt=""
                      />
                      {produit.sImgPath && (
                        <figcaption
                          style={{
                            color: "black",
                            textAlign: "center",
                            fontStyle: "italic",
                            fontSize: 12,
                          }}
                        >
                          Photo non contractuelle
                        </figcaption>
                      )}
                    </figure>
                    <Action className="action">Voir la fiche produit</Action>
                  </Product>
                </Grid.Column>
              ))}
            </Grid.Row>
            <Grid.Row>
              {message && (
                <Message fluid warning>
                  {message}
                </Message>
              )}
            </Grid.Row>
          </Grid>
        }
      />
    );
  }
}

ProduitFavoris.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps)(ProduitFavoris);
