const theme = {
  ADS: {
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    colors: {
      navBar: {
        background: "rgba(178, 179, 179, 0.2)",
      },
      title: {
        main: "rgba(244, 166, 35, 1)",
        link: "rgb(244, 141, 35)",
      },
      primary: {
        main: "rgba(88, 164, 202, 1)", // bleu ARAIR
        hover: "rgba(244, 166, 35, 1)",
        active: "rgb(244, 141, 35)",
      },
      secondary: {
        main: "rgba(237, 139, 0, 1)", // orange ARAIR
        hover: "rgba(204, 119, 0, 1)", // orange ARAIR 40%
      },
      warning: {
        main: "rgba(244, 166, 35, 1)", // orange
        hover: "rgb(244, 141, 35)", // orange foncé
      },
      error: {
        main: "rgba(230, 67, 49, 1)", // rouge
        hover: "rgb(249, 25, 0)", // rouge foncé
      },
      link: {
        main: "rgba(204, 119, 0, 1)", // orange ARAIR 40%
      },
      white: "rgba(255, 255, 255, 1)", // blanc
    },
    button: {
      primary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(88, 164, 202, 1)", // bleu ARAIR
        hoverBackground: "rgba(49, 120, 155, 1)", // bleu ARAIR 40%
      },
      secondary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(237, 139, 0, 1)", // orange ARAIR
        hoverBackground: "rgba(204, 119, 0, 1)", // orange ARAIR 40%
      },
      neutral: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(145, 147, 136, 1)", // gris ARAIR
        hoverBackground: "rgba(105, 107, 97, 1)", // gris ARAIR 40%
      },
    },
    text: {
      primary: "rgba(26, 27, 24, 1)", // gris ARAIR 10%
      secondary: "rgba(88, 164, 202, 1)", // bleu ARAIR
      disabled: "rgba(206, 207, 201, 1)", // gris ARAIR 80%
      hint: "rgba(88, 164, 202, 1)", // bleu ARAIR
    },
    action: {
      active: "#ffa327",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    border: {
      lighter: "#eff0f1",
      light: "#beb5b6",
      dark: "#a29999",
    },
    background: {
      paper: "rgb(255, 255, 255)",
      default: "rgb(236, 244, 249)", // gris ARAIR 95%
      light: "rgb(241, 242, 246)",
      lighter: "rgb(251, 252, 255)",
      dark: "rgba(178, 179, 179, 0.2)",
    },
  },
  AGIR: {
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    colors: {
      navBar: {
        background: "#39b2c6",
      },
      title: {
        main: "#b33e5e",
        link: "#a4185e",
      },
      primary: {
        main: "#606668", // gris
        hover: "#39b2c6",
        active: "#19a1b6",
      },
      secondary: {
        main: "#39b2c6", // blueagir
        hover: "rgba(75, 159, 70, 1)", // vert foncé
      },
      warning: {
        main: "#FD6A02", // orange
        hover: "#fd6a02bf",
      },
      error: {
        main: "rgba(230, 67, 49, 1)", // rouge
      },
      white: "rgba(255, 255, 255, 1)", // blanc
      black: "rgba(0, 0, 0, 1)", // black
    },
    button: {
      primary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(45, 171, 207, 1)", // bleu
        hoverBackground: "rgba(27, 131, 186, 1)", // bleu foncé
      },
      secondary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(147, 198, 83, 1)", // vert
        hoverBackground: "rgba(75, 159, 70, 1)", // vert foncé
      },
      neutral: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(88, 88, 88, 1)", // gris
        hoverBackground: "rgba(214, 215, 216, 1)", // gris clair
      },
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    border: {
      light: "rgb(222, 222, 222)",
      dark: "rgb(178, 179, 179)",
    },
    background: {
      paper: "#ffffff",
      default: "radial-gradient(#f6f7d9, #f0f2ba)",
      light: "rgb(241, 242, 246)",
      lighter: "#beedf4",
      dark: "#b2b3b338",
    },
  },
  ASTEN: {
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    colors: {
      navBar: {
        background: "#39b2c6",
      },
      title: {
        main: "#b33e5e",
        link: "#a4185e",
      },
      primary: {
        main: "#606668", // gris
        hover: "#004f9f", // bleu foncé
        active: "#41a1da", // bleu clair
      },
      secondary: {
        main: "#999d1e", // vert foncé
        hover: "#ced428", // vert clair
      },
      warning: {
        main: "#FD6A02", // orange
        hover: "#fd6a02bf",
      },
      error: {
        main: "rgba(230, 67, 49, 1)", // rouge
      },
      white: "rgba(255, 255, 255, 1)", // blanc
      black: "rgba(0, 0, 0, 1)", // black
    },
    button: {
      primary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(45, 171, 207, 1)", // bleu
        hoverBackground: "rgba(27, 131, 186, 1)", // bleu foncé
      },
      secondary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(147, 198, 83, 1)", // vert
        hoverBackground: "rgba(75, 159, 70, 1)", // vert foncé
      },
      neutral: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(88, 88, 88, 1)", // gris
        hoverBackground: "rgba(214, 215, 216, 1)", // gris clair
      },
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    border: {
      light: "rgb(222, 222, 222)",
      dark: "rgb(178, 179, 179)",
    },
    background: {
      paper: "#ffffff",
      default: "radial-gradient(#f6f7d9, #f0f2ba)",
      light: "rgb(241, 242, 246)",
      lighter: "#beedf4",
      dark: "#b2b3b338",
    },
  },
  APARD: {
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    colors: {
      navBar: {
        background: "linear-gradient(to right, transparent, rgba(147, 198, 83, 1))",
      },
      primary: {
        main: "rgba(45, 171, 207, 1)", // bleu
      },
      secondary: {
        main: "rgba(147, 198, 83, 1)", // vert
        hover: "rgba(75, 159, 70, 1)", // vert foncé
      },
      warning: {
        main: "rgba(244, 166, 35, 1)", // orange
      },
      error: {
        main: "rgba(230, 67, 49, 1)", // rouge
      },
      white: "rgba(255, 255, 255, 1)", // blanc
      black: "rgba(0, 0, 0, 1)", // black
      // white: "#ffffff", // blanc
      // black: "#413b3b"
    },
    button: {
      primary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(45, 171, 207, 1)", // bleu
        hoverBackground: "rgba(27, 131, 186, 1)", // bleu foncé
      },
      secondary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(147, 198, 83, 1)", // vert
        hoverBackground: "rgba(75, 159, 70, 1)", // vert foncé
      },
      neutral: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(88, 88, 88, 1)", // gris
        hoverBackground: "rgba(214, 215, 216, 1)", // gris clair
      },
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    border: {
      light: "rgb(222, 222, 222)",
      dark: "rgb(178, 179, 179)",
    },
    background: {
      paper: "#ffffff",
      default: "#fafafa",
      light: "#ffedd6",
      lighter: "#f9f2e8",
      dark: "rgba(178, 179, 179, 0.5)",
    },
  },
  ARAIR: {
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    colors: {
      navBar: {
        background: "linear-gradient(to right, transparent, rgba(237, 139, 0, 1))",
      },
      title: {
        main: "rgba(244, 166, 35, 1)",
        link: "rgb(244, 141, 35)",
      },
      primary: {
        main: "rgba(88, 164, 202, 1)", // bleu ARAIR
        hover: "rgba(244, 166, 35, 1)",
        active: "rgb(244, 141, 35)",
      },
      secondary: {
        main: "rgba(237, 139, 0, 1)", // orange ARAIR
        hover: "rgba(204, 119, 0, 1)", // orange ARAIR 40%
      },
      warning: {
        main: "rgba(244, 166, 35, 1)", // orange
        hover: "rgb(244, 141, 35)", // orange foncé
      },
      error: {
        main: "rgba(230, 67, 49, 1)", // rouge
        hover: "rgb(249, 25, 0)", // rouge foncé
      },
      link: {
        main: "rgba(204, 119, 0, 1)", // orange ARAIR 40%
      },
      white: "rgba(255, 255, 255, 1)", // blanc
    },
    button: {
      primary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(88, 164, 202, 1)", // bleu ARAIR
        hoverBackground: "rgba(49, 120, 155, 1)", // bleu ARAIR 40%
      },
      secondary: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(237, 139, 0, 1)", // orange ARAIR
        hoverBackground: "rgba(204, 119, 0, 1)", // orange ARAIR 40%
      },
      neutral: {
        text: "rgba(255, 255, 255, 1)", // blanc
        hover: "rgba(255, 255, 255, 1)", // blanc
        background: "rgba(145, 147, 136, 1)", // gris ARAIR
        hoverBackground: "rgba(105, 107, 97, 1)", // gris ARAIR 40%
      },
    },
    text: {
      primary: "rgba(26, 27, 24, 1)", // gris ARAIR 10%
      secondary: "rgba(88, 164, 202, 1)", // bleu ARAIR
      disabled: "rgba(206, 207, 201, 1)", // gris ARAIR 80%
      hint: "rgba(88, 164, 202, 1)", // bleu ARAIR
    },
    action: {
      active: "#ffa327",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    border: {
      lighter: "#eff0f1",
      light: "#beb5b6",
      dark: "#a29999",
    },
    background: {
      paper: "rgb(255, 255, 255)",
      default: "rgb(236, 244, 249)", // gris ARAIR 95%
      light: "rgb(241, 242, 246)",
      lighter: "rgb(251, 252, 255)",
      dark: "rgba(178, 179, 179, 0.2)",
    },
  },
};

export default theme;
