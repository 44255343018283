import React from "react";
import { Grid } from "semantic-ui-react";

import Layout from "../../../components/Front/Layout";

const MentionsLegales = () => {
  window.scrollTo(0, 0);
  return (
    <Layout
      mainPane={
        <Grid stackable container doubling>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h1>Mentions Légales</h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut elementum elit.
                Vestibulum eu purus eros. Quisque ac est tempus, sodales purus suscipit, hendrerit
                lacus. Mauris suscipit dui sed ipsum sodales, at dictum ligula semper. Sed convallis
                vel arcu id posuere. Phasellus pulvinar metus in justo semper mollis. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed
                egestas faucibus ligula quis hendrerit. Curabitur non diam ut est rhoncus volutpat
                et quis nisl. Duis vitae ultricies augue. Integer molestie pretium hendrerit. Sed at
                sagittis arcu, tempus blandit arcu. Nullam sed neque ac nulla condimentum tristique.
                Nunc fermentum ut neque et mollis. Sed in orci sit amet sapien accumsan luctus. Nam
                dapibus quam vel lectus faucibus tempor.
              </p>
              <p>
                Nullam sed blandit tellus. Curabitur lacinia lacus vitae quam tincidunt, vitae
                sollicitudin purus scelerisque. Sed in condimentum ligula. Ut sagittis posuere
                pulvinar. Sed ac fermentum orci. Sed eleifend urna id metus luctus, vel facilisis
                quam tempor. Morbi et lorem euismod, tincidunt metus eget, congue libero. Sed lorem
                leo, ultrices et vestibulum vel, eleifend a justo. Duis nisl ipsum, aliquet in lorem
                eu, malesuada venenatis urna. Ut molestie nisl ac fermentum mattis. Sed fringilla
                quam arcu, ac suscipit ex fermentum et. Morbi sagittis elit ac dui porta sagittis.
              </p>
              <p>
                Ut at turpis arcu. Sed pulvinar tristique odio, quis condimentum augue molestie id.
                Nulla ut ipsum dictum, maximus tortor mattis, congue lectus. In eleifend ligula
                tincidunt turpis tristique bibendum. Pellentesque at odio elit. Nulla porta eu justo
                a venenatis. Proin imperdiet massa velit, at egestas erat egestas a. Vivamus quis
                mattis elit, quis porttitor ligula. Vivamus rhoncus magna vitae erat accumsan
                euismod. Sed ut massa suscipit, sagittis sem porta, congue odio. Donec aliquet
                sodales purus eget rutrum. Etiam vehicula, lacus a pharetra convallis, erat lorem
                maximus nibh, vitae vulputate sem erat non mauris. Fusce in nisi ultrices, sodales
                mi nec, tristique felis. Pellentesque odio diam, lacinia quis eros non, facilisis
                volutpat enim.
              </p>
              <p>
                Curabitur placerat mi eget gravida accumsan. Aenean et venenatis ante. Duis et
                vehicula magna, eu rutrum orci. Sed quis risus et enim lobortis condimentum sit amet
                non tellus. Pellentesque maximus lacus enim, in fermentum ipsum gravida at. Fusce
                dictum dolor ut est aliquam, sit amet porta purus porta. Sed faucibus dictum eros,
                ut hendrerit magna tincidunt ut. Vestibulum ut aliquet mauris, at luctus mi. Donec
                tristique in turpis nec suscipit. Pellentesque at sem interdum, ullamcorper lacus
                ut, hendrerit lacus. Vivamus rutrum viverra purus vitae dictum.
              </p>
              <p>
                Curabitur nec bibendum arcu. Fusce sed congue libero. Interdum et malesuada fames ac
                ante ipsum primis in faucibus. Nullam quis finibus nibh, sed tempor mi. In libero
                massa, aliquam ut lectus sit amet, euismod convallis tortor. Maecenas nisl sapien,
                pharetra id libero at, congue porta nibh. Phasellus justo urna, ultricies sed dui
                vel, commodo tempus metus.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut elementum elit.
                Vestibulum eu purus eros. Quisque ac est tempus, sodales purus suscipit, hendrerit
                lacus. Mauris suscipit dui sed ipsum sodales, at dictum ligula semper. Sed convallis
                vel arcu id posuere. Phasellus pulvinar metus in justo semper mollis. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed
                egestas faucibus ligula quis hendrerit. Curabitur non diam ut est rhoncus volutpat
                et quis nisl. Duis vitae ultricies augue. Integer molestie pretium hendrerit. Sed at
                sagittis arcu, tempus blandit arcu. Nullam sed neque ac nulla condimentum tristique.
                Nunc fermentum ut neque et mollis. Sed in orci sit amet sapien accumsan luctus. Nam
                dapibus quam vel lectus faucibus tempor.
              </p>
              <p>
                Nullam sed blandit tellus. Curabitur lacinia lacus vitae quam tincidunt, vitae
                sollicitudin purus scelerisque. Sed in condimentum ligula. Ut sagittis posuere
                pulvinar. Sed ac fermentum orci. Sed eleifend urna id metus luctus, vel facilisis
                quam tempor. Morbi et lorem euismod, tincidunt metus eget, congue libero. Sed lorem
                leo, ultrices et vestibulum vel, eleifend a justo. Duis nisl ipsum, aliquet in lorem
                eu, malesuada venenatis urna. Ut molestie nisl ac fermentum mattis. Sed fringilla
                quam arcu, ac suscipit ex fermentum et. Morbi sagittis elit ac dui porta sagittis.
              </p>
              <p>
                Ut at turpis arcu. Sed pulvinar tristique odio, quis condimentum augue molestie id.
                Nulla ut ipsum dictum, maximus tortor mattis, congue lectus. In eleifend ligula
                tincidunt turpis tristique bibendum. Pellentesque at odio elit. Nulla porta eu justo
                a venenatis. Proin imperdiet massa velit, at egestas erat egestas a. Vivamus quis
                mattis elit, quis porttitor ligula. Vivamus rhoncus magna vitae erat accumsan
                euismod. Sed ut massa suscipit, sagittis sem porta, congue odio. Donec aliquet
                sodales purus eget rutrum. Etiam vehicula, lacus a pharetra convallis, erat lorem
                maximus nibh, vitae vulputate sem erat non mauris. Fusce in nisi ultrices, sodales
                mi nec, tristique felis. Pellentesque odio diam, lacinia quis eros non, facilisis
                volutpat enim.
              </p>
              <p>
                Curabitur placerat mi eget gravida accumsan. Aenean et venenatis ante. Duis et
                vehicula magna, eu rutrum orci. Sed quis risus et enim lobortis condimentum sit amet
                non tellus. Pellentesque maximus lacus enim, in fermentum ipsum gravida at. Fusce
                dictum dolor ut est aliquam, sit amet porta purus porta. Sed faucibus dictum eros,
                ut hendrerit magna tincidunt ut. Vestibulum ut aliquet mauris, at luctus mi. Donec
                tristique in turpis nec suscipit. Pellentesque at sem interdum, ullamcorper lacus
                ut, hendrerit lacus. Vivamus rutrum viverra purus vitae dictum.
              </p>
              <p>
                Curabitur nec bibendum arcu. Fusce sed congue libero. Interdum et malesuada fames ac
                ante ipsum primis in faucibus. Nullam quis finibus nibh, sed tempor mi. In libero
                massa, aliquam ut lectus sit amet, euismod convallis tortor. Maecenas nisl sapien,
                pharetra id libero at, congue porta nibh. Phasellus justo urna, ultricies sed dui
                vel, commodo tempus metus.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut elementum elit.
                Vestibulum eu purus eros. Quisque ac est tempus, sodales purus suscipit, hendrerit
                lacus. Mauris suscipit dui sed ipsum sodales, at dictum ligula semper. Sed convallis
                vel arcu id posuere. Phasellus pulvinar metus in justo semper mollis. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed
                egestas faucibus ligula quis hendrerit. Curabitur non diam ut est rhoncus volutpat
                et quis nisl. Duis vitae ultricies augue. Integer molestie pretium hendrerit. Sed at
                sagittis arcu, tempus blandit arcu. Nullam sed neque ac nulla condimentum tristique.
                Nunc fermentum ut neque et mollis. Sed in orci sit amet sapien accumsan luctus. Nam
                dapibus quam vel lectus faucibus tempor.
              </p>
              <p>
                Nullam sed blandit tellus. Curabitur lacinia lacus vitae quam tincidunt, vitae
                sollicitudin purus scelerisque. Sed in condimentum ligula. Ut sagittis posuere
                pulvinar. Sed ac fermentum orci. Sed eleifend urna id metus luctus, vel facilisis
                quam tempor. Morbi et lorem euismod, tincidunt metus eget, congue libero. Sed lorem
                leo, ultrices et vestibulum vel, eleifend a justo. Duis nisl ipsum, aliquet in lorem
                eu, malesuada venenatis urna. Ut molestie nisl ac fermentum mattis. Sed fringilla
                quam arcu, ac suscipit ex fermentum et. Morbi sagittis elit ac dui porta sagittis.
              </p>
              <p>
                Ut at turpis arcu. Sed pulvinar tristique odio, quis condimentum augue molestie id.
                Nulla ut ipsum dictum, maximus tortor mattis, congue lectus. In eleifend ligula
                tincidunt turpis tristique bibendum. Pellentesque at odio elit. Nulla porta eu justo
                a venenatis. Proin imperdiet massa velit, at egestas erat egestas a. Vivamus quis
                mattis elit, quis porttitor ligula. Vivamus rhoncus magna vitae erat accumsan
                euismod. Sed ut massa suscipit, sagittis sem porta, congue odio. Donec aliquet
                sodales purus eget rutrum. Etiam vehicula, lacus a pharetra convallis, erat lorem
                maximus nibh, vitae vulputate sem erat non mauris. Fusce in nisi ultrices, sodales
                mi nec, tristique felis. Pellentesque odio diam, lacinia quis eros non, facilisis
                volutpat enim.
              </p>
              <p>
                Curabitur placerat mi eget gravida accumsan. Aenean et venenatis ante. Duis et
                vehicula magna, eu rutrum orci. Sed quis risus et enim lobortis condimentum sit amet
                non tellus. Pellentesque maximus lacus enim, in fermentum ipsum gravida at. Fusce
                dictum dolor ut est aliquam, sit amet porta purus porta. Sed faucibus dictum eros,
                ut hendrerit magna tincidunt ut. Vestibulum ut aliquet mauris, at luctus mi. Donec
                tristique in turpis nec suscipit. Pellentesque at sem interdum, ullamcorper lacus
                ut, hendrerit lacus. Vivamus rutrum viverra purus vitae dictum.
              </p>
              <p>
                Curabitur nec bibendum arcu. Fusce sed congue libero. Interdum et malesuada fames ac
                ante ipsum primis in faucibus. Nullam quis finibus nibh, sed tempor mi. In libero
                massa, aliquam ut lectus sit amet, euismod convallis tortor. Maecenas nisl sapien,
                pharetra id libero at, congue porta nibh. Phasellus justo urna, ultricies sed dui
                vel, commodo tempus metus.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    />
  );
};
export default MentionsLegales;
