import styled from "styled-components";

const Field = styled.div`
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }
`;

export default Field;
