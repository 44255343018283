import {
  ADD_USER_REQUEST,
  EDIT_USER_REQUEST,
  DELETE_USER_REQUEST,
  SEARCH_USER_REQUEST,
  CLEAR_LIST_SEARCH_USER_REQUEST,
  LISTE_CONTACT_REQUEST,
  ADD_CONTACT_REQUEST,
  EDIT_CONTACT_REQUEST,
  DELETE_CONTACT_REQUEST,
  USER_SIGN_IN_REQUEST,
  USER_SIGN_OUT_REQUEST,
  SET_USER
} from "../constants/ActionTypes";

export const addUser = user => ({
  type: ADD_USER_REQUEST,
  payload: { user }
});

export const editUser = user => ({
  type: EDIT_USER_REQUEST,
  payload: { user }
});

export const deleteUser = (iPKUtilisateur, iFKClient) => ({
  type: DELETE_USER_REQUEST,
  payload: { iPKUtilisateur, iFKClient }
});

export const searchUser = user => ({
  type: SEARCH_USER_REQUEST,
  payload: { user }
});

export const clearListSearchUser = () => ({
  type: CLEAR_LIST_SEARCH_USER_REQUEST,
  payload: {}
});

export const setUser = user => ({
  type: SET_USER,
  payload: { user }
});

export const signUserIn = (sEmail, sMotDePasse, dCGUAccepte) => ({
  type: USER_SIGN_IN_REQUEST,
  payload: { sEmail, sMotDePasse, dCGUAccepte }
});

export const signUserOut = () => ({
  type: USER_SIGN_OUT_REQUEST,
  payload: { token: null, error: null }
});

export const addContact = user => ({
  type: ADD_CONTACT_REQUEST,
  payload: { user }
});

export const editContact = user => ({
  type: EDIT_CONTACT_REQUEST,
  payload: { user }
});

export const deleteContact = (iPKUtilisateur, iFKClient) => ({
  type: DELETE_CONTACT_REQUEST,
  payload: { iPKUtilisateur, iFKClient }
});

export const getContactsByUser = iPKUtilisateur => ({
  type: LISTE_CONTACT_REQUEST,
  payload: { iPKUtilisateur }
});
