import {
  LISTE_COMMANDE_TYPE_SUCCESS,
  LISTE_COMMANDE_TYPE_FAILURE,
  ADD_COMMANDE_TYPE_SUCCESS,
  ADD_COMMANDE_TYPE_FAILURE,
  DELETE_COMMANDE_TYPE_SUCCESS,
  DELETE_COMMANDE_TYPE_FAILURE
} from "../constants/ActionTypes";

const initialState = {
  liste: [],
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LISTE_COMMANDE_TYPE_SUCCESS: {
      return { ...state, liste: payload, error: null };
    }
    case LISTE_COMMANDE_TYPE_FAILURE: {
      return { ...state, liste: [], error: payload };
    }
    case ADD_COMMANDE_TYPE_SUCCESS: {
      return { ...state, liste: [...state.liste, payload], error: null };
    }
    case ADD_COMMANDE_TYPE_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case DELETE_COMMANDE_TYPE_SUCCESS: {
      return {
        ...state,
        liste: state.liste.filter(
          item => item.iPKCommandeType !== payload.iPKCommandeType
        ),
        error: null
      };
    }
    case DELETE_COMMANDE_TYPE_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    default:
      return state;
  }
};
