import React, { Component } from "react";
import { gql } from "apollo-boost";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Loader, Modal, Segment, Form, Icon } from "semantic-ui-react";

import { fetchApollo } from "../../../../graphql/apolloClient";

import LayoutAdmin from "../../../../components/Admin/Layout";
import BtnSubmit from "../../../../components/Core/Btn/Submit";
import ImportFamilyFormList from "../../../../components/Admin/Catalogue/ImportFamilyFormList";

import { importFamillesCatalogue } from "../../../../redux/actions/catalogue";

import "react-sortable-tree/style.css";

const Title = styled.div`
  color: ${(props) => props.theme.colors.title.link};
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 14px;
  margin-top: 14px;
`;

const ContentFilter = styled.div`
  z-index: 10;
  position: sticky;
  top: 0;
  vertical-align: middle;
  line-height: 60px;
  margin: 0 -20px;
  padding: 0 20px;
  padding-top: 1px;
  background: ${(props) => props.theme.background.light};
`;

const CustomLoader = styled(Loader)`
  position: relative !important;
  top: 50px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  &:before {
    border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
  }
  &:after {
    border-color: #767676 transparent transparent !important;
  }
`;

class CatalogueImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rightPanel: null,
      currentItem: null,
      itemsCheck: {},
      catalogue: {},
      tFamilleCatalogue: [],
      tIsadomFamilleCatalogue: null,
      basetIsadomFamilleCatalogue: [],
      tIsadomFamilleCatalogueChecked: null,
      sKeywordSearch: "",
      open: props.open,
      bConsoImport: true,
      bMatImport: true,
    };
  }

  componentDidMount() {
    const { iPKCatalogue } = this.props;
    this.getCatalogue(parseInt(iPKCatalogue, 10));
  }

  componentDidUpdate(prevProps) {
    const { open, iPKCatalogue } = this.props;
    if (prevProps.open !== open) {
      this.setState({ open });
      if (open) {
        this.getCatalogue(parseInt(iPKCatalogue, 10));
      }
    }
  }

  getCatalogue = (iPKCatalogue) => {
    const GET_CATALOGUE = gql`{
      getCatalogue(iPKCatalogue: ${iPKCatalogue}) {
        iPKCatalogue
        sLibelle
        iFKClient
        sLibelleClient
      }
    }`;

    fetchApollo(GET_CATALOGUE).then((response) => {
      const catalogue = response.data.getCatalogue;
      if (catalogue) {
        this.setState(
          {
            catalogue,
          },
          () => {
            this.getFamillesCatalogue(parseInt(catalogue.iPKCatalogue, 10));
          }
        );
      }
    });
  };

  getFamillesCatalogue = (iPKCatalogue) => {
    const GET_FAMILLE_CATALOGUE = gql`
      {
      listFamilleCatalogue(iPKCatalogue: ${iPKCatalogue}) {
        sPKFamille
        sLibelle
        sInformation
        tSousFamilleCatalogue {
          sPKSousFamille
          sLibelle
          tProduit {
            iPKProduit
            sLibelle
            sRefProduit
            sInformation
            sCodeNatureProduit
            bInactif
            iPKCommandeLigne
            iPKCommandeTypeLigne
            iPKMatPanier
          }
        }
        tProduit {
          iPKProduit
          sLibelle
          sRefProduit
          sInformation
          sCodeNatureProduit
          bInactif
          iPKCommandeLigne
          iPKCommandeTypeLigne
          iPKMatPanier
        }
      }
    }`;
    fetchApollo(GET_FAMILLE_CATALOGUE).then((response) => {
      const tFamilleCatalogue = response.data.listFamilleCatalogue;
      if (tFamilleCatalogue) {
        this.setState(
          {
            tFamilleCatalogue,
          },
          () => {
            this.getIsadomFamillesCatalogue();
          }
        );
      }
    });
  };

  getIsadomFamillesCatalogue = () => {
    const GET_ISADOM_FAMILLE_CATALOGUE = gql`
      {
        listIsadomFamilleCatalogue {
          sPKFamille
          sLibelle
          sInformation
          tSousFamilleCatalogue {
            sPKSousFamille
            sLibelle
            tProduit {
              iPKProduit
              sLibelle
              sRefProduit
              sInformation
              sCodeNatureProduit
              bInactif
            }
          }
          tProduit {
            iPKProduit
            sLibelle
            sRefProduit
            sInformation
            sCodeNatureProduit
            bInactif
          }
        }
      }
    `;
    fetchApollo(GET_ISADOM_FAMILLE_CATALOGUE).then((response) => {
      const tIsadomFamilleCatalogue = response.data.listIsadomFamilleCatalogue;
      if (tIsadomFamilleCatalogue) {
        this.setState(
          {
            tIsadomFamilleCatalogue,
            basetIsadomFamilleCatalogue: tIsadomFamilleCatalogue,
          },
          () => {
            this.setState({
              tIsadomFamilleCatalogueChecked: this.createList(),
            });
          }
        );
      }
    });
  };

  createList = () => {
    const { tIsadomFamilleCatalogue, tFamilleCatalogue } = this.state;
    return (
      tIsadomFamilleCatalogue &&
      tFamilleCatalogue &&
      tIsadomFamilleCatalogue.map((isaObject) => {
        const familleCatalogue = tFamilleCatalogue.filter(
          (famille) => isaObject.sPKFamille === famille.sPKFamille
        )[0];
        if (familleCatalogue) {
          isaObject.tProduit.forEach((isaProduit, index) => {
            const produitCatalogue = familleCatalogue.tProduit.filter(
              (produit) => isaProduit.sRefProduit === produit.sRefProduit
            )[0];
            if (produitCatalogue) {
              isaObject.tProduit[index] = { ...isaProduit, active: 1 };
            }
          });
          isaObject.tSousFamilleCatalogue.forEach((isaSousFamille, index) => {
            const sousFamilleCatalogue = familleCatalogue.tSousFamilleCatalogue.filter(
              (sousFamille) => isaSousFamille.sPKSousFamille === sousFamille.sPKSousFamille
            )[0];
            if (sousFamilleCatalogue) {
              isaSousFamille.tProduit &&
                isaSousFamille.tProduit.forEach((isaProduit, index2) => {
                  const produitCatalogue =
                    sousFamilleCatalogue.tProduit &&
                    sousFamilleCatalogue.tProduit.filter(
                      (produit) => isaProduit.sRefProduit === produit.sRefProduit
                    )[0];
                  if (produitCatalogue) {
                    isaSousFamille.tProduit[index2] = {
                      ...isaProduit,
                      active: 1,
                    };
                  }
                });
              isaObject.tSousFamilleCatalogue[index] = {
                ...isaSousFamille,
                active: 1,
              };
            }
          });
          return { ...isaObject, active: 1 };
        }
        return isaObject;
      })
    );
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { catalogue, itemsCheck } = this.state;
    dispatch(importFamillesCatalogue(itemsCheck, catalogue.iPKCatalogue));
  };

  handleSearchFilterChange = (evt, data) => {
    const { basetIsadomFamilleCatalogue } = this.state;
    this.setState(
      {
        [data.id]: data.value,
      },
      () => {
        const { sKeywordSearchImport } = this.state;
        const newFamillesCatalogue = [];
        basetIsadomFamilleCatalogue.forEach((f) => {
          let newSFProduits = [];
          const newSFFamillesCatalogue = [];
          if (f.tSousFamilleCatalogue.length > 0) {
            f.tSousFamilleCatalogue.forEach((sf) => {
              newSFProduits =
                sf.tProduit &&
                (sf.tProduit.filter((sp) => sp.sLibelle.includes(sKeywordSearchImport)) ||
                  sf.tProduit.filter((sp) =>
                    sp.sLibelle.toLowerCase().includes(sKeywordSearchImport)
                  ) ||
                  sf.tProduit.filter((sp) =>
                    sp.sLibelle
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .includes(sKeywordSearchImport)
                  ) ||
                  sf.tProduit.filter((sp) =>
                    sp.sLibelle
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .includes(sKeywordSearchImport)
                  ));
              if (newSFProduits && newSFProduits.length > 0) {
                newSFFamillesCatalogue.push({ ...sf, tProduit: newSFProduits });
              } else if (
                sf.sLibelle.includes(sKeywordSearchImport) ||
                sf.sLibelle
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(sKeywordSearchImport) ||
                sf.sLibelle.toLowerCase().includes(sKeywordSearchImport) ||
                sf.sLibelle
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(sKeywordSearchImport)
              ) {
                newSFFamillesCatalogue.push(sf);
              }
            });
          }
          const newProduits = f.tProduit.filter(
            (p) =>
              p.sLibelle.includes(sKeywordSearchImport) ||
              p.sLibelle
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(sKeywordSearchImport) ||
              p.sLibelle.toLowerCase().includes(sKeywordSearchImport) ||
              p.sLibelle
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(sKeywordSearchImport)
          );
          if (newProduits.length > 0) {
            newFamillesCatalogue.push({
              ...f,
              tProduit: newProduits,
              tSousFamilleCatalogue: newSFFamillesCatalogue,
            });
          } else if (
            f.sLibelle.includes(sKeywordSearchImport) ||
            f.sLibelle
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(sKeywordSearchImport) ||
            f.sLibelle.toLowerCase().includes(sKeywordSearchImport) ||
            f.sLibelle
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(sKeywordSearchImport)
          ) {
            newFamillesCatalogue.push(f);
          } else if (newSFFamillesCatalogue.length > 0) {
            newFamillesCatalogue.push({
              ...f,
              tSousFamilleCatalogue: newSFFamillesCatalogue,
            });
          }
        });

        this.setState({ tIsadomFamilleCatalogue: newFamillesCatalogue }, () => {
          this.setState({
            tIsadomFamilleCatalogueChecked: this.createList(),
          });
        });
      }
    );
  };

  handleImportFilterChange = (evt, data) => {
    const { basetIsadomFamilleCatalogue } = this.state;
    this.setState(
      {
        [data.id]: data.checked,
      },
      () => {
        const { bConsoImport, bMatImport } = this.state;
        const newFamillesCatalogue = [];
        basetIsadomFamilleCatalogue.forEach((f) => {
          let newSFProduits = [];
          const newSFFamillesCatalogue = [];
          if (f.tSousFamilleCatalogue.length > 0) {
            f.tSousFamilleCatalogue.forEach((sf) => {
              newSFProduits =
                sf.tProduit &&
                sf.tProduit.filter(
                  (sp) =>
                    (bMatImport && sp.sCodeNatureProduit === "M") ||
                    (bConsoImport && sp.sCodeNatureProduit === "C")
                );
              if (newSFProduits && newSFProduits.length > 0) {
                newSFFamillesCatalogue.push({ ...sf, tProduit: newSFProduits });
              }
            });
          }
          const newProduits = f.tProduit.filter(
            (p) =>
              (bMatImport && p.sCodeNatureProduit === "M") ||
              (bConsoImport && p.sCodeNatureProduit === "C")
          );
          if (newProduits.length > 0 || (newSFProduits && newSFProduits.length > 0)) {
            newFamillesCatalogue.push({
              ...f,
              tProduit: newProduits,
              tSousFamilleCatalogue: newSFFamillesCatalogue,
            });
          }
        });

        this.setState({ tIsadomFamilleCatalogue: newFamillesCatalogue }, () => {
          this.setState({
            tIsadomFamilleCatalogueChecked: this.createList(),
          });
        });
      }
    );
  };

  setProduits = (item, nodeProduit) => {
    this.setState({
      rightPanel: { width: 400, renderHtml: [nodeProduit] },
      currentItem: item,
    });
  };

  receiveItemsCheck = (itemsCheck) => {
    this.setState({ itemsCheck });
  };

  render() {
    const {
      rightPanel,
      bConsoImport,
      bMatImport,
      currentItem,
      tIsadomFamilleCatalogueChecked,
      sKeywordSearchImport,
      open,
    } = this.state;

    const { onClose } = this.props;

    return (
      <Modal
        style={{
          width: '90%',
          height: window.innerHeight - 110
        }}
        closeIcon
        onClose={onClose}
        open={open}
      >
        <Modal.Content>
          <LayoutAdmin tiny title="import Isadom" rightPanel={rightPanel}>
            <>
              <Title>Importer des familles de produits</Title>
              <p>
                Sélectionnez les familles, sous familles et produits que vous désirez ajouter à
                votre catalogue.
              </p>
              <>
                <ContentFilter>
                  <Segment
                    style={{
                      display: "flex",
                      paddingTop: 10,
                      paddingBottom: 0,
                      lineHeight: "40px",
                      marginTop: 10,
                    }}
                    compact
                  >
                    <Form>
                      <Form.Group inline>
                        <label>Type de produit :</label>
                        <Form.Checkbox
                          label={
                            <label>
                              Consommable <Icon name="file" />
                            </label>
                          }
                          id="bConsoImport"
                          checked={bConsoImport}
                          onChange={this.handleImportFilterChange}
                        />
                        <Form.Checkbox
                          label={
                            <label>
                              Matériel <Icon name="laptop" />
                            </label>
                          }
                          id="bMatImport"
                          checked={bMatImport}
                          onChange={this.handleImportFilterChange}
                        />
                        <Form.Input
                          id="sKeywordSearchImport"
                          type="text"
                          icon="search"
                          iconPosition="left"
                          style={{ width: 250 }}
                          value={sKeywordSearchImport}
                          placeholder="Recherche"
                          onChange={this.handleSearchFilterChange}
                        />
                      </Form.Group>
                    </Form>
                  </Segment>
                </ContentFilter>
                {tIsadomFamilleCatalogueChecked ? (
                  <ImportFamilyFormList
                    famillesCatalogue={tIsadomFamilleCatalogueChecked}
                    handleSubmit={this.handleSubmit}
                    setProduits={this.setProduits}
                    currentItem={currentItem}
                    sendItemsCheck={this.receiveItemsCheck}
                    importForm
                  />
                ) : (
                  <CustomLoader active />
                )}
              </>
            </>
          </LayoutAdmin>
        </Modal.Content>
        <Modal.Actions>
          <BtnSubmit style={{ float: "right" }} onClick={this.handleSubmit} primary size="small">
            Importer les familles et produits sélectionnés
          </BtnSubmit>
        </Modal.Actions>
      </Modal>
    );
  }
}

CatalogueImport.defaultProps = {
  open: false,
};

CatalogueImport.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKCatalogue: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  error: state.group.error,
});

export default connect(mapStateToProps)(CatalogueImport);
