// import jwtDecode from "jwt-decode";
import {
  GET_PANIER_SUCCESS,
  GET_PANIER_FAILURE,
  ADD_PANIER_SUCCESS,
  ADD_PANIER_FAILURE,
  EDIT_PANIER_SUCCESS,
  EDIT_PANIER_FAILURE,
  DELETE_PANIER_SUCCESS,
  DELETE_PANIER_FAILURE,
  VALIDE_PANIER_SUCCESS,
  VALIDE_PANIER_FAILURE
} from "../constants/ActionTypes";

const initialAuthState = {
  liste: [],
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case GET_PANIER_SUCCESS: {
      return { ...state, liste: payload, timestamp: Date.now(), error: null, success: null };
    }
    case GET_PANIER_FAILURE: {
      return { ...initialAuthState, timestamp: Date.now(), error: payload };
    }
    case ADD_PANIER_SUCCESS: {
      return { ...state, liste: [...state.liste, payload], timestamp: Date.now(), error: null };
    }
    case ADD_PANIER_FAILURE: {
      return {
        ...state,
        timestamp: Date.now(),
        error: payload.error
      };
    }
    case EDIT_PANIER_SUCCESS: {
      return {
        ...state,
        liste: state.liste.map(item =>
          item.iPKMatPanier === payload.iPKMatPanier ? payload : item
        ),
        timestamp: Date.now(),
        error: null
      };
    }
    case EDIT_PANIER_FAILURE: {
      return {
        ...state,
        timestamp: Date.now(),
        error: payload.error
      };
    }
    case DELETE_PANIER_SUCCESS: {
      return {
        ...state,
        liste: state.liste.filter(item => item.iPKMatPanier !== payload.iPKMatPanier),
        timestamp: Date.now(),
        error: null
      };
    }
    case DELETE_PANIER_FAILURE: {
      return {
        ...state,
        error: !Number.isInteger(payload)
          ? `${Math.floor(Math.random() * 10 + 1)} Le client possède un ou plusieur groupe !`
          : null,
        timestamp: Date.now()
      };
    }
    case VALIDE_PANIER_SUCCESS: {
      return { ...state, liste: [], error: null, timestamp: Date.now(), success: true };
    }
    case VALIDE_PANIER_FAILURE: {
      return { ...state, error: true, timestamp: Date.now(), success: null };
    }
    default:
      return state;
  }
};
