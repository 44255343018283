import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

const Btn = styled(Link)`
  display: inline-block;
  margin: 5px 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
`;

const BtnIcon = styled(Icon)`
  color: ${props => props.theme.colors.white} !important;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.secondary.hover} !important;
  }
`;

const BtnSignOut = () => (
  <Btn to="/deconnexion">
    <BtnIcon name="power off" />
  </Btn>
);

export default BtnSignOut;
