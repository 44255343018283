import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { Form, Message, Card } from "semantic-ui-react";

import { addCatalogue } from "../../../redux/actions/catalogue";
import { getClientsWithoutCatalogue } from "../../../redux/actions/client";

import BtnSubmit from "../../Core/Btn/Submit";

import "react-sortable-tree/style.css";

const FormTitle = styled.div`
  padding: 0.5em 0;
  margin-bottom: 10px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: ${props => props.theme.colors.title.main};
  font-weight: bold;
  text-transform: uppercase;
`;

class FormCatalogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sLibelle: "",
      iPKClient: null,
      message: null,
      errors: {},
      disabled: true
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getClientsWithoutCatalogue());
  }

  handleChange = (evt, data) => {
    const { errors } = this.state;
    const error = { ...errors, [data.id]: false };
    this.setState({ [data.id]: data.value, error, disabled: false });
  };

  handleSubmit = e => {
    const { dispatch, reloadList } = this.props;
    const { sLibelle } = this.state;
    e.stopPropagation();
    e.preventDefault();
    const errors = {};
    if (sLibelle.length === 0) {
      errors.sLibelle = "Champ obligatoire";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      dispatch(addCatalogue(this.state));
      this.setState({
        disabled: true,
        iPKClient: null,
        sLibelle: "",
        errors: {},
        message: "Catalogue bien ajouté"
      });
      setTimeout(() => reloadList(), 500);
    }
  };

  render() {
    const { tClient } = this.props;
    const { sLibelle, iPKClient, errors, disabled, message } = this.state;
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <FormTitle>Création d'un catalogue</FormTitle>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Form.Input
                fluid
                id="sLibelle"
                label="Nom"
                value={sLibelle}
                placeholder="Nom"
                error={errors.sLibelle ? { content: errors.sLibelle, pointing: "below" } : false}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.Select
                fluid
                id="iPKClient"
                label="Client"
                value={iPKClient}
                options={tClient.map(c => ({
                  key: parseInt(c.iPKClient, 10),
                  text: c.sLibelle,
                  value: parseInt(c.iPKClient, 10)
                }))}
                error={errors.iPKClient ? { content: errors.iPKClient, pointing: "below" } : false}
                clearable
                placeholder="Client"
                onChange={this.handleChange}
              />
            </Form.Field>
            {message && (
              <Message fluid positive>
                {message}
              </Message>
            )}
            <BtnSubmit disabled={disabled} onClick={this.handleSubmit} type="submit">
              Enregistrer
            </BtnSubmit>{" "}
          </Form>
        </Card.Content>
      </Card>
    );
  }
}

FormCatalogue.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reloadList: PropTypes.func.isRequired,
  tClient: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  tClient: state.client.liste
});

export default connect(mapStateToProps)(FormCatalogue);
